import AttestatiInput from "../components/inputs/customInputs/AttestatiInput"

function getCustomInput(inputType, inputProps) {
	switch (inputType) {
		case "attestati" :
			return <AttestatiInput {...inputProps}></AttestatiInput>
		default:
	}
}

export default getCustomInput