import React, { useEffect, useState } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import TextArea from '../../components/inputs/TextArea'
import TextField from '../../components/inputs/TextField'
import Footer from '../../components/layout/Footer'
import Header from '../../components/layout/Header'
import { useUser } from '../../context/UserContext'
import { getUrl } from '../../utils/functions'
import { useAuth } from '../../context/AuthContext'
import { useEvents } from '../../context/EventsContext'
import SearchQueryByProgramma from '../../components/sponsors/SearchQueryByProgramma.js'
import { v4 as uuid } from "uuid"
import swal from 'sweetalert';
import AdminNavbar from '../../components/layout/AdminNavbar';



const fetchBookingStats = async (api, ids, setSponsorBookedSeats) => {
    try {
        let data = await api(getUrl("booking_admin", `sponsor_booking/booking_stats/${ids}`))
        setSponsorBookedSeats(data)

    } catch (error) {

    }
}

const fetchSponsorAndEventsById = async (api, id, setSponsorEvents, setValues, callBack) => {
    const params = new URLSearchParams()// {skip: 0, limit: 1,...}

    const limit = 1
    params.set("skip", 0)
    params.set("limit", limit)
    params.set("id", id)
    try {
        setSponsorEvents((oldState) => ({ ...oldState, loading: true }))
        let data = await api(getUrl("booking_admin", `sponsor_booking/booking?${params.toString()}`))

        setSponsorEvents({
            status: true,
            data: data,
            loading: false,
            error: null
        })
        setValues(data?.[0])
        if (data?.[0] != null) {
            callBack(data[0])
        }
    } catch (error) {
        setSponsorEvents({
            status: false,
            data: null,
            loading: false,
            error: error?.response?.data?.detali?.[0]?.msg ?? "An error occured by the server"
        })
    }
}


function NewSponsorEvent() {
    const { id } = useParams()
    const { api } = useAuth()
    const history = useHistory()
    const { fetchEvents } = useEvents()
    const [values, setValues] = useState({})
    const [eventValues, setEventValues] = useState({})
    const [sponsorEvents, setSponsorEvents] = useState({
        status: false,
        data: null,
        loading: false,
        error: null
    })
    const [SponsorBookedStats, setSponsorBookedSeats] = useState(null)

    const handleOnChange = (event) => {
        setValues(oldValues => ({ ...oldValues, [event.target.name]: event.target.value }))
        console.log(values)
    }

    useEffect(() => {
        if (id !== "new") {
            fetchSponsorAndEventsById(api, id, setSponsorEvents, setValues)
        }
    }, [id])

    useEffect(() => {
        if (values.event_id) {
            fetchBookingStats(api, values.event_id, setSponsorBookedSeats)
        }
    }, [values.event_id, SponsorBookedStats?.[0]?.sponsor_booked_seats])

    useEffect(() => {
        if (values.event_id) {
            const handler = async (event_id) => {
                let [event] = await fetchEvents([event_id])
                if (event != null) {
                    setEventValues({
                        event_title: event.title,
                        event_time: event.time,
                        event_date: event.date,
                        event_location: event.locationData?.name,
                        event_location_capacity: event.locationData?.capacity,
                        event_bookable_places: event.bookable_places,
                        event_available_seat: (event.locationData?.capacity - event.bookable_places) - SponsorBookedStats?.[0]?.sponsor_booked_seats,
                        event_id : event_id
                    })
                }
            }
            handler(values.event_id)
            //fetchBookingStats(api, values.event_id, setSponsorBookedSeats)

        } else {
            setEventValues({})
        }
    }, [values.event_id, SponsorBookedStats?.[0]?.sponsor_booked_seats])



    const deleteSponsorEvent = () => {

        swal({
            title: "Sei sicuro di voler eliminare questa prenotazione?",
            text: "",
            icon: "warning",
            buttons: ["NO", "Si"],
        })
            .then(async (value) => {
                if (value == null) {
                    return;
                }
                const body = { ...values };
                //delete body.type;
                try {
                    let response = await api(getUrl("booking_admin", "sponsor_booking/booking"), "delete", {}, {
                        data: body
                    });
                    // if (response?.success === false) {
                    //     setError(response?.message?.delete);
                    //     return
                    // }
                    history.push("/sponsor");
                } catch (error) {
                    setSponsorEvents({
                        status: false,
                        data: null,
                        loading: false,
                        error: error?.response?.data?.detail?.[0]?.msg ?? "Unkown error occured!"
                    })
                }
            })
    }

    const handleOnSubmit = async () => { //values={name: "m", last_nmae: , number: "1"}
        const body = Object.keys(values).reduce((preValues, key) => {
            if (values[key] !== "" && values[key] != null) {
                const value = key === "number_of_participants" ? +values[key] : values[key]
                preValues[key] = value
            }
            return preValues
        }, {})
        body.participants = { ...body.participants }

        let participantsAlreadyExist = Object.keys(body?.participants).length

        if (participantsAlreadyExist === values.number_of_participants) {
            //delete body.id

        } else if (participantsAlreadyExist < values.number_of_participants) {
            //delete body.id
            let differenceNumber = values.number_of_participants - participantsAlreadyExist
            for (let i = 0; i < differenceNumber; i++) {
                const id = uuid();//create a unique random id for new participant
                body.participants[id] = {
                    access: "",
                    id: id,
                    pdfURL: process.env.REACT_APP_AZURE_BLOB_BASE_URL + `${id}` + ".pdf",
                    type: "Sponsor"
                }
            }
        } else if (participantsAlreadyExist > values.number_of_participants) {
            //delete body.id
            //body.participants = {}
            let differenceNumber = participantsAlreadyExist - values.number_of_participants
            let participants = { ...body.participants }
            for (let i = 0; i < differenceNumber; i++) {
                let keys = Object.keys(participants)
                let index = Math.floor(Math.random() * keys.length)
                delete participants[keys[index]]
            }
            body.participants = participants
        }

        try {
            if (id === "new") {
                //body["id"] = uuid();
                body["access_number"] = 0
            }
            body["event_id"] = values.event_id;
            let response = await api(getUrl("booking_admin", "sponsor_booking/booking"), "post", body)
            if (response?.success === true) {
                history.push("/sponsor")
            }
        } catch (error) {
            setSponsorEvents({
                status: false,
                data: null,
                loading: false,
                error: error?.response?.data?.detail?.[0]?.msg ?? "Unkown error occured!"
            })
        }
    }

    // let availableSeats = eventValues?.event_available_seat;
    // if (values.number_of_participants) {
    //     availableSeats = availableSeats - values.number_of_participants
    // }
    return (
        <div>
            <Header />
            <div className="admin-pages-main" style={{ minHeight: "68vh" }}>
            <AdminNavbar back="/sponsor" title="Sponsor e case editrici" home={true}/>

                <div className="container-fluid mt-1">
                    <div className="row">
                        <TextField
                            label="Nome Azienda"
                            className="col-md-4"
                            name="company_name"
                            value={values.company_name}
                            onChange={handleOnChange}
                            type="text"
                            required={false} />
                        <TextField
                            label="Email"
                            className="col-md-4"
                            name="email"
                            value={values.email}
                            onChange={handleOnChange}
                            type="text"
                            required={false} />
                    </div>
                    <div className="row">
                        <TextField
                            label="Nome"
                            className="col-md-4"
                            name="first_name"
                            value={values.first_name}
                            onChange={handleOnChange}
                            type="text"
                            required={false} />
                        <TextField
                            label="Cognome"
                            className="col-md-4"
                            name="last_name"
                            value={values.last_name}
                            onChange={handleOnChange}
                            type="text"
                            required={false} />
                    </div>
                    <div className="row">
                        <SearchQueryByProgramma
                            label="Evento"
                            className="col-md-4"
                            name="event_id"
                            value={values.event_id}
                            onChange={handleOnChange}
                            type="text"
                            required={false}
                            fetchEvents={fetchEvents} />
                        {/* <TextField
                            label="Evento"
                            className="col-md-4"
                            name="event_id"
                            value={values.event_id}
                            onChange={handleOnChange}
                            type="text"
                            required={false}/> */}
                        <TextField
                            label="Numero prenotazioni"
                            className="col-md-4"
                            name="number_of_participants"
                            value={values.number_of_participants}
                            onChange={handleOnChange}
                            type="text"
                            required={false} />
                    </div>
                    <div className="row">
                        <TextField
                            label="Data"
                            className="col-md-2"
                            name="event_date"
                            value={eventValues.event_date}
                            type="date"
                            required={false}
                            disabled={true} />
                        <TextField
                            label="Ora"
                            className="col-md-2"
                            name="event_time"
                            value={eventValues.event_time}
                            type="time"
                            required={false}
                            disabled={true} />
                        <TextField
                            label="Sala"
                            className="col-md-2"
                            name="event_location"
                            value={eventValues.event_location}
                            type="text"
                            required={false}
                            disabled={true} />
                        <TextField
                            label="Posti disponibili"
                            className="col-md-2"
                            name="event_location"
                            value={eventValues?.event_available_seat ?? ""}
                            type="text"
                            required={false}
                            disabled={true} />
                    </div>

                    <div className="row">
                        <TextArea
                            label="Note"
                            className="col-md-8"
                            name="note"
                            value={values.note}
                            onChange={handleOnChange}
                            type="text"
                            required={false} />
                    </div>
                    <div className="row mt-3">
                        <div className="col-auto">
                            <Link to="/sponsor" className="btn btn-primary mb-3">Torna alla pagina</Link>
                        </div>
                        <div className="col-auto">
                            {id !== "new" ? (<button
                                className="btn btn-primary"
                                onClick={deleteSponsorEvent}
                            >
                                Elimina
                            </button>) : ""}
                        </div>
                        <div className="col-auto">
                            <button
                                className="btn btn-primary"
                                onClick={handleOnSubmit}
                                disabled={values.number_of_participants > eventValues?.event_available_seat ? "disabled" : ""}
                            >
                                Salva
                            </button>
                        </div>

                    </div>
                </div >
            </div >
            <Footer />
        </div >


    )
}

export default NewSponsorEvent
