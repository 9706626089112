import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import '../../css/deletebookingcontents.css';
import { getUrl } from '../../utils/functions';

function DeleteBookingContents({ setShowModal, data, refreshBooking }) {

    const { api } = useAuth();
    const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const locationData = useLocation();
    const history = useHistory();

    const deletBookedEvent = async () => {
        const body = {...data};
        //delete body.type;
        try {
            let response = await api(getUrl("booking", "bookings/booking/"), "delete", {}, {
                data: body
            });
			if (locationData?.state?.fromBackoffice || locationData?.state?.isAdmin) {
				window.location = "/prenotazione_saltopiu";
			} else {
				history.push("/prenotazioni");
			}
			setSuccess(true);
        } catch (error) {
            setError(error?.response?.data?.detail?.[0]?.msg ?? "Unkown error occured!")
        }
    }
    return (
        <div className="delete-booking">
			{success == null && (				
				<div className="delete-contents">
                	<div className="close-btn-container">
                	    <button onClick={() => setShowModal(false)}>&#10006;</button>
                	</div>
                	<div className="main-text">
                	    <h3 className="text">Sei sicuro di voler eliminare questa prenotazione?</h3>
                	</div>
                	<div className="elimina-info">
                	    {/* <p>
                	        L'eliminazione avrà effettua sulla tua prenotazione e tutti gli ospiti da te invitati.
                	    </p> */}
                	</div>
                	<div className="text-question">
                	    <p>
                	        La prenotazione verrà annullata per tutti i partecipanti.
                	    </p>
                	</div>
                	{error != null && (
                	    <div>
                	        <p className="text-danger">{error}</p>
                	    </div>
                	)}
                	<div className="elimina-btn">
                	    <button onClick={deletBookedEvent}>Elimina prenotazione</button>
                	</div>
                	<div className="back-btn">
                	    <button onClick={() => setShowModal(false)}>No, non eliminare la prenotazione</button>
                	</div>
            	</div>
			)}
			{success == true && (
				<div className='delete-contents'>
					<div className="close-btn-container">
						<button onClick={() => setShowModal(false)}>&#10006;</button>
					</div>
					<div className='title-success'>
						<p>La prenotazione è stata eliminata!</p>
					</div>
					<div className='success-body'>
						<p>Hai rimosso la prenotazione all'evento.{locationData?.state?.fromBackoffice ? "" : "Puoi sempre tornare alla pagine dell'evento e iscriverti di nuovo."}</p>
					</div>
					<div className='success-back'>
						<button onClick={() => {
							if (locationData?.state?.fromBackoffice || locationData?.state?.isAdmin) {
								window.location = "/prenotazione_saltopiu";
							} else {
								refreshBooking();
							}
						}}>{locationData?.state?.fromBackoffice ? "Torna al backoffice" : "Torna alle mie prenotazioni"}</button>
					</div>
				</div>
			)}
        </div>
    )
}

export default DeleteBookingContents
