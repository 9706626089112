import React, { useState, useEffect, useRef } from "react";
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import Sidebar from "../components/layout/Sidebar";
import Main from "../components/layout/Main";
import { useAuth } from "../context/AuthContext";
import { useUser } from "../context/UserContext";
import { getUrl } from "../utils/functions";
import { ReactComponent as Loader } from "../img/loader.svg";
import Form from "react-bootstrap/Form";
import CheckBoxInput from "../components/inputs/CheckBoxInput";
import TextField from "../components/inputs/TextField";
import SelectInput from "../components/inputs/SelectInput";
import { useHistory } from "react-router";
import { getListOfValueJson, getAllCountries, getCitiesOfState, getStatesOfCountry } from "../utils/data";
import SideBarContents from "../components/layout/SideBarContents";

const getDefaultValues = (formfields) => {
	const values = {};
	if (formfields !== null) {
		formfields?.forEach((field) => {
			if (field.type === "bool") {
				values[field.code] = field.value ? true : false;
				return;
			}
			values[field.code] = field.value;
		});
	}
	return values;
};

const fetchPageContents = async (api, setData, setLoading, setFieldValues) => {
	try {
		setLoading(true);
		let data = await api(getUrl("user", "accrediti/getstate"));
		if (data?.form?.formfields != null) {
			data?.form?.formfields?.sort((a, b) => {
				// a, b are one of the objects inside the data.form_fields
				if (a.row === b.row) {
					return a.order < b.order ? -1 : 1;
				}
				return a.row < b.row ? -1 : 1;
			});
		}
		setData(data);
		setLoading(false);
		setFieldValues(getDefaultValues(data?.form?.formfields));
	} catch (error) {
		//setLoading(false);
		setData(null);
	}
};

function Accreditations() {
	const [user, fetchUser] = useUser();
	const { api } = useAuth();
	const history = useHistory();
	const formRef = useRef();
	const [loading, setLoading] = useState(null);
	const [data, setData] = useState(null);
	const [fieldValues, setFieldValues] = useState(getDefaultValues(data?.form?.formfields));
	const [error, setError] = useState(null);
	const [showMessageIntoPopUp, setShowMessageIntoPopUp] = useState(null);

	useEffect(() => {
		fetchPageContents(api, setData, setLoading, setFieldValues);
	}, []);

	const handleOnChange = (event) => {
		setFieldValues((oldValues) => ({ ...oldValues, [event.target.name]: event.target.value }));
		if (event.target.name === "AMBITO_ID") {
			setFieldValues((oldValues) => ({ ...oldValues, PROFESSION_ID: undefined }));
		}
	};

	const sortFields = () => {
		const rows = [];
		let items = [];
		let previousRow = undefined;
		for (let i = 0; i < data?.form?.formfields?.length; i++) {
			if (previousRow == null) {
				previousRow = data?.form?.formfields[i].row;
			}
			if (data?.form?.formfields[i].row !== previousRow) {
				rows.push(items);
				items = [];
				previousRow = data?.form?.formfields[i].row;
				items.push(data?.form?.formfields[i]);
			} else {
				items.push(data?.form?.formfields[i]);
			}
		}
		if (items.length > 0) {
			rows.push(items);
		}
		return rows;
	};
	const rows = sortFields();

	const handleOnModalClick = () => {
		switch (data.button.action) {
			case "send_form":
				if (formRef.current.checkValidity()) {
					submitForm();
				} else {
					formRef.current.reportValidity();
				}
				return;
			case "go_to_external":
				window.open(data.button.link, "_blank");
				return;
			case "go_to_internal":
				history.push(data.button.link);
				return;
			case "refresh":
				//refreshPopUpContents();
				return;
			default:
		}
	};

	const refreshFetchContents = () => {
		fetchPageContents(api, setData, setLoading, setFieldValues);
	};

	const submitForm = async () => {
		// {"COUNTRY": "1005", ...}, [COUNTRY, STATE, ...]

		let values = {};
		Object.keys(fieldValues).forEach((key) => {
			if (["COUNTRY", "STATE", "CITY"].includes(key)) {
				const element = formRef.current.querySelector(`select[name="${key}"]`);
				if (element) {
					const name = element.querySelector(`option[value="${fieldValues[key]}"]`).getAttribute("data-name");
					values[key] = name;
					return;
				}
			}
			values[key] = fieldValues[key];
		});
		let body = data.form.api.body;
		if (data.form.api.body) {
			//"{"birthdate:", ...}", now body is like a string
			Object.keys(values).forEach((key) => {
				body = body.replace(key, values[key]);
			});
			//debugger;
			body = JSON.parse(body);
		}
		let params = data.form.api.params;
		if (data.form.api.params) {
			Object.keys(values).forEach((key) => {
				params = params.replace(key, values[key]);
			});
			// params = JSON.parse(params)
		}
		let url;
		if (body !== null) {
			url = data.form.api.baseurl;
		}
		if (params !== null) {
			url = `${data.form.api.baseurl}?${params}`;
		}
		try {
			let response = await api(url, data.form.api.method.toLowerCase(), body);
			if (response?.success === false) {
				setShowMessageIntoPopUp(response?.message?.error);
				return;
			} else if (response?.success === true && response?.message?.create_new === "Prenotazione confermata") {
				history.push("/prenotazioni");
				return;
			} else if (response?.status?.success === true) {
				refreshFetchContents();
			}
			setFieldValues({});
			fetchUser();
		} catch (error) {
			setError(error?.response?.detail ?? "An error occured by the server");
		}
		//refreshPopUpContents();
	};

	return (
		<div>
			<Header />
			{/* <div className="row w-100" style={{ minHeight: "68vh" }}> */}
			<div className="page-body">
				<Sidebar>
					{/* <p>In questo spazio trovi il meglio della produzione culturale curata dal Salone: video, interviste, lezioni, podcast, approfondimenti. Una grande libreria a tua disposizione: scegli un contenuto e inizia il viaggio.</p> */}
					<SideBarContents />
				</Sidebar>
				<div className="separating-line"></div>
				<Main>
					{loading && (
						<div className="loading fixed">
							<Loader style={{ width: "130px", height: "130px" }} />{" "}
						</div>
					)}

					<div className="accreditation-page">
						<div className="title">
							<h1>{data?.header?.title}</h1>
						</div>
						<div className="text-body">
							<h3 dangerouslySetInnerHTML={{ __html: data?.header?.body }}></h3>
							{showMessageIntoPopUp != null && <p className="text-uppercase">{showMessageIntoPopUp}</p>}
							{error != null && <p className="text-danger">{error}</p>}
						</div>
						{loading === false && data?.form?.formfields && (
							<Form ref={formRef}>
								{rows.map((row) => {
									return (
										<div className="row">
											{row.map((item) => {
												if (item.LOV.length !== 0 || item.LOV_json) {
													let options = [];
													if (item.LOV.length !== 0) {
														options = item.LOV.map((option) => {
															return { id: option, name: option };
														});
													} else {
														if (item.LOV_json === "country.json") {
															options = getAllCountries();
														} else if (item.LOV_json === "state.json") {
															let country = data.form.formfields.find(
																(field) => field.LOV_json === "country.json"
															);
															options = getStatesOfCountry(fieldValues[country.code]);
														} else if (item.LOV_json === "city.json") {
															let state = data.form.formfields.find(
																(field) => field.LOV_json === "state.json"
															);
															options = getCitiesOfState(fieldValues[state.code]);
														} else if (item.LOV_json === "profession.json") {
															let data = getListOfValueJson("profession.json");
															let ambitos = {};
															data.forEach((profession) => {
																if (ambitos[profession.group] == null) {
																	ambitos[profession.group] = profession.group;
																}
															});
															ambitos = Object.keys(ambitos);
															if (item.code === "AMBITO_ID") {
																options = ambitos.map((ambito) => ({ id: ambito, name: ambito }));
															}
															if (item.code === "PROFESSION_ID") {
																options = data
																	.filter(
																		(profession) => profession.group === fieldValues["AMBITO_ID"]
																	)
																	.map((profession) => ({
																		name: profession.name,
																		id: profession.id,
																	}));
															}
														} else {
															options = getListOfValueJson(item.LOV_json);
														}
													}
													return (
														<SelectInput
															className={
																row.length === 2 ? "col-sm-6" : row.length === 3 ? "col-sm-4" : "col"
															}
															name={item.code}
															label={item.label}
															required={item.mandatory}
															type="text"
															options={options}
															disabled={!item.editable}
															onChange={(event) => {
																handleOnChange(event);
															}}
															value={fieldValues[item.code]}
															placeholder={item.placeholder}
														/>
													);
												} else if (item.type === "bool") {
													return (
														<CheckBoxInput
															className={
																row.length === 2 ? "col-sm-6" : row.length === 3 ? "col-sm-4" : "col"
															}
															name={item.code}
															label={item.label}
															required={item.mandatory}
															disabled={!item.editable}
															onChange={(event) => {
																setFieldValues((oldValues) => ({
																	...oldValues,
																	[event.target.name]: event.target.checked ? true : false,
																}));
															}}
															value={fieldValues[item.code]}
														/>
													);
												} else {
													return (
														<TextField
															className={
																row.length === 2 ? "col-sm-6" : row.length === 3 ? "col-sm-4" : "col"
															}
															name={item.code}
															label={item.label}
															required={item.mandatory}
															disabled={!item.editable}
															//type={item.type}
															type={item.type === "datetime" ? "date" : "text"}
															onChange={handleOnChange}
															value={fieldValues[item.code]}
														/>
													);
												}
											})}
										</div>
									);
								})}
							</Form>
						)}
						{data?.button != null && (
							<div className="button-action mt-4">
								<button
									className="blue-btn"
									onClick={handleOnModalClick}>
									{data?.button?.text}
								</button>
							</div>
						)}
					</div>
				</Main>
			</div>
			<Footer />
		</div>
	);
}

export default Accreditations;
