import React, { useContext, createContext, useState } from 'react'
import { getUrl } from '../utils/functions'
import { useAuth } from './AuthContext'

const EventsContext = createContext(null)

function EventsProvider({ children }) {
    const [events, setEvents] = useState({})
    const {api} = useAuth()

    // const [isFetchingEvents, setIsFetchingEvents] = useState()

    const fetchEvents = async (eventsId, loadingCallBack = () => { }) => {
        const requiredEventsId = eventsId.filter((id) => events[id] == null)//[1, 2, 3, ...]
        loadingCallBack(true);
        let eventsResponse = await Promise.all(requiredEventsId.map(async (eventId) => {
            try {
                let data = await api(getUrl("programma", `api/v1/bookable-program-items/${eventId}`));
                const requests = [];
                // const authors = [];
                // const exhibitors = [];
                 let location;
                 requests.push(async () => {
                     location = await api(getUrl("programma", `api/v1/locations/${data?.data?.location}`));
                 })
                // requests.push(...(data?.data?.authors?.map((author) => {
                //     return async () => {
                //         const result = await api(getUrl("programma", `api/v1/authors/${author}`));
                //         authors.push(result);
                //     }
                // })));
                // requests.push(...(data?.data?.exhibitors?.map((exhibitor) => {
                //     return async () => {
                //         const result = await api(getUrl("programma", `api/v1/exhibitors/${exhibitor}`));
                //         exhibitors.push(result);
                //     }
                // })));
                 await Promise.all(requests.map(async (request) => await request()));
                // setEventInfo({
                //     data: data?.data,
                //     location: location?.data,
                //     exhibitors: exhibitors,
                //     authors: authors,
                // });
                return {...data?.data, locationData: location?.data} // {id:..., event_name:..., locationData: {locationId: ..., locationName: ...,...}, ...}
            } catch (error) {
                return null
            }
        })) // [{event1}, {event2}, null, ...] 
        loadingCallBack(false)
        const newEvents = { ...events }
        console.log(eventsResponse)
        eventsResponse.filter((event) => event != null).forEach((event) => newEvents[event.id] = event)//{1: {event1}, 2: {event2}, ..}
        setEvents(newEvents)
        return eventsId.map((eventId) => newEvents[eventId])
    }
    return (
        <EventsContext.Provider value={{events, fetchEvents}}>
            {children}
        </EventsContext.Provider>
    )
}

const useEvents = () => {
    const context = useContext(EventsContext)
    return context
}

export {EventsProvider, EventsContext, useEvents }
