import React, { Component, useEffect } from 'react'
import Carousel from 'react-bootstrap/Carousel'
import 'bootstrap/dist/css/bootstrap.min.css';

function BootstrapCarousel(props) {

        const [contents, setContents] = React.useState();

        useEffect(() => {
                let slideToRender = [];
                let contentToRender;
                let items = props.items;

                let compPerSlide;
                if (window.screen.width > 768) {
                        compPerSlide = 3;
                }
                else {
                        compPerSlide = 1;
                }
                let slideCnt = items.length % compPerSlide === 0 ? items.length / compPerSlide : parseInt(items.length / compPerSlide + 1);

                for (let i = 0; i < slideCnt; i++) {
                        contentToRender = [];
                        let j = 0, k = 0;
                        for (j = compPerSlide * i + j; k < compPerSlide && j < items.length; j++) {
                                k++;
                                contentToRender.push(items[j]);
                        }

                        if (compPerSlide === 3) {
                                slideToRender.push(
                                        <Carousel.Item key={i}>
                                                <div className="row main-padding">
                                                        {contentToRender[0] === undefined ? "" : contentToRender[0]}
                                                        {contentToRender[1] === undefined ? "" : contentToRender[1]}
                                                        {contentToRender[2] === undefined ? "" : contentToRender[2]}
                                                </div>
                                        </Carousel.Item>
        
                                );
                        }
                        else {
                                slideToRender.push(
                                        <Carousel.Item key={i}>
                                                <div className="row main-padding">
                                                        {contentToRender[0] === undefined ? "" : contentToRender[0]}
                                                </div>
                                        </Carousel.Item>
        
                                );
                        }
                        
                }

                setContents(slideToRender);
        }, [props.items]);

        return (
                <div className="w-100">
                        <div className='container-fluid' >
                                <Carousel>
                                        {contents}
                                </Carousel>
                        </div>
                </div>
        )
}
export default BootstrapCarousel  