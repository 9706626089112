import React, { useState, useEffect, useRef } from 'react'
import { useHistory, Link } from 'react-router-dom';
import '../../css/eventCard.css'
import BookingPopUp from '../BookingsPopUp';
import SmallPopUpHatched from '../SmallPopUpHatched';
import DeleteBookingContents from './DeleteBookingContents';

import add_icon from "../../img/prenotazione_icons/omino.svg"
import delete_icon from "../../img/prenotazione_icons/elimina.svg"
import forward_icon from "../../img/prenotazione_icons/Arrow.svg"
import EventAddPeopleModal from './EventAddPeopleModal';
import checkmark from "../../img/checkmark.svg"

function EventCard({
	size,
	color,
	data_evento,
	ora,
	luogo_sala,
	citta_padiglione,
	titolo,
	sottotitolo,
	bookedIdEvent,
	eventId,
	fullBookingData,
	refreshBooking,
	snooze
}){

	const [showDeleteParticipantModal, setShowDeleteParticipantModal] = useState(false);
	const [showAddPeopleModal, setShowAddPeopleModal] = useState(false);
	const bookingData = {
		data_evento,
		ora,
		luogo_sala,
		citta_padiglione,
		titolo,
		sottotitolo,
		bookedIdEvent,
		eventId
	};

	const conditionalAddPeople = function() {
		if (fullBookingData.type === "Minori" || fullBookingData.type === "Standard") {
			if (color  === "red") {
				return <img src={add_icon} className='aggiungi-body' style={{"visibility" : "hidden"}}></img>
			} else if (Object.keys(fullBookingData.participants).length >= 4 && fullBookingData.type === "Minori") {
				return (<img src={""} className="aggiungi-body"></img>);
			} else if (Object.keys(fullBookingData.participants).length >= 2 && fullBookingData.type === "Standard") {
				return (<img src={""} className="aggiungi-body"></img>);
			} else {
				return (<img src={add_icon} className='aggiungi-body'onClick={()=>{
					setShowAddPeopleModal(true);
				}}/>);
			}
		}
	}

	const colors = (baseColor) => {
		if (baseColor === "#fc0") {
			return {
				"backgroundColor" : baseColor,
				"color" : "black"
			}
		} else {
			return {
				"backgroundColor" : baseColor,
				"color" : "white"
			}
		}
	}

	return(
		<div className={'card-container ' + (size === "wide" ? "card-container-wide" : "card-container-small")}>
				<div className='side-color' style={colors(color)} onClick={color === "red" ? () => {
					console.log("");
				} : () => {
					snooze()
				}}>
					<p className='data-evento-side'>{data_evento}</p>
					<p className='ora-evento-side'>{ora}</p>
					<p className='luogo-sala-side'>{luogo_sala}</p>
					<p className='citta-padiglione-side'>{citta_padiglione ? citta_padiglione : "città o padiglione"}</p>
				</div>
				<div className={'main-body ' + (size === "wide" ? "wide-main-body" : "")}>
					<div className='evento-text-body'>
						<p className='titolo-evento-body'>{titolo}</p>
					</div>
					<div className='icons-body'>
						<div>
							{conditionalAddPeople()}				
							<img src={delete_icon} className='elimina-body' onClick={()=>{
								setShowDeleteParticipantModal(true)
							}}/>
							{color === "#fc0" ? (<img src={checkmark} className="dettagli-body" onClick={() => {
								snooze();
							}}></img>) : (<Link to={{
                    			pathname: `/prenotazione/${bookedIdEvent}`,
                    			state: { eventId: eventId }
                			}} style={color === "red" ? {"visibility" : "hidden"} : {"" : ""}}>
								<img src={forward_icon} className='dettagli-body'/>
							</Link>)}
						</div>
					</div>
				</div>
				{showDeleteParticipantModal && (<SmallPopUpHatched setShowModal={setShowDeleteParticipantModal}>
                <DeleteBookingContents
                    id={showDeleteParticipantModal}
                    setShowModal={setShowDeleteParticipantModal}
                    data={fullBookingData}
					refreshBooking = {refreshBooking}
                ></DeleteBookingContents>
            	</SmallPopUpHatched>)}
				{showAddPeopleModal && (<BookingPopUp setShowModal={setShowAddPeopleModal}>
					<EventAddPeopleModal
                    setShowModal={setShowAddPeopleModal}
					data={fullBookingData}
					type={fullBookingData.type}
					refreshBooking = {refreshBooking}
					></EventAddPeopleModal>
				</BookingPopUp>)}
			</div>
	)
}

export default EventCard