import React, { useEffect, useState, useRef } from 'react'
import TextField from '../../components/inputs/TextField'
import Footer from '../../components/layout/Footer'
import Header from '../../components/layout/Header'
import PrenotazioneSaltoTable from '../../components/prenotazione_salto/PrenotazioneSaltoTable'
import SearchFieldPrenotazioneSalto from '../../components/prenotazione_salto/SearchFieldPrenotazioneSalto'
import { useAuth } from '../../context/AuthContext'
import '../../css/prenotazionesaltopiu.css'
import { downloadBlobFile, generateFileName, getUrl } from '../../utils/functions'
import Pagination from "../../components/Pagination.js"
import PopUp from '../../components/PopUp';
import NewPrenotazioneSaltoPopUp from '../../components/prenotazione_salto/NewPrenotazioneSaltoPopUp.js'
import { ReactComponent as Loader } from '../../img/loader.svg'
import AddNewUserToSalto from '../../components/prenotazione_salto/AddNewUserToSalto'
import imgNotification from "../../img/icon/notification.svg"
import { useHistory } from 'react-router'
import SelectInput from '../../components/inputs/SelectInput'
import { ReactComponent as Export } from '../../img/icon/export_table.svg';
import { useValuesFiltered } from '../../context/ValuesFilteredContext'
import AdminNavbar from '../../components/layout/AdminNavbar';
import { ReactComponent as CleanFields } from '../../img/icon/filter-remove.svg';



function PrenotazioneSaltoPiu() {
    const { api } = useAuth()
    const [valuesFiltered, setValuesFiltered] = useValuesFiltered()
    const history = useHistory()
    const [values, setValues] = useState(valuesFiltered)
    const [completeSearch, setCompleteSearch] = useState({
        data: null,
        loading: false,
        status: false,
        error: null,
        page: 1
    })
    const [hasNextPage, setHasNextPage] = useState(true)
    const [showModal, setShowModal] = useState(false);
    const [showModaleToAddNewUser, setShowModaleToAddNewUser] = useState(false)
    const [popUpContents, setPopUpContents] = useState(null)
    const [loading, setLoading] = useState(null);
    const [valuePerPage, setValuePerPage] = useState(null)
    const [blobFileLoading, setBlobFileLoading] = useState(false)
    const [recordsNumber, setRecordsNumber] = useState(null)
    const valuesLengthRef = useRef(Object.keys(values).length)

    

    const searchEventTypes = async(value, setData) => {
        var data = []
        const eventTypes = [ "Standard", "Scuole", "Minori", "Professionali" ]
        eventTypes.forEach(type => {
            if(type.toLowerCase().includes(value.toLowerCase()))
                data.push({type : type})
        });
        setData({data : data});
    }

    const searchEvents = async (value, setData, setLoading, page = 1) => {
        if (value.length > 2) {
            try {
                setLoading(true);
                let data = await api(getUrl("programma", `api/v1/bookable-program-items?filter[search]=${value}&page[number]=${page}&page[size]=8`));
                setData(data)
            } catch (error) {
                setData(null)
            }
            setLoading(false)
        }
    }

    const searchUsers = async (value, setData, setLoading, page = 1, setHasNextPage) => {
        if (value.length > 2) {
            const limit = 8;//per page
            try {
                setLoading(true);
                let data = await api(getUrl("booking_admin", `backofficesaltopiubookings/user?search=${value}&skip=${((page - 1) * limit)}&limit=${limit}`));
                if (data?.length < limit) {
                    //alert(data?.length)
                    setHasNextPage((data.length === 0 && page > 1) ? page - 1 : page)
                }
                setData(data, data?.length < limit ? true : false)
            } catch (error) {
                setData(null)
            }
            setLoading(false)
        }
    }

    const fetchRecordsNumber = async (id) => {
        const params = new URLSearchParams()
        let settedEventType = false;
        if (id != null && typeof id === 'string' && (id === "Scuole" || id === "Minori" || id === "Standard" || id === "Professionali")) {
            params.set("event_type", id);
            settedEventType = true;
        } else if (id != null && typeof id === 'string') {
            params.set("user_id", id)
        }
        if (id != null && typeof id === 'number') {
            params.set("event_id", id)
        }
        if (values.user?.id) {
            params.set("user_id", values.user?.id)
        }
        if (values.event?.id) {
            params.set("event_id", `${values.event?.id}`)
        }
        if (values.event_type && !settedEventType) {
            params.set("event_type", `${values.event_type}`)
        }

        try {
            var config = {
                service: 'coupon_admin',
                method: 'post',
                url: `backofficesaltopiubookings/booking/count?${params.toString()}`,
                headers: {
                    'Content-Type': 'application/json'
                },
            };
            let response = await api(getUrl(config.service, config.url), config.method, {}, config.headers)
            setRecordsNumber(response)
        } catch (error) {

        }
    }

    const handleOnCompleteSearch = async (page = 1, id) => {
        fetchRecordsNumber(id)
        const params = new URLSearchParams()
        const limit = valuePerPage?.limit ? valuePerPage.limit : 5
        params.set("skip", (page - 1) * limit)
        params.set("limit", limit)
        let settedEventType = false;
        //params.set("participant_id", "")
        if (id != null && typeof id === 'string' && (id === "Scuole" || id === "Minori" || id === "Standard" || id === "Professionali")) {
            params.set("event_type", id);
            settedEventType = true;
        } else if (id != null && typeof id === 'string') {
            params.set("user_id", id)
        }
        if (id != null && typeof id === 'number') {
            params.set("event_id", id)
        }
        if (values.user?.id) {
            params.set("user_id", values.user?.id)
        }
        if (values.event?.id) {
            params.set("event_id", `${values.event?.id}`)
        }
        if (values.event_type && !settedEventType) {
            params.set("event_type", `${values.event_type}`)
        }


        try {
            setCompleteSearch(oldState => ({ ...oldState, loading: true }));
            let data = await api(getUrl("booking_admin", `backofficesaltopiubookings/booking?${params.toString()}`));
            if (data?.length === 0 && page > 1) {
                setHasNextPage(false)
                return;
            }
            setCompleteSearch({
                data: data,
                loading: false,
                status: true,
                error: null,
                page: page
            })
            if (data?.length < limit) {
                setHasNextPage(false)
            } else {
                setHasNextPage(true)
            }
        } catch (error) {
            setCompleteSearch({
                data: null,
                loading: false,
                status: false,
                error: "An error occured",
                page: 1
            })
        }
    }

    const AddNewUserToEvent = async () => {
        if (values?.event?.bookable_places === values?.event.booked_places) {
            history.push({
                pathname: "/message",
                state: {
                    message: "Il numero di posti prenotabili per questo evento è esaurito.",
                    type: "error",
                    back: true,
                    //link: "/login",
                    //label: "Go back to Login page",
                    img: imgNotification
                }
            });
        }
        try {
            setLoading(true);
            let data = await api(getUrl("booking_admin", `backofficesaltopiubookings/getstate?user_id=${values.user?.id}&event_id=${values.event?.id}`));
            if (data?.status === "success") {
                setLoading(false);
                setPopUpContents(data);
                setShowModal(true);
            }

        } catch (error) {
            setLoading(false);
            setPopUpContents(null)
        }
    }

    const refreshPopUpContents = () => {
        AddNewUserToEvent();
    }

    const handleOnChange = (event) => {
        setValuePerPage(oldValues => ({ ...oldValues, [event.target.name]: event.target.value }))
    }

    const exportXslFile = () => {
        const params = new URLSearchParams()
        //params.set("participant_id", "")
        if (values.user?.id) {
            params.set("user_id", values.user?.id)
        }
        if (values.event?.id) {
            params.set("event_id", `${values.event?.id}`)
        }
        if (values.event_type) {
            params.set("event_type", `${values.event_type}`)
        }

        var config = {
            service: 'coupon_admin',
            method: 'post',
            url: `report/download/booking?${params.toString()}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: params
        };
        let prefix = "Export prenotazioni"
        downloadBlobFile(api, config, generateFileName(prefix), setBlobFileLoading)

    }

    useEffect(() => {
        const newValueLength = Object.keys(values).length
        if (newValueLength === 0 && valuesLengthRef.current > 0) {
            searchUsershByFilter()
        }
        valuesLengthRef.current = newValueLength
    }, [values])

    useEffect(() => {
        handleOnCompleteSearch()
        fetchRecordsNumber()
    }, [valuePerPage])

    const searchUsershByFilter = (id) => {
        handleOnCompleteSearch(1, id)
    }

    return (
        <div>
            <Header />
            <div className="admin-pages-main" style={{ minHeight: "68vh" }}>

                {showModaleToAddNewUser === true && (<PopUp
                    setShowModal={setShowModaleToAddNewUser}
                    headerTitle={"Aggiungi nuovo utente"}
                >
                    <AddNewUserToSalto
                        setShowModal={setShowModaleToAddNewUser}
                    />
                </PopUp>

                )}
                <AdminNavbar back="/admin_dashboard" title="Prenotazione Utenti SaltoPiù" />
                <div className="container-fluid">
                    <div className="row search-box-prenotazione-salto">
                        <div className="col-12 pb-3">
                            <div className="row">
                                <SearchFieldPrenotazioneSalto
                                    className="col-md-5 col-10"
                                    name="search_user"
                                    onChange={(item) => {
                                        setValues({ ...values, user: item })
                                        setValuesFiltered({ ...values, user: item })
                                    }}
                                    type="text"
                                    required={false}
                                    placeholder="Cerca utente per cognome o email"
                                    searchResults={searchUsers}
                                    searchUsershByFilter={(id_user) => searchUsershByFilter(id_user)}
                                    feedback={`${values?.user ? "feedback" : ""}`}
                                    value={values?.user ? values?.user?.first_name + " " + values?.user?.last_name + ", " + values?.user?.email : ""}
                                    onClick={
                                        () => {
                                            if (values?.user?.id != null) {
                                                setValues({ ...values, user: undefined })
                                                setValuesFiltered({})
                                                return
                                            }
                                        }
                                    }
                                />
                                {}
                            </div>

                            {}
                        </div>
                    </div>
                    <div className="row search-box-prenotazione-salto">
                        <div className="col-12 pb-3">
                            <div className="row">
                                <SearchFieldPrenotazioneSalto
                                    className="col-md-5 col-10"
                                    name="search_event"
                                    onChange={(item) => {
                                        setValues({ ...values, event: item })
                                        setValuesFiltered({ ...values, event: item })
                                    }}
                                    type="text"
                                    required={false}
                                    placeholder="Cerca evento"
                                    searchResults={searchEvents}
                                    searchUsershByFilter={(id_event) => searchUsershByFilter(id_event)}
                                />
                            </div>
                            </div>
                            </div>
                            <div className="row search-box-prenotazione-salto">
                            <div className="col-12 pb-3">
                            <div className="row">
                                <SearchFieldPrenotazioneSalto
                                    className="col-md-5 col-10"
                                    name="search_event_type"
                                    onChange={(item) => {
                                        setValues({ ...values, event_type: item })
                                        setValuesFiltered({ ...values, event_type: item })
                                    }}
                                    type="text"
                                    required={false}
                                    placeholder="Cerca tipo evento"
                                    searchResults={searchEventTypes}
                                    searchUsershByFilter={(id_event) => searchUsershByFilter(id_event)}
                                />
                            </div>
                            </div>
                            <div className="col-12 pb-3">
                            <div className="row">
                                <TextField
                                    label="Evento"
                                    className="col-md-4"
                                    name="event"
                                    value={values.event?.title}
                                    feedback={`${values?.event?.title ? "feedback" : ""}`}
                                    type="text"
                                    required={false}
                                    disabled={true}
                                    prefix={values.event?.id && <div
                                        className="delete-values"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Svuota i campi"
                                        onClick={() => {
                                            setValues({ ...values, event: undefined })
                                            setValuesFiltered({ ...values, event: undefined })
                                        }}
                                    >
                                        -
                                    </div>}
                                />
                                <TextField
                                    label="Data"
                                    className="col-6 col-md-3"
                                    name="event_date"
                                    value={values.event?.date}
                                    feedback={`${values?.event?.date ? "feedback" : ""}`}
                                    type="date"
                                    required={false}
                                    disabled={true}
                                />
                                <TextField
                                    label="Ora"
                                    className="col-6 col-md-2"
                                    name="time"
                                    value={values.event?.time}
                                    feedback={`${values?.event?.time ? "feedback" : ""}`}
                                    type="time"
                                    required={false}
                                    disabled={true}
                                />
                                <TextField
                                    label="ID evento"
                                    className="col-md-2"
                                    name="event_id"
                                    value={values.event?.id}
                                    feedback={`${values?.event?.id ? "feedback" : ""}`}
                                    type="text"
                                    required={false}
                                    disabled={true}
                                />
                            </div>
                            <div className="row">
                                <TextField
                                    label="Numero totale di posti prenotabili"
                                    className="col-6 col-md-3"
                                    name="bookable_places"
                                    value={values.event?.bookable_places}
                                    feedback={`${values?.event?.bookable_places ? "feedback" : ""}`}
                                    type="text"
                                    required={false}
                                    disabled={true}
                                />
                                <TextField
                                    label="Numero di posti prenotati"
                                    className="col-6 col-md-2"
                                    name="booked_places"
                                    value={values.event?.booked_places}
                                    feedback={`${values?.event?.booked_places ? "feedback" : ""}`}
                                    type="text"
                                    required={false}
                                    disabled={true}
                                />
                                <div className="col-md-1">
                                    <div className="remove-field-values">
                                        <CleanFields
                                            onClick={() => {
                                                setValuesFiltered({})
                                                setValues({})
                                            }}
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Svuota i campi"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row pl-1">
                        <div className="col-auto">
                            <button
                                className="btn-new-booking-salto"
                                disabled={values.event?.id == null || values.user?.id == null ? "disabled" : ""}
                                onClick={AddNewUserToEvent}
                            >
                                Nuova prenotazione
                            </button>
                        </div>
                        <div className="col-md-4">
                            <button
                                className="col-md-4 serach-btn-salto"
                                //disabled={values.event?.id == null && values.user?.id == null ? "disabled" : ""}
                                onClick={() => searchUsershByFilter()}
                            >
                                Cerca
                            </button>
                        </div>
                    </div>

                    {(loading || completeSearch.loading || blobFileLoading) && (<div className="loading fixed"><Loader /> </div>)}
                    {showModal === true && popUpContents != null && (<PopUp
                        setShowModal={setShowModal}
                        loading={loading}
                        headerTitle={popUpContents?.header?.title}
                    >
                        <NewPrenotazioneSaltoPopUp
                            data={popUpContents}
                            onCloseModal={setShowModal}
                            loading={loading}
                            refreshPopUpContents={refreshPopUpContents}
                        />
                    </PopUp>)}

                    <div className="row mt-3">
                        <SelectInput
                            className="col-md-4"
                            name="limit"
                            required={false}
                            options={[
                                { id: 5, name: 5 },
                                { id: 10, name: 10 },
                                { id: 25, name: 25 },
                                { id: 50, name: 50 }
                            ]}
                            placeholder="inserimento numero risultati in pagina"
                            emptyOption={true}
                            onChange={handleOnChange}
                            value={valuePerPage?.limit}
                        />
                        <div className="records-export">
                            <div className='records-find'>{recordsNumber} posti prenotati</div>
                            <Export className="export-table"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Export"
                                onClick={exportXslFile}
                            />
                        </div>
                    </div>

                    {completeSearch?.data != null && (
                        <PrenotazioneSaltoTable
                            data={completeSearch.data}
                            setCompleteSearch={setCompleteSearch} />
                    )}

                    {completeSearch?.data != null && (
                        <Pagination hasPrevious={completeSearch?.page > 1}
                            hasNext={hasNextPage}
                            currentPage={completeSearch?.page}
                            onClick={(pageStatus) => {
                                handleOnCompleteSearch(pageStatus === "prev" ? completeSearch?.page - 1 : completeSearch?.page + 1)
                            }} />
                    )}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PrenotazioneSaltoPiu
