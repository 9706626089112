import React, { useState } from 'react'
import { useUser } from '../../context/UserContext';
import '../../css/sidebarcontents.css';
import ConsentModal from '../newsletter_consent/ConsentModal'


function SideBarContents() {
	const [user] = useUser()
	const [showNewsletter, setShowNewsletter] = useState(false);

	return (
		<div className="content-sidebar-container">
			<p className="sidebar-p" ></p>
			{(user?.consent_send_commercial_newsletter === false && user?.consent_send_cultural_newsletter === false) && (<>
				<p className="sidebar-newsletter-text">C'è anche una bella newsletter.</p>
				<button className='button blue-btn' style={{ "width": "100%", "lineHeight": "2rem" }} onClick={() => {
					// setShowNewsletter(true);
					window.open("https://www.salonelibro.it/home-sotto-sezione-news-2/rimaniamo-in-contatto.html")
				}}>Iscrivimi</button>
				{showNewsletter ? (
					<ConsentModal showModal={setShowNewsletter}></ConsentModal>
				) : ""}</>)}
		</div>
	)
}

export default SideBarContents
