import React, { useEffect, useState } from 'react';
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import { useUser } from '../../context/UserContext';
import "../../css/moduliprenotazione.css";
import { useAuth } from '../../context/AuthContext';
import { downloadBlobFile, generateFileName, getUrl } from '../../utils/functions';
import { ReactComponent as Loader } from "../../img/loader.svg";
import { Link } from 'react-router-dom';
import AdminNavbar from '../../components/layout/AdminNavbar';



const fetchFormsFromBackOffice = async (api, setFormsFromBackOffice) => {
    try {
        setFormsFromBackOffice((oldState) => ({ ...oldState, loading: true }))
        let response = await api(getUrl("user_admin", `backoffice/forms`))
        setFormsFromBackOffice((oldState) => ({
            ...oldState,
            loading: false,
            availableForms: response?.available
        }))

    } catch (error) {

    }
}

function CorsiDisponibili() {
    const { api } = useAuth()
    const [formsFromBackOffice, setFormsFromBackOffice] = useState({
        loading: false,
        availableForms: null,
        error: false
    })
    const [blobFileLoading, setBlobFileLoading] = useState(null)


    useEffect(() => {
        fetchFormsFromBackOffice(api, setFormsFromBackOffice)
    }, [api])

    const exportXslFile = (formName, formId) => {
        var config = {
            service: 'user_admin',
            method: 'post',
            url: `report/download/form?form_id=${formId}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {},
        };
        let prefix = "Export - " + formName + " - " + formId
        downloadBlobFile(api, config, generateFileName(prefix), setBlobFileLoading)
    }

    return (
        <div>
            {blobFileLoading && <div className="loading fixed" >
                <Loader />
            </div>}
            <Header />
            <div className="admin-pages-main">
                <div>
                    <AdminNavbar back="/admin_dashboard" title="Corsi Disponibili" />
                    <div className="container-fluid">
                        <div className="row moduli-prenotazione">
                            <div className="col-12 corsi-disponibili">
                                {formsFromBackOffice?.availableForms && formsFromBackOffice?.availableForms.map((form) => {
                                    return <div className="card m-3 p-3">
                                        <div className="row">
                                            <div className="col-auto mb-3">
                                                <h3>{form.title}</h3>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-3">
                                                <button
                                                    className='download-delete'
                                                    onClick={() => { exportXslFile(form.name, form.id) }}
                                                >
                                                    Export</button>
                                            </div>
                                            {/* <div className="col-3">
                                                <button className='download-delete'>Prenotazione</button>
                                            </div> */}
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CorsiDisponibili