import Cittadinanze from './jsons/cittadinanze.json';
import Lingue from './jsons/lingue.json';
import Country from './jsons/csc/country.json';
import State from './jsons/csc/state.json';
import City from './jsons/csc/city.json';
import Profession from './jsons/profession.json';

export const getAllCountries = () => {
    return Country
}

export const getStatesOfCountry = (countryId) => {

    return State.filter((state) => +countryId === +state["country_id"])
}

export const getCitiesOfState = (stateId) => {

    return City.filter((city) => +city.state_id === +stateId).sort(function(a, b) {
        let nameA = a.name.toUpperCase(); // ignore upper and lowercase
        let nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {//nameA sort before nameB
          return -1;
        }
        if (nameA > nameB) {//nameB sort befor nameA
          return 1;
        }
      
        // names must be equal
        return 0;
      });
}

export const getListOfValueJson = (file) => {
    switch (file) {
        case "lingue.json":
            return Lingue

        case "cittadinanze.json":
            return Cittadinanze
        case "profession.json":
            return Profession
        default:
            return []
    }
}