import React, { useState, useEffect, useRef } from "react";
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import Sidebar from "../components/layout/Sidebar";
import Main from "../components/layout/Main";
import { useAuth } from "../context/AuthContext";
import { useUser } from "../context/UserContext";
import { getUrl } from "../utils/functions";
import { ReactComponent as Loader } from "../img/loader.svg";
import { useHistory } from "react-router";
import SideBarContents from "../components/layout/SideBarContents";
import { Link } from "react-router-dom";
import "../css/attestatoInsegnanti.css";
import bibliografia from "../utils/educare_alla_lettura_bibliografia.pdf";

function AttestatoInsegnanti() {
	const [passwordForm, setPasswordForm] = useState({});
	const [responseText, setResponseText] = useState(false);
	const [loading, setLoading] = useState(false);
	const { api } = useAuth();
	const handlePasswordForm = (event) => {
		setPasswordForm((passwordForm) => ({ ...passwordForm, [event.target.name]: event.target.value }));
	};

	useEffect(() => {}, [responseText]);

	async function checkPasswords() {
		setLoading(true);
		let label;
		let verify_type;
		if (Object.keys(passwordForm).length < 5) {
			setResponseText("Inserire un minimo di 5 password per ottenere l'attestato.");
			setLoading(false);
			return;
		} else {
			label = "educare2024_" + Object.keys(passwordForm).length;
			verify_type = "partial_" + Object.keys(passwordForm).length;
		}
		let body = {
			label: label,
			verify_type: verify_type,
			passwords: passwordForm,
		};
		let response;
		try {
			response = await api(getUrl("user", "attestati/verify_partial_password"), "post", body);
			setResponseText(response);
			setLoading(false);
		} catch (err) {
			setResponseText(err.detail);
			setLoading(false);
		}
	}

	function returnPswInput(inputName) {
		return (
			<input
				name={inputName}
				onChange={(e) => {
					handlePasswordForm(e);
				}}
				type="text"></input>
		);
	}
	return (
		<div>
			{loading && (
				<div className="loading fixed">
					<Loader style={{ width: "130px", height: "130px" }} />{" "}
				</div>
			)}
			<Header />
			<div className="page-body">
				<Sidebar>
					<SideBarContents />
				</Sidebar>
				<div className="separating-line"></div>
				<Main>
					<p className="attestato_titolo">Educare alla lettura - Leggere è partecipare</p>
					<p className="attestato_subtitolo">
						<a href="https://drive.google.com/drive/folders/1lX9nJ65W2GneEP1DI1nXAUY6FfeJmExy?usp=drive_link">
							<b>CLICCA QUI PER SCARICARE E USUFRUIRE DEI MATERIALI DEL CORSO</b>
						</a>
					</p>
					<p className="attestato_subtitolo">
						Scarica l'attestato di presenza ad ogni singolo appuntamento del corso Educare alla lettura - Leggere è partecipare,
						realizzato dal <b>Salone Internazionale del Libro di Torino</b> e dal <b>Cepell - Centro per il Libro e la Lettura</b>, cliccando sul link della lezione corrispondente. 
					</p>
					<div className="attestato_link_container">
						<p className="attestato_data">Scarica qui l'attestato dell'incontro di mercoledì 29 novembre 2023</p>
						<Link to="attestati?attestati_label=marchetta">
							<i>Una scuola di parole e di storie, </i>
							<b>con Giusi Marchetta, Simone Giusti e Eraldo Affinati</b>
						</Link>
						<br />
						<p className="attestato_data">Scarica qui l'attestato dell'incontro di martedì 16 gennaio 2024</p>
						<Link to="attestati?attestati_label=lancini">
							<i>Stare bene a scuola: insegnare in un clima sereno, </i>
							<b>con Matteo Lancini</b>
						</Link>
						<br />
						<p className="attestato_data">Scarica qui l'attestato dell'incontro di martedì 23 gennaio 2024</p>
						<Link to="attestati?attestati_label=batini">
							<i>Dispersione scolastica e giustizia sociale: insegnare per includere, </i>
							<b>con Federico Batini</b>
						</Link>
						<br />
						<p className="attestato_data">Scarica qui l'attestato dell'incontro di martedì 30 gennaio 2024</p>
						<Link to="attestati?attestati_label=roghi">
							<i>Storia della nostra scuola: insegnare a partecipare, </i>
							<b>con Vanessa Roghi</b>
						</Link>
						<br />
						<p className="attestato_data">Scarica qui l'attestato dell'incontro di martedì 6 febbraio 2024</p>
						<Link to="attestati?attestati_label=corsini">
							<i>Una valutazione educativa: insegnare a valutare e a valutarsi, </i>
							<b>con Cristiano Corsini</b>
						</Link>
						<br />
					</div>
					<p className="attestato_subtitolo">
						Potrai scaricare l'attestato finale di formazione (10 ore), al termine del corso, avendo seguito tutti e 5 gli incontri.
					</p>
					<div className="full_attestato_container">
						<p className="full_attestato_password">
							Durante gli appuntamenti è stata fornita una password. Inserisci la password corrispondente all’incontro nel campo qui sotto e scarica l'attestato.
						</p>
						<div className="full_attestato_password_container">
							<label>Una scuola di parole e storie:{returnPswInput("marchetta")}</label>
							<label>Stare bene a scuola: insegnare in un clima sereno:{returnPswInput("lancini")}</label>
							<label>Dispersione scolastica e giustizia sociale: insegnare per includere:{returnPswInput("batini")}</label>
							<label>Storia della nostra scuola: insegnare a partecipare:{returnPswInput("roghi")}</label>
							<label>Una valutazione educativa: insegnare a valutare e a valutarsi:{returnPswInput("corsini")}</label>
						</div>
						<br />
						<button
							className="full_attestato_verify blue-btn"
							onClick={() => {
								checkPasswords();
							}}>
							Verifica
						</button>
						{responseText ? (
							<p
								dangerouslySetInnerHTML={{
									__html: responseText?.header ? responseText?.header?.body : responseText,
								}}></p>
						) : (
							""
						)}
					</div>
				</Main>
			</div>
			<Footer />
		</div>
	);
}

export default AttestatoInsegnanti;
