import React from "react";

function FormInput({ data, setFormFieldsValue }) {
	const [value, setValue] = React.useState(data.field_value ?? "");

	if (
		data.LOV.every((el) => {
			return !isNaN(el);
		})
	) {
		let selectOptions = data.LOV.sort((a, b) => {
			return parseInt(a) - parseInt(b);
		});
		data.LOV = selectOptions;
	}

	let attributes = {
		required: data.is_mandatory ? "required" : "",
		disabled: data.is_editable ? "" : "disabled", //disabled attributes in the fields
		value: value, //value of field
		onChange: (event) => {
			// onChnage attribute in the field
			if (event.target.type === "checkbox") {
				setValue(event.target.checked);
				setFormFieldsValue((state) => {
					return {
						...state,
						[data.form_formField_id]: event.target.checked,
					};
				});
			} else {
				setValue(event.target.value);
				setFormFieldsValue((state) => {
					return {
						...state,
						[data.form_formField_id]: event.target.value,
					};
				});
			}
		},
	};
	// if (Array.isArray(data.LOV) && data.LOV.length === 0 && data.is_editable === true) {
	// 	attributes["placeholder"] = data.field_label;
	// }
	return (
		<div className="col-12 col-md-6">
			{data?.field_typeFE === "Bool" ? "" : <p className="mt-2 mb-2 p-14">{data.field_label}</p>}

			{Array.isArray(data.LOV) && data.LOV.length > 0 ? (
				<div className="select-container">
					<select
						className="w-100"
						{...attributes}
						defaultValue=""
						id={"input-key-" + data.field_id}>
						<option
							value=""
							disabled>
							{/*{data.field_label}*/}
						</option>
						{data.LOV.map((option) => {
							return (
								<option
									key={option}
									value={option}>
									{option}
								</option>
							);
						})}
					</select>
					<svg
						width="15"
						height="22"
						viewBox="0 0 15 22"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M12.5 8.5L7.5 13.5L2.5 8.5"
							stroke="black"
							strokeOpacity="0.3"
							strokeWidth="1.5"
						/>
					</svg>
				</div>
			) : (
				<>
					{data?.field_typeFE === "Bool" ? (
						<div
							style={{
								display: "flex",
								marginTop: "5%",
							}}>
							<input
								className=""
								{...attributes}
								type="checkbox"
								id={"input-key-" + data.field_id}
								style={{}}
							/>
							<a
								className="mt-2 mb-2 a-14"
								style={{ marginLeft: "10px" }}
								href="https://www.salonelibro.it/privacy-policy.html"
								target="_blank"
								rel="noreferrer">
								{data.field_label}
							</a>
						</div>
					) : (
						<input
							className="w-100"
							{...attributes}
							type="text"
							id={"input-key-" + data.field_id}
						/>
					)}
				</>
			)}
		</div>
	);
}

export default FormInput;
