import React, { forwardRef } from 'react'

const TextField = forwardRef(({ name, onClick, onChange, required = true, value = "", placeholder = "", type = "text", label, className = "col", disabled = false, autoComplete = "off", onFocus, onBlur, suffix, prefix, feedback }, ref) => {
	return (
			<div className={className}>
				{/* {(label) ? <p className="mt-2 mb-2 p-14">{label}{required && "*"}</p> : ""} */}
				{(label && label.includes) ? (label.includes("Titolo Tradotto") ? <p className="mt-2 mb-2 p-14" style={{"paddingTop" : "30px"}}>{label}{required && "*"}</p> : <p className="mt-2 mb-2 p-14">{label}{required && "*"}</p>) : ""}
				<div className="container-fluid">
					<div className="row">
						{prefix && <div className="d-flex align-items-center col-auto p-0">{prefix}</div>}
						<div className={`col p-0 ${feedback ? "form-feedback" : ""}`}>
							{label && label.includes("Titolo Tradotto") ? <input className="w-100" style={{"display" : "none"}}></input> : 
								<input
								onFocus={onFocus}
								onBlur={onBlur}
								autoComplete={autoComplete}
								ref={ref}
								className="w-100"
								type={type}
								name={name}
								placeholder={placeholder}
								required={required ? "required" : ""}
								onChange={onChange}
								value={value}
								disabled={disabled ? "disabled" : ""}
								onClick={onClick}
							/>
							}
							{/* <input
								onFocus={onFocus}
								onBlur={onBlur}
								autoComplete={autoComplete}
								ref={ref}
								className="w-100"
								type={type}
								name={name}
								placeholder={placeholder}
								required={required ? "required" : ""}
								onChange={onChange}
								value={value}
								disabled={disabled ? "disabled" : ""}
								onClick={onClick}
							/> */}
						</div>
						{suffix && <div className="col-auto p-0">{suffix}</div>}
					</div>
				</div>
			</div>
		)
	})

export default TextField
