import React from "react";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import Sidebar from "./components/layout/Sidebar";
import Main from "./components/layout/Main";
import { Link } from 'react-router-dom';
import BookingContent from "./BookingContent";
import book from "./img/bg_header_educare.jpg";
import europa from "./img/didattica/L'EUROPA.jpg";
import salone from "./utils/salone";
import Cookies from "js-cookie";



class BookingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      firstname: "",
      title: ""
    };
  }

  visitCertClick(e) {
    this.props.history.push(`/certificatepage/${this.props.match.params.data}`)
  }

  componentDidMount() {
    if (this.props.match.params.data === "formazione") {
      this.setState({
        title:
          <div>
            <div className="row text-center">
              <div className="col-8">
                <h1 className="h0" style={{ color: "#1488f3" }}>Educare alla lettura</h1>
              </div>
              <div className="col-4">
                <button className="button white-btn w-100" onClick={(e) => this.visitCertClick(e)}>Ottieni Attestati</button>
              </div>
            </div>
            <div className="row text-center justify-content-center mt-5">
              <img src={book} className=" bookingpage-img" alt=""></img>
            </div>
            <div className="row mt-5">
              <div className="col-12">
                <p>
                  Educare alla lettura è il percorso formativo valido per l’aggiornamento di docenti e bibliotecari organizzato dal Salone del Libro e dal MIBACT Centro per il Libro e la Lettura, in collaborazione con AIB – Associazione Italiana Biblioteche. Rimodulato quest’anno in forma online, il corso intende offrire la possibilità di sviluppare competenze specifiche e approfondite sui fondamenti dell’educazione alla lettura grazie al confronto e dialogo con scrittori, illustratori, pedagogisti e scienziati italiani e stranieri.
                  <br />
                  La partecipazione ad Educare alla lettura permette di ottenere alla sua conclusione un attestato di formazione valido ai fini dell’acquisizione dei crediti formativi per i corsisti che frequenteranno almeno 5 incontri; al termine dei singoli appuntamenti, invece, viene rilasciato un attestato di presenza.
                </p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-4">
                <a
                  href="https://www.salonelibro.it/ita/downloadpress/educare-alla-lettura/64-educare-alla-lettura-programma/file.html"
                  target="_blank" rel="noreferrer">
                  <button className="button blue-btn w-100 bookingpage-button">
                    Scarica il programma completo
                  </button>
                </a>
              </div>
            </div>
          </div>
      });

    }
    else if (this.props.match.params.data === "didattica2") {
      this.setState({
        title:
          <div>
            <div className="row text-center">
              <div className="col-8">
                <h1 className="h0" style={{ color: "#1488f3" }}>Tre lezioni sull'Europa</h1>
              </div>
              {/* <div className="col-4">
                <button className="button white-btn w-100" onClick={(e) => this.visitCertClick(e)}>Ottieni Attestati</button>
              </div> */}
              <div className="w-100 text-center my-3">
                <img className="img-fluid" src={europa} alt="" />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-12">
                <p>
                  Tre appuntamenti per far conoscere ai bambini e ai ragazzi la storia del Manifesto di Ventotene e i meccanismi che regolano
                  l’Unione Europea. Tre momenti di formazioni per orientarli nel loro lavoro di scrittura del decalogo per il progetto l<Link to="/speciali/candidateform/EU"><i>’Europa ancora da scrivere</i></Link>.
                  Le classi prenotate potranno intervenire tramite chat durante gli incontri e fare le loro domande ai relatori.
                  Per informazioni sul progetto l’Europa ancora da scrivere visita la <a href=" https://www.salonelibro.it/ita/salto-scuola/salto-scuola-articolo/l-europa-ancora-da-scrivere.html" target="_blank">sua pagina </a>
                  sul sito del Salone e iscrivi la tua classe qui su SalTo+ nell’area dedicata alla candidatura ai progetti per le scuole.

                </p>
              </div>
            </div>
          </div>
      })
    } else {
      this.setState({
        title:
          <div>
            <div className="row text-center">
              <div className="col-8">
                <h1 className="h0" style={{ color: "#1488f3" }}>Vita Nova a scuola</h1>
              </div>
              {/* <div className="col-4">
                <button className="button white-btn w-100" onClick={(e) => this.visitCertClick(e)}>Ottieni Attestati</button>
              </div> */}
            </div>
            <div className="row mt-2">
              <div className="col-12">
                <p>
                  Vita Nova inaugura <span className="font-weight-bold">venerdì 4 dicembre</span> con tre appuntamenti rivolti alle scuole.
                  <br />
                  Il primo appuntamento, rivolto alle scuole primarie, è un dialogo su Realtà-Fantasia per ricordare Gianni Rodari e Pinin Carpi. Alle 9 <span className="font-weight-bold">Susanna Mattiangeli</span> e <span className="font-weight-bold">Luca Doninelli</span> interverranno accompagnati dalle letture di Carla Signoris e Max Paiella.
                  <br />
                  Si proseguirà alle 10:30 con un appuntamento per le secondarie di primo grado con con i filosofi <span className="font-weight-bold">Maura Gancitano</span> e <span className="font-weight-bold">Andrea Colamedici</span>, che andranno a fondo dei presunti opposti Maschi-Femmine: perché la diversità è una grande fonte di meraviglia e di ispirazione, se si impara a non dare il mondo per scontato.
                  <br />
                  Alle 12, chiuderà la mattinata l’incontro per le scuole superiori con <span className="font-weight-bold">Roberto Saviano</span> a partire dal rapporto Sud-Nord.
                  <br />
                  <span className="font-weight-bold">Prenota</span> la tua classe e interagisci con gli ospiti portando le domande dei tuoi studenti.

                </p>
              </div>
            </div>
          </div>
      });
    }
    salone
      .get(`/user/info`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      })
      .catch((error) => {
        console.log(error.response.data.detail);
        this.props.history.push("/login");
      })
      .then((response) => {
        this.setState({
          firstname: response?.data?.first_name,
        })
      });

    console.log(this.props.match.params.data)
    salone
      .get(`/mediagallery/bookingallowedcontents/${this.props.match.params.data}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      )
      .catch((error) => {
        console.log(error.response?.data.detail);
        this.props.history.push("/login")
      })

      .then((response) => {
        this.setState({
          data: response?.data,
        });
      });


  }

  render() {
    var items = [];

    for (let i = 0; i < this.state.data.length; i++) {

      items.push(

        <BookingContent
          key={this.state.data[i]?.id}
          id={this.state.data[i]?.id}
          title={this.state.data[i]?.title}
          subtitle={this.state.data[i]?.subtitle}
          videoFavicon={this.state.data[i]?.videoFavicon}
          booked={this.state.data[i]?.isAlreadyBooked}
          available={this.state.data[i]?.available}
          history={this.props.history}
          startTimeLive={this.state.data[i]?.startTimeLive}
          url={this.props.match.params.data}
        />


      );
    }
    {/* <div className="col-12">
            <Section data={this.state.data[i]} />
          </div> */}
    return (
      <div>
        <Header />
        <div className="row w-100">
          <Sidebar>
            <h3 style={{ textTransform: "uppercase" }}>Ciao <span style={{ color: "#1488F3" }}>{this.state.firstname}</span>,<br />Benvenuta/o su SalTo+</h3>
            
            <p>In questo spazio trovi il meglio della produzione culturale curata dal Salone: video, interviste, lezioni, podcast, approfondimenti. Una grande libreria a tua disposizione: scegli un contenuto e inizia il viaggio.</p>
          </Sidebar>
          <Main>
            <div className="container custom-container">
              {this.state.title}
              <div className="row my-5">
                {console.log(items)}
                {items}
              </div>
            </div>
          </Main>
        </div>
        <Footer />
      </div>
    );
  }
}

export default BookingPage;
