import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Export } from '../../img/icon/export_table.svg';
import { ReactComponent as Trash } from '../../img/icon/trash.svg';
import { ReactComponent as OnlineCourse } from '../../img/icon/online-course.svg';
import { ReactComponent as EmailVerified } from '../../img/icon/email-verified.svg';
import { ReactComponent as YouHaveMessage } from '../../img/icon/you-have-message.svg';
import { ReactComponent as Edit } from '../../img/icon/edit.svg';
import swal from 'sweetalert';
import { useAuth } from '../../context/AuthContext';
import { downloadBlobFile, generateFileName, getUrl } from '../../utils/functions';
import { ReactComponent as Loader } from "../../img/loader.svg";
import SelectInput from '../inputs/SelectInput';


function UtentiTable({ data, page, recordsNumber, bodyForExportTable, searchUsershByFilter, valuePerPage, setValuePerPage }) {

    const { api } = useAuth()
    const [blobFileLoading, setBlobFileLoading] = useState(null)


    const deleteuser = (userId) => {
        swal({
            title: "Sei sicuro di voler eliminare questo utente?",
            text: "",
            icon: "warning",
            buttons: ["NO", "Si"],
        })
            .then(async (value) => {
                if (value == null) {
                    return;
                }
                try {
                    let response = await api(getUrl("user_admin", `backoffice/delete_user?user_id=${userId}`), "post", {});
                    if (response?.success === true) {
                        swal({
                            title: "Richiesta di eliminazione utente inviata.",
                            tex: "",
                            icon: "success"
                        })
                        searchUsershByFilter()
                    }
                } catch (error) {
                }
            })
    }

    const verifyEmail = (userId) => {
        swal({
            title: "Vuoi verificare la mail?",
            text: "",
            icon: "info",
            buttons: ["NO", "Si"],
        })
            .then(async (value) => {
                if (value == null) {
                    return;
                }
                try {
                    let response = await api(getUrl("user_admin", `backoffice/mail_confirm?user_id=${userId}`), "post", {});
                    if (response?.success === true) {
                        swal({
                            title: "La mail è stata verificata correttamente.",
                            tex: "",
                            icon: "success"
                        })
                        searchUsershByFilter()
                    }
                    else {
                        swal({
                            title: "La mail non è stata verificata!",
                            tex: "",
                            icon: "error",
                        })
                    }
                } catch (error) {
                }
            })
    }

    const exportXslFile = () => {
        delete bodyForExportTable.limit
        delete bodyForExportTable.offset

        console.log(bodyForExportTable)
        var config = {
            service: 'user_admin',
            method: 'post',
            url: "report/download/user",
            headers: {
                'Content-Type': 'application/json'
            },
            data: bodyForExportTable,
        };
        downloadBlobFile(api, config, generateFileName(), setBlobFileLoading)

    }
    const handleOnChange = (event) => {
        setValuePerPage(oldValues => ({ ...oldValues, [event.target.name]: event.target.value }))
    }
    return <div className='row mt-2 users-list'>
        <SelectInput
            className="col-md-4"
            name="limit"
            required={false}
            options={[
                { id: 5, name: 5 },
                { id: 10, name: 10 },
                { id: 25, name: 25 },
                { id: 50, name: 50 }
            ]}
            placeholder="inserimento numero risultati in pagina"
            emptyOption={true}
            onChange={handleOnChange}
            value={valuePerPage?.limit}
        />
        <div className="records-export">
            <div className='records-find'>{recordsNumber} risultati</div>
            {/* <Export className={`export-table ${blobFileLoading ? "downloading" : ""}`} */}
            <Export className={`export-table ${blobFileLoading ? "" : ""}`}
                data-toggle="tooltip"
                data-placement="top"
                title="Export"
                onClick={exportXslFile}
            />
        </div>
        {blobFileLoading && <div className="loading fixed" >
            <Loader />
        </div>}
        <table className="table table-striped">
            <thead>
                <tr>
                    <th>N.</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Nome</th>
                    <th>Cognome</th>
                    <th>Email</th>
                    <th>Ambito</th>
                    <th>Professione</th>
                    <th>Città</th>
                    <th>Stato</th>
                    <th>Regione</th>
                    <th>Status</th>

                </tr>
            </thead>
            <tbody>
                {data.map((row, index) => {
                    return <tr className={index % 2 === 0 ? "highlighted" : ""} key={row.id}>
                        <td>{index + 1 + ((page - 1) * (valuePerPage?.limit ?? 5))}</td>
                        <td>
                            <Link to={`/admin_users/${row.sso_id}`} className="users-edit"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Modifica l'utente" >
                                {/* <i className='far'>&#xf044;</i> */}
                                <Edit />
                            </Link>
                        </td>
                        <td>
                            <span className="delete-user"><Trash onClick={(userId) => {
                                deleteuser(userId = row.sso_id)
                            }} data-toggle="tooltip"
                                data-placement="top"
                                title="Cancella l'utente" />
                            </span>
                        </td>
                        <td>
                            <Link to={`/admin_moduli_prenotazione/${row.sso_id}`} className="online-course"><OnlineCourse
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Gestione dei corsi" />
                            </Link>
                        </td>
                        <td>
                            <span className="online-course">
                                {row.email_verified === 1 ? (
                                    <EmailVerified onClick={(userId) => {
                                        verifyEmail(userId = row.sso_id)
                                    }} data-toggle="tooltip"
                                        data-placement="top"
                                        title="Email verificata" />
                                ) : (<YouHaveMessage onClick={(userId) => {
                                    verifyEmail(userId = row.sso_id)
                                }} data-toggle="tooltip"
                                    data-placement="top"
                                    title="Verificare l'email" />)}
                            </span>
                        </td>
                        <td>{row.first_name}</td>
                        <td>{row.last_name}</td>
                        <td>{row.email}</td>
                        <td>{row.profession_group}</td>
                        <td>{row.profession}</td>
                        <td>{row.city}</td>
                        <td>{row.country}</td>
                        <td>{row.state}</td>
                        <td>{row.client_roles}</td>
                    </tr>
                })}
            </tbody>
        </table>
    </div>;
}

export default UtentiTable;
