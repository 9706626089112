import React, {useState, useEffect, useContext} from 'react'
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import DeleteBookingContents from '../components/booking_events/DeleteBookingContents';
import DeleteParticipantBooking from '../components/booking_events/DeleteParticipantBooking';
import EventAddPeopleModal from '../components/booking_events/EventAddPeopleModal';
import { getUrl } from '../utils/functions';
import Footer from '../components/layout/Footer';
import Header from '../components/layout/Header';
import Main from '../components/layout/Main';
import Sidebar from '../components/layout/Sidebar';
import SideBarContents from '../components/layout/SideBarContents';
import PopUpHatched from '../components/PopUpHatched';
import SmallPopUpHatched from '../components/SmallPopUpHatched';
import { useUser } from '../context/UserContext';
import "../css/addToBooking.css"
import plus_icon from "../img/prenotazione_icons/add_black_36dp.svg"
import { useAuth } from '../context/AuthContext';
import mobileContext from '../context/MobileContext';

function AddToBooking({}) {
	const [_, __, userInfo] = useUser();
	const locationData = useLocation();
	const history = useHistory();
	const { api } = useAuth();
	const mobileInfo = useContext(mobileContext);

	const bookingId = locationData?.state?.data?.id;
	const userId = locationData?.state?.data?.user_id;
	const title = locationData?.state?.title;
	const [bookingData, setBookingData] = useState({});
	const [selectedUsers, setSelectedUsers] = useState([]);

	const [reRender, setReRender] = useState(false);

	const [openDeleteUser, setOpenDeleteUser] = useState(false);
	const [showAddPeople, setShowAddPeople] = useState(false);
	const [showDeleteBooking, setShowDeleteBooking] = useState(false);
	
	async function getBooking(bookingId, userId) {
		try {
			let url = `bookings/booking/?booking_id=${bookingId}`;
			if (userId && locationData?.state?.fromBackoffice) {
				url += `&user_id=${userId}`;
			}
			let data = await api(getUrl("booking", url));
			setBookingData(data?.[0]);
		} catch (error) {
			console.log(error);
			setBookingData({});
		}
	}

    useEffect(() => {
		getBooking(bookingId, userId);
		
		for (let x = 0; x > document.querySelectorAll(".person > label > input").length; x++) {
			document.querySelectorAll(".person > label > input")[x].checked = false;
		}
		setSelectedUsers([]);
    }, [reRender])

	function reload() {
		setReRender(!reRender);
	}

	function reloadToBackOffice() {
		window.location = "/prenotazione_saltopiu";
	}

	const toggleSelected = function(selectedUser) {
		if (selectedUsers.indexOf(selectedUser) > -1) {
			setSelectedUsers(selectedUsers.filter((val, index) => {return val !== selectedUser}));
		} else {
			setSelectedUsers([...selectedUsers, selectedUser]);
		}
	}

	const appendSelected = function(toAppend) {
		setSelectedUsers([...selectedUsers, toAppend]);
	}

	const removeSelected = function(toRemove) {
		if (selectedUsers.indexOf(toRemove) > -1) {
			setSelectedUsers(selectedUsers.filter((val, index) => {return val !== toRemove}));
		}
	}

	const maxPeopleReached = function() {
		const limit = bookingData.type === "Standard" ? 2 : (bookingData.type === "Minori" ? 4 : 2);
		if (bookingData.number_of_participants >= limit) {
			return true;
		} else {
			return false;
		}
	}

    return (
        <div>
            <Header />
            {/* <div className="row w-100"> */}
            <div className="page-body">
                <Sidebar >
                    <SideBarContents />
                </Sidebar>
				<div className='separating-line'></div>
                <Main>
					<div className='main-add-container'>
						{mobileInfo.isMobile ? <p className='history-link' onClick={() => {
							history.goBack();
						}}>{"<"} Torna indietro</p> : ""}
						<p style={{"fontSize" : "32px", "lineHeight" : "38px", "fontWeight" : "600", "color" : "#16181E", "marginBottom" : "36px"}}>{title}</p>
						<div className='descriptor'>
							<svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            		  			<path fillRule="evenodd" clipRule="evenodd" d="M9.63656 0.649902C12.5182 0.649902 14.8684 3.00006 14.8684 5.88172C14.8684 8.76338 12.5182 11.1135 9.63656 11.1135C6.7549 11.1135 4.40474 8.76338 4.40474 5.88172C4.40474 3.00006 6.7549 0.649902 9.63656 0.649902ZM9.63656 11.0135C14.4209 11.0135 18.3229 14.9155 18.2729 19.7499L16.4957 19.6999C16.4957 15.9019 13.4346 12.8408 9.63656 12.8408C5.83853 12.8408 2.77747 15.9019 2.72747 19.7499L0.950195 19.6999C0.950195 14.9155 4.8522 11.0135 9.63656 11.0135ZM9.63656 2.47718C7.74798 2.47718 6.23201 3.99314 6.23201 5.88172C6.23201 7.7703 7.74798 9.28627 9.63656 9.28627C11.5251 9.28627 13.0411 7.7703 13.0411 5.88172C13.0411 3.99314 11.5251 2.47718 9.63656 2.47718Z" fill="black" fillOpacity="0.3" />
            				</svg>
							<p>Hai prenotato questo evento {() => {
									if (bookingData.partecipants.length == 1) {
										return "solo per te";
									} else if (bookingData.partecipants.length == 2) {
										return "per un amico";
									} else if (bookingData.partecipants.length == 3) {
										return "per due amici";
									}
								}}
							</p>
						</div>
						<div className='remove-people'>
							<div className='people-container'>
								{bookingData?.participants != undefined && Object.keys(bookingData?.participants).map((partecipantKey, index) => {
									return (<div className='person' key={`partecipante-${index}`}>
										<label><input disabled={(()=> {
											if (Object.keys(bookingData?.participants).length > 1) {
												if (bookingData?.participants[partecipantKey].type === "Booker") {
													return true;
												} else if (index === 0 && bookingData?.type === "Minori") {
													return true;
												} else {
													return false;
												}
											} else {
												return false;
											}
										})()} onInput={(event) => {
											// toggleSelected(event.target.value);
											if (event.target.checked) {
												appendSelected(event.target.value);
											} else {
												removeSelected(event.target.value);
											}



										}} type='checkbox' key={index} value={partecipantKey}></input>{
											(() => {
												const nome = bookingData?.participants[partecipantKey].nome.charAt(0).toUpperCase() + bookingData?.participants[partecipantKey].nome.slice(1);
												const cognome = bookingData?.participants[partecipantKey].cognome.charAt(0).toUpperCase() + bookingData?.participants[partecipantKey].cognome.slice(1);
												return nome + " " + cognome;
											})()
										}</label>
									</div>)
								})}
							</div>
							<div className='add-actions' style={{"marginLeft" : "-12px"}} onClick={() => {
								if (!maxPeopleReached()) {
									setShowAddPeople(true);
								}
							}}>
								<img className={maxPeopleReached() ? "add-icon-max-reached" : ""} src={plus_icon}></img>
								<p>{maxPeopleReached() ? "Non puoi aggiungere altri partecipanti" : "Aggiungi un altro partecipante"}</p>
							</div>
						</div>
						
						<p style={{"marginBottom" : "16px"}}>Se vuoi togliere dei minori dal tuo evento, selezione il loro nome e clicca su rimuovi selezionati.</p>
						<div className='actions-container-add-booking'>
							<button className='remove-selected' onClick={() => {
								if (Object.keys(bookingData?.participants).length > 1) {
									setOpenDeleteUser(true);
								} else {
									setShowDeleteBooking(true);
								}
							}}>Rimuovi Selezionati</button>
							<button className='select-all' onClick={() => {
								var checkboxes = document.querySelectorAll(".person > label");
								// checkboxes.forEach((singleEl) => {
								// 	singleEl.click();
								// })

								var checkboxesValues = []
								checkboxes.forEach((checkbox) => {
									if (!checkbox.children[0].disabled) {
										if (checkbox.children[0].checked) {
											checkbox.children[0].checked = false;
										} else {
											checkbox.children[0].checked = true;
											checkboxesValues.push(checkbox.children[0].value);
										}
									}
								})
								setSelectedUsers(checkboxesValues);
							}}>Seleziona tutti</button>
							{locationData.state.fromBackoffice === true ? (
								<div className="buttons-styled" style={{"width" : "100%", "marginBottom" : "24px", "marginTop" : "8px"}}>
                                	<button className="button-message back" onClick={() => {
										window.location = "/prenotazione_saltopiu";
									}}> Torna al Back Office</button>
                            	</div>
							) : ""}
							<p className='delete-booking-text' style={{"cursor" : "pointer"}} onClick={() => {
								setShowDeleteBooking(true);
							}}>Elimina questa prenotazione</p>

						</div>
					</div>
				{openDeleteUser && selectedUsers.length > 0 && (
					<PopUpHatched setShowModal={setOpenDeleteUser}>
						<DeleteParticipantBooking
							id={selectedUsers}
                    		setShowModal={setOpenDeleteUser}
                    		data={bookingData}
							title={"Sei sicuro di voler eliminare questi partecipanti?"}
							description={"Vuoi tornare indietro o cancellare questi partecipanti?"}
                    		refreshBookedEvent={reload}>
						</DeleteParticipantBooking>
					</PopUpHatched>
				)}
				{showAddPeople && (
					<PopUpHatched setShowModal={setShowAddPeople}>
						<EventAddPeopleModal
							setShowModal={setShowAddPeople}
							data = {bookingData}
							type = {bookingData?.type}
							refreshBooking = {reload}
						></EventAddPeopleModal>
					</PopUpHatched>
				)}
				{showDeleteBooking && (
					<SmallPopUpHatched setShowModal={setShowDeleteBooking}>
						<DeleteBookingContents
						id={showDeleteBooking}
						setShowModal={setShowDeleteBooking}
						data={bookingData}
						refreshBooking = {locationData.state.fromBackoffice === true ? reloadToBackOffice : reload}
                		></DeleteBookingContents>
					</SmallPopUpHatched>
				)}
                </Main>
            </div>
            <Footer showNewsletter={false} />
        </div>
    )
}

export default AddToBooking
