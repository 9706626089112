import React from "react";
import Logo from "../../img/footer/logo_salto 3.png";
import instagram from "../../img/icon/ig-footer.png";
import youtube from "../../img/icon/yt-footer.png";
import flickr from "../../img/icon/fl-footer.png";
import facebook from "../../img/icon/fb-footer.png";
import twitter from "../../img/icon/tw-footer.png";
import tiktok from "../../img/icon/tiktok-footer.png"
import "../../css/footer.css"
import ConsentModal from "../newsletter_consent/ConsentModal";

class Footer extends React.Component {
	state = {
		showNewsletterSection: this.props.showNewsletter === undefined ? true : false,
		showNewsletterModal: false
	}

	showModal = (bool) => {
		this.setState({ showNewsletterModal: bool });
	}

	render() {
		return (
			<div className="footer-main-container">
				<footer className="container-fluid">
					<div className="row footer-row">
						<div className="col-12 col-lg-3 col-md-auto d-flex flex-column mt-3">
							<div className="footer-icon-container">
								<p className="logo-label d-none d-md-flex">un progetto di</p>
								<a href="https://salonelibro.it" target="_blank" rel="noreferrer">
									<img className="logo-salto-footer" src={Logo} alt="" />
								</a>
							</div>
						</div>
						<div className="col-md-7 mt-3">
							<div className="row">
								<div className="col-md-10">
									<div className="row d-none d-md-flex">
										<div className="col-12">
											<p className="footer-p">
												<a href="mailto:saltopiu@salonelibro.it"
													target="_blank"
													rel="noreferrer">saltopiu@salonelibro.it
												</a>
											</p>
										</div>
									</div>
									<div className="row">
										<div className="col-12 d-flex flex-wrap flex-md-nowrap justify-content-between">
											<div className="text-left">
												<a href="https://www.salonelibro.it/privacy-policy.html" target="_blank"
													rel="noreferrer">
													<p className="gray-text mb-2 footer-p">Privacy Policy</p>
												</a>
											</div>
											<div className="text-left">
												<a href="https://www.salonelibro.it/privacy-policy.html" target="_blank"
													rel="noreferrer">
													<p className="gray-text mb-2 footer-p">Cookie Policy</p>
												</a>
											</div>
											<div className="text-left">
												<a href="#" target="_blank" rel="noreferrer">
													<p className="gray-text mb-2 footer-p">
														condizioni generali di
														vendita
													</p>
												</a>
											</div>
											<div className="text-left">
												<a href="https://www.salonelibro.it/dati-societari.html" target="_blank"
													rel="noreferrer">
													<p className="gray-text mb-2 footer-p">Dati societari</p>
												</a>
											</div>
										</div>
									</div>
									<div className="row d-none d-md-flex">
										<div className="col-12">
											<p className="footer-p-small mb-1 gray-text">
												Salone Libro srl · Via Giannone, 10 10121 Torino (TO) · P.IVA
												12057500014 info@salonelibro.it
											</p>
										</div>
									</div>
									<div className="row  d-none d-md-flex">
										<div className="col-12">
											<p className="footer-p-small gray-text">
												Associazione Culturale Torino, la Città del Libro · Via Giannone, 10
												10121 Torino (TO) · P.IVA: 97841070010 ·
												torinocittadellibro@legalmail.it
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-2 mt-3">
							<div className="row w-100 footer-icons">
								<div className="col-12 d-none d-flex">
									<a  href="https://www.facebook.com/SaloneLibroTorino"
										target="_blank" rel="noreferrer">
										<img className="foot-logo" src={facebook} alt=""></img>
									</a>
									<a  href="https://www.instagram.com/salonelibro/" target="_blank"
										rel="noreferrer">
										<img className="foot-logo" src={instagram} alt=""></img>
									</a>
									<a  href="https://twitter.com/SalonedelLibro" target="_blank"
										rel="noreferrer">
										<img className="foot-logo" src={twitter} alt=""></img>
									</a>
									<a  href="https://www.youtube.com/user/FondazioneLibro"
										target="_blank" rel="noreferrer">
										<img className="foot-logo" src={youtube} alt=""></img>
									</a>
									<a href="https://www.flickr.com/photos/33302214@N06"
										target="_blank" rel="noreferrer">
										<img className="foot-logo" src={flickr} alt=""></img>
									</a>
									<a href="https://www.tiktok.com/@salonelibro?lang=it-IT"
										target="_blank" rel="noreferrer" style={{"marginLeft" : "0.25rem"}}>
										<img className="foot-logo" src={tiktok} alt="" style={{"marginRight" : "0"}}></img>
									</a>
								</div>
							</div>
						</div>
					</div>
				</footer>
				{this.state.showNewsletterModal && (
					<ConsentModal showModal={this.showModal}></ConsentModal>
				)}
			</div>
		)
	}
}

export default Footer;
