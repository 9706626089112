import React, { useState, useEffect, useRef } from "react";
import Footer from "../components/layout/Footer";
import Sidebar from "../components/layout/Sidebar";
import Main from "../components/layout/Main";
import Header from "../components/layout/Header";
import { useAuth } from "../context/AuthContext";
import { getUrl } from "../utils/functions";
import { ReactComponent as Loader } from "../img/loader.svg";
import Form from "react-bootstrap/Form";
import CheckBoxInput from "../components/inputs/CheckBoxInput";
import TextField from "../components/inputs/TextField";
import SelectInput from "../components/inputs/SelectInput";
import { useHistory } from "react-router";
import { getListOfValueJson, getAllCountries, getCitiesOfState, getStatesOfCountry } from "../utils/data";
import BuonoDaLeggerePic from "../img/buonodaleggere/buono_da_leggere_2024.jpg";
import SideBarContents from "../components/layout/SideBarContents";

const getDefaultValues = (formFields) => {
	const values = {};
	if (formFields !== null) {
		formFields?.forEach((field) => {
			if (field.type === "bool") {
				values[field.code] = field.value ? true : false;
				return;
			}
			values[field.code] = field.value;
		});
	}
	return values;
};

const fetchPageContents = async (api, setData, setLoading, setFieldValues) => {
	try {
		setLoading(true);
		let data = await api(getUrl("booking", "buonodaleggere/getstate/"));
		if (data?.form?.formFields != null) {
			data?.form?.formfields?.sort((a, b) => {
				// a, b are one of the objects inside the data.form_fields
				if (a.row === b.row) {
					return a.order < b.order ? -1 : 1;
				}
				return a.row < b.row ? -1 : 1;
			});
		}
		setData(data);
		setLoading(false);
		setFieldValues(getDefaultValues(data?.form?.formFields));
	} catch (error) {
		setLoading(false);
		setData(null);
	}
};

function BuonoDaLeggere() {
	const { api } = useAuth();
	const history = useHistory();
	const formRef = useRef();
	const [loading, setLoading] = useState(null);
	const [data, setData] = useState(null);
	const [fieldValues, setFieldValues] = useState({});
	const [error, setError] = useState(null);
	const [showMessageIntoPopUp, setShowMessageIntoPopUp] = useState(null);

	useEffect(() => {
		fetchPageContents(api, setData, setLoading, setFieldValues);
	}, []);

	const handleOnChange = (event) => {
		setFieldValues((oldValues) => ({ ...oldValues, [event.target.name]: event.target.value }));
	};

	const sortFields = () => {
		const rows = [];
		let items = [];
		let previousRow = undefined;
		for (let i = 0; i < data?.form?.formfields?.length; i++) {
			if (previousRow == null) {
				previousRow = data?.form?.formfields[i].row;
			}
			if (data?.form?.formfields[i].row !== previousRow) {
				rows.push(items);
				items = [];
				previousRow = data?.form?.formfields[i].row;
				items.push(data?.form?.formfields[i]);
			} else {
				items.push(data?.form?.formfields[i]);
			}
		}
		if (items.length > 0) {
			rows.push(items);
		}
		return rows;
	};
	const rows = sortFields();

	const handleOnClick = () => {
		switch (data.button.action) {
			case "send_form":
				if (formRef.current.checkValidity()) {
					submitForm();
				} else {
					formRef.current.reportValidity();
				}
				return;
			case "go_to_external":
				window.open(data.button.link, "_blank");
				return;
			case "go_to_internal":
				history.push(data.button.link);
				return;
			case "refresh":
				//refreshPopUpContents();
				return;
			default:
				return;
		}
	};

	const submitForm = async () => {
		let values = {};
		Object.keys(fieldValues).forEach((key) => {
			if (["COUNTRY", "STATE", "CITY"].includes(key)) {
				const element = formRef.current.querySelector(`select[name="${key}"]`);
				if (element) {
					const name = element.querySelector(`option[value="${fieldValues[key]}"]`).getAttribute("data-name");
					values[key] = name;
					return;
				}
			}
			values[key] = fieldValues[key];
		});

		let body = data.form.api.body;
		Object.keys(values)?.forEach((key) => {
			body = body.replaceAll(key, values[key]);
		});
		body = JSON.parse(body);

		try {
			let response = await api(data.form.api.baseurl, data.form.api.method.toLowerCase(), body);
			if (response?.success === false) {
				setShowMessageIntoPopUp(response?.message?.error);
				return;
			} else if (response?.success === true) {
				fetchPageContents(api, setData, setLoading, setFieldValues);
				return;
			}
			setFieldValues({});
		} catch (error) {
			setError(error?.response?.detail ?? "An error occured by the server");
		}
	};

	return (
		<div>
			<Header />
			<div className="page-body">
				<Sidebar>
					<SideBarContents />
				</Sidebar>
				<div className="separating-line"></div>
				<Main>
					{loading && (
						<div className="loading fixed">
							<Loader style={{ width: "130px", height: "130px" }} />{" "}
						</div>
					)}
					<div className="main-padding">
						<div className="accreditation-page mb-5">
							<div className="title">
								<h1>{data?.header?.title}</h1>
							</div>
							<div className="text-body">
								<p dangerouslySetInnerHTML={{ __html: data?.header?.body }}></p>
								{showMessageIntoPopUp != null && <p className="text-uppercase">{showMessageIntoPopUp}</p>}
								{error != null && <p className="text-danger">{error}</p>}
							</div>
							{loading === false && data?.form?.formfields && (
								<Form ref={formRef}>
									{rows.map((row) => {
										return (
											<div className="row">
												{row.map((item) => {
													if (item.LOV.length !== 0 || item.LOV_json) {
														let options = [];
														if (item.LOV.length !== 0) {
															options = item.LOV.map((option) => {
																return { id: option, name: option };
															});
														} else {
															if (item.LOV_json === "country.json") {
																options = getAllCountries();
															} else if (item.LOV_json === "state.json") {
																let country = data.form.formfields.find(
																	(field) => field.LOV_json === "country.json"
																);
																options = getStatesOfCountry(fieldValues[country.code]);
															} else if (item.LOV_json === "city.json") {
																let state = data.form.formfields.find(
																	(field) => field.LOV_json === "state.json"
																);
																options = getCitiesOfState(fieldValues[state.code]);
															} else if (item.LOV_json === "profession.json") {
																options = getListOfValueJson("profession.json");
															} else {
																options = getListOfValueJson(item.LOV_json);
															}
														}
														return (
															<SelectInput
																className={
																	row.length === 2
																		? "col-sm-6"
																		: row.length === 3
																		? "col-sm-4"
																		: "col"
																}
																name={item.code}
																label={item.label}
																required={item.mandatory}
																type="text"
																options={options}
																disabled={!item.editable}
																onChange={handleOnChange}
																value={fieldValues[item.code]}
																//placeholder={`Inserisci ${item.field_label}`}
															/>
														);
													} else if (item.type === "bool") {
														return (
															<CheckBoxInput
																className={
																	row.length === 2
																		? "col-sm-6"
																		: row.length === 3
																		? "col-sm-4"
																		: "col"
																}
																name={item.code}
																label={item.label}
																required={item.mandatory}
																disabled={!item.editable}
																onChange={(event) => {
																	setFieldValues((oldValues) => ({
																		...oldValues,
																		[event.target.name]: event.target.checked ? true : false,
																	}));
																}}
																value={fieldValues[item.code]}
															/>
														);
													} else {
														return (
															<TextField
																className={
																	row.length === 2
																		? "col-sm-6"
																		: row.length === 3
																		? "col-sm-4"
																		: "col"
																}
																name={item.code}
																label={item.label}
																required={item.mandatory}
																disabled={!item.editable}
																//type={item.type}
																type={item.type === "datetime" ? "date" : "text"}
																onChange={handleOnChange}
																value={fieldValues[item.code]}
															/>
														);
													}
												})}
											</div>
										);
									})}
								</Form>
							)}

							{data?.button != null && (
								<div className="button-action mt-3">
									<button
										className="blue-btn"
										onClick={handleOnClick}>
										{data?.button?.text}
									</button>
								</div>
							)}
						</div>

						<h1 className="h0 text-primary">Torna il Buono da leggere!</h1>
						<p style={{ fontSize: "24px", marginTop: "15px" }}>Un’occasione per ampliare la tua biblioteca personale.</p>
						<div className="w-100 text-center my-3">
							<img
								className="img-fluid"
								src={BuonoDaLeggerePic}
								style={{ maxWidth: "70%" }}
							/>
						</div>
						<p style={{ fontSize: "24px" }}>
							<br />
							<b>Richiedi il tuo Buono da leggere da 10 euro e spendilo in libri tra gli stand del Salone!</b>
							<br/>
							In cinque giorni, grazie al sostegno del pubblico, verranno distribuiti <b>8.000 Buoni</b> del valore di 
							10 euro utilizzabili per acquistare libri di narrativa, saggistica, fumetti, albi illustrati e 
							audiolibri. Possono fare richiesta ragazze e ragazzi dai 14 ai 21 anni e per le studentesse e 
							gli studenti delle università e degli istituti AFAM del Piemonte. 
							<br/>I Buoni saranno disponibili 
							fino a esaurimento.<br/>
							<br />
							<b>Scarica il tuo voucher e recati al punto di distribuzione Buoni giovani 14 - 21 e universitari 
								(stand R40-S41, Pad. 3).</b>
							<br />
							Ricorda di portare con te la carta di identità o un altro documento che attesti la tua residenza 
							o il tuo domicilio in Piemonte oppure la Smart Card assieme al tuo biglietto di ingresso e l'app Campus
							Piemonte ID Meal (se studi all'Università). 
							Qui ti verrà consegnato un Buono da spendere al Salone tra gli editori aderenti 
							(li riconoscerai dalla locandina esposta allo stand). Potrai ritirare il tuo Buono allo 
							stand dalle ore 10.00 alle ore 13.00 e dalle ore 14.00 alle ore 19.00.<br/>
							<b><u>Questo voucher non è valido per le classi.</u></b></p>
					</div>
				</Main>
			</div>
			<Footer />
		</div>
	);
}

export default BuonoDaLeggere;
