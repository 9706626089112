import React from 'react'
import '../css/pagination.css';



function Pagination({ hasPrevious, hasNext, currentPage, onClick }) {
    const getItems = () => {
        let items = []
        items.push(<button className="button-pagination" onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            onClick("prev")
        }} disabled={!hasPrevious ? "disabled" : ""}>&lt;</button>)
        items.push(<button className="button-pagination">{currentPage}</button>)
        items.push(<button className="button-pagination" onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            onClick("next")
        }} disabled={!hasNext ? "disabled" : ""}>&gt;</button>)
        
        return items
    }


    return (
        <div className="pagination">
            {getItems()}
        </div>
    )
}

export default Pagination
