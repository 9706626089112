import React from 'react'
import { useParams } from 'react-router';
import AccessTableInfo from './AccessTableInfo';
import CurrentAccessInfo from './CurrentAccessInfo';

function MinoriValidation({ data }) {

    const participants = Object.keys(data.participants)
        .map((participantKey) => data.participants[participantKey]); //participants=[{}, {}, ...]

    const { participant_id } = useParams()
    const currentAccess = (participants.filter((participant) => participant.id === participant_id))[0];

    return (
        <div className="container-success-message">
            {currentAccess != null && (
                <>
                    <CurrentAccessInfo
                        currentAccess={currentAccess}
                    />
                    <AccessTableInfo
                        data={data}
                        currentAccess={currentAccess}
                    />
                </>)}

        </div>
    )
}


export default MinoriValidation
