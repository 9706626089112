import { createContext } from "react";

const mobile = {
	isMobile : (() => {
		return window.innerWidth < 825;
	})()
}

const mobileContext = createContext(mobile);

export default mobileContext