import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import Footer from '../components/layout/Footer'
import Header from '../components/layout/Header'
import Main from '../components/layout/Main'
import Sidebar from '../components/layout/Sidebar'
import { useAuth } from '../context/AuthContext'
import { useUser } from '../context/UserContext'
import { getUrl } from '../utils/functions'
import { ReactComponent as Error } from '../img/icon/error.svg'
import '../css/bookingeventvalidation.css'
import Tick from '../img/icon/tick.svg'
import { Form } from "react-bootstrap";
import SideBarContents from '../components/layout/SideBarContents'


function BuonoDaLeggereValidation() {
    const [_, __, userInfo] = useUser()
    const { buonodaleggere_code } = useParams()
    const { api } = useAuth()
    const history = useHistory()

    const [validateQRCode, setValidateQRCode] = useState({
        status: false,
        data: null,
        loading: false,
        error: null
    })
    const [qRCode, setQRCodeCode] = useState("");

    const fetchValidateQRCode = async (code) => {

        try {
            setValidateQRCode({ ...validateQRCode, loading: true })
            let data = await api(getUrl("booking_admin", `buonodaleggerevalidation/validatecoupon?coupon_code=${code}`), "post")
            if (data?.success === true) {
                setValidateQRCode({
                    status: true,
                    data: data,
                    loading: false,
                    error: null
                })
            } else if (data?.success === false) {
                setValidateQRCode({
                    status: false,
                    data: null,
                    loading: false,
                    error: data?.message?.error
                })
            }
        } catch (error) {
            setValidateQRCode({
                status: false,
                data: null,
                loading: false,
                error: error?.response?.data?.detail?.[0]?.msg || error?.response?.data?.detail || "An error occured by the server!"
            })
        }
    }

    useEffect(() => {
        if (buonodaleggere_code) {
            fetchValidateQRCode(buonodaleggere_code)
        }
    }, [buonodaleggere_code])

    const handleSubmit = () => {
        history.push(`/validazione_buonodaleggere/${qRCode}`)
    }

    return (
        <div>
            <Header />
            {/* <div className="row w-100" style={{ minHeight: "68vh" }}> */}
            <div className="page-body">
                <Sidebar>
                    {/* <p>In questo spazio trovi il meglio della produzione culturale curata dal Salone: video, interviste, lezioni, podcast, approfondimenti. Una grande libreria a tua disposizione: scegli un contenuto e inizia il viaggio.</p>
                    <br />
                    <br /> */}
                    <SideBarContents />
                </Sidebar>
                <div className='separating-line'></div>
                <Main>
                    {!buonodaleggere_code && (
                        <div className="p-3">
                            <div className="row">
                                <div className="col-12">
                                    <p style={{ fontWeight: "bold" }}>Inserisci Codice libreria </p>
                                </div>
                            </div >
                            <Form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-8">
                                        <input
                                            type="text"
                                            id="libcode"
                                            placeholder="codice libreria..."
                                            onChange={(e) => setQRCodeCode(e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-8">
                                        <button type="submit" className="button blue-btn w-100">
                                            Valida il buono
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    )}
                    {validateQRCode?.error != null && (
                        <div className="container-error-message">
                            <Error />
                            <h3 className="text-danger">{validateQRCode?.error}</h3>
                        </div>

                    )}
                    {validateQRCode?.loading === false && validateQRCode?.data != null && (
                        <div className="container-tick-info">
                            <img src={Tick} />
                            <h2>{validateQRCode?.data?.message?.validate_coupon}</h2>
                        </div>
                    )}
                </Main>
            </div>
            <Footer />
        </div>
    )
}

export default BuonoDaLeggereValidation
