
export const zeroPad = (num) => {
    return ('0' + num).slice(-2);
};
export function getYYYYMMDDHHMM(date) {

    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minutes = date.getMinutes();
    return `${year}-${zeroPad(month)}-${zeroPad(day)} ${zeroPad(hour)}:${zeroPad(minutes)}`;
}

export function getYYYYMMDDHH(date, seprator = "/") {

    const fullDateTime = zeroPad(date.getDate()) + seprator + zeroPad(date.getMonth() + 1) + seprator + date.getFullYear() + " - " + zeroPad(date.getHours()) + ":" + zeroPad(date.getMinutes()) + ":" + zeroPad(date.getSeconds())
    return fullDateTime;
}

export function getOffset(el) {
    var _x = 0;
    var _y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
        _x += el.offsetLeft - el.scrollLeft;
        _y += el.offsetTop - el.scrollTop;
        el = el.offsetParent;
    }
    return { top: _y, left: _x };
};

export const biuldNumeroAlunni = () => {
    let arr = [];
    for (let i = 1; i <= 50; i++) {
        arr.push(<option key={i} value={i}>{i}</option>)
    }
    return arr;
}

export const getUrl = (service, endPoint) => {
    switch (service) {
        case "user":
            return process.env.REACT_APP_SERVER_URL_USER_PROFILE + endPoint
        case "dashboard":
            return process.env.REACT_APP_SERVER_URL_USER_DASHBOARD + endPoint
        case "media":
            return process.env.REACT_APP_SERVER_URL_USER_MEDIA + endPoint
        case "programma":
            return process.env.REACT_APP_PROGRAMMA_BASE_URL + endPoint
        case "booking":
            return process.env.REACT_APP_BOOKING_BASE_URL + endPoint
        case "booking_admin":
            return process.env.REACT_APP_BOOKING_ADMIN_BASE_URL + endPoint
        case "user_admin":
            return process.env.REACT_APP_USER_ADMIN_BASE_URL + endPoint
        case "coupon_admin":
            return process.env.REACT_APP_COUPON_ADMIN_URL + endPoint
        default:
        //""
    }
}

export const generateFileName = (prefix = "Export utenti", extension = ".csv") => {
    let moment = new Date();
    const zeroPad = (num) => {
        return ('0' + num).slice(-2);
    };
    let fileName = prefix + " - " + moment.getFullYear() + "" + zeroPad(moment.getMonth() + 1) + "" + zeroPad(moment.getDate()) + "_" + zeroPad(moment.getHours()) + "" + zeroPad(moment.getMinutes()) + "" + zeroPad(moment.getSeconds()) + extension;
    return fileName;
}

export const downloadBlobFile = async (api, config, fileName, loadingCallBack) => {
    try {
        const a = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);
        loadingCallBack?.(true);


        let response = await api(getUrl(config.service, config.url), config.method, config.data, config.headers)
        //let response = await lcv.get(`errorcsv/table/export?errorCsv_id=${id}`, { data: JSON.stringify(filterBody) })
        loadingCallBack?.(false);
        const blobFile = new Blob([response], { type: 'text/csv' });
        console.log("blob", response);
        const url = window.URL.createObjectURL(blobFile);
        a.href = url;

        a.download = fileName; //Export - {datetime.datetime.now().strftime('%Y%m%d_%H%M%S')}.csv
        a.click();
        window.URL.revokeObjectURL(url);
    } catch (error) {
        loadingCallBack?.(false);
    }
}