import React, { createContext, useContext, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { getUrl } from '../utils/functions';



const UserContext = createContext(null);


const UserProvider = ({ children }) => {
    const [user, setUser] = React.useState(null);
    const [userInfo, setUserInfo] = React.useState(null);
    const {auth, api, setAuth, token} = useAuth();
    const [loading, setLoading] = React.useState(auth);

    const fetchUser = async () => {
        try {
            setLoading(true);
            let data = await api(getUrl("user", "userprofile/userComplete")) ;
            let userInfoData = await api(getUrl("user", "userprofile/userInfo")) ;
            setUser(data);
            setUserInfo(userInfoData);
        }catch(error) {
            setUser(null);
            setUserInfo(null);
        }
        setLoading(false);
    }

   
    useEffect(() => {
        if(auth){
            fetchUser();
        }else{
            setUser(null);
            setUserInfo(null)
        }
    }, [auth])
    return (
        <UserContext.Provider value={[user, fetchUser, setUser, userInfo, setUserInfo, loading]}>
            {children}
        </UserContext.Provider>
    )
}

const useUser = (redirect = true) => {
    const [user, fetchUser, setUser, userInfo, setUserInfo] = useContext(UserContext);
    return [user, fetchUser, userInfo];
}


const useLogOut = () => {
    const [user, fetchUser, setUser] = useContext(UserContext);
    const {logout} = useAuth(); 
    const userLogout = () =>{
        setUser(null) 
        logout();
    }
   
    return userLogout;
}

export { UserContext, UserProvider, useUser, useLogOut };