import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import "../../css/eventcomponent.css";
import { ReactComponent as MessageIcon } from "../../img/icon/message_icon.svg";
import ButtonEvent, { getEventType } from "./ButtonEvent";
import { ReactComponent as NotificationBell } from "../../img/icon/notification.svg";
import { useAuth } from "../../context/AuthContext";
import { getUrl } from "../../utils/functions";

export const StripedColor = styled.div`
	//border: 1px solid black;
	margin-right: 10px;
	width: 50px;
	background-color: ${({ color }) => color};
	//border: .5px solid ${({ color }) => (color === colors.unbooked ? "#c1bdbd" : color)};
	display: flex;
	align-items: center;
	justify-content: center;
	height: ${({ isMobile }) => (isMobile ? "auto" : "100px")};
	flex-shrink: 0;
	svg {
		cursor: pointer;
	}
`;
export const colors = {
	unbooked: "white",
	deleted: "red",
	school: "green",
	snooze: "#fc0",
	booked: "#0065F2",
};

export const getColorForEvent = (data, bookedEvent) => {
	let color = colors.unbooked;
	const eventType = getEventType(data);
	//return "yellow";

	if (bookedEvent) {
		// let snoozeTimeStamp = new Date(bookedEvent.last_snooze_timestamp)
		let snoozeTimeStamp = bookedEvent.last_snooze_timestamp.includes("+")
			? new Date(bookedEvent.last_snooze_timestamp.split("+")[0])
			: new Date(bookedEvent.last_snooze_timestamp);
		let updatedAtTime = new Date(data.updated_at);

		if (data.deleted_at !== "" && data.deleted_at != null) {
			color = colors.deleted;
		} else if ((data.deleted_at == null || data.deleted_at === "") && updatedAtTime.getTime() >= snoozeTimeStamp.getTime()) {
			color = colors.snooze;
		} else if ((data.deleted_at == null || data.deleted_at === "") && eventType === "Scuole") {
			color = colors.school;
		} else if ((data.deleted_at == null || data.deleted_at === "") && eventType !== "Scuole") {
			color = colors.booked;
		}
	}
	return color;
};

export const getDuration = (duration) => {
	if (duration < 60) {
		return `${duration} minuti`;
	}
	let hour = Math.floor(duration / 60);
	let minutes = duration % 60;
	if (minutes === 0) {
		return `${hour} Or${hour === 1 ? "a" : "e"}`;
	}
	return `${hour} Or${hour === 1 ? "a" : "e"} ${minutes} minuti`;
};

function EventComponent({ data, bookedEvent, isMobile }) {
	const history = useHistory();
	const { api } = useAuth();
	const [color, setColor] = useState(getColorForEvent(data, bookedEvent));

	useEffect(() => {
		const newColor = getColorForEvent(data, bookedEvent);
		if (newColor !== color) {
			setColor(newColor);
		}
	}, [bookedEvent?.id]);

	const snoozeTimeStamp = async () => {
		try {
			let response = await api(getUrl("booking", `bookings/snoozeTimestamp/?booking_id=${bookedEvent.id}`), "post");
			const eventType = getEventType(data);
			if (eventType === "Scuole") {
				setColor(colors.school);
				return;
			}
			setColor(colors.booked);
		} catch (error) {}
	};

	return (
		<div className="container-event-component">
			<div className="event-section-one">
				<div className="striped-responsive">
					<div className="data-title-place">
						<div className="data-event">
							{data.date}, Ore {data.time}, Durata {getDuration(+data.duration)}
						</div>
						<div className="event-title">
							<h2>{data.title}</h2>
						</div>
						<div className="event-subtitle">
							<h3>{data.subtitle}</h3>
						</div>
						<div className="event-place">
							<h5>
								{data.location.name}, {data.location.address}
							</h5>
						</div>
					</div>
				</div>
				<div className="meeting-with">
					<p>incontro con</p>
					<h4>{data.authors.map((author) => author.first_name + " " + author.last_name).join(", ")}</h4>
				</div>

				 <ButtonEvent
					data={data}
				 bookedEvent={bookedEvent}/>

				<div className="a-cura-di">
					{data?.exhibitors.length > 0 ? (
						<>
							<p>A cura di</p>
							<h5>{data.exhibitors.map((exhibitor) => exhibitor.name).join(", ")}</h5>
						</>
					) : (
						""
					)}
				</div>
				<div className="event-description">
					<p
						className={`${data.description.length > process.env.REACT_APP_CHARACTER_LIMIT ? "has-more" : ""}`}
						onClick={() => {
							if (data.description.length > process.env.REACT_APP_CHARACTER_LIMIT) {
								history.push(`/evento/${data.id}`);
							}
						}}
						dangerouslySetInnerHTML={{
							__html:
								data.description.substr(0, process.env.REACT_APP_CHARACTER_LIMIT) +
								(data.description.length > process.env.REACT_APP_CHARACTER_LIMIT
									? "<span>[continua a leggere]</span>"
									: ""),
						}}></p>
				</div>

				{data.tags
					.filter((tag) => tag.tag_group.id === +process.env.REACT_APP_PARTE_DEL_PERCORSO)
					.map(({ key, tag }) => {
						return (
							<div
								className="message-icon"
								key={"message_icon_" + key}>
								<MessageIcon />
								<div className="parte-del-percorso">
									<h4>Parte del percorso </h4>
									<h4>{tag?.name ? tag.name : ""}</h4>
								</div>
							</div>
						);
					})}

				<div className="categories">
					{data.tags
						.filter((tag) => tag.tag_group.id !== +process.env.REACT_APP_PARTE_DEL_PERCORSO)
						.map((tag) => (
							<p>{tag.name.replaceAll("_", " ")}</p>
						))}
				</div>
			</div>
		</div>
	);
}

export default EventComponent;
