import { createContext, useContext, useState } from "react";

const ValuesFilteredContext = createContext(null)

const ValuesFilterProvider = ({ children }) => {
    const [valuesFiltered, setValuesFiltered] = useState({})

    return (
        <ValuesFilteredContext.Provider value={[ valuesFiltered, setValuesFiltered ]}>
            {children}
        </ValuesFilteredContext.Provider>
    )
}

const useValuesFiltered = () => {
    const context = useContext(ValuesFilteredContext)
    return context
}

export { ValuesFilterProvider, useValuesFiltered }