import React, { useContext, useState } from 'react'
import { UserContext } from '../../context/UserContext'
import { useAuth } from '../../context/AuthContext'
import { getUrl } from '../../utils/functions'
import '../../css/sidebarcontents.css';
import CustomPopUp from '../CustomPopUp';
import mobileContext from '../../context/MobileContext';
import newsLetterImage from '../../img/newsletter/SSO_Saltoplus.png'


function ConsentModal({showModal}) {
	const [user, updateUser, __, userInfo, ___, loading] = useContext(UserContext);
	const { api } = useAuth();
	const mobileInfo = useContext(mobileContext);
	const [error, setError] = useState(false);
	const [callSuccess, setCallSuccess] = useState(false);

	const updateNewsletterConsent = async (consentParams) => {
		let response;
		try {
			response = await api(getUrl("user", "userprofile/newsletter"), "post", consentParams);
			setCallSuccess(true);
		} catch (error) {
			setError("C'è stato un errore nell'aggiornamento del consenso");
		}
	}

    return (
		<div>
			<CustomPopUp
				setShowModal={showModal}
				customModalStyle={""}				
				customBoxStyle={mobileInfo.isMobile ? {"maxWidth" : "90%"} : {"minWidth" : "800px"}}
				customBodyStyle={mobileInfo.isMobile ? {"padding" : "20px 20px"} : {"padding" : "0px 0px"}}
			>
				<div className="newsletter-modal-container">
					{mobileInfo.isMobile ? "" : (<div className='newsletter-right-image'>
						<img src={newsLetterImage}></img>
					</div>)}
					
					<div className='newsletter-left'>
						<div className="close-btn-container page-1-close-btn mt-3" onClick={() => {
							showModal(false);
						}}><button>✖</button></div>
						<div className='newsletter-header'>
							{callSuccess ? (<h1 className='newsletter-title'>
								Sei stato iscritto alla newsletter, grazie per la fiducia.
							</h1>) : (<>
								<h1 className='newsletter-title'>Iscrivimi alle newsletter</h1>
								<h3 className='newsletter-sub-title'>Iscriviti, troverai un mondo di novità.</h3>
							</>)}
							{/* sei stato iscritto alla newsletter, grazie per la fiducia. */}
						</div>
						<div className='newsletter-body' style={callSuccess ? {"display" : "none"} : {"":""}}>
							<p className='newsletter-p' >E-mail</p>
							<input placeholder='Inserisci la tua email...' defaultValue={userInfo.email} disabled></input>
							<div className='newsletter-checkbox-container'>
								<div className='checkbox-row'>
									<input id='cultural-consent' type='checkbox' defaultChecked={user.consent_send_cultural_newsletter}></input><label className='cultural-consent'>Mi voglio iscrivere alla newsletter culturale</label>
								</div>
								<div className='checkbox-row'>
									<input id='commercial-consent' type='checkbox' defaultChecked={user.consent_send_commercial_newsletter}></input><label className='cultural-consent'>Mi voglio iscrivere alla newsletter marketing</label>
								</div>
							</div>
							<p className='newsletter-p' >Cliccando confermi i termini di servizio. Puoi prendere visione dei termini <a href='https://www.salonelibro.it/privacy-policy.html'>qui</a></p>
							{error ? (<p style={{"color" : "red"}}>{error}</p>) : ""}
							<button className='button blue-btn' style={callSuccess ? {"display" : "none"} : {"width" : "100%", "height" : "50px"}} onClick={() => {
								const culturalConsent = document.getElementById("cultural-consent").checked;
								const commercialConsent = document.getElementById("commercial-consent").checked;
								updateNewsletterConsent({
									consent_send_cultural_newsletter : culturalConsent,
									consent_send_commercial_newsletter : commercialConsent
								})
							}}>Iscrivimi</button>
						</div>
						<button className='button blue-btn' style={callSuccess ? {"width" : "100%", "height" : "50px", "marginTop" : "200px"} : {"display":"none"}} onClick={() => {
							updateUser();
							window.location = "/dashboard";
						}}>Torna alla schermata principale</button>
					</div>
				</div>
			</CustomPopUp>
        </div>
    )
}

export default ConsentModal
