import React from 'react'
import { useParams } from 'react-router';
import Tick from '../../img/icon/tick.svg'
import { getYYYYMMDDHH } from '../../utils/functions';

function ScuolaValidation({ data }) {
    const participants = Object.keys(data.participants)
        .map((participantKey) => data.participants[participantKey]); //participants=[{}, {}, ...]


    const { participant_id } = useParams()
    const currentAccess = (participants.filter((participant) => participant.id === participant_id))[0];
    const accessDateTime = currentAccess ? new Date(currentAccess.access) : ""

    return (
        <div className="container-success-message">
            <div className="container-tick-info">
                <img src={Tick} alt="success" />
                <h2>Validazione accesso effettuata!</h2>
                {currentAccess != null && (
                    <div className="info-validation">
                        <h3>Dati della prenotazione:</h3>
                        <p>Nome della scuola: <b>{currentAccess.class_name}</b></p>
                        <p>Nome della classe: <b>{currentAccess.school_name}</b></p>
                        <p>Numero di studenti e accompagnatori: <b>{currentAccess.number_of_participants}</b></p>
                        <p>Data e ora accesso: <b>{getYYYYMMDDHH(accessDateTime)}</b></p>

                    </div>
                )}
            </div>

        </div>
    )
}

export default ScuolaValidation
