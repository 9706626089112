import React, { useState, useEffect, useRef } from 'react';
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import TextField from '../../components/inputs/TextField';
import SelectInput from '../../components/inputs/SelectInput';
import "../../css/users.css";
import Pagination from '../../components/Pagination';
import { useAuth } from '../../context/AuthContext';
import { getUrl } from '../../utils/functions';
import SearchFieldPrenotazioneSalto from '../../components/prenotazione_salto/SearchFieldPrenotazioneSalto';
import BuonoTable from '../../components/tablebuono/BuonoTable';
import { ReactComponent as Loader } from "../../img/loader.svg";
import { useValuesFiltered } from '../../context/ValuesFilteredContext';
import AdminNavbar from '../../components/layout/AdminNavbar';
import { ReactComponent as CleanFields } from '../../img/icon/filter-remove.svg';


function GestioneBuonoDaLeggere() {
    const { api } = useAuth()
    const [valuesFiltered, setValuesFiltered] = useValuesFiltered()
    const [values, setValues] = useState(valuesFiltered)
    const [bodyForExportTable, setBodyForExportTable] = useState(null)
    const [loading, setLoading] = useState(false)
    const [hasNextPage, setHasNextPage] = useState(false)
    const [filteredUsers, setFilteredUsers] = useState({
        status: false,
        data: null,
        recordsNumber: null,
        page: 1,
        error: null
    })
    const [valuePerPage, setValuePerPage] = useState(null)
    const valuesLengthRef = useRef(Object.keys(values).length)



    const searchUsers = async (value, setData, setLoading, page = 1, setHasNextPage) => {
        if (value.length > 2) {
            const limit = 8;//per page
            try {
                setLoading(true);
                let data = await api(getUrl("booking_admin", `backofficesaltopiubookings/user?search=${value}&skip=${((page - 1) * limit)}&limit=${limit}`));
                if (data?.length < limit) {
                    //alert(data?.length)
                    setHasNextPage((data.length === 0 && page > 1) ? page - 1 : page)
                }
                setData(data, data?.length < limit ? true : false)
            } catch (error) {
                setData(null)
            }
            setLoading(false)
        }
    }
    const handleOnChange = (event) => {
        setValues(oldValues => ({ ...oldValues, [event.target.name]: event.target.value }))
        setValuesFiltered(oldValues => ({ ...oldValues, [event.target.name]: event.target.value }))
    }

    const searchUsersCoupon = async (page = 1, id) => {
        const limit = valuePerPage?.limit ? valuePerPage.limit : 5
        let body = {
            limit: limit,
            //offset: 0
            offset: (page - 1) * limit
        }
        if (id != null) {
            body.user_id = id
        }
        if (values?.user != null) {
            body.user_id = values.user.id
        }
        Object.keys(values).filter(key => key !== "user").forEach((key) => {
            if (values[key] != null && values[key] !== "") {

                body[key] = values[key]
            }
        })
        setBodyForExportTable(body)

        try {
            setLoading(true)
            let data = await api(getUrl("coupon_admin", "backoffice_coupon/get_all_coupon"), "post", body)

            setLoading(false)
            if (data?.data?.length === 0 && page > 1) {
                setHasNextPage(false)
                return;
            }
            setFilteredUsers({
                status: true,
                data: data?.data,
                recordsNumber: data?.n_record,
                page: page,
                error: null
            })
            if (data?.data?.length < limit) {
                setHasNextPage(false)
            } else {
                setHasNextPage(true)
            }
        } catch (error) {
            setFilteredUsers({
                status: false,
                data: null,
                recordsNumber: null,
                page: 1,
                error: error?.response?.data?.detali?.[0]?.msg ?? "An error occured by the server"
            })
            setHasNextPage(false)
        }
    }

    useEffect(() => {
        const newValueLength = Object.keys(values).length
        if (newValueLength === 0 && valuesLengthRef.current > 0) {
            searchUsersCoupon()
        }
        valuesLengthRef.current = newValueLength
    }, [values])

    useEffect((id) => {
        searchUsersCoupon();
    }, [valuePerPage])

    const searchUsershByFilter = (id) => {
        searchUsersCoupon(1, id)
    }

    return <div>
        <Header />
        <div className="admin-pages-main">
            {loading && <div className="loading fixed" >
                <Loader />
            </div>}
            <AdminNavbar back="/admin_dashboard" title="Buono da leggere" />
            <div className="w-100">
                <div className="users-admin-page">
                    <div className="row mb-3">
                        <SearchFieldPrenotazioneSalto
                            className='col-md-5 col-10'
                            name="search_user"
                            onChange={(item) => {
                                setValues({ ...values, user: item })
                                setValuesFiltered({ ...values, user: item })
                            }}
                            type='text'
                            required={false}
                            placeholder='Cerca utente per cognome o email'
                            searchResults={searchUsers}
                            searchUsershByFilter={(id) => searchUsershByFilter(id)}
                            feedback={`${values?.user ? "feedback" : ""}`}
                            value={values?.user ? values?.user?.first_name + " " + values?.user?.last_name + ", " + values?.user?.email : ""}
                            onClick={
                                () => {
                                    if (values?.user?.id != null) {
                                        setValues({ ...values, user: undefined })
                                        setValuesFiltered({})
                                        return
                                    }
                                }
                            }
                            // prefix={values.user?.id && <div
                            //     className="delete-values"
                            //     onClick={() => {
                            //         setValues({ ...values, user: undefined })
                            //         setValuesFiltered({})
                            //     }}
                            // >
                            //     -
                            // </div>}
                        />
                    </div>
                    {/* <div className="row">
                        <TextField
                            label="Nome"
                            className="col-md-4"
                            name="name"
                            value={values?.user?.first_name}
                            feedback={`${values?.user?.first_name ? "feedback" : ""}`}
                            onChange={handleOnChange}
                            type="text"
                            required={false}
                            disabled={true}
                            prefix={values.user?.id && <div
                                className="delete-values"
                                onClick={() => {
                                    setValues({ ...values, user: undefined })
                                    setValuesFiltered({})
                                }}
                            >
                                -
                            </div>}
                        />
                        <TextField
                            label="Cognome"
                            className="col-md-4"
                            name="last_name"
                            value={values.user?.last_name}
                            feedback={`${values?.user?.last_name ? "feedback" : ""}`}
                            onChange={handleOnChange}
                            type="text"
                            required={false}
                            disabled={true}
                        />
                        <TextField
                            label="Email"
                            className="col-md-4"
                            name="email"
                            value={values.user?.email}
                            feedback={`${values?.user?.email ? "feedback" : ""}`}
                            onChange={handleOnChange}
                            type="text"
                            required={false}
                            disabled={true}
                        />
                    </div> */}
                    <div className="row">
                        <TextField
                            label="Codice buono"
                            className="col-md-4"
                            name="code"
                            value={(values.code)?.toUpperCase()}
                            feedback={`${values?.code ? "feedback" : ""}`}
                            onChange={handleOnChange}
                            type="text"
                            required={false}
                        />
                        <SelectInput
                            label="Validato"
                            className="col-md-4"
                            name="valid"
                            value={values.valid}
                            feedback={`${values?.valid ? "feedback" : ""}`}
                            onChange={handleOnChange}
                            // placeholder="Inserisci..."
                            emptyOption={true}
                            options={
                                [{ id: true, name: "No" },
                                { id: false, name: "Si" },
                                    //{ id: 3, name: "Nessun buono da leggere" }
                                ]}
                            required={false}
                        />
                        <div className="col-md-1">
                            <div className="remove-field-values">
                                <CleanFields
                                    onClick={() => {
                                        setValues({})
                                        setValuesFiltered({})                                        
                                    }}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Svuota i campi"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-4">
                            <button
                                className="search-btn"
                                onClick={() => searchUsershByFilter()}
                            >Cerca</button>
                        </div>
                    </div>
                </div>
                {filteredUsers?.data != null && <BuonoTable
                    data={filteredUsers?.data}
                    recordsNumber={filteredUsers?.recordsNumber}
                    bodyForExportTable={bodyForExportTable}
                    searchUsersCoupon={() => searchUsershByFilter()}
                    valuePerPage={valuePerPage}
                    setValuePerPage={setValuePerPage}
                    page={filteredUsers?.page}
                />}
                {filteredUsers?.data != null && (
                    <Pagination hasPrevious={filteredUsers?.page > 1}
                        hasNext={hasNextPage}
                        currentPage={filteredUsers?.page}
                        onClick={(pageStatus) => {
                            searchUsersCoupon(pageStatus === "prev" ? filteredUsers?.page - 1 : filteredUsers?.page + 1)
                        }} />
                )}
            </div>
        </div>
        <Footer />
    </div>;
}

export default GestioneBuonoDaLeggere;
