import React, { useState, useEffect, useRef, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Footer from "./layout/Footer";
import Sidebar from "./layout/Sidebar";
import Main from "./layout/Main";
import Header from "./layout/Header"
import { useUser } from "../context/UserContext";
import { useAuth } from "../context/AuthContext";
import { getUrl } from '../utils/functions';
import Form from "react-bootstrap/Form";
import TextField from '../components/inputs/TextField';
import SelectInput from './inputs/SelectInput';
import { getAllCountries, getCitiesOfState, getListOfValueJson, getStatesOfCountry } from '../utils/data';
import SideBarContents from './layout/SideBarContents';


import { ToastContainer } from 'react-toastify';
import toastContext from '../context/ToastContext';


const convertToID = (countryName, stateName, cityName) => {
    const country = getAllCountries().find(country => country.name === countryName);
    if (!country) {
        return [undefined, undefined, undefined];
    }
    const state = getStatesOfCountry(country.id).find(state => state.name === stateName);
    if (!state) {
        return [country.id, undefined, undefined];
    }
    const city = getCitiesOfState(state.id).find(city => city.name === cityName);
    return [country.id, state.id, city?.id ?? undefined]
}

const fetchForm = async (api, setForm, setFieldValues) => {
    try {
        setForm((form) => ({ ...form, loading: true }))
        let data = await api(getUrl("user", "userprofile/userExtended"));
        data.form_fields.sort((a, b) => { // a, b are one of the objects inside the data.form_fields
            if (a.row === b.row) {
                return a.order < b.order ? -1 : 1
            }
            return a.row < b.row ? -1 : 1
        })
        const rows = [];
        let items = [];
        let previousRow = undefined;
        for (let i = 0; i < data.form_fields.length; i++) {
            if (previousRow == null) {
                previousRow = data.form_fields[i].row;
            }
            if (data.form_fields[i].row !== previousRow) {
                rows.push(items);
                items = [];
                previousRow = data.form_fields[i].row;
                items.push(data.form_fields[i])
            } else {
                items.push(data.form_fields[i])
            }
        }
        if (items.length > 0) {
            rows.push(items)
        }
        data.rows = rows; //adding to data from response
        setForm((form) => ({ ...form, loading: false, data: data, status: true, error: null }))
        let values = {};
        let csc = {
            countryFormFieldId: undefined,
            country: "",
            stateFormFieldId: undefined,
            state: "",
            cityFormFieldId: undefined,
            city: "",
            resCountryFormFieldId: undefined,
            resCountry: "",
        }
        data.form_fields.forEach((item) => {
            if (item.form_formField_id === 209) {
                csc.countryFormFieldId = item.form_formField_id;

                csc.country = item.field_actual_value
            }
            if (item.form_formField_id === 213) {
                csc.resCountryFormFieldId = item.form_formField_id;

                csc.resCountry = item.field_actual_value
            }
            if (item.LOV_json === "state.json") {
                csc.stateFormFieldId = item.form_formField_id;
                csc.state = item.field_actual_value

            }
            if (item.LOV_json === "city.json") {
                csc.cityFormFieldId = item.form_formField_id;
                csc.city = item.field_actual_value

            }
            values[item.form_formField_id] = item.field_actual_value
        });
        if (csc.countryFormFieldId && csc.stateFormFieldId && csc.cityFormFieldId) {
            const [countryId, stateId, cityId] = convertToID(csc.country, csc.state, csc.city);
            values[csc.countryFormFieldId] = countryId
            values[csc.stateFormFieldId] = stateId
            values[csc.cityFormFieldId] = cityId
        }
        if (csc.resCountryFormFieldId) {
            const [resCountry] = convertToID(csc.resCountry, "", "");
            values[csc.resCountryFormFieldId] = resCountry
        }

        console.log("Values", values);
        setFieldValues(values)
    } catch (error) {
        setForm((form) => ({ ...form, loading: false, data: null, status: false, error: "An error occured" }))
    }
}

function ProfileExtension({ onConfirm, setExtendProfile }) {
    const [form, setForm] = useState({
        loading: false,
        status: false,
        data: null,
        error: null
    })
    const [fieldValues, setFieldValues] = useState({});
    const [userData, __, userInfo] = useUser();
    const { api } = useAuth();
    const history = useHistory();
    const formRef = useRef();
	const toastProvider = useContext(toastContext);

    useEffect(() => {
        fetchForm(api, setForm, setFieldValues)
    }, [api])

    const handleOnChange = (event) => {
        setFieldValues(oldValues => ({ ...oldValues, [event.target.name]: event.target.value }))
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            let body = Object.keys(fieldValues).map((id) => {
                let form_field = form.data.form_fields.find((field) => +id === field.form_formField_id)
                if (fieldValues[id] && (form_field.LOV_json === "country.json" || form_field.LOV_json === "state.json" || form_field.LOV_json === "city.json")) {
                    const csc = formRef.current.querySelector(`select[name='${form_field.form_formField_id}'] option[value='${fieldValues[form_field.form_formField_id]}']`);
                    if (csc) {
                        return ({ form_formField_id: id, field_update_value: csc.innerText })
                    }
                }
                return { form_formField_id: id, field_update_value: fieldValues[id] }
            })
            let data = await api(getUrl("user", "userprofile/userExtended"), "post", body)
            if (data?.status?.success) {
				if (data?.status?.success) {
                    history.push({
                        pathname: "/userprofile",
                        state: {
							updateSuccess : true
                        }
                    });
                }
                onConfirm();
            }

        } catch (error) {
			toastProvider.toastError("Errore nell'aggiornamento del profilo");
        }
    }
    return (
        <div>
            <Header />
            <div className="page-body">
                <Sidebar>
                	<SideBarContents />
                </Sidebar>
                <div className='separating-line'></div>
                <Main >
					{userData.profession[0]?.extend === false ? (
						<div className="row title-row">
							<div className="col-12">
								<h1>Estensione profilo SalTo+</h1>
								<p style={{"fontWeight" : "500", "fontSize" : "18px", "marginTop" : "24px"}}>Il tuo profilo non prevede l'estensione. <span style={{"color" : "#007bff", "cursor" : "pointer"}} onClick={() => {
								setExtendProfile(false);
							}}>Clicca qui</span> per tornare al tuo profilo.</p>
							</div>
						</div>
					) : (
							<Form method="post" className="col-md-8 userprofile-form" onSubmit={handleSubmit}>
                        		<div className="col-md-12 py-3" ref={formRef}>
                        		    <div className="row title-row">
                        		        <div className="col-12">
                        		            <h1>Estensione profilo SalTo+</h1>
											<a className='navigation-link' onClick={() => { setExtendProfile(false) }}>Profilo</a>
                        		        </div>
                        		    </div>
								<ToastContainer></ToastContainer>

								{form.loading !== true && form.data != null && form.data.rows.map((row) => {
									return <div className="row">
										{row.map((item) => {
											if (item.LOV.length !== 0 || item.LOV_json) {
												let options = [];
												if (item.LOV.length !== 0) {
													options = item.LOV.map((option) => {
														return { id: option, name: option }
													})
												} else {
													if (item.LOV_json === "country.json") {
														options = getAllCountries()
													} else if (item.LOV_json === "state.json") {
														let country = form.data.form_fields.find(field => field.LOV_json === "country.json")
														options = getStatesOfCountry(fieldValues[country.form_formField_id])
													} else if (item.LOV_json === "city.json") {
														let state = form.data.form_fields.find(field => field.LOV_json === "state.json")
														options = getCitiesOfState(fieldValues[state.form_formField_id])
													} else {
														options = getListOfValueJson(item.LOV_json)
													}
												}
												return <SelectInput
													className={row.length === 2 ? "col-md-6" : (row.length === 3 ? "col-4" : "col")}
													name={item.form_formField_id}
													label={item.field_label}
													required={item.is_mandatory}
													type="text"
													options={options}
													disabled={!item.is_editable}
													onChange={handleOnChange}
													value={fieldValues[item.form_formField_id]}
												/>
											
											} else {
												return <TextField
													className={row.length === 2 ? "col-md-6" : (row.length === 3 ? "col-md-4" : "col")}
													name={item.form_formField_id}
													label={item.field_label}
													required={item.is_mandatory}
													disabled={!item.is_editable}
													type="text"
													onChange={handleOnChange}
													value={fieldValues[item.form_formField_id]} />
											
											}
										})}
									</div>
								})}
								{form.loading === false && (
									<>
										<div className="row my-4">
											<div className="col-md-6 col-12">
												<button type="submit" className="button blue-btn w-100">
													Conferma
												</button>
											</div>
										</div>
									</>
								)}	
                        		</div>
                    		</Form>
					)}          
                </Main>
            </div>
            <Footer showNewsletter={false}/>
        </div>
    )
}

export default ProfileExtension
