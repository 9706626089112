import React from "react";
import "../css/indexPrenotazioni.css";
import Header from "../components/layout/Header";
import Sidebar from "../components/layout/Sidebar";
import SideBarContents from "../components/layout/SideBarContents";
import Main from "../components/layout/Main";
import educare from "../img/indice_attestati/educare_cubo.jpg";
import bookmakers from "../img/indice_attestati/bookmakers2024.jpg";
import itamondo from "../img/indice_attestati/ITAMONDO_card.png";

const indexMapping = [
	/*{
		path: "attestati_insegnanti",
	 	imageSrc: educare,
	 	alt: "attestati insegnanti",
	},
	{
		path: "/forms/form/60",
		imageSrc: bookmakers,
		alt: "prenotazioni bookmakers",
	},*/
	{
		path: "/VideoPage/256",
		imageSrc: itamondo,
		alt: "italiano al mondo",
	},
	{
		path: "/attestati_insegnanti",
		imageSrc: educare,
		alt: "educare alla lettura",
	},
];

function PrenotazioniIndice() {
	return (
		<div>
			<Header />
			<div className="page-body">
				<Sidebar>
					<SideBarContents />
				</Sidebar>
				<div className="separating-line"></div>
				<Main>
					<h1>I nostri corsi</h1>
					<br />
					<div className="index_container">
						{indexMapping.map((card) => {
							return (
								<a
									href={card.path}
									className="index_card_container">
									<img
										className="index_card"
										src={card.imageSrc}
										alt={card.alt}
									/>
								</a>
							);
						})}
					</div>
				</Main>
			</div>
		</div>
	);
}
export default PrenotazioniIndice;
