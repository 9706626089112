import React, { useState, useEffect, useContext, useRef } from "react";
import { Link } from 'react-router-dom';
import '../../css/header.css';
// import liveimg from "../../img/icon/live.gif";
import saltopiu from "../../img/home/saltopiu.png";
// import salonedellibro from "../../img/logo/Logo-Salone_trasp.png"
// import unprogettodi from "../../img/logo/Un-progetto-di.png"
// import salone from "../../utils/salone";
import { useLogOut } from '../../context/UserContext';
// import Notification from "../../shared/Notification";
// import imgNotification from "../../img/icon/notification.svg";
// import Cookies from "js-cookie";
import { useAuth } from "../../context/AuthContext";
import PopUpLogin from "../PopUpLogin"
import LoginModal from "../login_modal/LoginModal";
// import CustomPopUp from "../CustomPopUp";
import mobileContext from "../../context/MobileContext";


function Header(props) {

	const { auth } = useAuth()
	// const history = useHistory();
	// const location = useLocation();
	// const [user] = useUser(false);
	const logOutContext = useLogOut();
	const mobileInfo = useContext(mobileContext);
	const [showLogin, setShowLogin] = useState(false);
	const [showProfileModal, setShowProfileModal] = useState(false);
	const menuOptionsRef = useRef(null)

	const logout = () => {
		logOutContext()
		//history.push('/');
		window.location.href = process.env.REACT_APP_REDIRECT_LOGOUT.replace("{{REDIRECT_URI}}", process.env.REACT_APP_REDIRECT_URI)
	}

	// const profileModalCustomStyle = mobileInfo.isMobile === true ? {
	// 	"height": "auto",
	// 	"width": "10%",
	// 	"margin-top": "7%",
	// 	"margin-right": "3%",
	// 	"padding": "20px",
	// 	"border-radius": "15px",
	// 	"min-width": "initial",
	// 	"max-width": "initial"
	// } : {
	// 	"height": "auto",
	// 	"width": "45%",
	// 	"margin-top": "40%",
	// 	"margin-right": "8%",
	// 	"padding": "20px",
	// 	"border-radius": "15px",
	// 	"min-width": "initial",
	// 	"max-width": "initial"
	// }

	useEffect(() => {
		const handler = (event) => {
			if (menuOptionsRef.current != null && !menuOptionsRef.current?.contains(event.target)) {
				setShowProfileModal(false);
			}
		}
		document.addEventListener("click", handler);
		return () => {
			document.removeEventListener("click", handler);
		}
	}, [menuOptionsRef.current])

	let item;
	if (auth && !mobileInfo.isMobile) {
		item = (
			<div ref={menuOptionsRef} className="row w-100 justify-content-end navigation-container">
				<div className="col-auto" style={{ "cursor": "pointer", "height": "fit-content" }}>
					<Link to="/userprofile">
						<svg width="20" height="30" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{"paddingBottom" : "5px"}}>
							<path fillRule="evenodd" clipRule="evenodd" d="M9.63656 0.649902C12.5182 0.649902 14.8684 3.00006 14.8684 5.88172C14.8684 8.76338 12.5182 11.1135 9.63656 11.1135C6.7549 11.1135 4.40474 8.76338 4.40474 5.88172C4.40474 3.00006 6.7549 0.649902 9.63656 0.649902ZM9.63656 11.0135C14.4209 11.0135 18.3229 14.9155 18.2729 19.7499L16.4957 19.6999C16.4957 15.9019 13.4346 12.8408 9.63656 12.8408C5.83853 12.8408 2.77747 15.9019 2.72747 19.7499L0.950195 19.6999C0.950195 14.9155 4.8522 11.0135 9.63656 11.0135ZM9.63656 2.47718C7.74798 2.47718 6.23201 3.99314 6.23201 5.88172C6.23201 7.7703 7.74798 9.28627 9.63656 9.28627C11.5251 9.28627 13.0411 7.7703 13.0411 5.88172C13.0411 3.99314 11.5251 2.47718 9.63656 2.47718Z" fill="#246BF6" fillOpacity="1" />
						</svg>
						<p style={{ "display": "inline-block", "marginBottom": "0", "marginLeft": "5px", "height": "100%", "paddingTop": "2px" }}>Profilo</p>
					</Link>
				</div>
				<div className="col-auto" style={{ "cursor": "pointer", "height": "fit-content", "paddingRight": "0" }} onClick={() => {
					setShowProfileModal(!showProfileModal);
				}}>
					<svg viewBox="0 0 100 80" width="35" height="30" fill="#246BF6" style={{ "marginTop": "5px" }}>
						<rect width="60" height="10" rx="10"></rect>
						<rect y="20" width="60" height="10" rx="10"></rect>
						<rect y="40" width="60" height="10" rx="10"></rect>
					</svg>
					<p style={{ "display": "inline", "marginBottom": "0" }}>Menu</p>
				</div>
				{showProfileModal && (
					<div className="profile-modal-container" >
						<Link to={auth ? "/extensionuserprofile" : ""}>
							<p style={{ "fontSize": "1rem", "lineHeight": "1.2rem", "padding": "1vh 0" }}>Aggiorna profilo</p>
						</Link>
						<Link to={auth ? "/userprofile" : ""}>
							<p style={{ "fontSize": "1rem", "lineHeight": "1.2rem", "padding": "1vh 0" }}>Informazioni account</p>
						</Link>
						<p onClick={logout} style={{ "fontSize": "1rem", "lineHeight": "1.2rem", "padding": "1vh 0", "cursor": "pointer" }}>Logout</p>
					</div>
				)}
			</div>
		);
	} else if (auth && mobileInfo.isMobile) {
		item = (
			<div className="row w-100 justify-content-end profile-logo-container-mobile">
				<div className="col-auto" style={{ "cursor": "pointer", "height": "fit-content" }}>
					<Link to="/userprofile">
						<svg width="30" height="36" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd" d="M9.63656 0.649902C12.5182 0.649902 14.8684 3.00006 14.8684 5.88172C14.8684 8.76338 12.5182 11.1135 9.63656 11.1135C6.7549 11.1135 4.40474 8.76338 4.40474 5.88172C4.40474 3.00006 6.7549 0.649902 9.63656 0.649902ZM9.63656 11.0135C14.4209 11.0135 18.3229 14.9155 18.2729 19.7499L16.4957 19.6999C16.4957 15.9019 13.4346 12.8408 9.63656 12.8408C5.83853 12.8408 2.77747 15.9019 2.72747 19.7499L0.950195 19.6999C0.950195 14.9155 4.8522 11.0135 9.63656 11.0135ZM9.63656 2.47718C7.74798 2.47718 6.23201 3.99314 6.23201 5.88172C6.23201 7.7703 7.74798 9.28627 9.63656 9.28627C11.5251 9.28627 13.0411 7.7703 13.0411 5.88172C13.0411 3.99314 11.5251 2.47718 9.63656 2.47718Z" fill="#246BF6" fillOpacity="1" />
						</svg>
					</Link>
				</div>
				<div className="col-auto" style={{ "cursor": "pointer", "height": "fit-content", "padding": "0", "width": "40px" }} onClick={() => {
					setShowProfileModal(!showProfileModal);
				}}>
					<svg viewBox="0 0 100 80" width="50" height="40" fill="#246BF6" style={{ "marginTop": "5px" }}>
						<rect width="60" height="10" rx="10"></rect>
						<rect y="20" width="60" height="10" rx="10"></rect>
						<rect y="40" width="60" height="10" rx="10"></rect>
					</svg>
				</div>
				{showProfileModal && (
					<div className="profile-modal-container">
						<Link to={auth ? "/extensionuserprofile" : ""}>
							<p style={{ "fontSize": "1rem", "lineHeight": "1.2rem", "padding": "1vh 0" }}>Aggiorna profilo</p>
						</Link>
						<Link to={auth ? "/userprofile" : ""}>
							<p style={{ "fontSize": "1rem", "lineHeight": "1.2rem", "padding": "1vh 0" }}>Informazioni account</p>
						</Link>
						<p onClick={logout} style={{ "fontSize": "1rem", "lineHeight": "1.2rem", "padding": "1vh 0", "cursor": "pointer" }}>Logout</p>
					</div>
				)}
			</div>
		);
	}
	else {
		item = (
			<div>
				<a href={process.env.REACT_APP_REDIRECT_LOGIN.replace("{{REDIRECT_URI}}", process.env.REACT_APP_REDIRECT_URI)}>
					<button
						className="button auth-btn white-btn ml-2 float-right signup-login"
						style={mobileInfo.isMobile ? { marginBottom: "10px" } : {}}>
						Accedi
					</button>
				</a>
				<a href={"https://account.salonelibro.it/auth/realms/salto/protocol/openid-connect/registrations?scope=openid&response_type=code&client_id=saltopiu-profile&redirect_uri=https%3A%2F%2Fsaltopiu.salonelibro.it%2Fcallback"}>
					<button className="button auth-btn white-btn ml-2 float-right signup-login">Registrati</button>
				</a>
				{/* <button
					className="button ml-2 float-right signup-login"
					onClick={() => {
						setShowLogin(true);
					}}>Accedi</button> */}
			</div>
		);
	}

	return (
		<div className="header-main-container big-wrapper">
			<header className="w-100">
				<div className="row align-items-center" style={mobileInfo.isMobile ? {"marginRight" : "0", "marginLeft" : "0"} : {"margin":"0"}}>

					<div className="col-auto header-logo-container">
						<Link to={auth ? "/dashboard" : "/"}>
							<img className="logo" src={saltopiu} alt="" />
						</Link>
					</div>
					<div className="col pr-0 d-flex justify-content-end">
						{item}
					</div>


				</div>

				{showLogin && (<PopUpLogin setShowModal={setShowLogin}>
					<LoginModal setShowModal={setShowLogin}></LoginModal>
				</PopUpLogin>)}
			</header>
		</div>
	);
}

export default Header;