import React, { useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { getUrl } from '../../utils/functions'
import TextField from '../inputs/TextField'

function AddNewUserToSalto({ setShowModal }) {
    const { api } = useAuth()
    const [values, setValues] = useState({})
    const [message, setMessage] = useState({
        successMessage: null,
        error: null
    })

    const handleOnChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value })
    }

    const addUser = async () => {
        let body = values 
        body.enabled = true
        try {
            let response = await api(getUrl("user_admin", "backoffice/create_user"), "post", body)
            if (response?.success === true) {
                setMessage({
                    successMessage: "l'utente è stato aggiunto correttamente.",
                    error: null
                })
            }
            //setShowModal(false)
            setTimeout(()=>{
                setShowModal(false);
            }, 2000)

        } catch (error) {
            setMessage({
                successMessage: null,
                error: "Utente già esistente"
            })
        }
    }

    return (
        <div className="container-fluid">
            {message.successMessage && <p className='mt-2'>{message.successMessage}</p>}
            {message.error && <p className='mt-2 text-danger'>{message.error}</p>}
            <div className="row">
                <TextField
                    label="Nome"
                    className="col-md-4"
                    name="firstName"
                    value={values.firstName}
                    type="text"
                    onChange={handleOnChange}
                    required={false}
                    disabled={false}
                />
                <TextField
                    label="Cognome"
                    className="col-md-4"
                    name="lastName"
                    value={values.lastName}
                    onChange={handleOnChange}
                    type="text"
                    required={false}
                    disabled={false}
                />
                <TextField
                    label="E-mail"
                    className="col-md-4"
                    name="email"
                    value={values.email}
                    onChange={handleOnChange}
                    type="text"
                    required={false}
                    disabled={false}
                />
            </div>
            <div className="row mt-4 mb-3 justify-content-center">
                <div className="col-auto">
                    <button
                        className="aggiuni-utente"
                        onClick={addUser}
                    >Aggiungi</button>
                </div>
            </div>
        </div>
    )
}

export default AddNewUserToSalto
