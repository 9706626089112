import React, { useRef, useEffect } from "react";
import "../css/popupBookings.css";


const BookingPopUp = ({setShowModal, children}) =>{
    const modalRef = useRef(null);
    const clickOutref = useRef(null);
const closeModal = ()=>{
    modalRef.current.classList.add("fadeOut");
    setTimeout(()=>{
        setShowModal(false);
    }, 400)
}

useEffect(() => {
    const handler = (event) => {
        if (clickOutref.current != null && !clickOutref.current.contains(event.target)) {
            closeModal();
        }
    }
    document.addEventListener("click", handler);
    return () => {
        document.removeEventListener("click", handler);
    }
}, [clickOutref.current])
    return(
        <div className="modal-bookings" ref={modalRef}>
            <div className="modal-box-bookings" ref={clickOutref}>
                <div className="modal-body-bookings">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default BookingPopUp;
