import React, { lazy, Suspense } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Buono from "./Buono";
import Home from "./pages/Home";
import MediaLibrary from "./pages/MediaLibrary";
import ValidationPage from "./ValidationPage";
import Candidatura from "./pages/Candidatura";
import VideoPage from "./pages/VideoPage";
import CertificatePage from "./CertificatePage";
import BookingPage from "./BookingPage";
import UserProfile from "./pages/UserProfile";
import FormCandidatura from "./candidate_events/FormCandidatura";
import { UserProvider } from "./context/UserContext";
import { AuthProvider } from "./context/AuthContext";
import CookiePrivacy2 from "./CookiePrivacy2";
import Message from "./pages/Message";
import PrivateRoute from "./components/PrivateRoute";
import MediaLibrarySection from "./pages/MediaLibrarySection";
import "./css/modal.css";
import "./css/login.css";
import ProfileExtension from "./components/ProfileExtension";
import EventPage from "./pages/EventPage";
import SingleEventPage from "./pages/SingleEventPage";
import BookingEvents from "./pages/BookingEvents";
import BookedEventDetails from "./pages/BookedEventDetails";
import Accreditations from "./pages/Accreditations";
import AttestatoInsegnanti from "./pages/AttestatoInsegnanti";
import AttestatoInsegnantiLink from "./pages/AttestatoInsegnantiLink";
import Sponsors from "./pages/admin/Sponsors";
import { EventsProvider } from "./context/EventsContext";
import NewSponsorEvent from "./pages/admin/NewSponsorEvent";
import PrenotazioneSaltoPiu from "./pages/admin/PrenotazioneSaltoPiu";
import BookingEventValidation from "./pages/BookingEventValidation";
import BuonoDaLeggere from "./pages/BuonoDaLeggere";
import BookingValidationByBackOffice from "./pages/admin/validation_backoffice/BookingValidationByBackOffice";
import BuonoDaLeggereValidation from "./pages/BuonoDaLeggereValidation";
import Attestati from "./pages/Attestati";
import addToBooking from "./pages/AddToBooking";
import AdminDashboard from "./pages/admin/AdminDashboard";
import Users from "./pages/admin/Users";
import UserPanelExtension from "./pages/admin/UserPanelExtension";
import GestioneBuonoDaLeggere from "./pages/admin/GestioneBuonoDaLeggere";
import ModuliPrenotazione from "./pages/admin/ModuliPrenotazione";
import UserPanelComplete from "./pages/admin/UserPanelComplete";
import CorsiDisponibili from "./pages/admin/CorsiDisponibili";
import { ValuesFilterProvider } from "./context/ValuesFilteredContext";
import ScrollToTop from "./components/ScrollToTop";
import PrenotazioniIndice from "./pages/PrenotazioniIndice";
import Dashboard from "./pages/Dashboard"

//const Dashboard = lazy(() => import("./pages/Dashboard"));
const ReportLibrerie = lazy(() => import("./ReportLibrerie"));

class App extends React.Component {
	render() {
		return (
			<BrowserRouter>
				<ScrollToTop></ScrollToTop>
				<Suspense fallback={<div></div>}>
					<AuthProvider>
						<UserProvider>
							<EventsProvider>
								<ValuesFilterProvider>
									<CookiePrivacy2 />

									<Switch>
										<Route
											path="/"
											exact
											component={Home}
										/>
										<Route
											path="/dashboard"
											component={Dashboard}
										/>
										<Route
											path="/buonolibro"
											component={Buono}
										/>
										<Route
											path="/CertificatePage/:data"
											component={CertificatePage}
										/>
										<Route
											path="/CertificatePage"
											component={CertificatePage}
										/>
										<Route
											path="/validationpage/:code"
											component={ValidationPage}
										/>
										<Route
											path="/validationpage"
											component={ValidationPage}
										/>
										<Route
											path="/bookingpage/:data"
											component={BookingPage}
										/>
										<Route
											path="/medialibrary"
											component={MediaLibrary}
										/>
										<Route
											path="/media/:section_name"
											component={MediaLibrarySection}
										/>
										<Route
											path="/VideoPage/:id"
											component={VideoPage}
										/>
										<Route
											path="/forms/form/:code"
											component={FormCandidatura}
										/>
										<Route
											path="/forms/:form_type"
											component={Candidatura}
										/>
										<Route
											path="/eventi/sala/:location_id"
											component={EventPage}
										/>
										<Route
											path="/eventi"
											component={EventPage}
										/>
										<Route
											path="/evento/:evento_id"
											component={SingleEventPage}
										/>
										<Route
											path="/prenotazioni"
											component={BookingEvents}
										/>
										<Route
											path="/prenotazione/:evento_id"
											component={BookedEventDetails}
										/>
										<Route
											path="/addToBooking"
											component={addToBooking}
										/>
										<Route
											path="/accrediti"
											component={Accreditations}
										/>
										<Route
											path="/attestati_insegnanti/:attestatoID"
											component={AttestatoInsegnantiLink}
										/>
										<Route
											path="/attestati_insegnanti"
											component={AttestatoInsegnanti}
										/>
										<Route
											path="/indice_prenotazioni"
											component={PrenotazioniIndice}
										/>
										<Route
											path="/buonodaleggere"
											component={BuonoDaLeggere}
										/>
										<Route
											path="/attestati"
											component={Attestati}
										/>
										<PrivateRoute
											path="/booking_validation/backofficebookings_sponsor/validate/:booking_id/:participant_id"
											forAdmin={true}>
											<BookingValidationByBackOffice />
										</PrivateRoute>
										<PrivateRoute
											path="/booking_validation/backofficebookings/validate/:booking_id/:participant_id"
											forAdmin={true}>
											<BookingEventValidation />
										</PrivateRoute>
										<PrivateRoute
											path="/validazione_buonodaleggere/:buonodaleggere_code"
											forAdmin={true}>
											<BuonoDaLeggereValidation />
										</PrivateRoute>
										<PrivateRoute
											path="/validazione_buonodaleggere"
											forAdmin={true}>
											<BuonoDaLeggereValidation />
										</PrivateRoute>
										<PrivateRoute
											path="/sponsor/:id"
											forAdmin={true}>
											<NewSponsorEvent />
										</PrivateRoute>
										<PrivateRoute
											path="/sponsor"
											forAdmin={true}>
											<Sponsors />
										</PrivateRoute>
										<PrivateRoute
											path="/prenotazione_saltopiu"
											forAdmin={true}>
											<PrenotazioneSaltoPiu />
										</PrivateRoute>
										<PrivateRoute
											path="/admin_dashboard"
											forAdmin={true}>
											<AdminDashboard />
										</PrivateRoute>
										<PrivateRoute
											path="/admin_users_extension/:userId"
											forAdmin={true}>
											<UserPanelExtension />
										</PrivateRoute>
										<PrivateRoute
											path="/admin_users/:userId"
											forAdmin={true}>
											<UserPanelComplete />
										</PrivateRoute>
										<PrivateRoute
											path="/admin_users"
											forAdmin={true}>
											<Users />
										</PrivateRoute>
										<PrivateRoute
											path="/admin_buonodaleggere"
											forAdmin={true}>
											<GestioneBuonoDaLeggere />
										</PrivateRoute>
										<PrivateRoute
											path="/admin_moduli_prenotazione/:userId"
											forAdmin={true}>
											<ModuliPrenotazione />
										</PrivateRoute>
										<PrivateRoute
											path="/admin_corsi_disponibili"
											forAdmin={true}>
											<CorsiDisponibili />
										</PrivateRoute>
										<PrivateRoute path="/userprofile">
											<UserProfile key={Math.random()} />
										</PrivateRoute>
										<PrivateRoute path="/extensionuserprofile">
											<UserProfile
												showExtendedProfile={true}
												key={Math.random()}
											/>
										</PrivateRoute>
										<Route
											path="/reportlibrerie/:code"
											component={ReportLibrerie}
										/>
										<Route
											path="/message"
											component={Message}
										/>
									</Switch>
								</ValuesFilterProvider>
							</EventsProvider>
						</UserProvider>
					</AuthProvider>
				</Suspense>
			</BrowserRouter>
		);
	}
}
export default App;
