import React from 'react'

function TextArea({ name, onChange, required = true, value = "", placeholder = "", type = "text", label, className = "col", disabled = false }) {
    return (
        <div className={className}>
            <p className="mt-2 mb-2 p-14">{label}{required && "*"}</p>
            <textarea
                className="w-100"
                type={type}
                name={name}
                placeholder={placeholder}
                required={required ? "required" : ""}
                onChange={onChange}
                value={value}
                disabled={disabled ? "disabled" : ""}
            />
        </div>
    )
}

export default TextArea
