import React, { useState, useRef, useEffect, useContext } from 'react'
import { useHistory, useLocation } from "react-router-dom"
import mobileContext from '../context/MobileContext';
import Footer from "../components/layout/Footer";
import Sidebar from "../components/layout/Sidebar";
import Main from "../components/layout/Main";
import Header from "../components/layout/Header";
import { useUser } from "../context/UserContext";
import Form from "react-bootstrap/Form";
import TextField from '../components/inputs/TextField';
import SelectInput from '../components/inputs/SelectInput';
import { getAllCountries, getCitiesOfState, getStatesOfCountry } from '../utils/data';
import { useAuth } from '../context/AuthContext';
import { getUrl } from '../utils/functions';
import ProfileExtension from '../components/ProfileExtension';
import { getOffset } from "../utils/functions";
import swal from 'sweetalert';
import SideBarContents from '../components/layout/SideBarContents';
import "../css/profile.css"

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import toastContext from '../context/ToastContext';

const fetchProfession = async (api, setGroupProfessions, profession, setValues) => {
    try {
        let data = await api(getUrl("user", "userprofile/professions"));
        let ambitos = {}
        data.forEach((profession) => {
            if (ambitos[profession.group] == null) {
                ambitos[profession.group] = profession.group
            }
        })
        ambitos = Object.keys(ambitos)
        setGroupProfessions({
            professions: data,
            ambitos: ambitos.map((ambito) => ({ id: ambito, name: ambito }))
        })

        if (profession) {
            setValues(oldValues => ({ ...oldValues, ambito: profession.group, profession: profession.id }))
        }

    } catch (error) {
        setGroupProfessions({
            professions: [],
            ambitos: []
        })
    }
}

const fetchInterests = async (api, setInterests) => {
    try {
        let data = await api(getUrl("user", "userprofile/interests"));
        let interestItems = {};
        for (let inter of data) {
            interestItems[inter.id] = inter;
        }
        setInterests(interestItems);
    } catch (error) {
        setInterests(null);
    }

}

const getValuesFromUserComplete = (user) => {

    let outPut = {};
    Object.keys(user).forEach((key) => {
        if (user[key] != null && key !== "id") {

            outPut[key] = user[key]
        }
    })
    outPut.interests = outPut["interests"].map((item) => item["id"])
    if (user.country != null && user.city != null && user.state != null) {
        [outPut.country, outPut.state, outPut.city] = convertToID(user.country, user.state, user.city)
    }
    return outPut
}

const convertToID = (countryName, stateName, cityName) => {
    const country = getAllCountries().find(country => country.name === countryName);
    if (!country) {
        return [undefined, undefined, undefined];
    }
    const state = getStatesOfCountry(country.id).find(state => state.name === stateName);
    if (!state) {
        return [country.id, undefined, undefined];
    }
    const city = getCitiesOfState(state.id).find(city => city.name === cityName);
    return [country.id, state.id, city?.id ?? undefined]
}

function UserProfile({ showExtendedProfile = false }) {

    const [user, fetchUser, userInfo] = useUser()
    const { api } = useAuth()
    const history = useHistory()
    let locationData = useLocation()
    const [values, setValues] = useState(getValuesFromUserComplete(user))
    const [extendProfile, setExtendProfile] = useState(showExtendedProfile)
    const [groupProfessions, setGroupProfessions] = useState({
        professions: [],
        ambitos: []
    })
    const [interests, setInterests] = useState({});
    const [error, setError] = useState(null)
    const countryRef = useRef(null);
    const stateRef = useRef(null);
    const cityRef = useRef(null);

    const mobileInfo = useContext(mobileContext);
	const toastProvider = useContext(toastContext);

    useEffect(() => {
        let outPut = {};
        if (user.country != null && user.city != null && user.state != null) {
            [outPut.country, outPut.state, outPut.city] = convertToID(user.country, user.state, user.city)
        }
        setValues(oldValues => ({ ...oldValues, ...outPut }))
    }, [user])

    useEffect(() => {
        fetchProfession(api, setGroupProfessions, values.profession[0], setValues);
        fetchInterests(api, setInterests)
		if (locationData.state?.updateSuccess === true) {
			toastProvider.toastSuccess("Profilo aggiornato con successo");
		} else if (locationData.state?.updateSuccess === false) {
			toastProvider.toastWarn("Errore nell'aggiornamento del profio")
		}
    }, [])

    useEffect(() => {
        if (locationData.state?.scroll === true && locationData.state?.element != null) {
            const { top } = getOffset(document.getElementById(locationData.state.element));//top=1671
            window.scrollTo({
                top: top - 100,
                behavior: "smooth"
            })
        }
    })

    const handleOnChange = (event) => {
        setValues(oldValues => ({ ...oldValues, [event.target.name]: event.target.value }))
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        const moment = Date.now()
        const userAge = (new Date(values.birthdate))?.getTime?.()
        if (!userAge) {
            return
        }
        const differnetAgeInYears = (moment - userAge) / 31557600000
        if (differnetAgeInYears < 14) {
            setError({
                name: "birthdate",
                error: "Non puoi completare il profilo se hai meno di 14 anni."
            })
            return
        }
        swal({
            title: "Sei sicuro della professione inserita?",
            text: "La professione che scegli determina le attività e gli incontri a cui potrai partecipare.",
            icon: "warning",
            buttons: ["NO", "Si"],
        })
            .then(async (value) => {
                if (value == null) {
                    return;
                }
                const body = { ...values, interests: values.interests.join(",") };
                const country = countryRef.current.querySelector(`option[value='${values.country}']`);
                if (country) {
                    body.country = country.innerText;
                }
                const state = stateRef.current.querySelector(`option[value='${values.state}']`);
                if (state) {
                    body.state = state.innerText;
                }
                const city = cityRef.current.querySelector(`option[value='${values.city}']`);
                if (city) {
                    body.city = city.innerText;
                }
                try {
                    let data = await api(getUrl("user", "userprofile/userComplete"), "post", body)
                    if (data?.status?.success) {
						history.push({
                            pathname: "/userprofile",
                            state: {
								updateSuccess : true
                            }
                        });
                        fetchUser();
                    }
                } catch (error) {
					history.push({
						pathname: "/userprofile",
						state: {
							updateSuccess : false
						}
					});
                }
            })

        return false
    }


    const onConfirm = () => {
        setExtendProfile(false);
		setTimeout(() => {
			toastProvider.toastSuccess("Profilo aggiornato con successo");
		}, 500);
    }
    if (extendProfile) {
        return <ProfileExtension onConfirm={onConfirm} setExtendProfile={setExtendProfile} />
    }


    return (

        <div>
            <Header />
            {/* <div className="row w-100 m-0"> */}
            <div className="page-body">
                <Sidebar>
                    {/* <p>In questo spazio trovi il meglio della produzione culturale curata dal Salone: video, interviste, lezioni, podcast, approfondimenti. Una grande libreria a tua disposizione: scegli un contenuto e inizia il viaggio.</p> */}
                    <SideBarContents />
                </Sidebar>
                <div className='separating-line'></div>
                <Main>
                    <div className="col-md-8 col-12 py-3">
                        <div className="row">
                            <div className="col-12">
                                <h1>Il mio profilo SalTo+</h1>
                                <h5 className="mt-3" style={{ display: "block" }}>
                                    Completa il profilo per avere il meglio del Salone come e quando vuoi.<br />
                                </h5>
                            </div>
                        </div>
						<ToastContainer></ToastContainer>
                        <Form method="post" onSubmit={handleSubmit} className="userprofile-form">
                            <div className="row">
                                {/* {values.birthdate < 14 years ----> user can not insert it} */}
                                {/* {values.birthdate must be insert > 14 years} */}
                                <TextField
                                    className="col-md-6 col-12"
                                    name="birthdate"
                                    label="Data di nascita"
                                    required={true}
                                    type="date"
                                    placeholder="Inserisci data..."
                                    onChange={handleOnChange}
                                    value={values.birthdate} />

                            </div>
                            <div className="row">
                                <SelectInput
                                    ref={countryRef}
                                    className="col-md-6 col-12"
                                    name="country"
                                    label="Stato di Residenza"
                                    required={true}
                                    placeholder="Inserisci stato..."
                                    options={getAllCountries()}
                                    onChange={(event) => {
                                        handleOnChange(event)
                                        setValues(oldValues => ({ ...oldValues, "state": undefined }))
                                    }}
                                    value={values.country} />
                                <SelectInput
                                    ref={stateRef}
                                    className="col-md-6 col-12"
                                    name="state"
                                    label="Regione di Residenza"
                                    required={true}
                                    placeholder="Inserisci regione..."
                                    options={getStatesOfCountry(values.country)}
                                    disabled={values.country == null || values.country === ""}
                                    value={values.state}
                                    onChange={(event) => {
                                        handleOnChange(event)
                                        setValues(oldValues => ({ ...oldValues, "city": undefined }))
                                    }} />
                            </div>
                            <div className="row">
                                <SelectInput
                                    ref={cityRef}
                                    className="col-md-6 col-12"
                                    name="city"
                                    label="Città di Residenza"
                                    required={true}
                                    placeholder="Inserisci città..."
                                    options={getCitiesOfState(values.state)}
                                    disabled={values.state == null || values.state === ""}
                                    value={values.city}
                                    onChange={handleOnChange} />
                            </div>

                            <div className="row mt-5">
                                <div className="col-12">
                                    <h5>Completa la tua anagrafica professionale</h5>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <p className="mt-2 mb-2 p-14">
                                        Compila questi campi per partecipare a progetti, iniziative e ricevere tutti i vantaggi legati al tuo ambito professionale
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <SelectInput
                                    className="col-md-6 col-12"
                                    name="ambito"
                                    label="Ambito"
                                    required={true}
                                    disabled={user.profession[0]?.group !== 'Altro' && user.profession[0] !== undefined}
                                    placeholder={mobileInfo.isMobile ? "Ambito..." : "Inserisci ambito..."}
                                    options={groupProfessions.ambitos}
                                    value={values.ambito}
                                    onChange={(event) => {
                                        handleOnChange(event)
                                        setValues(oldValues => ({ ...oldValues, "profession": undefined }))
                                    }} />
                                <SelectInput
                                    className="col-md-6 col-12"
                                    name="profession"
                                    label="Professione"
                                    required={true}
                                    disabled={user.profession[0]?.name !== 'altro' && user.profession[0] !== undefined}
                                    placeholder={mobileInfo.isMobile ? "Professione..." : "Inserisci professione..."}
                                    options={groupProfessions.professions.filter((profession) => profession.group === values.ambito).map((profession) => ({
                                        name: profession.description, id: profession.id
                                    }))}
                                    value={values.profession}
                                    onChange={handleOnChange} />
                            </div>
                            {/* (+user.profession[0]?.id >= 18 && +user.profession[0]?.id <= 23) */}
                            <div className="row mt-2">
                                <div className="col-12 d-flex">
                                    <button className="extended-userprofile" onClick={() => setExtendProfile(true)}>Estendi Profilo &gt;&gt;</button>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-12">
                                    <h3>Interessi</h3>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <p className="mt-2 mb-2 p-14">
                                        Segnalaci quali sono gli argomenti che ti interessando di più cosi  che i contenuti vengano
                                        personalizzati secondo le tue necessità e preferenze.
                                    </p>
                                </div>
                            </div>

                            <div className="row mt-2">{Object.keys(interests).map((interstId) => {
                                interstId = +interstId;
                                let interest = interests[interstId];
                                return <div className="col-md-6 col-12" key={interest.id}>
                                    <div className="row">
                                        <div className="col-auto">
                                            <input
                                                key={interest.id}
												className='profile-checkboxes'
                                                id={` checkbox-${interstId}`}
                                                name="interests"
                                                type="checkbox"
                                                checked={values.interests.indexOf(interstId) > -1 ? "checked" : ""}
                                                onChange={(event) => {
                                                    let index = values.interests.indexOf(interstId);
                                                    if (index > -1) {
                                                        values.interests.splice(index, 1);
                                                        setValues((oldValues) => ({ ...oldValues, interests: [...values.interests] }))
                                                    } else {
                                                        setValues((oldValues) => ({ ...oldValues, interests: [...values.interests, interstId] }))
                                                    }

                                                }}
                                            />
                                        </div>
                                        <div className={mobileInfo.isMobile ? "col-9" : "col-10"}>
                                            <label htmlFor={` checkbox-${interstId}`} className="p-14">{interest.name}</label>
                                        </div>
                                    </div>
                                </div>
                            })}</div>

                            {/* <div className="row mt-5">
                                <div className="col-12">
                                    <h5>Iscriviti alle newsletter del salone</h5>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-1 pt-2">
                                    <input
                                        id="culture-newsletter"
                                        type="checkbox"
										className='profile-checkboxes'
                                        checked={values.consent_send_cultural_newsletter ? "checked" : ""}
                                        onChange={(event) => {
                                            let isChecked = event.target.checked;
                                            handleOnChange({
                                                target: {
                                                    value: isChecked,
                                                    name: "consent_send_cultural_newsletter"
                                                }
                                            })
                                        }}
                                    />
                                </div>
                                <div className={mobileInfo.isMobile ? "col-10 profile-newsletter-mobile" : "col-11"}>
                                    <p className="mt-2 mb-2 p-14">
                                        La newsletter per restare aggiornato sulle iniziative culturali del Salone e dei suoi partner
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-1 pt-2">
                                    <input
                                        id="commercial-newsletter"
                                        type="checkbox"
										className='profile-checkboxes'
                                        checked={values.consent_send_commercial_newsletter ? "checked" : ""}
                                        onChange={(event) => {
                                            let isChecked = event.target.checked;
                                            handleOnChange({
                                                target: {
                                                    value: isChecked,
                                                    name: "consent_send_commercial_newsletter"
                                                }
                                            })
                                        }}
                                    />
                                </div>
                                <div className={mobileInfo.isMobile ? "col-10 profile-newsletter-mobile" : "col-11"}>
                                    <p className="mt-2 mb-2 p-14">
                                        La newsletter per restare aggiornato su promozioni commerciali e vantaggi legati ai partner del Salone
                                    </p>
                                </div>
                            </div> */}

                            <div className="row my-4">
                                <div className="col-md-6 col-12">
                                    <button type="submit" className="button blue-btn w-100">
                                        Aggiorna il mio profilo
                                    </button>
                                </div>
                                {error?.name === "birthdate" && (
                                    <div className="col-6 align-items-end d-flex">
                                        <p className="text-danger mb-1">
                                            {error.error}
                                        </p>
                                    </div>
                                )}
                            </div>

                        </Form>


                    </div>
                </Main>
            </div>
            <Footer showNewsletter={false} />
        </div>
    )
}

export default UserProfile
