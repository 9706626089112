
import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import BookedEvent from '../components/booking_events/BookedEvent'
import Footer from '../components/layout/Footer'
import Header from '../components/layout/Header'
import Main from '../components/layout/Main'
import Sidebar from '../components/layout/Sidebar'
import { useAuth } from '../context/AuthContext'
import { useUser } from '../context/UserContext'
import "../css/bookingevents.css";
import { getUrl } from '../utils/functions';
import { ReactComponent as Loader } from '../img/loader.svg';
import SideBarContents from '../components/layout/SideBarContents'
import mobileContext from '../context/MobileContext';

import PresentationModal from '../components/booking_events/PresentationModal'
import UnpoppablePopUp from '../components/UnpoppablePopUp'
import FilterBookings from '../components/booking_events/FilterBookings'
import { useParams } from 'react-router-dom';

import toastContext from '../context/ToastContext'
import { ToastContainer } from 'react-toastify'

function BookingEvents() {
    const [_, __, userInfo] = useUser();
    const { api } = useAuth();
	const { location_id } = useParams();
    const [bookedEvents, setBookedEvents] = useState([]);
	const [showedEvents, setShowedEvents] = useState([]);
	const [bookedEventsFullData, setBookedEventsFullData] = useState({});
    const [loading, setLoading] = useState(false);
	const [showPresentationModal, setShowPresentationModal] = useState(window.localStorage.showEventPresentation ? false : true);
	const [notification, setNotification] = useState({
		"deleted" : [],
		"updated" : []
	});
	const mobileInfo = useContext(mobileContext);
	const toastProvider = useContext(toastContext);

	const fetchBookedEvents = async (setEvents) => {
		try {
			setLoading(true);
			let data = await api(getUrl("booking", "bookings/booking/"));
			setLoading(false);
			data = data?.map((item) => {
				if (item.last_snooze_timestamp.indexOf("T") === -1) {
					item.last_snooze_timestamp += "+00:00"
				}
				return item
			})
			if (setEvents) {
				setBookedEvents(data)
				setShowedEvents(data)
			} else {
				return data;
			}
		} catch (error) {
			setLoading(false);
			setBookedEvents([]);
			setShowedEvents([]);
		}
	}

	const addNotification = function (newNotification, key) {
		if (key === "deleted") {
			setNotification({
				"deleted" : [...notification["deleted"], newNotification],
				"updated" : notification["updated"]
			})
		} else if (key === "updated") {
			setNotification({
				"deleted" : notification["deleted"],
				"updated" : [...notification["updated"], newNotification]
			})
		} else {
			return "key not present";
		}
	}

	const addBookedEvent = function(newEvent) {
		setBookedEvents([...bookedEvents, newEvent]);
	}

	const addEventFullData = function(eventId, fullData) {
		let eventToAdd = {};
		eventToAdd[eventId] = fullData;
		setBookedEventsFullData(bookedEventsFullData => ({
			...bookedEventsFullData,
			...eventToAdd
		}))
	}

	const findEventById = function(eventId) {
		return bookedEvents.find((eventData) => {
			if (eventData.id === eventId) {
				return eventData;
			}
		})
	}

    useEffect(() => {
        if (location_id) {
            let query = "";
            query += `filter[location]=${location_id}&`;
            fetchBookedEvents(query);
            return
        }
        fetchBookedEvents(true)
    }, [])

	const refreshBookedEvent = () => {
        fetchBookedEvents(true)
    }

	const eventsGroupedByDate = showedEvents?.sort((first, second) => {
		return first.event_id - second.event_id;
	}).reduce((group, eventGrouping) => {
		const { date } = eventGrouping;
		group[date] = group[date] ?? [];
		group[date].push(eventGrouping);
		const groupToReturn = {};
		const keys = Object.keys(group);
		keys.sort((a, b) => {
			var firstSplit = a.split("-");
			var firstDate = new Date(firstSplit[0], firstSplit[1] - 1, firstSplit[2]);
			var secondSplit = b.split("-");
			var secondDate = new Date(secondSplit[0], secondSplit[1] - 1, secondSplit[2]);
			return firstDate - secondDate;
		});
		keys.forEach(key => {
			groupToReturn[key] = group[key]
		});
		return groupToReturn;
	}, {});

    return (
        <div>
            <Header />
            {/* <div className="row w-100"> */}
            <div className="page-body">
                <Sidebar>
                    <SideBarContents />
                </Sidebar>
				<div className='separating-line'></div>
                <Main>
					{(notification != false) && (<div>
							{notification["updated"].map((singleNotification, index) => {
								const toastWarnContent = () => {
									return <p>Un evento è stato modificato!<a href="/prenotazioni" style={{"marginLeft" : "1px", "display" : "inline-block"}}>Clicca qui</a> per le tue prenotazioni.</p>
								}

								toastProvider.toastWarn(toastWarnContent);
								notification["updated"].splice(index, 1);
							})}
							{notification["deleted"].map((singleNotification, index) => {
								const toastErrorContent = () => {
									return <p>Un evento è stato cancellato! <a href="/prenotazioni" style={{"marginLeft" : "1px", "display" : "inline-block"}}>Clicca qui</a> per le tue prenotazioni.</p>
								}

								toastProvider.toastError(toastErrorContent);
								notification["deleted"].splice(index, 1);
							})}
						<ToastContainer></ToastContainer>
					</div>)}
                    <div className="booking-page">
						<h1 style={{"fontSize" : "40px", "lineHeight" : "48px", "fontWeight" : "600", "marginBottom" : "12px"}}>Prenotazioni</h1>
                        <p className="message-booking">
                            Ciao {userInfo?.first_name},<br />
                            {showedEvents?.length === 0 ? "Non hai ancora prenotato nessun evento a cui partecipare!" : `Hai ${showedEvents?.length} ${showedEvents?.length === 1 ? "prenotazione" : "prenotazioni"} ai nostri eventi!`}
                        </p>
                        <p className="explain">
							Per prenotare un evento sfoglia il programma, e clicca sul pulsante prenota!<br/> La procedura guidata ti accompagnerà nel processo di prenotazione.
                        </p>
                        <div className="go-to-programmes">
                            <Link to="/eventi">Vai al programma</Link>
                        </div>
						<div className='desc-event'>
							{/* <p className='desc-event-title'>Programma del Salone</p> */}
						</div>
                        {loading && (<div className="loading fixed"><Loader style={{ width: "130px", height: "130px" }} /> </div>)}

						<FilterBookings eventsFullData={bookedEventsFullData} setData={setShowedEvents} findEvent={findEventById} />

                        {loading === false && mobileInfo.isMobile === false && Object.keys(eventsGroupedByDate)?.map(function (date, index) {
								return (
									<div className='event-card-row' key={index}>
										<p className='date-text' style={mobileInfo.isMobile ? {"display" : "none"} : {"": ""}}>{(new Date(date)).toLocaleString('it-IT', {day : "numeric", month: "long", weekday: "short"})}</p>
										<div className='events-container'>
										{
											eventsGroupedByDate[date].map((event) => {
												return <BookedEvent
												key={event?.event_id}
												bookedIdEvent={event?.id}
												bookedEventDetails={event}
												size = {eventsGroupedByDate[date].length > 1 ? "short" : "wide"}
												eventId={event?.event_id}
												refreshBooking={refreshBookedEvent}
												newNotification={addNotification}
												addToFullData = {addEventFullData}
											/>
											})
										}
										</div>
									</div>
								)								
							}
						)}
						{loading === false && mobileInfo.isMobile === true && Object.keys(eventsGroupedByDate)?.map(function (date, index) {
								return (
									<div className='event-card-row'>
										<div className='events-container'>
										{
											eventsGroupedByDate[date].map((event) => {
												return <BookedEvent
												key={event?.event_id}
												bookedIdEvent={event?.id}
												bookedEventDetails={event}
												size = {eventsGroupedByDate[date].length > 1 ? "short" : "wide"}
												eventId={event?.event_id}
												refreshBooking={refreshBookedEvent}
												newNotification={addNotification}
												addToFullData = {addEventFullData}
											/>
											})
										}
										</div>
									</div>
								)								
							}
						)}
                    </div>
					{showPresentationModal && (<UnpoppablePopUp setShowModal={setShowPresentationModal}>
						<PresentationModal setShowModal={setShowPresentationModal}/>
					</UnpoppablePopUp>)}
                </Main>
            </div>
            <Footer showNewsletter={false} />

        </div>
    )
}

export default BookingEvents
