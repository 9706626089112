import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as SearchIcon } from '../../img/icon/search_icon.svg';
import { getUrl } from '../../utils/functions';
import { useAuth } from '../../context/AuthContext';
import MultipleSelect from '../inputs/MultipleSelect';

const fetchAllProgrammes = async (api, setProgrammes) => {
    try {
        let data = await api(getUrl("programma", `api/v1/tags?filter[tagGroup]=${process.env.REACT_APP_TAG_GROUP_ID_PROGRAM}`));
        setProgrammes(data)
    } catch (error) {
        setProgrammes(null)
    }
}

const fetchAllArguments = async (api, setArguments) => {
    try {
        let data = await api(getUrl("programma", `api/v1/tag-groups`));
        setArguments(data)
    } catch (error) {
        setArguments(null)
    }
}

const fetchAllLocations = async (api, setLocations) => {
    try {
        let data = await api(getUrl("programma", `api/v1/locations`));
        setLocations(data)
    } catch (error) {
        setLocations(null);
    }
}

const getDefaultValueFromScanQRCode = (locationFromQRCode) => {
    if (locationFromQRCode) {
        return [+locationFromQRCode]
    } else {
        return []
    }
}

let timeOutHandler;

//! DECIDE QUALE FILTRI MOSTRARE
const visibleInputs = {
	"programma" : false,
	"ricerca" : true,
	"argomenti" : false,
	"giorno" : false,
	"orario" : false
}
//! ~~~~~~~~~~~~~~~~~~~~~~~~~~~~

function FilterEvents({ fetchEvents, locationId }) {
    const { api } = useAuth();
    const [programmes, setProgrammes] = useState(null);
    const [allArguments, setArguments] = useState(null);
    const [values, setValues] = useState({
        programTags: undefined,
        argumetTags: undefined,
        date: undefined,
        time: undefined,
        search: "",
        location: locationId ? +locationId : undefined
    });
    const [locations, setLocations] = useState(null);
    const searchRef = useRef();


    useEffect(() => {
        fetchAllProgrammes(api, setProgrammes);
        fetchAllArguments(api, setArguments);
        fetchAllLocations(api, setLocations);
    }, []);

    const hourData = [];
    for (let i = 8; i <= 23; i++) {
        hourData.push({ id: `${i}:00`, name: `${i}:00` });
        if (i !== 23) {
            hourData.push({ id: `${i}:15`, name: `${i}:15` });
            hourData.push({ id: `${i}:30`, name: `${i}:30` });
            hourData.push({ id: `${i}:45`, name: `${i}:45` });
        }
    }

    const searchQuery = (values) => {

        let query = "";
        if (values.time) {
            query += `filter[time]=${values.time}&`
        }
        if (values.date) {
            query += `filter[date]=${values.date}&`
        }
        if (values.location) {
            query += `filter[location]=${values.location}&`
        }
        let tags = [];
        if (values.argumetTags != null && values.argumetTags != "") {
            tags = values.argumetTags.split(",");
        }
        if (values.programTags != null && values.programTags != "") {
            const programTags = values.programTags.split(",");
            programTags.forEach((tag) => {
                if (tags.indexOf(tag) === -1) {
                    tags.push(tag)
                }
            })
        }
        if (tags.length > 0) {
            if (tags.length === 1) {
                query += `filter[tags]=${tags}&`
            } else {
                query += `filter[tags]=${tags.join(",")}&`
            }
        }
        if (values.search) {
            query += `filter[search]=${values.search}&`
        }

        if (timeOutHandler) {
            clearTimeout(timeOutHandler)
        }
        timeOutHandler = setTimeout(() => fetchEvents(query), 700)

    }

    useEffect(() => {
        const handler = (e) => {
            if (e.keyCode === 13) {
                searchQuery(values);
            }
        };
        searchRef.current.addEventListener("keyup", handler)
            ;
        return () => {
            if (searchRef.current != null) {
                searchRef.current.removeEventListener("keyup", handler)

            }
        }
    }, [values])

    return (
        <div style={{ paddingLeft: "10px" }}>
			<div style={{ width: "250px", paddingLeft: "10px", marginTop: "20px", height: "40px" }}>
            	{visibleInputs.programma && (
            	    <MultipleSelect textPreview={"Eventi Salone"}
            	        data={programmes?.data ?? []}
            	        onChange={(tags) => {
            	            const newValues = { ...values, programTags: tags.join(",") };
            	            setValues(newValues);
            	            searchQuery(newValues);
            	        }} />
            	)}
			</div>
            <div className="container-search-arguments">
                {visibleInputs.ricerca && (<div className="search-box">
                    <input ref={searchRef} type="text" placeholder="Cerca l'evento"
                        value={values.search}
                        onChange={(event) => {
                            const newValues = { ...values, search: event.target.value };
                            setValues(newValues);
                            searchQuery(newValues);
                        }} />
                    <SearchIcon onClick={() => searchQuery(values)} />
                </div>)}
                {visibleInputs.argomenti && (<div style={{ width: "250px", marginLeft : "40px" }}>
                    <MultipleSelect textPreview={"Tutti gli argomenti"}
                        data={allArguments?.data ?? []}
                        onChange={(tags) => {
                            const newValues = { ...values, argumetTags: tags.join(",") };
                            setValues(newValues);
                            searchQuery(newValues);
                        }} />
                </div>)}
            </div>
            <div className="container-day-hour">
                {visibleInputs.giorno && (<div className="container-day-label">
                    <div className="day-label">Giorno</div>
                    <MultipleSelect data={[
						{ id: "2022-05-19", name: "19 Maggio" },
						{ id: "2022-05-20", name: "20 Maggio" },
						{ id: "2022-05-21", name: "21 Maggio" },
						{ id: "2022-05-22", name: "22 Maggio" },
						{ id: "2022-05-23", name: "23 Maggio" }
                    ]} textPreview={"Scegli un giorno"}
                        className={"select-day"}
                        onChange={(dates) => {
                            const newValues = { ...values, date: dates.join(",") };
                            setValues(newValues);
                            searchQuery(newValues);
                        }} />
                </div>)}
                {visibleInputs.orario && (<div className="container-hour-label">
                    <div className="day-label">Orario</div>
                    <MultipleSelect data={hourData}
                        textPreview={"Scegli orari"}
                        className={"select-day"}
                        onChange={(times) => {
                            const newValues = { ...values, time: times.join(",") };
                            setValues(newValues);
                            searchQuery(newValues);
                        }} />
                </div>)}
            </div>

        </div>
    )
}

export default FilterEvents
