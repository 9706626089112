import React, { useRef, useState, useEffect } from "react";
import "../../css/bookingpopupcontents.css";
import TextField from "../inputs/TextField";
import SelectInput from "../inputs/SelectInput";
import { useHistory } from "react-router";
import Form from "react-bootstrap/Form";
import { useAuth } from "../../context/AuthContext";
import { ReactComponent as Loader } from "../../img/loader.svg";
import { getListOfValueJson, getAllCountries, getCitiesOfState, getStatesOfCountry } from "../../utils/data";
import CheckBoxInput from "../inputs/CheckBoxInput";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const getDefaultValues = (formFields) => {
	const values = {};
	if (formFields !== null) {
		formFields?.forEach((field) => {
			if (field.type === "bool") {
				values[field.code] = field.value ? true : false;
				return;
			}
			values[field.code] = field.value;
		});
	}
	return values;
};

function BookingPopUpContents({ data, closeModal, refreshPopUpContents, loading, setLoading, notify }) {
	const history = useHistory();
	const formRef = useRef();
	const { api } = useAuth();
	const [fieldValues, setFieldValues] = useState(getDefaultValues(data?.form?.formfields));
	const [error, setError] = useState(null);
	const [showMessageIntoPopUp, setShowMessageIntoPopUp] = useState(null);

	const handleOnChange = (event) => {
		setFieldValues((oldValues) => ({ ...oldValues, [event.target.name]: event.target.value }));
	};

	const sortFields = () => {
		data.form?.formfields?.sort((a, b) => {
			// a, b are one of the objects inside the data.form_fields
			if (a.row === b.row) {
				return a.order < b.order ? -1 : 1;
			}
			return a.row < b.row ? -1 : 1;
		});
		const rows = [];
		let items = [];
		let previousRow = undefined;
		for (let i = 0; i < data?.form?.formfields?.length; i++) {
			if (previousRow == null) {
				previousRow = data?.form?.formfields[i].row;
			}
			if (data?.form?.formfields[i].row !== previousRow) {
				rows.push(items);
				items = [];
				previousRow = data?.form?.formfields[i].row;
				items.push(data?.form?.formfields[i]);
			} else {
				items.push(data?.form?.formfields[i]);
			}
		}
		if (items.length > 0) {
			rows.push(items);
		}
		return rows;
	};
	const rows = sortFields();

	const handleOnModalClick = () => {
		switch (data.button.action) {
			case "send_form":
				if (formRef.current.checkValidity()) {
					submitForm();
					if (data.code === "complete_profile") {
						setShowMessageIntoPopUp("Stiamo completando il tuo profilo. Ti preghiamo di attendere.");
						setLoading(true);
						setTimeout(() => {
							setShowMessageIntoPopUp(false);
							refreshPopUpContents();
						}, 3000);
					}
				} else {
					formRef.current.reportValidity();
				}
				return;
			case "go_to_external":
				window.open(data.button.link, "_blank");
				return;
			case "go_to_internal":
				history.push(data.button.link);
				return;
			case "refresh":
				refreshPopUpContents();
				return;
			default:
				return;
		}
	};

	const submitForm = async () => {
		let values = {};
		Object.keys(fieldValues).forEach((key) => {
			if (["COUNTRY", "STATE", "CITY"].includes(key)) {
				const element = formRef.current.querySelector(`select[name="${key}"]`);
				if (element) {
					const name = element.querySelector(`option[value="${fieldValues[key]}"]`).getAttribute("data-name");
					values[key] = name;
					return;
				}
			}
			values[key] = fieldValues[key];
		});

		let body = data.form.api.body;
		Object.keys(values).forEach((key) => {
			body = body.replaceAll(key, values[key]);
		});
		body = JSON.parse(body);

		try {
			let response = await api(data.form.api.baseurl, data.form.api.method.toLowerCase(), body);
			if (response?.success === false) {
				setShowMessageIntoPopUp(response?.message?.error);
				return;
			} else if (response?.success === true && response?.message?.create_new === "Prenotazione confermata") {
				notify();
				refreshPopUpContents();
				closeModal(false);
				setFieldValues({});
			}
		} catch (error) {
			setError(error?.response?.detail ?? "An error occured by the server");
		}
	};
	return (
		<div className="container">
			{loading && (
				<div className="loading fixed">
					<Loader style={{ width: "130px", height: "130px" }} />{" "}
				</div>
			)}
			<div className="body-text">
				<h3 dangerouslySetInnerHTML={{ __html: data?.header?.body }}></h3>
				{showMessageIntoPopUp != null && <p className="text">{showMessageIntoPopUp}</p>}
				{error != null && <p className="text-danger">{error}</p>}
			</div>
			{data.code === "success_school" ? (
				<>
					<p>
						Gentile docente, questo incontro è riservato alle scuole. Puoi prenotarlo per min 3 – max 35 posti). Ricorda di
						includere nel totale te e i tuoi colleghi.
					</p>
				</>
			) : (
				""
			)}
			{loading === false && data?.form?.formfields && (
				<Form ref={formRef}>
					{rows.map((row) => {
						return (
							<div className="row">
								{row.map((item) => {
									if (data?.code === "success_standard" && item.hidden_field === true) {
										return <div key={item.code}></div>;
									}
									if (item.LOV.length !== 0 || item.LOV_json) {
										let options = [];
										if (item.LOV.length !== 0) {
											options = item.LOV.map((option) => {
												return { id: option, name: option };
											});
										} else {
											if (item.LOV_json === "country.json") {
												options = getAllCountries();
											} else if (item.LOV_json === "state.json") {
												let country = data.form.formfields.find((field) => field.LOV_json === "country.json");
												options = getStatesOfCountry(fieldValues[country.code]);
											} else if (item.LOV_json === "city.json") {
												let state = data.form.formfields.find((field) => field.LOV_json === "state.json");
												options = getCitiesOfState(fieldValues[state.code]);
											} else if (item.LOV_json === "profession.json") {
												options = getListOfValueJson("profession.json");
											} else {
												options = getListOfValueJson(item.LOV_json);
											}
										}
										return (
											<SelectInput
												className={row.length === 2 ? "col-sm-6" : row.length === 3 ? "col-sm-4" : "col"}
												name={item.code}
												label={item.label}
												required={item.mandatory}
												type="text"
												options={options}
												disabled={!item.editable}
												onChange={handleOnChange}
												value={fieldValues[item.code]}
												//placeholder={`Inserisci ${item.field_label}`}
											/>
										);
									} else if (item.type === "bool") {
										return (
											<CheckBoxInput
												className={row.length === 2 ? "col-sm-6" : row.length === 3 ? "col-sm-4" : "col"}
												name={item.code}
												label={item.label}
												required={item.mandatory}
												disabled={!item.editable}
												onChange={(event) => {
													setFieldValues((oldValues) => ({
														...oldValues,
														[event.target.name]: event.target.checked ? true : false,
													}));
												}}
												value={fieldValues[item.code]}
											/>
										);
									} else {
										return (
											<TextField
												className={row.length === 2 ? "col-sm-6" : row.length === 3 ? "col-sm-4" : "col"}
												name={item.code}
												label={item.label}
												required={item.mandatory}
												disabled={!item.editable}
												//type={item.type}
												type={item.type === "datetime" ? "date" : "text"}
												onChange={handleOnChange}
												value={fieldValues[item.code]}
											/>
										);
									}
								})}
							</div>
						);
					})}
				</Form>
			)}
			{data?.button != null && (
				<div className="button-action">
					<button
						className="blue-btn"
						onClick={handleOnModalClick}>
						{data?.button?.text}
					</button>
				</div>
			)}
		</div>
	);
}
export default BookingPopUpContents;
