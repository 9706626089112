import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router';
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import YTVideo from '../components/media/YTVideo';
import SpreakerPlayer from '../components/media/SpreakerPlayer';
import AzureVideo from '../components/media/AzureVideo';
import { getUrl } from '../utils/functions';
import { useAuth } from '../context/AuthContext';
import Vimeo from '../components/media/Vimeo';
import Sidebar from "../components/layout/Sidebar"
import SideBarContents from "../components/layout/SideBarContents"
import Main from "../components/layout/Main"

const fetchVideo = async (api, setVideo, contentId) => {
    try {
        let data = await api(getUrl("media", `media/content/${contentId}`))
        setVideo(data)

    } catch (error) {
        setVideo(null)
    }
}

function VideoPage() {

    const {api} = useAuth()
    const params = useParams()
    const [video, setVideo] = useState(null)

    useEffect(() => {
        fetchVideo(api, setVideo, params.id)
    }, [params.id])

    const getVideoContent = () => {
        let Component;
       debugger;
        switch (video.type) {
            case "azuremediaplayer":
                Component = AzureVideo;
                break;
            case "PODCAST":
                Component = SpreakerPlayer;
                break;
            // case "VIMEO":
            //     Component = Vimeo;
            //     break;
            default:
                Component = YTVideo;
        }
        return <Component
            title={video.attributes.title}
            subtitle={video.attributes.subtitle}
            description={video.attributes.description}
            url={video.attributes.url}
            chat={video.attributes.chat}
        />;
    }

    return (
        <div>
            <Header />
			<div className="page-body">
                <Sidebar>
                    <SideBarContents />
                </Sidebar>
				<div className='separating-line'></div>
                <Main>
            		{video != null ? getVideoContent() : <p style={{"fontSize" : "1.8rem"}}>Il convegno è riservato ai soli iscritti</p>}
				</Main>
			</div>
            <Footer />
        </div>
    )
}

export default VideoPage
