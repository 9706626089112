import React, { useState, useEffect, useContext } from "react";
import Footer from "../components/layout/Footer";
import Sidebar from "../components/layout/Sidebar";
import Main from "../components/layout/Main";
import Header from "../components/layout/Header";
import { useUser } from "../context/UserContext";
import { getUrl } from "../utils/functions";
import { useAuth } from "../context/AuthContext";
import { useHistory } from "react-router";
import SideBarContents from "../components/layout/SideBarContents";
import DashCard from "../components/dashboard/DashCard";
import DashVideo from "../components/dashboard/DashVideo";
import DashCardMobile from "../components/dashboard/DashCardMobile";
import mobileContext from "../context/MobileContext";

const fetchLayOut = async (api, setLayOut) => {
	try {
		let data = await api(getUrl("dashboard", "dashboard/cards"));
		setLayOut(data);
	} catch (error) {
		setLayOut(null);
	}
};

function Dashboard() {
	const [_, __, userInfo] = useUser();
	const { api } = useAuth();
	const history = useHistory();
	const mobileInfo = useContext(mobileContext);
	const [layOut, setLayOut] = useState([]);

	useEffect(() => {
		fetchLayOut(api, setLayOut);
	}, []);

	let getCardsArray = (() => {
		const cardsToReturn = [];
		if (layOut) {
			layOut.forEach((row) => {
				row.forEach((card) => {
					if (card.type === "card") {
						cardsToReturn.push(card);
					}
				});
			});
		}
		return cardsToReturn;
	})();

	const getDashCards = (isMobile) => {
		if (layOut === null) {
			return;
		}
		const flattenCardsArr = layOut.flat().filter((card) => {
			return card.type === "card";
		});
		let rows = [];
		flattenCardsArr.map((card) => {
			if (!rows.includes(card.row)) rows.push(card.row);
		});
		rows = rows.sort((a, b) => {
			return a - b;
		});
		const groupedCards = [];
		rows.forEach((rowIndex) => {
			let tempArr = [];
			flattenCardsArr.forEach((card) => {
				if (card.row === rowIndex) tempArr.push(card);
			});
			groupedCards.push(tempArr);
		});
		return (
			<div className="target-divve">
				{groupedCards.map((singleRow, rowIndex) => {
					return (
						<div
							className="dashboard-row row"
							style={{ justifyContent: singleRow[0].alignment }}
							key={rowIndex}>
							{singleRow.map((card, cardIndex) => {
								return (
									<DashCard
										cardParams={card}
										key={rowIndex + "-" + cardIndex}
										isMobile={isMobile}></DashCard>
								);
							})}
						</div>
					);
				})}
			</div>
		);
	};

	function getVideoCard(isMobile) {
		if (layOut === null) {
			return;
		}
		let videoCard = [];
		let isFirst = true;
		layOut.forEach((row) => {
			row.forEach((card, index) => {
				if (card.type === "video" || card.type === "azuremediaplayer" || card.type === "staticvideo") {
					videoCard.push(
						<DashVideo
							isMobile={isMobile}
							cardParams={card}
							hideTitle={!isFirst}></DashVideo>
					);
					isFirst = false;
				}
			});
		});
		return videoCard;
	}

	return (
		<div>
			<Header />
			{/* <div className="row w-100 mx-0 mx-md-auto"> */}
			<div className="page-body">
				<Sidebar>
					<SideBarContents />
				</Sidebar>
				<div className="separating-line"></div>
				<Main>
					<div
						className="dashboard-dashboard-container"
						style={{ width: "100%" }}>
						{/* {getDashCardsDesktop()} */}
						{!mobileInfo.isMobile ? (
							<>
								{getDashCards(false)}
								{getVideoCard(false)}
							</>
						) : (
							<div className="dashboard-mobile-container">
								<p className="dashboard-mobile-title">
									Ciao!
									<br />
									Benvenuto su Salto+
								</p>
								<p className="dashboard-mobile-subtitle">
									Ciao! Questa è SalTo+! Accedi ai contenuti e ai servizi della piattaforma digitale del Salone
									Internazionale del Libro di Torino.
								</p>
								<div className="dashboard-mobile-card-external-container">
									{getCardsArray.map((singleCard) => {
										return <DashCardMobile cardParams={singleCard}></DashCardMobile>;
									})}
									{getVideoCard(true)}
								</div>
							</div>
						)}
					</div>
				</Main>
			</div>
			<Footer />
		</div>
	);
}

export default Dashboard;
