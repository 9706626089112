import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';


function EventCard({ title, subTitle, code, img, formType }) {
    return (

        <Card className="mt-3" style={{ width: '18rem' }}>
            <Card.Img src={`/static/media/${img}`} />

            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Card.Subtitle>{subTitle}</Card.Subtitle>
                {/* <Card.Text>
                    {description}
                </Card.Text> */}

                {(
                    // formType === "laboratorio_0_6" ||
                    formType === "laboratorio_scuola_primaria" ||
                    formType === "laboratorio_scuola_secondaria") ? (
                    <Link to="#" className="button d-block prenota-link-disabled white-btn w-100">La fase di candidatura è chiusa. Tutti gli iscritti verranno contattati via mail.</Link>
                ) : (
                    <Link to={`/forms/form/${code}`} className="button d-block prenota-link white-btn w-100">Prenotati</Link>
                )}

            </Card.Body>
        </Card>

    )
}

export default EventCard;
