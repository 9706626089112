import React, { useState, useEffect } from "react";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import { useHistory } from "react-router-dom";
import { Form } from "react-bootstrap";
import buono_img from "./img/home/buono.jpg"
import salone from "./utils/salone";
import swal from 'sweetalert';
import imgNotification from "./img/icon/notification.svg";
import Cookies from "js-cookie";



function ValidationPage(props) {
  const history = useHistory();
  //const [message, setMessage] = useState("");
  const [firstMessage, setFirstMessage] = useState("");
  const [secondMessage, setSecondMessage] = useState(<div></div>);
  const [form, setForm] = useState();
  const [libraryCode, setLibraryCode] = React.useState("");
  const [buonoCode, setBuonoCode] = useState(props.match.params.code);

  //const [buonoForm, setBuonoForm] = useState();
  //const [libreriaForm, setLibreriaForm] = useState();

  const handleSubmit = (event) => {
    event.preventDefault();


    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {

      swal({
        title: "Sei sicuro di voler associare questo buono alla tua libreria?",
        icon: "warning",
        buttons: ["NO", "Si"],
      })
        .then((value) => {
          console.log(value);
          if (value == null) {
            return;
          }
          salone
            .get(`/buonolibrovalidation/consume/${document.getElementById("buono_code").value}&${document.getElementById("libcode").value}`,
              {
                headers: {
                  "content-type": "application/json",
                },
              }
            )
            .catch((error) => {
              console.log(error.response?.data.detail);
              setSecondMessage("C'è stato un errore, riprova più tardi");
            })

            .then((response) => {
              let messageState = {
                img: imgNotification
              };
              if (response.data !== null && response.data !== undefined) {
                if (response.data[0].valid) {
                  // setSecondMessage(<div className="row text-center text-primary">
                  //   <p className="w-100">Il buono da leggere è stato validato e correttamente associato alla tua libreria</p>
                  // </div>);
                  messageState.message = "Il buono da leggere è stato validato e correttamente associato alla tua libreria";
                  messageState.type = "confirm";
                  messageState.back = true;
                }
                else {
                  // setSecondMessage(<div className="row text-center text-danger">
                  //   <p className="w-100">{response.data[0].status}</p>
                  // </div>);
                  messageState.message = response.data[0].status;
                  messageState.type = "error";
                  messageState.back = true;
                }
              }
              else {
                // setSecondMessage(<div className="row text-center text-danger">
                //   <p className="w-100">Purtroppo il buono da leggere non è valida, risulta già utilizzato.</p>
                // </div>);
                messageState.message = "Purtroppo il buono da leggere non è valido, risulta già utilizzato.";
                messageState.type = "error";
                messageState.back = true;
              }
              history.push({
                pathname: "/message",
                state: messageState
              });
            });
        })


    }
  };

  const buono = (
    <div className="w-100 mb-3">
      <Form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <p style={{ fontWeight: "bold" }}>Inserisci Codice buono </p>
          </div>
        </div >
        <div className="row">
          <div className="col-12">
            <input
              type="text"
              id="buono_code"
              placeholder="codice buono..."
              onChange={(e) => setBuonoCode(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <p style={{ fontWeight: "bold" }}>Inserisci Codice libreria </p>
          </div>
        </div >
        <div className="row">
          <div className="col-12">
            <input
              type="text"
              id="libcode"
              placeholder="codice libreria..."
              onChange={(e) => setLibraryCode(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12">
            <button type="submit" className="button blue-btn w-100">
              Valida il buono
            </button>
          </div>
        </div>
      </Form>
    </div>
  );

  const libreria = (
    <div className="w-100 mb-3">
      <div className="row">
        <div className="col-12">
          <p style={{ fontWeight: "bold" }}>Inserisci Codice libreria </p>
        </div>
      </div >
      <Form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <input
              type="text"
              id="libcode"
              placeholder="codice libreria..."
              onChange={(e) => setLibraryCode(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <button type="submit" className="button blue-btn w-100">
              Valida il buono
            </button>
          </div>
        </div>
      </Form>
    </div>
  );

  useEffect(() => {
    if (props.match.params.code !== undefined) {
      salone
        .get(`/buonolibrovalidation/verify/${buonoCode}`,
          {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${Cookies.get("token")}`,
            },
          }
        )
        .catch((error) => {
          console.log(error.response?.data.detail);
          history.push("/login");
        })

        .then((response) => {
          let data = response.data[0];
          if (data.consumed === false && data.valid === true) {
            setFirstMessage(<div><h1>Il tuo buono è valido!</h1> <br /> <h2>Inserisci di seguito il codice della libreria in cui riscattarlo.</h2></div>);
            setForm(libreria);
          }
          else if (data.consumed === true && data.valid === true) {
            setFirstMessage(<div><h1>Il tuo buono è già stato utilizzato.</h1> <br /> <h2> Riprova utilizzando un nuovo codice. </h2></div>);
          } else {
            setFirstMessage(<div><h1>Purtroppo il tuo buono non è valido.</h1> <br /> <h2> Riprova utilizzando un codice corretto</h2></div>);
          }
        });
    }
    else {
      setFirstMessage(<div><h1>Valida* il Buono da Leggere!</h1> <br /> <h2>Compila il form con il codice della libreria e il codice associato al buono.</h2> <h4 className="mt-5">*Attenzione! La compilazione del form è a cura della libreria.</h4></div>);
      setForm(buono);
    }
  }, []);

  return (
    <div>
      <Header />
      <div className="container custom-container">
        <div className="row">
          <img src={buono_img} className="buono-img mx-auto" alt="" />
        </div>
        <div className="row my-4">
          <div className="col-12 col-md-6 mb-3">
            {firstMessage}
          </div>
          <div
            className="col-12 col-md-6"
          >
            {form}
          </div>
        </div>
        <div className="row my-4">
          <div
            className="col-12 offset-md-6 col-md-6"
          >
            {secondMessage}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ValidationPage;
