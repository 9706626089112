import React, { useState, useEffect, useRef } from 'react'
import Pagination from "../../components/Pagination.js"
import TextField from '../../components/inputs/TextField'
import Footer from '../../components/layout/Footer'
import Header from '../../components/layout/Header'
import "../../css/sponsors.css"
import { downloadBlobFile, generateFileName, getUrl } from '../../utils/functions'
import { useAuth } from '../../context/AuthContext'
import SponsorsTable from '../../components/sponsors/SponsorsTable.js'
import SearchQueryByProgramma from '../../components/sponsors/SearchQueryByProgramma.js'
import SelectInput from '../../components/inputs/SelectInput.js'
import { ReactComponent as Export } from '../../img/icon/export_table.svg'
import { ReactComponent as Loader } from '../../img/loader.svg'
import { useHistory } from 'react-router-dom'
import { useValuesFiltered } from '../../context/ValuesFilteredContext.js'
import AdminNavbar from '../../components/layout/AdminNavbar';
import { ReactComponent as CleanFields } from '../../img/icon/filter-remove.svg';


function Sponsors() {
    const { api } = useAuth()
    const [valuesFiltered, setValuesFiltered] = useValuesFiltered()
    const [sponsorsEvents, setSponsorsEvents] = useState({
        status: false,
        data: null,
        page: 1,
        error: null
    })
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState(valuesFiltered)
    const [hasNextPage, setHasNextPage] = useState(false)
    const [blobFileLoading, setBlobFileLoading] = useState(false)
    const [recordsNumber, setRecordsNumber] = useState(null)
    const history = useHistory()
    const searchQueryRef = useRef()
    const valuesLengthRef = useRef(Object.keys(values).length)

    const handleOnChange = (event) => {
        setValues(oldValues => ({ ...oldValues, [event.target.name]: event.target.value }))
        setValuesFiltered(oldValues => ({ ...oldValues, [event.target.name]: event.target.value }))
    }

    const fetchSponsorsAndEvents = async (page = 1) => {
        const params = new URLSearchParams()
        Object.keys(values).map((key) => {
            if (values[key]) {
                params.set(key, values[key])
            }
        })
        const limit = values?.limit ? values.limit : 5
        params.set("skip", (page - 1) * limit)
        params.set("limit", limit)
        try {
            setLoading(true)
            let data = await api(getUrl("booking_admin", `sponsor_booking/booking?${params.toString()}`))
            setLoading(false)
            if (data?.length === 0 && page > 1) {
                setHasNextPage(false)
                return;
            }
            setSponsorsEvents({
                status: true,
                data: data,
                page: page,
                error: null
            })
            if (data?.length < limit) {
                setHasNextPage(false)
            } else {
                setHasNextPage(true)
            }
        } catch (error) {
            setSponsorsEvents({
                status: false,
                data: null,
                page: 1,
                error: error?.response?.data?.detali?.[0]?.msg ?? "An error occured by the server"
            })
            setHasNextPage(false)
        }
    }


    const searchByFilter = () => {
        fetchSponsorsAndEvents()
        fetchRecordsNumber()
    }

    const exportXslFile = () => {
        const params = new URLSearchParams()
        Object.keys(values).map((key) => {
            if (values[key]) {
                params.set(key, values[key])
            }
        })
        var config = {
            service: 'coupon_admin',
            method: 'post',
            url: `report/download/sponsor?${params.toString()}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {}
        };
        let prefix = "Export sponsor"
        downloadBlobFile(api, config, generateFileName(prefix), setBlobFileLoading)

    }

    const fetchRecordsNumber = async () => {
        const params = new URLSearchParams()
        Object.keys(values).map((key) => {
            if (values[key]) {
                params.set(key, values[key])
            }
        })
        try {
            var config = {
                service: 'coupon_admin',
                method: 'post',
                url: `sponsor_booking/booking/count?${params.toString()}`,
                headers: {
                    'Content-Type': 'application/json'
                },
            };
            let response = await api(getUrl(config.service, config.url), config.method, {}, config.headers)
            setRecordsNumber(response)
        } catch (error) {

        }
    }

    useEffect(() => {
        const newValueLength = Object.keys(values).length
        if (newValueLength === 0 && valuesLengthRef.current > 0) {
            searchByFilter()
        }
        valuesLengthRef.current = newValueLength
    }, [values])

    useEffect(() => {
        fetchSponsorsAndEvents()
        fetchRecordsNumber()
    }, [values?.limit])

    return (
        <div>
            <Header />
            <div className="admin-pages-main" style={{ minHeight: "68vh" }}>
                <AdminNavbar back="/admin_dashboard" title="Sponsor e case editrici" />
                <div className="container-fluid">
                    {(loading || sponsorsEvents.loading || blobFileLoading) && (<div className="loading fixed"><Loader /> </div>)}
                    <div className="sponsor-page">
                        <div className="row">
                            <SearchQueryByProgramma
                                label="Evento"
                                className="col-md-4"
                                name="event_id"
                                value={values.event_id}
                                feedback={`${values?.event_id ? "feedback" : ""}`}
                                onChange={handleOnChange}
                                type="text"
                                required={false}
                                ref={searchQueryRef} />
                            <TextField
                                label="Nome Azienda"
                                className="col-md-4"
                                name="company_name"
                                value={values.company_name}
                                feedback={`${values?.company_name ? "feedback" : ""}`}
                                onChange={handleOnChange}
                                type="text"
                                required={false} />

                        </div>
                        <div className="row">
                            <TextField
                                label="Nome"
                                className="col-md-4"
                                name="first_name"
                                value={values.first_name}
                                feedback={`${values?.first_name ? "feedback" : ""}`}
                                onChange={handleOnChange}
                                type="text"
                                required={false} />
                            <TextField
                                label="Cognome"
                                className="col-md-4"
                                name="last_name"
                                value={values.last_name}
                                feedback={`${values?.last_name ? "feedback" : ""}`}
                                onChange={handleOnChange}
                                type="text"
                                required={false} />
                            <TextField
                                label="Email"
                                className="col-md-4"
                                name="email"
                                value={values.email}
                                feedback={`${values?.email ? "feedback" : ""}`}
                                onChange={handleOnChange}
                                type="text"
                                required={false} />
                        </div>
                        <div className="row">
                            <TextField
                                label="Note"
                                className="col-md-4"
                                name="note"
                                value={values.note}
                                feedback={`${values?.note ? "feedback" : ""}`}
                                onChange={handleOnChange}
                                type="text"
                                required={false} />
                            <div className="col-md-1">
                                <div className="remove-field-values">
                                    <CleanFields
                                        onClick={() => {
                                            setValuesFiltered({})
                                            setValues({})
                                            if (searchQueryRef.current) {
                                                searchQueryRef.current.clear()
                                            }
                                        }}
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Svuota i campi"
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="row mt-3 align-items-center">
                            <div className="col-auto">
                                <button
                                    className="btn-new-booking-sponsor"
                                    onClick={() => history.push("/sponsor/new")}
                                >
                                    Nuova prenotazione
                                </button>
                            </div>

                            <div className="col-md-2">
                                <button className="search-btn" onClick={searchByFilter}>Cerca</button>
                            </div>
                        </div>

                        <div className="row mt-5">
                            <SelectInput
                                className="col-md-4"
                                name="limit"
                                required={false}
                                options={[
                                    { id: 5, name: 5 },
                                    { id: 10, name: 10 },
                                    { id: 25, name: 25 },
                                    { id: 50, name: 50 }
                                ]}
                                placeholder="inserimento numero risultati in pagina"
                                emptyOption={true}
                                onChange={handleOnChange}
                                value={values?.limit}
                            />
                            <div className="records-export">
                                <div className='records-find'>{recordsNumber} posti prenotati</div>
                                <Export className="export-table"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Export"
                                    onClick={exportXslFile}
                                />
                            </div>
                        </div>

                        {sponsorsEvents?.data != null && <SponsorsTable
                            data={sponsorsEvents?.data}
                            setSponsorsEvents={setSponsorsEvents}
                        />
                        }
                    </div>

                    {sponsorsEvents?.data != null && (
                        <Pagination hasPrevious={sponsorsEvents?.page > 1}
                            hasNext={hasNextPage}
                            currentPage={sponsorsEvents?.page}
                            onClick={(pageStatus) => {
                                fetchSponsorsAndEvents(pageStatus === "prev" ? sponsorsEvents?.page - 1 : sponsorsEvents?.page + 1)
                            }} />
                    )}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Sponsors
