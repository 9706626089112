import React, { useState } from 'react'
import { useParams } from 'react-router'
import Tick from '../../img/icon/tick.svg'
import { getYYYYMMDDHH } from '../../utils/functions'


function AccessTableInfo({ data, currentAccess }) {

    const [expandRow, setExpandRow] = useState(false)
    const { participant_id } = useParams()

    const accessDateTime = currentAccess ? new Date(currentAccess.access) : ""
    const getExpandInformation = () => {
        const participants = Object.values(data.participants);
        participants.sort((a, b) => {
            return a.id === participant_id ? -1 : (b.id === participant_id ? 1 : 0)
        })
        return <td colSpan="11" className="expand-information">
            {participants.map((item) => {
                let accessDateTime1 = item.access ? new Date(item.access) : ""

                switch (item.type) {
                    case "Booker":
                    case "Friend":
                        return <p>Nome: <b>{item.nome}</b> Cognome: <b>{item.cognome}</b> Email: <b>{item.email}</b> {item.access != null && (<>
                            Data e ora access: <b>{getYYYYMMDDHH(accessDateTime1)}</b>
                            <img src={Tick} style={{ width: "30px", height: "30px" }} />
                        </>)
                        }</p>

                    case "Classroom":
                        return <p>Nome della classe: <b>{item.class_name}</b> Nome della scuola: <b>{item.school_name}</b> {item.access != null &&
                            <img src={Tick} style={{ width: "30px", height: "30px" }} />
                        }</p>
                    case "Minor":
                        return <p>Nome: <b>{item.nome}</b> Cognome: <b>{item.cognome}</b> {item.access != null && (<>
                            Data e ora access: <b>{getYYYYMMDDHH(accessDateTime1)}</b>
                            <img src={Tick} style={{ width: "30px", height: "30px" }} />
                        </>)
                        }</p>
                    case"Sponsor":
                        return <p>Id: <b>{item.id}</b> {item.access != "" && (<>
                            Data e ora access: <b>{getYYYYMMDDHH(accessDateTime1)}</b>
                            <img src={Tick} style={{ width: "30px", height: "30px" }} />
                        </>)
                        }</p>
                    default:
                        return;
                }
            })}
        </td>
    }
    return (
        <div className="booked-list prenotazione-color-table access-table">
            <table className="table table-striped ">
                <thead>
                    <tr>
                        <th></th>
                        <th>Nome</th>
                        <th>Cognome</th>
                        <th style={{width: "200px"}}>Data e ora accesso</th>
                        <th className="participants">N° prenotati</th>
                        <th style={{width: "200px"}}>N° accessi</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className={"highlighted"} >
                        <td><span className="plus" onClick={() => {
                            setExpandRow(!expandRow)
                        }}>{expandRow ? "-" : "+"}</span>
                        </td>

                        <td>{currentAccess.nome}</td>
                        <td>{currentAccess.cognome}</td>
                        <td>{getYYYYMMDDHH(accessDateTime)}</td>
                        <td className="number-of-participants">{data.number_of_participants}</td>
                        <td>{data.access_number}</td>

                    </tr>
                    {expandRow && (
                        <tr className={"highlighted expanded"}>
                            {getExpandInformation()}
                        </tr>
                    )
                    }
                </tbody>
            </table>
        </div >
    )
}

export default AccessTableInfo
