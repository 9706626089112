import React, { useEffect, useRef, useState } from 'react';
import "./css/modal-cookie.css";
import "./css/cookie.css";




function CookiePrivacy2() {

    const [cookie, setCookie] = useState(true);
    const modalRef = useRef(null);

    useEffect(() => {
        if (window.localStorage.getItem("cookiePrivacy") === "true") {
            setCookie(false);
        }
        // swal({
        //     title: "Cookie Privacy!",
        //     text: "Here you can see cookie privacy",
        //     icon: "info",
        //     button: "Accetta e chiudi",
        // }).then(()=>{
        //     window.localStorage.setItem("cookiePrivacy", true);
        // });
    }, [])

    const acceptCookies = () => {
        window.localStorage.setItem("cookiePrivacy", true);
        modalRef.current.classList.add("fadeOut");
        setTimeout(() => {
            setCookie(false);;
        }, 400)

    }
    return (

        cookie === true ? (
            <div className="modal-cookie" ref={modalRef}>
                <div className="modal-cookie-wrapper">
                    <div className="modal-box">
                        <div className="modal-header">
                            <h3>Cookie Policy</h3>
                            {/* <button onClick={closeModal}>&#10006;</button> */}
                        </div>
                        <div className="modal-body">
                            <div className="row space">
                                <div className="col-xs-12 col-md-10 p">
                                    <p>
                                        Per poter garantire le funzionalità e migliorare la fruizione di questo sito web vengono utilizzati solo cookies tecnici.
                                    </p>
                                    <ul className="links">
                                        <li>
                                            <a href="https://www.salonelibro.it/privacy-policy.html" target="_blank">Clicca qui per leggere la nostra informativa sulla privacy.</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xs-12 col-md-2">
                                    <div className="buttons-container">
                                        <button className="accept-buuton-cookies mx-md-2 float-right" onClick={acceptCookies}>Accetto</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : ""


    )
}

export default CookiePrivacy2;
