import React, { useEffect, useState } from 'react'
import "../../css/presentationModal.css"
import forward_icon from "../../img/prenotazione_icons/Arrow.svg"
import evento_prenotato from "../../img/presentazione_images/evento_prenotato.png"
import evento_modificato from "../../img/presentazione_images/evento_modificato.png"
import evento_eliminato from "../../img/presentazione_images/evento_eliminato.png"
import pagina_1 from "../../img/presentazione_images/pagina_1.png"
import pagina_2 from "../../img/presentazione_images/pagina_2.png"
import pagina_3 from "../../img/presentazione_images/pagina_3.png"
import pagina_4 from "../../img/presentazione_images/pagina_4.png"
import aggiungi from "../../img/prenotazione_icons/omino.svg"
import elimina from "../../img/prenotazione_icons/elimina.svg"
import dettagli from "../../img/prenotazione_icons/Arrow.svg"



function PresentationModal({setShowModal}) {
	const [section, setSection] = useState(0);
	const sectionRef = React.useRef(section);

	let swipeStartX = 0;
	let swipeStartY = 0;

	let swipeEndX = 0;
	let swipeEndY = 0;

	let startFunc, endFunc;

	function handleSwipe() {

		const sectionValue = sectionRef.current;
		const deltaAngle = (swipeStartY - swipeEndY) / (swipeStartX - swipeEndX);
		const isInAngle = (deltaAngle > -1.5 && deltaAngle < 1.5) ? true : false;
		console.log(deltaAngle);

		if (swipeStartX < swipeEndX && isInAngle) {
			const newPage = sectionValue === 0 ? sectionValue : sectionValue - 1
			setSection(newPage);
		} else if (swipeStartX > swipeEndX && isInAngle) {
			const newPage = sectionValue === 3 ? sectionValue : sectionValue + 1
			setSection(newPage);
		} else if (swipeStartX == swipeEndX) {
			return;
		}
	}

	useEffect(() => {
		startFunc = (event) => {
			swipeStartX = event.changedTouches[0].screenX;
			swipeStartY = event.changedTouches[0].screenY;
		};
		endFunc = (event) => {
			swipeEndX = event.changedTouches[0].screenX;
			swipeEndY = event.changedTouches[0].screenY;
			handleSwipe();
		}
	}, [])


	useEffect(() => {
		sectionRef.current = section;
		const swipeZone = document.getElementById("presentation-modal");

		swipeZone.removeEventListener("touchstart", startFunc);
		swipeZone.addEventListener("touchstart", startFunc, false);
		
		swipeZone.removeEventListener("touchend", endFunc);
		swipeZone.addEventListener("touchend", endFunc, false);
	}, [section])

	return (
		<div className='presentation-container' id='presentation-modal'>
			<div className="close-btn-container">
                    <button onClick={() => setShowModal(false)}>&#10006;</button>
            </div>
			<div className='presentation-header'>
				{section === 0 && (<p>Ciao! Qui gli utenti trovano gli eventi a cui si sono prenotati e quelli a cui partecipare.</p>)}	
				{section === 1 && (<p>Il Salone sempre in tasca, come un libro.</p>)}	
				{section === 2 && (<p>Potrai vedere i dettagli dell'evento, cancellarti o aggiungere un altro partcipante.</p>)}	
				{section === 3 && (<p>E se cambia qualcosa?</p>)}
			</div>
			<div className='presentation-body'>
				{section === 0 && (<div className='upper-title'>
					<p>Per prenotare l'evento dovrai accedere alla pagina dove sono mostrati gli eventi disponibili.</p>
				</div>)}
				{section === 1 && (<div className='lower-title'>
					<p>Quando avrai prenotato un evento lo troverai su questa pagina.</p>
					<p>Sarà fatto così:</p>
					<img src={evento_prenotato} className='event-card-logo'></img>
				</div>)}
				{section === 2 && (<div>
					<div className='logo-text-row lower-title'>
						<img className='logo-row' src={aggiungi}></img>
						<p>Aggiungi un amico per vedere l'evento con lui. Assieme è meglio.</p>
					</div>
					<div className='logo-text-row'>
						<img className='logo-row' src={elimina}></img>
						<p>Non vuoi venire? Faccelo sapere, così lascio il posto a qualcun altro.</p>
					</div>
					<div className='logo-text-row'>
						<img className='logo-row' src={dettagli}></img>
						<p>Non ti ricordi tutto dell'evento? Tranquillo, è tutto qui.</p>
					</div>
				</div>)}
				{section === 3 && (<div className='risen-up-body'>
					<div className='logo-logo-row lower-title'>
						<div className='two-logo-row'>
							<img src={evento_modificato}></img>
						</div>
						<div className='two-logo-row'>
							<img src={evento_eliminato}></img>
						</div>
						<div className='one-logo-row'>
							<img src={evento_prenotato}></img>
						</div>
					</div>
					<p>Giallo quando cambia qualcosa (poi ridiventa blu dopo che hai guardato). Rosso quando l'evento è cancellato. Se è blu... sorridi. :)</p>
				</div>)}
			</div>
			<div className='presentation-footer'>
				{(section === 0 || section === 1 || section === 2) && (<div className='presentation-navigation-container' style={window.screen.width < 650 ? {"visibility" : "hidden"} : {}}>
						<img src={forward_icon} className='back-icon' style={section == 0 ?  {"visibility":"hidden"} : {}} onClick={() => {
							setSection(section - 1);
						}}></img>
						<img src={forward_icon} className='forward-icon' onClick={() => {
							setSection(section + 1);
						}}></img>
				</div>					
				)}
				{section === 3 && (<div className='footer-button'>
					<button className='go-back-button' onClick={()=>{
						setShowModal(false);
						window.localStorage.showEventPresentation = false;
					}}>Torna agli eventi</button>
				</div>)}
			</div>
			<div className='pagination'>
				{section === 0 && (
					<img src={pagina_1} className='pagination-image'></img>
				)}
				{section === 1 && (
					<img src={pagina_2} className='pagination-image'></img>
				)}
				{section === 2 && (
					<img src={pagina_3} className='pagination-image'></img>
				)}
				{section === 3 && (
					<img src={pagina_4} className='pagination-image'></img>
				)}
			</div>
			
		</div>
	)
}

export default PresentationModal