import React, { useEffect, useState } from 'react'
import { useEvents } from '../../context/EventsContext'
// import { ReactComponent as Trash } from '../../img/icon/trash.svg'
import { Link } from 'react-router-dom'
import DownloadPDF from '../../img/icon/download_pdf.svg'
import { getYYYYMMDDHH } from '../../utils/functions'
import Tick from '../../img/icon/tick.svg'
import { useAuth } from '../../context/AuthContext'
import { getUrl } from '../../utils/functions'
import { ReactComponent as Loader } from '../../img/loader.svg'


function SponsorsTable({ data, setSponsorsEvents }) {
    const { events, fetchEvents } = useEvents()
    const { api } = useAuth()
    const [expandRow, setExpandRow] = useState([]) // an array of rowIds
    const [validateQRCode, setValidateQRCode] = useState({
        status: false,
        dataValidation: null,
        loading: false,
        error: null
    })

    useEffect(() => {
        fetchEvents(data.map((item) => item.event_id))
    }, [data])

    const bookingValidation = async (bookingId, participantId) => {

        try {
            setValidateQRCode({ ...validateQRCode, loading: true })
            let dataValidation = await api(getUrl("booking_admin", `access/validate_backoffice/${bookingId}/${participantId}`))
            setValidateQRCode({
                status: true,
                dataValidation: dataValidation,
                loading: false,
                error: null
            })
            const index = data.findIndex((item) => item.id === bookingId)
            if (index != null) {
                setSponsorsEvents((oldSponsorsEvents) => {
                    const newSponsorsEvents = { ...oldSponsorsEvents }
                    newSponsorsEvents.data[index] = dataValidation
                    return newSponsorsEvents

                })
            }

        } catch (error) {
            setValidateQRCode({
                status: false,
                dataValidation: null,
                loading: false,
                error: error?.response?.dataValidation?.detail?.[0]?.msg || error?.response?.dataValidation?.detail || "An error occured by the server!"
            })
        }
    }

    const getExpandInformation = (row) => {
        return <td colSpan="11" className="expand-information">
            {Object.values(row.participants).map((item) => {
                let accessDateTime = item.access ? new Date(item.access) : ""

                switch (item.type) {
                    case "Sponsor":
                        return <p>
                            {accessDateTime != '' ? (<img src={Tick} className="img-inside-info-table" />) : (
                                <button
                                    className="button-inside-info-table"
                                    onClick={() => bookingValidation(row.id, item.id)}
                                >
                                    Valida
                                </button>
                            )}
                            {item.pdfURL != "" && <a href={item.pdfURL} download>
                                <img src={DownloadPDF} style={{ width: "30px", height: "30px", marginRight: "10px" }} />
                            </a>}
                            Id: <b>{item.id}</b>
                            Accesso: <b>{accessDateTime != '' ? getYYYYMMDDHH(accessDateTime) : ""}</b>

                            {validateQRCode?.loading && (<div className="loading fixed"><Loader style={{ width: "130px", height: "130px" }} /> </div>)}
                        </p>
                    default:
                        return;
                }
            })}
        </td>
    }



    return (
        <div className="row booked-list">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th></th>
                        <th>Id prenotazione</th>
                        <th className="company-name">Nome azienda</th>
                        <th>Nome</th>
                        <th>Cognome</th>
                        <th>Evento</th>
                        <th>Email</th>
                        <th className="participants">N° prenotati</th>
                        <th>N° accessi</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => {
                        const isExpanded = expandRow.includes(row.id)
                        return <>
                            <tr className={index % 2 === 0 ? "highlighted" : ""} key={row.id}>
                                <td><span className="plus" onClick={() => {
                                    if (isExpanded) {
                                        setExpandRow(expandRow.filter((rowId) => rowId !== row.id))
                                    } else {
                                        setExpandRow([...expandRow, row.id])
                                    }
                                }}>{isExpanded ? "-" : "+"}</span></td>
                                <td>{row.id}</td>
                                <td>{row.company_name}</td>
                                <td>{row.first_name}</td>
                                <td>{row.last_name}</td>
                                <td>{events[row.event_id]?.title}</td>
                                <td>{row.email}</td>
                                <td className="number-of-participants">{row.number_of_participants}</td>
                                <td>{row.access_number}</td>
                                <td>
                                    {/* <span className="delete">
                                        <Trash />
                                    </span> */}
                                    <Link to={`/sponsor/${row.id}`} className="edit" >
                                        <i className='far'>&#xf044;</i>
                                    </Link>
                                </td>
                            </tr>
                            {isExpanded && (
                                <tr className={index % 2 === 0 ? "highlighted" : ""} key={row.id + "expanded"}>
                                    {/* <td colSpan="9"
                                        dangerouslySetInnerHTML={{
                                            __html: [row.id,
                                            row.company_name,
                                            row.first_name,
                                            row.last_name,
                                            events[row.event_id]?.title,
                                            row.email, row.number_of_participants].join("&nbsp; &nbsp; &nbsp;")
                                        }}>
                                    </td> */}
                                    {getExpandInformation(row)}
                                </tr>
                            )}
                        </>
                    })}

                </tbody>
            </table>
        </div>
    )
}

export default SponsorsTable
