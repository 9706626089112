import React from 'react'
import Tick from '../../img/icon/tick.svg'
import { getYYYYMMDDHH } from '../../utils/functions';

function CurrentAccessInfo({ currentAccess }) {

    const accessDateTime = currentAccess ? new Date(currentAccess.access) : ""
    return (
        <div className="container-tick-info">
            <img src={Tick} />
            <h2>Validazione accesso effettuata!</h2>
            {currentAccess != null && (
                <div className="info-validation">
                    <h3>Dati della prenotazione:</h3>
                    {currentAccess.nome && <p>Nome: <b>{currentAccess.nome}</b></p>}
                    {currentAccess.cognome && <p>Cognome: <b>{currentAccess.cognome}</b></p>}
                    {currentAccess.email && <p>Email: <b>{currentAccess.email}</b></p>}
                    <p>Data e ora accesso: <b>{getYYYYMMDDHH(accessDateTime)}</b></p>
                </div>
            )}

        </div>
    )
}

export default CurrentAccessInfo
