import { createContext } from "react";
import "../css/toastCustomCss.css"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide } from 'react-toastify'

import success_toast from "../img/toast/success_toast.png"
import error_toast from "../img/toast/error_toast.png";
import warning_toast from "../img/toast/warning_toast.png";

const toastBaseOptions = {
	position: "top-right",
	autoClose: false,
	hideProgressBar: false,
	closeOnClick: true,
	pauseOnHover: false,
	draggable: false,
	progress: undefined,
	theme: "colored",
	transition: Slide,
	pauseOnFocusLoss : true,
	className : "toast-notification-wrapper",
	bodyClassName : "toast-notification-body"
}

const toastSuccessOptions = {...toastBaseOptions, ...{
	icon : ({theme, type}) => <img src={success_toast} />,
	className : "toast-info-wrapper toast-notification-wrapper"
}}

const toastWarnOptions = {...toastBaseOptions, ...{
	icon : ({theme, type}) => <img src={warning_toast} />,	
	bodyClassName : "toast-notification-body toast-body-warn",
	className : "toast-warn-wrapper toast-notification-wrapper"
}}

const toastErrorOptions =  {...toastBaseOptions, ...{
	icon : ({theme, type}) => <img src={error_toast} />,
	className : "toast-error-wrapper toast-notification-wrapper"
}}

const toastNotifications = {
	toastSuccess : (notificationText) => {
		toast.success(notificationText, toastSuccessOptions);
	},
	toastInfo : (notificationText) => {
		toast.info(notificationText, toastSuccessOptions);
	},
	toastWarn : (notificationText) => {
		toast.warn(notificationText, toastWarnOptions);
	},
	toastError : (notificationText) => {
		toast.error(notificationText, toastErrorOptions);
	}
}

const toastContext = createContext(toastNotifications);

export default toastContext