import React, { useEffect, useRef, useState } from 'react';
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import { useUser } from '../../context/UserContext';
import TextField from '../../components/inputs/TextField';
import "../../css/userpanel.css"
import { useHistory } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { getAllCountries, getCitiesOfState, getListOfValueJson, getStatesOfCountry } from '../../utils/data';
import { getUrl } from '../../utils/functions';
import { useAuth } from '../../context/AuthContext';
import imgNotification from "../../img/icon/notification.svg";
import { useParams } from 'react-router-dom';
import SelectInput from '../../components/inputs/SelectInput';
import { ReactComponent as Loader } from "../../img/loader.svg";
import AdminNavbar from '../../components/layout/AdminNavbar';




const convertToID = (countryName, stateName, cityName) => {
    const country = getAllCountries().find(country => country.name === countryName);
    if (!country) {
        return [undefined, undefined, undefined];
    }
    const state = getStatesOfCountry(country.id).find(state => state.name === stateName);
    if (!state) {
        return [country.id, undefined, undefined];
    }
    const city = getCitiesOfState(state.id).find(city => city.name === cityName);
    return [country.id, state.id, city?.id ?? undefined]
}

const fetchForm = async (api, setForm, setFieldValues, userId) => {
    try {
        setForm((form) => ({ ...form, loading: true }))
        let data = await api(getUrl("user_admin", `backoffice/user_extend?user_id=${userId}`));
        data.form_fields.sort((a, b) => { // a, b are one of the objects inside the data.form_fields
            if (a.row === b.row) {
                return a.order < b.order ? -1 : 1
            }
            return a.row < b.row ? -1 : 1
        })
        const rows = [];
        let items = [];
        let previousRow = undefined;
        for (let i = 0; i < data.form_fields.length; i++) {
            if (previousRow == null) {
                previousRow = data.form_fields[i].row;
            }
            if (data.form_fields[i].row !== previousRow) {
                rows.push(items);
                items = [];
                previousRow = data.form_fields[i].row;
                items.push(data.form_fields[i])
            } else {
                items.push(data.form_fields[i])
            }
        }
        if (items.length > 0) {
            rows.push(items)
        }
        data.rows = rows; //adding to data from response
        setForm((form) => ({ ...form, loading: false, data: data, status: true, error: null }))
        let values = {};
        let csc = {
            countryFormFieldId: undefined,
            country: "",
            stateFormFieldId: undefined,
            state: "",
            cityFormFieldId: undefined,
            city: "",
            resCountryFormFieldId: undefined,
            resCountry: "",
        }
        data.form_fields.forEach((item) => {
            if (item.form_formField_id === 209) {
                csc.countryFormFieldId = item.form_formField_id;

                csc.country = item.field_actual_value
            }
            if (item.form_formField_id === 213) {
                csc.resCountryFormFieldId = item.form_formField_id;

                csc.resCountry = item.field_actual_value
            }
            if (item.LOV_json === "state.json") {
                csc.stateFormFieldId = item.form_formField_id;
                csc.state = item.field_actual_value

            }
            if (item.LOV_json === "city.json") {
                csc.cityFormFieldId = item.form_formField_id;
                csc.city = item.field_actual_value

            }
            values[item.form_formField_id] = item.field_actual_value
        });
        if (csc.countryFormFieldId && csc.stateFormFieldId && csc.cityFormFieldId) {
            const [countryId, stateId, cityId] = convertToID(csc.country, csc.state, csc.city);
            values[csc.countryFormFieldId] = countryId
            values[csc.stateFormFieldId] = stateId
            values[csc.cityFormFieldId] = cityId
        }
        if (csc.resCountryFormFieldId) {
            const [resCountry] = convertToID(csc.resCountry, "", "");
            values[csc.resCountryFormFieldId] = resCountry
        }

        console.log("Values", values);
        setFieldValues(values)
    } catch (error) {
        setForm((form) => ({ ...form, loading: false, data: null, status: false, error: "An error occured" }))
    }
}

function UserPanelExtension() {
    const [form, setForm] = useState({
        loading: false,
        status: false,
        data: null,
        error: null
    })
    const [loadingUpdate, setLoadingUpdate] = useState(false)
    const [fieldValues, setFieldValues] = useState({})
    const [_, __, userInfo] = useUser()
    const { api } = useAuth()
    const history = useHistory()
    const formRef = useRef()
    const { userId } = useParams()

    useEffect(() => {
        fetchForm(api, setForm, setFieldValues, userId)
    }, [api])

    const handleOnChange = (event) => {
        setFieldValues(oldValues => ({ ...oldValues, [event.target.name]: event.target.value }))
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            let body = Object.keys(fieldValues).map((id) => {
                let form_field = form.data.form_fields.find((field) => +id === field.form_formField_id)
                if (fieldValues[id] && (form_field.LOV_json === "country.json" || form_field.LOV_json === "state.json" || form_field.LOV_json === "city.json")) {
                    const csc = formRef.current.querySelector(`select[name='${form_field.form_formField_id}'] option[value='${fieldValues[form_field.form_formField_id]}']`);
                    if (csc) {
                        return ({ form_formField_id: id, field_update_value: csc.innerText })
                    }
                }
                return { form_formField_id: id, field_update_value: fieldValues[id] }
            })
            setLoadingUpdate(true)
            let data = await api(getUrl("user_admin", `backoffice/user_extend/update?user_id=${userId}`), "post", body)
            setLoadingUpdate(false)
            if (data?.status?.success) {
                if (data?.status?.success) {
                    history.push({
                        pathname: "/message",
                        state: {
                            message: "Profilo esteso correttamente!",
                            type: "confirm",
                            //back: true,
                            link: "/admin_users",
                            label: "Torna alla pagina",
                            img: imgNotification
                        }
                    });
                }
            }

        } catch (error) {
            setLoadingUpdate(false)
        }
    }

    return (
        <div>
            <Header />
            <div className="admin-pages-main">
                <div className='container-fluid mb-5'>
                    <Form method="post" onSubmit={handleSubmit}>
                        <div className="col-md-8 col-12 px-4 py-3" ref={formRef}>
                            <AdminNavbar 
                            back={`/admin_users/${userId}`} 
                            title="Estensione profilo SalTo+" 
                            home={true}
                            />

                            {(form.loading || loadingUpdate) && <div className="loading fixed" >
                                <Loader />
                            </div>}
                            {/* <div className="row"> */}
                            {form.loading !== true && form.data != null && form.data.rows.map((row) => {
                                return <div className="row">
                                    {row.map((item) => {
                                        if (item.LOV.length !== 0 || item.LOV_json) {
                                            let options = [];
                                            if (item.LOV.length !== 0) {
                                                options = item.LOV.map((option) => {
                                                    return { id: option, name: option }
                                                })
                                            } else {
                                                if (item.LOV_json === "country.json") {
                                                    options = getAllCountries()
                                                } else if (item.LOV_json === "state.json") {
                                                    let country = form.data.form_fields.find(field => field.LOV_json === "country.json")
                                                    options = getStatesOfCountry(fieldValues[country.form_formField_id])
                                                } else if (item.LOV_json === "city.json") {
                                                    let state = form.data.form_fields.find(field => field.LOV_json === "state.json")
                                                    options = getCitiesOfState(fieldValues[state.form_formField_id])
                                                } else {
                                                    options = getListOfValueJson(item.LOV_json)
                                                }
                                            }
                                            return <SelectInput
                                                className={row.length === 2 ? "col-6" : (row.length === 3 ? "col-4" : "col")}
                                                name={item.form_formField_id}
                                                label={item.field_label}
                                                required={item.is_mandatory}
                                                type="text"
                                                options={options}
                                                disabled={!item.is_editable}
                                                onChange={handleOnChange}
                                                value={fieldValues[item.form_formField_id]}
                                            //placeholder={`Inserisci ${item.field_label}`} 
                                            />

                                        } else {
                                            return <TextField
                                                className={row.length === 2 ? "col-6" : (row.length === 3 ? "col-4" : "col")}
                                                name={item.form_formField_id}
                                                label={item.field_label}
                                                required={item.is_mandatory}
                                                disabled={!item.is_editable}
                                                type="text"
                                                onChange={handleOnChange}
                                                value={fieldValues[item.form_formField_id]} />

                                        }
                                    })}
                                </div>
                            })}
                            {/* </div> */}
                            {form.loading === false && (
                                <>
                                    {/* <div className="row">
                                        <div className="col">
                                            Devi prenotare un evento? <Link>Clicca qui</Link> per accreditarti.
                                        </div>
                                    </div> */}
                                    <div className="row mt-5">
                                        <div className="col-md-6 col-12">
                                            <button type="submit" className="button blue-btn w-100">
                                                Conferma
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>

                    </Form>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default UserPanelExtension