import React, { useContext } from "react";
import { Link } from "react-router-dom";
import mobileContext from "../../context/MobileContext";
import warningImage from "../../img/report_problem_black_48dp.svg"

class Main extends React.Component {
	state = {
		isMobile : window.innerWidth < 520
	}
    
    render() {
        return (
            // <div className="col-lg-9 my-5>
            <div className="main-page-body">
				{this.state.isMobile && window.location.pathname !== "/dashboard" && !window.location.pathname.includes("/prenotazione/") && window.location.pathname !== "/addToBooking" ? <>
					<Link to="/dashboard">
						<p style={{"color" : "#0065F2"}}><b>{"<"}</b> Torna alla dashboard</p>
					</Link>
				</> : ""}
					{this.props.children}
            </div>
        );
    }
}

export default Main;