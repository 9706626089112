import React, { useState } from "react";
import Logo from "../../img/footer/logo_salto 3.png";
import instagram from "../../img/icon/ig-footer.png";
import youtube from "../../img/icon/yt-footer.png";
import flickr from "../../img/icon/fl-footer.png";
import facebook from "../../img/icon/fb-footer.png";
import twitter from "../../img/icon/tw-footer.png";
import tiktok from "../../img/icon/tiktok-footer.png"
import "../../css/footer.css"
import { useUser } from "../../context/UserContext";
import ConsentModal from "../newsletter_consent/ConsentModal";
import { useLocation } from "react-router-dom";

function Footer({showNewsletterBool}) {
	const [user] = useUser()
	const [showNewsletter, setShowNewsletter] = useState(false);
	const location = useLocation()

	return (
		<footer className="pb-4 pt-4">
			{location.pathname.startsWith("/booking_validation/backofficebookings/validate") ? "" : (
				(user?.consent_send_commercial_newsletter === false && user?.consent_send_cultural_newsletter === false && showNewsletterBool !== false) && (<div className="mobile-newsletter-footer">
					<h1>C'è anche una bella newsletter.</h1>
					<button className="button blue-btn" onClick={() => {
						setShowNewsletter(true);
					}}>Iscrivimi</button>
				</div>)
			)}

			{showNewsletter ? (
				<ConsentModal showModal={setShowNewsletter}></ConsentModal>
			) : ""}

			<div className="col-12 col-md-2 d-flex justify-content-left justify-content-md-start footer-mobile-logo-container">
				<a href="https://salonelibro.it" target="_blank" rel="noreferrer">
					<img className="logo" src={Logo} alt="" />
				</a>
			</div>
			<div className="row pt-4 pb-4 footer-mobile-socialmedia-container" style={{ "borderBottom": "2px solid lightgray", "margin" : "0" }}>
				<div className="col-12">
					<p className="m-0  footer-p-socialmedia">Seguici, dai!</p>
				</div>
				<div className="col-12 d-flex flex-column b-2">
					<div className="d-flex justify-content-left">
						<a className="" href="https://www.facebook.com/SaloneLibroTorino" target="_blank" rel="noreferrer">
							<img className="foot-logo" src={facebook} alt=""></img>
						</a>
						<a className="" href="https://www.instagram.com/salonelibro/" target="_blank" rel="noreferrer">
							<img className="foot-logo" src={instagram} alt=""></img>
						</a>
						<a className="" href="https://www.youtube.com/user/FondazioneLibro" target="_blank" rel="noreferrer">
							<img className="foot-logo" src={youtube} alt=""></img>
						</a>
						<a className="" href="https://www.flickr.com/photos/33302214@N06" target="_blank" rel="noreferrer">
							<img className="foot-logo" src={flickr} alt=""></img>
						</a>
						<a className="" href="https://twitter.com/SalonedelLibro" target="_blank" rel="noreferrer">
							<img className="foot-logo" src={twitter} alt=""></img>
						</a>
						<a className="" href="https://www.tiktok.com/@salonelibro?lang=it-IT" target="_blank" rel="noreferrer">
							<img className="foot-logo" src={tiktok} alt=""></img>
						</a>
					</div>
				</div>
			</div>
			<div className="row pb-2" style={{"marginRight" : "0", "marginLeft" : "0"}}>
				<div className="">
					<div className="row mt-4 justify-content-around" style={{"marginRight" : "0", "marginLeft" : "0"}}>
						<div className="col-6 col-md-2 text-left footer-mobile-last-text">
							<a href="https://www.salonelibro.it/privacy-policy.html" target="_blank" rel="noreferrer">
								<p className="gray-text m-0 footer-p">Privacy Policy</p>
							</a>
						</div>
						<div className="col-6 col-md-2 text-left footer-mobile-last-text">
							<a href="https://www.salonelibro.it/privacy-policy.html" target="_blank" rel="noreferrer">
								<p className="gray-text m-0 footer-p">Cookie Policy</p>
							</a>
						</div>
						<div className="col-6 col-md-2 text-left footer-mobile-last-text">
							<a href="https://www.salonelibro.it/dati-societari.html" target="_blank" rel="noreferrer">
								<p className="gray-text m-0 footer-p">Dati societari</p>
							</a>
						</div>
						<div className="col-6 col-md-2 text-left footer-mobile-last-text">
							<a href="mailto:saltopiu@salonelibro.it" target="_blank" rel="noreferrer">
								<p className="gray-text m-0 footer-p">Contatti</p>
							</a>
						</div>
					</div>
				</div>
			</div>
		</footer>
	)

}

export default Footer;
