import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function Sidebar({children}) {
	const history = useHistory();

	return (
         // <div className="col-md-3 d-lg-block d-none pl-5 my-5" style={{ borderRight: "1px solid #165BE2" }}>
         <div className="sidebar-main-container">
			<p style={{"color" : "#246BF6", "cursor" : "pointer"}} onClick={() => {
				if (window.location.pathname.includes("evento")) {
					history.push("/eventi");
				} else {
					history.goBack();
				}
			}}><b>{"<"}</b> Torna indietro</p>
			<h3 className="sidebar-title" >Ciao!<br /> Benvenuta/o su SalTo+</h3>
			<br/>
			<h4 className="sidebar-sub-title" >La piattaforma digitale del Salone Internazionale del Libro dedicata ai lettori e alle lettrici che vogliono vivere il meglio della produzione culturale del Salone tutto l’anno.</h4>
			<br/>
            	{children}
         </div>
	)
}

export default Sidebar;