import React from "react";


class SpreakerPlayer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div
                className="container" >
                <div className="row">
                    <div className="col-12" dangerouslySetInnerHTML={{ __html: this.props.url }}>


                        {/* <iframe src="https://widget.spreaker.com/player?episode_id=43717890&theme=light&playlist=false&autoplay=false&cover_image_url=https://d3wo5wojvuv7l.cloudfront.net/images.spreaker.com/original/25f13f00242c4162643a50ac2c4d8370.jpg" width="100%" height="400px" frameborder="0"></iframe> */}

                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-12">
                        <h1 className="yt-title">{this.props.title}</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h3>{this.props.subtitle}</h3>
                    </div>
                </div>
                <div className="row mb-5 pb-5">
                    <div className="col-12">{this.props.description}</div>
                </div>
                <div>
                    {this.props.chat == undefined || this.props.chat === "" ? "" : <iframe src={this.props.chat} frameborder="0" allowtransparency="true" height="250"></iframe>}
                </div>
            </div>
        );
    }
}

export default SpreakerPlayer;
