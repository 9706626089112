import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Cookies from "js-cookie";
import saloneUserProfile from '../utils/saloneUserProfile';
import Axios from 'axios';
import { ReactComponent as Loader } from '../img/loader.svg'


const getToken = async (data, onSuccess, onFailed, setLoading) => {

    try {
        setLoading(true)
        let response = await saloneUserProfile.post("auth/getToken", data, {
            // params: {
            //     auth_code: code,
            //     redirect_uri: redirect_uri
            // }
        })
        onSuccess(response?.data?.access_token, response?.data?.refresh_token)

    } catch (error) {
        onFailed()
    }
    setLoading(false)
}

const fetchTokenFromRefreshToken = async (refreshToken) => {
    let response = await saloneUserProfile.post("auth/refreshToken", {}, {
        params: {
            refresh_token: refreshToken,
        }
    })
    return response;
}

const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
    const history = useHistory()
    const query = new URLSearchParams((history.location.hash || "").substr(1))
    const hasCode = query.get("code") != null
    const [loading, setLoading] = useState(hasCode || Cookies.get("refresh_token") != null)
    const [auth, setAuth] = useState(Cookies.get("refresh_token") != null)
    const [token, setToken] = useState(null)


    useEffect(() => {
        if (hasCode) {
            const onSuccess = (token, refresh_token) => {
                Cookies.set("refresh_token", refresh_token, { httpOnly: false });
                setToken(token);
                setAuth(true);
                let url = "/dashboard"
                if (window.localStorage.getItem("redirect_uri")) {
                    url = window.localStorage.getItem("redirect_uri");
                    window.localStorage.removeItem("redirect_uri")
                }
                history.push(url)
            }
            let data = { "auth_code": query.get("code"), "redirect_uri": process.env.REACT_APP_REDIRECT_URI }
            getToken(data, onSuccess, logout, setLoading)
        } else if ((auth || token == null)) {
            getTokenFromRefreshToken();
        }
    }, [history])

    const logout = () => {
        Cookies.remove("refresh_token");
        setAuth(false);
        setToken(null);
        history.push("/")
    }

    const getTokenFromRefreshToken = async () => {
        try {
            setLoading(true);
            let response = await fetchTokenFromRefreshToken(Cookies.get("refresh_token"))
            setToken(response?.data?.access_token);
            setAuth(true);
            setLoading(false);
            return response?.data?.access_token;
        } catch (error) {
            setLoading(false);
            logout();
        }
    }



    const api = async (url, method = "get", data = {}, options = {}) => {
        const request = async (method, args) => {
            return await Axios[method](...args);
        }
        let args = [url];
        if (method === "post") {
            args.push(data);
        }
        options.headers = {
            ...options.headers,
            Authorization: `Bearer ${token}`,
        }
        args.push(options);
        let response;
        try {
            response = await request(method, args);
        } catch (error) {
            if (error?.response?.data?.detail === "Token verification failed" || error?.response?.data?.detail === "Not authenticated") {

                try {
                    let newToken = await getTokenFromRefreshToken(Cookies.get("refresh_token"));
                    args[args.length - 1].headers.Authorization = `Bearer ${newToken}`;
                } catch (error) {
                    // in this line refresh token is expired
                    logout();
                    return;
                }
                response = await request(method, args);

            } else {
                throw error?.response?.data;
				// throw error;
            }
        }
        return response.data
    }

    return (
        <AuthContext.Provider value={{ auth, loading, api, logout }}>
            {loading ? <div className="loading fixed">
                <Loader />
            </div> : children}
        </AuthContext.Provider>
    )
}

const useToken = () => {
    const context = useContext(AuthContext)
    return context.token
}

const useAuth = () => {
    const context = useContext(AuthContext)
    return context
}

export { AuthContext, AuthProvider, useToken, useAuth };