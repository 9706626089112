import React, { useState, useEffect } from 'react';
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import { useUser } from '../../context/UserContext';
import "../../css/moduliprenotazione.css";
import { Link, useParams } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { getUrl } from '../../utils/functions';
import { ReactComponent as Loader } from "../../img/loader.svg";
import swal from 'sweetalert';
import AdminNavbar from '../../components/layout/AdminNavbar';



const fetchFormsFromBackOffice = async (api, setFormsFromBackOffice, userId) => {
    try {
        setFormsFromBackOffice((oldState) => ({ ...oldState, loading: true }))
        let response = await api(getUrl("user_admin", `backoffice/forms?user_id=${userId}`))
        setFormsFromBackOffice((oldState) => ({
            ...oldState,
            loading: false,
            bookedForms: response?.booked,
            availableForms: response?.available
        }))

    } catch (error) {

    }
}


function ModuliPrenotazione() {
    const [user, __, userInfo] = useUser()
    const { userId } = useParams()
    const { api } = useAuth()

    const [formsFromBackOffice, setFormsFromBackOffice] = useState({
        loading: false,
        bookedForms: null,
        availableForms: null,
        error: false
    })
    const [blobFileLoading, setBlobFileLoading] = useState(null)


    useEffect(() => {
        fetchFormsFromBackOffice(api, setFormsFromBackOffice, userId)
    }, [api, userId])

    // const exportXslFile = (formName, formId) => {
    //     var config = {
    //         service: 'user_admin',
    //         method: 'post',
    //         url: `report/download/form?form_id=${formId}`,
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //         data: {},
    //     };
    //     let prefix = "Export - " + formName + " - " + formId
    //     downloadBlobFile(api, config, generateFileName(prefix), setBlobFileLoading)
    // }

    const deleteRegisteredCourse = (formId, userId) => {
        swal({
            title: "Sei sicuro voler eliminare questa iscrizione?",
            text: "",
            icon: "warning",
            buttons: ["NO", "Si"]
        })
            .then(async (value) => {
                if (value == null) {
                    return;
                }
                try {
                    let response = await api(getUrl("user_admin", `backoffice/delete_form_backoffice?user_id=${userId}&form_id=${formId}`), "post", {})
                    if (response?.success === true) {
                        swal({
                            title: "Eliminazione effettuata.",
                            tex: "",
                            icon: "success"
                        })
                        fetchFormsFromBackOffice(api, setFormsFromBackOffice, userId)
                    }
                } catch (error) {

                }
            })
    }
    return (
        <div>
            {(blobFileLoading || formsFromBackOffice?.loading) && <div className="loading fixed" >
                <Loader />
            </div>}
            <Header />
            <div className="admin-pages-main">
                <AdminNavbar back="/admin_users" title="Moduli Di Prenotazione" />
                <div className="container-fluid">
                    <div className="row moduli-prenotazione">
                        <div className="col-5 iscrizioni-effettuate">
                            <h2 style={{ 'color': 'white' }}>Iscrizioni effettuate</h2>
                            {formsFromBackOffice?.bookedForms?.length === 0 && (<p>Non ci sono corsi effettuati. </p>)}
                            {formsFromBackOffice?.bookedForms && formsFromBackOffice?.bookedForms.map((form) => {
                                return <div className="card m-3 p-3">
                                    <div className="row mb-3">
                                        <div className="col-auto ">
                                            <h3>{form.title}</h3>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {/* <div className="col-3">
                                                <button
                                                    className='download-delete'
                                                    onClick={() => { exportXslFile(form.name, form.id) }}
                                                >
                                                    Export</button>
                                            </div> */}
                                        <div className="col-3">
                                            <button
                                                className='download-delete'
                                                onClick={() => deleteRegisteredCourse(form.id, userId)}
                                            >
                                                Cancella
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                        <div className="col-5 corsi-disponibili-moduli">
                            <h2 style={{ 'color': 'white' }}>Corsi disponibili</h2>
                            {formsFromBackOffice?.availableForms && formsFromBackOffice?.availableForms.map((form) => {
                                return <div className="card m-3 p-3">
                                    <div className="row mb-3">
                                        <div className="col-auto">
                                            <h3>{form.title}</h3>
                                        </div>
                                    </div>
                                    <div className="row align-items-center">
                                        {/* <div className="col-auto">
                                                <button
                                                    className='download-delete'
                                                    onClick={() => { exportXslFile(form.name, form.id) }}
                                                >
                                                    Export</button>
                                            </div> */}
                                        <div className="col-auto">
                                            <Link className='booking-course p-2'
                                                to={{
                                                    pathname: `/forms/form/${form.id}`,
                                                    state: {
                                                        userId: userId,
                                                        back: true,
                                                        fromAdmin: true
                                                    }
                                                }}>Prenotazione</Link>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div >
            <Footer />
        </div >
    )
}

export default ModuliPrenotazione