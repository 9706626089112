import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import {Link} from 'react-router-dom'
import { getUrl } from '../../utils/functions';
import { useAuth } from '../../context/AuthContext';
import BootstrapCarousel from '../../shared/BootstrapCarousel'
import Content from '../../Content';


const fetchCarouselItems = async (api, setItems, sectionName) => {
    try {
        let data = await api(getUrl("media", `media/contentList/?skip=0&limit=100&section=${sectionName}`))
        setItems(data)

    } catch (error) {
        setItems(null)
    }
}

function MediaPageSection({ sectionName }) {
    const history = useHistory()
    const { api } = useAuth()
    const [items, setItems] = useState([])

    useEffect(() => {
        fetchCarouselItems(api, setItems, sectionName)
    }, [sectionName])

    console.log(items)

    return (
        <div className="mb-3" style={{"width" : "100%"}}>
            <div className="row mw-100 m-0" style={{ padding: "5px 5%" }}>
                <Link to={`/media/${sectionName}`}><h3>{sectionName}</h3></Link>
            </div>
            <div className="row media-page-section-carousel">
                <BootstrapCarousel
                    items={items.map((item) => {
                        return <Content
                            key={item.id}
                            id={item.id}
                            title={item.attributes.title}
                            subtitle={item.attributes.subtitle}
                            videoFavicon={item.attributes["video_favicon"]}
                            history={history} />
                    })}
                ></BootstrapCarousel>
            </div>
        </div>
    )
}

export default MediaPageSection
