import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/layout/Footer";
import Sidebar from "../components/layout/Sidebar";
import Main from "../components/layout/Main";
import Header from "../components/layout/Header";
import { useUser } from "../context/UserContext";
import "../css/eventpage.css";
import EventComponent from "../components/booking_events/EventComponent";
import Pagination from "../components/Pagination";
import FilterEvents from "../components/booking_events/FilterEvents";
import { getUrl, getYYYYMMDDHHMM } from "../utils/functions";
import { useAuth } from "../context/AuthContext";
import { ReactComponent as Loader } from "../img/loader.svg";
// import BookedEvent from '../components/booking_events/BookedEvent';
import { useParams } from "react-router-dom";
import SideBarContents from "../components/layout/SideBarContents";
import PopUp from "../components/PopUp";

const fetchBookedEvents = async (api, setBookedEvents) => {
	try {
		let data = await api(getUrl("booking", "bookings/booking/"));
		data = data?.map((item) => {
			if (item.last_snooze_timestamp.indexOf("T") === -1) {
				item.last_snooze_timestamp += "+00:00";
			}
			return item;
		});
				setBookedEvents(data);
	} catch (error) {
		setBookedEvents(null);
	}
};

function EventPage() {
	const [_, __, userInfo] = useUser();
	const { api } = useAuth();
	const { location_id } = useParams();
	const [availableEvents, setAvailableEvents] = useState(null);
	const [query, setQuery] = useState("");
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 540);
	const [loading, setLoading] = useState(null);
	const [bookedEvents, setBookedEvents] = useState([]);
	const [showGuide, setShowGuide] = useState(window.localStorage.showEventGuide);

	useEffect(() => {
		fetchBookedEvents(api, setBookedEvents);
	}, []);

	const invertCloseModal = (bool) => {
		if (bool === false) {
			setShowGuide("true");
		} else if (bool === true) {
			setShowGuide("false");
		}
	};

	const fetchEvents = async (query, page = 1) => {
		const date = getYYYYMMDDHHMM(new Date());

		try {
			setLoading(true);
			let data = await api(
				getUrl(
					"programma",
					`api/v1/bookable-program-items?include=authors,exhibitors,location,tags,tags.tagGroup&filter[after]=${date}&${query.substring(
						0,
						query.length - 1
					)}&page[number]=${page}&page[size]=4`
				)
			);
			
			setAvailableEvents(data);
			setLoading(false);
			setQuery(query);
		} catch (error) {
			setAvailableEvents(null);
		}
	};
	const fetchPage = async (link) => {
		try {
			let data = await api(link);
			if (isMobile) {
				data = { ...data, data: [...(availableEvents?.data ?? []), ...data?.data] };
			}
			setAvailableEvents(data);
		} catch (error) {
			setAvailableEvents(null);
		}
	};

	useEffect(() => {
		if (location_id) {
			let query = "";
			query += `filter[location]=${location_id}&`;
			fetchEvents(query);
			return;
		}
		fetchEvents("");
	}, []);

	useEffect(() => {
		const resizeHandler = () => {
			if (window.innerWidth <= 540 && !isMobile) {
				setIsMobile(true);
			}
			if (window.innerWidth > 540 && isMobile) {
				setIsMobile(false);
			}
		};
		window.addEventListener("resize", resizeHandler);
		return () => {
			window.removeEventListener("resize", resizeHandler);
		};
	}, [isMobile]);

	return (
		<div>
			{showGuide !== "true" && (
				<PopUp setShowModal={invertCloseModal}>
					<div className="popup-guide-modal-container">
						<p>
							In questa pagina è elencata una selezione di eventi a prenotazione. Non trovi l'incontro a cui vuoi
							partecipare? Sul sito salonelibro.it puoi consultare il programma completo.
						</p>
						<button
							onClick={() => {
								window.localStorage.showEventGuide = "true";
								setShowGuide("true");
							}}>
							torna alla prenotazioni
						</button>
					</div>
				</PopUp>
			)}
			<Header />
			{/* <div className="row w-100"> */}
			<div className="page-body">
				<Sidebar>
					<SideBarContents />
				</Sidebar>
				<div className="separating-line"></div>
				<Main>
					<h1 className="booking">Eventi</h1>

					{/* <h4 className="program">
                        Programma del Salone
                    </h4> */}
					<div className="go-to-booked-events">
						<Link to="/prenotazioni">Vai alle prenotazioni</Link>
					</div>

					<FilterEvents
						fetchEvents={fetchEvents}
						locationId={location_id}
					/>

					{loading && (
						<div className="loading fixed">
							<Loader />{" "}
						</div>
					)}

					{availableEvents?.data?.length != 0 && (
						<div className="results-box">
							<div>
								{/* <h5>{availableEvents?.meta?.to ?? 0} Risultati per la ricerca </h5> */}
								{availableEvents?.meta?.current_page && (
									<p>
										Pagina {availableEvents?.meta?.current_page}
										{availableEvents?.links?.prev != null && isMobile === false && (
											<span onClick={() => fetchPage(availableEvents?.links?.prev)}>pagina precedente</span>
										)}
										{availableEvents?.links?.next != null && (
											<span onClick={() => fetchPage(availableEvents?.links?.next)}>pagina successiva</span>
										)}
									</p>
								)}
							</div>
						</div>
					)}
					{availableEvents?.data?.length === 0 && (
						<div className="results-box">
							<h3>
								La ricerca dell'evento non ha prodotto risultati.
								<br />
								Vai al programma generale cliccando{" "}
								<a
									href="https://www.salonelibro.it/programma.html?filters[programs]=5"
									target="_blank">
									qui
								</a>{" "}
								per vedere tutti gli eventi disponibili.
							</h3>
						</div>
					)}
					{availableEvents &&
						availableEvents.data?.map((event) => {
							return (
								<EventComponent
									isMobile={isMobile}
									key={event.id}
									data={event}
									bookedEvent={bookedEvents?.find((bookedEvent) => bookedEvent.event_id === event.id)}
								/>
							);
						})}
					{availableEvents && isMobile === false && (
						<div className="pagination-position">
							<Pagination
								hasPrevious={availableEvents?.links?.prev != null}
								hasNext={availableEvents?.links?.next != null}
								currentPage={availableEvents?.meta?.current_page}
								onClick={(page) => {
									if (page === "next") {
										fetchPage(availableEvents?.links?.next);
									} else {
										fetchPage(availableEvents?.links?.prev);
									}
								}}
							/>
						</div>
					)}
					{availableEvents && isMobile && availableEvents?.links?.next != null && (
						<div className="other-results">
							<button onClick={() => fetchPage(availableEvents?.links?.next)}>vedi altri risultati</button>
						</div>
					)}
				</Main>
			</div>
			<Footer showNewsletter={false} />
		</div>
	);
}

export default EventPage;
