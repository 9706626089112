import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Redirect, Route, useLocation } from 'react-router'
import { useAuth } from '../context/AuthContext'
import { UserContext } from '../context/UserContext'
import { ReactComponent as Loader } from '../img/loader.svg'
import { getUrl } from '../utils/functions'

function PrivateRoute({ children, path, forAdmin, ...rest }) {
    const { auth, api } = useAuth()
    const [user, _, __, userInfo, ___, loading] = useContext(UserContext)
    const [adminLoading, setAdminLoading] = useState(forAdmin)
    const history = useHistory()
    const location = useLocation()

    const checkForAdmin = async (page = 1) => {
        const params = new URLSearchParams()
        const limit = 4
        params.set("skip", (page - 1) * limit)
        params.set("limit", limit)
        try {
            await api(getUrl("booking_admin", `sponsor_booking/booking?${params.toString()}`))
            setAdminLoading(false)
            //history.push("/admin_dashboard")
        } catch (error) {
            if (+error.response?.status === 401) {
                history.push("/dashboard")
            }
        }
    }

    useEffect(() => {
        if (auth === true && loading === false && forAdmin === true && adminLoading === true) {
            checkForAdmin()
        }
    }, [auth, forAdmin, loading])

    if (auth === false && location.pathname.startsWith("/booking_validation/backofficebookings/validate")) {
        window.localStorage.setItem("redirect_uri", location.pathname)
        window.location.href = process.env.REACT_APP_REDIRECT_LOGIN.replace("{{REDIRECT_URI}}", process.env.REACT_APP_REDIRECT_URI)

        return;
    }

    return (
        <Route path={path} {...rest} render={() => {
            return auth ? (loading || (forAdmin && adminLoading) ? <div className="loading fixed">
                <Loader />
            </div> : children) : <Redirect to="/" />
        }} />
        //false: user.admin !== true
    )
}

export default PrivateRoute
