import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import '../../css/deletebookingcontents.css';
import { getUrl } from '../../utils/functions';

function DeleteParticipantBooking({ setShowModal, data, id, refreshBookedEvent, title, description }) {

    const { api } = useAuth();
    const [error, setError] = useState(null);

    const updateParticipants = async () => {
        try {
            const body = { ...data };
            body.participants = { ...body.participants };
			if (typeof id === "object") {
				id.forEach((singleId) => {
					delete body.participants[singleId];
					body.number_of_participants = body.number_of_participants - 1;
				});
			} else if (id !== true) {
				delete body.participants[id];
				body.number_of_participants = body.number_of_participants - 1;
			}
            let response = await api(getUrl("booking", "bookings/booking/"), "post", body);
            if (response?.success === false) {
                setError(response?.message?.delete)
                return;
            }
            refreshBookedEvent();
            setShowModal(false);
        } catch (error) {
            setError(error?.response?.data?.detail ?? "Unkown error occured!")
        }
    }
    return (
        <div className="delete-booking">
            <div className="highlited-part">
                <div className="svg-container">

                </div>
            </div>
            <div className="delete-contents">
                <div className="close-btn-container">
                    <button onClick={() => setShowModal(false)}>&#10006;</button>
                </div>
                <div className="main-text">
                    <h3 className="text">{title ? title : "Sei sicuro di voler eliminare questo partecipante?"}</h3>
                </div>
                {/* <div className="elimina-info">
                    <p>
                        L'eliminazione avrà effettua sulla tua prenotazione e tutti gli ospiti da te invitati.
                    </p>
                </div> */}
                <div className="text-question">
                    <p>
                    {description ? description : "Vuoi tornare indietro o cancellare questo partecipante?"}
                    </p>
                </div>
                {error != null && (
                    <div>
                        <p className="text-danger">{error}</p>
                    </div>
                )}
                <div className="elimina-btn">
                    <button onClick={updateParticipants}>Elimina</button>
                </div>
                <div className="back-btn">
                    <button onClick={() => setShowModal(false)}>Torna indietro</button>
                </div>
            </div>
        </div>
    )
}

export default DeleteParticipantBooking
