import React, { useState, useEffect } from 'react';
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import { useUser } from '../../context/UserContext';
import TextField from '../../components/inputs/TextField';
import SelectInput from '../../components/inputs/SelectInput';
import "../../css/userpanel.css";
import { useHistory } from 'react-router-dom';
import { getAllCountries, getCitiesOfState, getStatesOfCountry } from '../../utils/data';
import { getUrl } from '../../utils/functions';
import { useAuth } from '../../context/AuthContext';
import { useParams } from 'react-router-dom';
import Form from "react-bootstrap/Form";
import swal from 'sweetalert';
import { useRef } from 'react';
import imgNotification from "../../img/icon/notification.svg";
// import CheckBoxInput from '../../components/inputs/CheckBoxInput';
import { ReactComponent as Loader } from "../../img/loader.svg";
import AdminNavbar from '../../components/layout/AdminNavbar';





const fetchProfession = async (api, setGroupProfessions, profession, setValues) => {
    try {
        let data = await api(getUrl("user", "userprofile/professions"));
        let ambitos = {}
        data.forEach((profession) => {
            if (ambitos[profession.group] == null) {
                ambitos[profession.group] = profession.group
            }
        })
        ambitos = Object.keys(ambitos)
        setGroupProfessions({
            professions: data,
            ambitos: ambitos.map((ambito) => ({ id: ambito, name: ambito }))
        })

        if (profession) {
            setValues(oldValues => ({ ...oldValues, ambito: profession.group, profession: profession.id }))
        }

    } catch (error) {
        setGroupProfessions({
            professions: [],
            ambitos: []
        })
    }
}


const convertToID = (countryName, stateName, cityName) => {
    const country = getAllCountries().find(country => country.name === countryName);
    if (!country) {
        return [undefined, undefined, undefined];
    }
    const state = getStatesOfCountry(country.id).find(state => state.name === stateName);
    if (!state) {
        return [country.id, undefined, undefined];
    }
    const city = getCitiesOfState(state.id).find(city => city.name === cityName);
    return [country.id, state.id, city?.id ?? undefined]
}

const fetchUserComplete = async (api, setLoading, setValues, userId) => {
    try {
        setLoading(true)
        let data = await api(getUrl("user_admin", `backoffice/user_complete?user_id=${userId}`))
        setLoading(false)


        let [countryId, stateId, cityId] = convertToID(data.country, data.state, data.city)
        data.country = countryId
        data.state = stateId
        data.city = cityId
        setValues(data)
    } catch (error) {

    }
}

function UserPanelComplete() {
    const [_, __, userInfo] = useUser()
    const history = useHistory()
    const { api } = useAuth()
    const { userId } = useParams()
    const containerRef = useRef()
    const [values, setValues] = useState({})
    const [groupProfessions, setGroupProfessions] = useState({
        professions: [],
        ambitos: []
    })
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrormessage] = useState(null)


    useEffect(() => {
        fetchProfession(api, setGroupProfessions, values?.profession?.[0], setValues);
        fetchUserComplete(api, setLoading, setValues, userId)

    }, [])

    const handleOnChange = (event) => {
        setValues(oldValues => ({ ...oldValues, [event.target.name]: event.target.value }))
    }

    const saveUserProfile = (event) => {
        event.preventDefault();
        swal({
            title: "Vuoi aggiornare?",
            text: "",
            icon: "warning",
            buttons: ["NO", "Si"],
        }).then(async (value) => {
            if (value == null) {
                return;
            }
            let body = { ...value, "sso_id": userId }

            Object.keys(values).forEach((key) => {
                if (values[key] != null && values[key] !== "") {
                    if (["country", "state", "city"].includes(key)) {
                        const csc = containerRef.current.querySelector(`select[name='${key}'] option[value='${values[key]}']`);
                        if (csc) {
                            body[key] = csc.innerText;
                            return
                        }
                    }
                    body[key] = values[key]
                }
            })
            try {
                setLoading(true)
                let data = await api(getUrl("user_admin", "backoffice/user_complete/update"), "post", body)
                setLoading(false)
                if (data?.status?.success) {
                    history.push({
                        pathname: "/message",
                        state: {
                            message: "Aggiornamento effettuato con successo!",
                            type: "confirm",
                            //back: true,
                            link: "/admin_users",
                            label: "Torna alla pagina utenti",
                            img: imgNotification
                        }
                    });
                }
            } catch (error) {
                setLoading(false)
                setErrormessage("Si è verificato un errore sul server. Riprovare più tardi.")
            }
        })
        return false
    }
    return <div>
        <Header />
        <div className="admin-pages-main">
            <AdminNavbar
                back="/admin_users"
                title="Completa Profilo"
                home={true}
            />
            <div className="container-fluid mb-5">
                {loading && <div className="loading fixed" >
                    <Loader />
                </div>}
                <div className="complete-profile" ref={containerRef}>
                    <Form method="post" onSubmit={saveUserProfile}>
                        <div className="row">
                            <TextField
                                label="Nome"
                                className="col-md-4"
                                name="first_name"
                                value={values.first_name}
                                onChange={handleOnChange}
                                type="text"
                                disabled={true}
                                required={true}
                            />
                            <TextField
                                label="Cognome"
                                className="col-md-4"
                                name="last_name"
                                value={values.last_name}
                                onChange={handleOnChange}
                                type="text"
                                disabled={true}
                                required={true}
                            />
                            <TextField
                                className="col-md-2"
                                label="Data di nascita"
                                name="birthdate"
                                required={true}
                                type="date"
                                value={values.birthdate}
                                onChange={handleOnChange}
                            />
                        </div>

                        <div className="row">
                            <SelectInput
                                className="col-md-4"
                                name="country"
                                label="Stato"
                                required={true}
                                options={getAllCountries()}
                                placeholder="Inserisci stato..."
                                onChange={(event) => {
                                    handleOnChange(event)
                                    setValues(oldValues => ({ ...oldValues, "state": undefined }))
                                }}
                                value={values?.country}
                            />
                            <SelectInput
                                className="col-md-4"
                                name="state"
                                label="Regione"
                                required={true}
                                placeholder="Inserisci regione..."
                                options={getStatesOfCountry(values?.country)}
                                disabled={values?.country == null || values?.country === ""}
                                value={values?.state}
                                onChange={(event) => {
                                    handleOnChange(event)
                                    setValues(oldValues => ({ ...oldValues, "city": undefined }))
                                }}
                            />
                            <SelectInput
                                className="col-md-4"
                                name="city"
                                label="Città"
                                required={true}
                                type="text"
                                placeholder="Inserisci città..."
                                options={getCitiesOfState(values?.state)}
                                disabled={values?.state == null || values?.state === ""}
                                value={values?.city}
                                onChange={handleOnChange}
                            />
                        </div>

                        <div className="row">
                            <SelectInput
                                className="col-md-4"
                                name="profession_group"
                                label="Ambito"
                                required={true}
                                placeholder="Inserisci ambito..."
                                options={groupProfessions.ambitos}
                                value={values?.profession_group}
                                onChange={(event) => {
                                    handleOnChange(event)
                                    setValues(oldValues => ({ ...oldValues, "profession": undefined }))
                                }}
                            />
                            <SelectInput
                                className="col-md-4"
                                label="Professione"
                                name="profession"
                                required={true}
                                disabled={!(values?.profession_group)}
                                placeholder="Inserisci professione..."
                                options={groupProfessions?.professions?.filter((profession) => profession.group === values?.profession_group).map((profession) => ({
                                    name: profession.description, id: profession.description
                                }))}
                                value={values?.profession}
                                onChange={handleOnChange}
                            />
                        </div>
                        {/* <div className="row mt-3">
                                <CheckBoxInput
                                    className='col-10'
                                    label="La newsletter per restare aggiornato sulle iniziative culturali del Salone e dei suoi partner."
                                    name="consent_send_cultural_newsletter"
                                    required={false}
                                    value={values?.consent_send_cultural_newsletter}
                                    onChange={(event) => {
                                        setValues(oldValues => ({ ...oldValues, [event.target.name]: event.target.checked ? true : false }))
                                    }}
                                />
                            </div>
                            <div className="row">
                                <CheckBoxInput
                                    className='col-10'
                                    label="La newsletter per restare aggiornato su promozioni commerciali e vantaggi legati ai partner del Salone."
                                    name="consent_send_commercial_newsletter"
                                    required={false}
                                    value={values?.consent_send_commercial_newsletter}
                                    onChange={(event) => {
                                        setValues(oldValues => ({ ...oldValues, [event.target.name]: event.target.checked ? true : false }))
                                    }}
                                />
                            </div> */}
                        <div className="row p-3">
                            <p>{values.interests}</p>
                        </div>
                        <div className="row mt-1 align-items-center">
                            <div className="col-md-4">
                                <button className="w-100 save-profile">
                                    {"Aggiorna"}
                                </button>
                            </div>
                            <div className="col-md-4">
                                {values?.profession_extend ? (
                                    <button
                                        className="w-100 save-profile"
                                        onClick={() => {
                                            history.push(`/admin_users_extension/${userId}`)
                                        }}
                                    >
                                        Estendi profilo
                                    </button>
                                ) : ""}
                            </div>
                            <div className="col-md-3 text-danger">
                                {errorMessage}
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
        <Footer />
    </div>;
}

export default UserPanelComplete;
