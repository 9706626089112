import React from 'react'

function CheckBoxInput({ name, onChange, required = true, value = "", placeholder = "", label, className = "col", disabled = false }) {
    if (required) {
		label += "*";
	}
	return (
        <div className={`py-2 ${className}`}>

            <input
                type="checkbox"
                name={name}
                checked={value ? "cheked" : ""}
                placeholder={placeholder}
                required={required ? "required" : ""}
                onChange={onChange}
                value={value}
                disabled={disabled ? "disabled" : ""}
            />
            <span dangerouslySetInnerHTML={{ __html: label }} className="ml-2"></span>
        </div>
    )
}

export default CheckBoxInput
