import React from 'react'
import { Link } from 'react-router-dom'
import "../../css/adminnavbar.css"

function AdminNavbar({back, title, home=true}) {
    return (
        <div className="nav-bar px-2">
            <Link className='back-to-previous-page' to={`${back}`}>
                &lt;
            </Link>
            {home && (
                <Link className='back-to-previous-page' to="/admin_dashboard">
                <i class="fa fa-home" style={{ 'fontSize': '25px', 'color': '#007bff' }}></i>
            </Link>
            )}

            <h2 className='mt-1'>{title}</h2>
        </div>
    )
}

export default AdminNavbar