import React from "react";
import { AzureMP } from "react-azure-mp";
import { Link } from "react-router-dom";
import "../../css/dashboardVideo.css";
import right_arrow from "../../img/prenotazione_icons/Arrow.svg"

function DashVideo({cardParams, isMobile, hideTitle}) {
	const classBase = isMobile ? "dashboard-mobile-video" : "dashboard-video"
	const videoType = cardParams.type;

	const videoBody = () => {
		if (videoType === "video") {
			return <iframe className={classBase} src={cardParams.url} scrolling="no" allow="autoplay; fullscreen" title={cardParams.title}></iframe>
		} else if (videoType === "azuremediaplayer") {
			return <div className={classBase}> <AzureMP skin="amp-flush"
			src={[
				{
					src: cardParams.url,
					type: "application/vnd.ms-sstr+xml"
				}
			]}></AzureMP></div>
		} else if (videoType === "staticvideo") {
			return <img className={classBase}  src={cardParams.url} alt={cardParams.title}></img>
		}
	};

	return (<div className={classBase + "-element"}>
			{/* Rimuovere per avere il titolo della card */}
			{/* <p className={classBase + "-taste"} style={hideTitle ? {"visibility" : "hidden"} : {"" : ""}}>{cardParams?.path?.includes("nexoplus") ? "Un regalo per te!" : ""}</p> */}
			<div className={classBase + "-container"}>
				{videoBody()}
				<div className={classBase + "-right-side"}>
					<p className={classBase + "-title"}>{cardParams.title}</p>
					<p className={classBase + "-subtitle"}>{cardParams.subtitle}</p>
					{cardParams.type === "staticvideo" ? (<>
							<a href={cardParams.path} className={classBase + "-navigation"}>
								<p> {cardParams.image}</p>
								<img className={classBase + "-navigation-arrow"} src={right_arrow} alt="navigation-arrow-video" style={{"width" : "28.41px"}}></img>
							</a>
						</>) : (
						<>
							<Link to={cardParams.path} className={classBase + "-navigation"}>
								<p> Guarda il video</p>
								<img className={classBase + "-navigation-arrow"} src={right_arrow} alt="navigation-arrow-video" style={{"width" : "28.41px"}}></img>
							</Link>
						</>
					)
					}
				</div>
			</div>
	</div>)
}

export default DashVideo