import React, { useRef, useEffect } from "react";
import "../css/popup.css";

const PopUp = ({ setShowModal, children, headerTitle }) => {
	const modalRef = useRef(null);
	const clickOutref = useRef(null);
	const closeModal = () => {
		modalRef.current.classList.add("fadeOut");
		setTimeout(() => {
			setShowModal(false);
		}, 400);
	};

	useEffect(() => {
		const handler = (event) => {
			if (clickOutref.current != null && !clickOutref.current.contains(event.target)) {
				closeModal();
			}
		};
		document.addEventListener("click", handler);
		return () => {
			document.removeEventListener("click", handler);
		};
	}, [clickOutref.current]);
	return (
		<div
			className="modal"
			ref={modalRef}>
			<div
				className="modal-box"
				ref={clickOutref}
				style={{ margin: "0 16px" }}>
				<div
					className="modal-header"
					style={headerTitle === "" ? { borderBottom: "none" } : { "": "" }}>
					<h3>{headerTitle}</h3>
					<button onClick={closeModal}>&#10006;</button>
				</div>
				<div className="modal-body">{children}</div>
			</div>
		</div>
	);
};

export default PopUp;
