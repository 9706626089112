import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../css/dashboardCard.css";

function DashCardMobile({ cardParams }) {
	return (
		<div className="dashboard-mobile-card-container">
			<a
				href={cardParams.path}
				className="dashboard-card-styler"
				style={cardParams.style.card_styler}>
				<img
					src={cardParams.image.split(".")[0] + "-mobile.jpg"}
					className="dashboard-mobile-card-icon"
					alt="icon-dashboard"></img>
				{/* <img src="https://devel-saltopiu.salonelibro.it/static/media/MEDIA+.png" className="dashboard-mobile-card-icon"></img> */}
				{/* <p
					className="dashboard-mobile-card-title"
					style={{ alignSelf: "center" }}>
					{cardParams.title}
				</p> */}
				{/* <p className="dashboard-mobile-card-subtitle" style={cardParams.style.title}>{cardParams.subtitle}</p> */}
			</a>
		</div>
	);
}

export default DashCardMobile;
