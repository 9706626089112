import React, { useState, useEffect, useRef } from "react";
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import Sidebar from "../components/layout/Sidebar";
import Main from "../components/layout/Main";
import { useAuth } from "../context/AuthContext";
import { useUser } from "../context/UserContext";
import { getUrl } from "../utils/functions";
import { ReactComponent as Loader } from "../img/loader.svg";
import { useHistory, useLocation, useParams } from "react-router";
import SideBarContents from "../components/layout/SideBarContents";

function AttestatoInsegnantiLink() {
	const { attestatoID } = useParams();
	const { api } = useAuth();
	const [loading, setLoading] = useState(null);
	const [data, setData] = useState(null);
	const [fieldValues, setFieldValues] = useState();

	const getDefaultValues = (formfields) => {
		const values = {};
		if (formfields !== null) {
			formfields?.forEach((field) => {
				if (field.type === "bool") {
					values[field.code] = field.value ? true : false;
					return;
				}
				values[field.code] = field.value;
			});
		}
		return values;
	};

	const fetchPageContents = async (api, setData, setLoading, setFieldValues) => {
		try {
			setLoading(true);
			let data = await api(getUrl("user", "accrediti/getstate"));
			if (data?.form?.formfields != null) {
				data?.form?.formfields?.sort((a, b) => {
					// a, b are one of the objects inside the data.form_fields
					if (a.row === b.row) {
						return a.order < b.order ? -1 : 1;
					}
					return a.row < b.row ? -1 : 1;
				});
			}
			setData(data);
			setLoading(false);
			setFieldValues(getDefaultValues(data?.form?.formfields));
		} catch (error) {
			//setLoading(false);
			setData(null);
		}
	};
	useEffect(() => {
		fetchPageContents(api, setData, setLoading, setFieldValues);
	}, []);
	console.log(data);
	return (
		<div>
			<Header />
			<div className="page-body">
				<Sidebar>
					<SideBarContents />
				</Sidebar>
				<div className="separating-line"></div>
				<Main>
					<p className="attestato_titolo">Attestati insegnanti {attestatoID}</p>
				</Main>
			</div>
			<Footer />
		</div>
	);
}

export default AttestatoInsegnantiLink;
