import React from 'react'
import './attestatiInput.css';

function AttestatiInput(props) {
	const input = props.input;
	const title = props.title;
	const checkmarkVisibility = props.checkmarkVisibility;
	return (
		<div className="certificate">
			<div className="row">
				<div className="title-attestati col-sm-2">
					<p>{title}</p>
				</div>
				<div className="input-attestati col-sm-4">
					{input}
				</div>
				<div className={`checkmark-attestati ${checkmarkVisibility ? "" : "hidden"}`}>
					<img src="https://image.similarpng.com/very-thumbnail/2021/05/Checkmark-green-tick-isolated-on-transparent-background-PNG.png"></img>
				</div>
			</div>
		</div>
	)
}

export default AttestatiInput
