import React, { useEffect, useRef } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import Sidebar from "../components/layout/Sidebar";
import Main from "../components/layout/Main";
import FormInput from "./FormInput";
import { ReactComponent as Loader } from "../img/loader.svg";
import "../css/form.css";
import { useUser } from "../context/UserContext";
import Form from "react-bootstrap/Form";
import imgNotification from "../img/icon/notification.svg";
import { getUrl } from "../utils/functions";
import { useAuth } from "../context/AuthContext";
import SideBarContents from "../components/layout/SideBarContents";
import { useLocation } from "react-router-dom";

const fetchFieldsForm = async (url, api, setForm, setFormFieldsValue) => {
	try {
		setForm((state) => {
			return {
				...state,
				loading: true,
			};
		});
		let data = await api(url);
		setForm({
			status: true,
			data: data,
			loading: false,
			error: null,
		});
		if (Array.isArray(data?.form_fields)) {
			let values = {};
			for (let field of data?.form_fields) {
				values[field.form_formField_id] = field.field_value;
			}

			setFormFieldsValue(values);
		}
	} catch (error) {
		setForm({
			status: false,
			data: null,
			loading: false,
			error: error.detail ?? "An error occured by server.",
		});
		console.error(error);
	}
};

function FormCandidatura() {
	const location = useLocation();
	const userId = location?.state?.userId;
	const back = location?.state?.back;
	const fromAdmin = location?.state?.fromAdmin;

	const [formFieldsValue, setFormFieldsValue] = React.useState({});
	const [form, setForm] = React.useState({
		status: false,
		data: null,
		loading: false,
		error: null,
	});

	const [submitMessage, setSubmitMessage] = React.useState({
		status: false,
		data: null,
		error: null,
	});

	let { code } = useParams();
	const [_, __, userInfo] = useUser();
	const history = useHistory();
	const { api } = useAuth();

	useEffect(() => {
		let url = getUrl("user", `candidature/getform/?form_id=${code}`);
		if (fromAdmin) {
			url = getUrl("user_admin", `backoffice/getform_backoffice?form_id=${code}&user_id=${userId}`);
		}
		fetchFieldsForm(url, api, setForm, setFormFieldsValue);
	}, [setForm, setFormFieldsValue, code, api]);

	const submitForm = async (event) => {
		event.preventDefault();
		let url = getUrl("user", "candidature/submitform/");
		if (fromAdmin) {
			url = getUrl("user_admin", `backoffice/submitform_backoffice?user_id=${userId}`);
		}
		try {
			let submitData = [];
			for (let id of Object.keys(formFieldsValue)) {
				submitData.push({
					form_formField_id: id,
					field_update_value: formFieldsValue[id],
				});
			}
			let data = await api(url, "post", submitData);
			setSubmitMessage({
				status: true,
				data: data?.response,
				error: null,
			});
			if (fromAdmin) {
				history.push({
					pathname: "/message",
					state: {
						message: data?.response,
						type: "confirm",
						link: `/admin_moduli_prenotazione/${userId}`,
						label: "Torna alla pagina",
						img: imgNotification,
					},
				});
			} else {
				history.push({
					pathname: "/message",
					state: {
						message: data?.response,
						type: "confirm",
						link: "/dashboard",
						label: "Torna alla pagina",
						img: imgNotification,
					},
				});
			}
		} catch (error) {
			setSubmitMessage({
				status: false,
				data: null,
				error: error.response?.data?.detail,
			});
			console.error(error.response?.data?.detail, error);
		}
		console.log(submitMessage);
	};
	let displayInviaButton = false;
	let buttonLabel = "";
	let message = "";
	return (
		<div>
			<Header />
			<div className="row w-100 mx-0 mx-md-auto">
				<Sidebar>
					<SideBarContents />
				</Sidebar>
				<Main>
					<div className="main-padding">
						{form.loading === true && (
							<div className="loading">
								<Loader />
							</div>
						)}
						{form.error != null && (
							<h3 className="text-black">
								{form?.error}{" "}
								{form.error === "Profilo non completo" && (
									<Link
										to={{
											pathname: "/userprofile",
										}}>
										clicca qui.
									</Link>
								)}
							</h3>
						)}

						{form.data != null && form.loading === false && form.status === true && (
							<>
								<h1
									className="h0 text-primary"
									style={{ color: "#1488f3" }}>
									{form.data.form_title}
								</h1>
								<div className="w-100 text-center my-3">
									<img
										className="img-fluid"
										src={`/static/media/${form.data.picture}`}
										alt=""
									/>
								</div>
								<p
									dangerouslySetInnerHTML={{
										__html: form.data.form_longDescr
											.replace(/^(")+/, "")
											.replace(/(")+$/, "")
											.replace(/(\n)+/, "<p></p>"),
									}}></p>
								<div className="form-candidatura">
									<Form
										method="post"
										onSubmit={submitForm}>
										<div className="row">
											{form.data?.form_fields.map((formField) => {
												if (formField.hidden_field === true) {
													if (
														formField.field_label === "Message" &&
														formField.field_value != null &&
														!fromAdmin
													) {
														message = formField.field_value;
													}
													if (formField.field_label === "Show Button") {
														displayInviaButton = true;
														buttonLabel = "Prenota la lezione";
														/*if (formField.field_value == null) {
															displayInviaButton = false;
														} else {
															displayInviaButton = true;
															buttonLabel = formField.field_value;
														}
														if (fromAdmin) {
															displayInviaButton = true;
															buttonLabel = "Invia la tua candidatura";
														}*/
													}
													return <div key={formField.field_id}></div>;
												}

												return (
													<FormInput
														data={formField}
														key={formField.field_id}
														setFormFieldsValue={setFormFieldsValue}
													/>
												);
											})}
										</div>

										<div className="row my-4">
											<div className="col-6">
												{code !== "SCRITTORE" ? (
													displayInviaButton && (
														<button
															className="button blue-btn w-100 mt-3"
															type="submit">
															{buttonLabel === "" ? "Prenota la lezione" : buttonLabel}
														</button>
													)
												) : (
													<p className="font-weight-bold text-danger">
														La fase di candidatura è terminata. Ricordiamo a tutti i docenti che possono
														ancora iscriversi a tutti gli altri progetti: Un libro tante scuole, L'Europa
														ancora da scrivere e il Bookblog.
													</p>
												)}
											</div>
											<div
												className={`${message.includes("mailto:") ? "" : "col-md-6"
													} col-12 d-flex flex-column justify-content-center`}>
												{message !== "" &&
													(message.includes("mailto:") ? (
														<span
															id="success"
															className="text-primary"
															dangerouslySetInnerHTML={{ __html: message }}></span>
													) : message.includes("completo") ? (
														<span
															id="success"
															className="text-primary">
															{message}
															<Link
																to={{ pathname: "/userprofile" }}
																className="text-primary"
																style={{ fontWeight: "600" }}>
																{" "}
																Clicca qui.
															</Link>
														</span>
													) : message.includes("esteso") ? (
														<span
															id="success"
															className="text-primary">
															{message}
															<Link
																to={{ pathname: "/extensionuserprofile" }}
																className="text-primary"
																style={{ fontWeight: "600" }}>
																{" "}
																Clicca qui.
															</Link>
														</span>
													) : (
														<span
															id="success"
															className="text-primary">
															{message}
														</span>
													))}
												{submitMessage.error != null && (
													<p
														id="success"
														className="text-danger">
														{submitMessage?.error}
													</p>
												)}
											</div>
										</div>
									</Form>
								</div>
							</>
						)}
					</div>
				</Main>
			</div>
			<Footer />
		</div>
	);
}

export default FormCandidatura;
