import React, { useEffect, useState } from "react";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import Sidebar from "./components/layout/Sidebar";
import Main from "./components/layout/Main";
import "./css/buono.css";
import { useHistory, Link } from "react-router-dom";
import buonoImg from "./img/home/buono.jpg";
import { Form } from "react-bootstrap";
import salone from "./utils/salone";
import { useUser } from "./context/UserContext";
import Cookies from "js-cookie";


const fetchBuoniRimasti = async (setBuoniRimasti) => {
  try {
    let response = await salone.get("/buonolibro/buoniRimasti", {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
    setBuoniRimasti(response.data?.counter);
  } catch (error) {
    console.log(error.response.data);
  }
}

const fetchBuonoInfo = async (setBuonoInfo, history, renderDefaultPage) => {
  let response;
  try {
    response = await salone.get(`/buonolibro/buono/info`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });

  } catch (error) {
    history.push("/login");
    return;
  }

  setBuonoInfo({
    data: Array.isArray(response.data) ? response.data[0] : response.data,
    loading: false,
    status: true
  });
  switch (response.data[0]?.layoutId) {
    case 1:
    default:
      renderDefaultPage();
  }
}

const Buono = () => {
  const [buoniRimasti, setBuoniRimasti] = React.useState(null);
  const [buonoInfo, setBuonoInfo] = useState({
    loading: false,
    data: null,
    status: false
  });
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [description, setDescription] = useState("");
  const [buono, setBuono] = useState("");
  //const [mecCode, setMecCode] = useState();
  const [error, setError] = useState();
  const history = useHistory();
  const [user] = useUser();
  //const [displayButton, setDisplayButton] = React.useState(true);

  let responseForMechCode = ""; //Note: change according to API
  if (buonoInfo.loading === false && buonoInfo?.data?.responseForMechCode != null) {
    responseForMechCode = buonoInfo?.data?.responseForMechCode;
  }


  let pdfUrls = [];
  if (buonoInfo.loading === false && buonoInfo.data != null && buonoInfo.data.pdfUrls != null) {
    pdfUrls = buonoInfo.data.pdfUrls;
  }
  if (pdfUrls.length === 0 && buono?.pdfUrls != null) {
    pdfUrls = buono.pdfUrls;
  }

  useEffect(() => {
    fetchBuoniRimasti(setBuoniRimasti);
    const renderDefaultPage = () => {
      setDescription(<p> Il Buono da Leggere è l’occasione per ampliare la biblioteca scolastica facendo un salto <span className="font-weight-bold">nelle librerie del Piemonte</span>. <br />
        Grazie al sostegno della Regione Piemonte, il Salone distribuisce <span className="font-weight-bold">6.000 Buoni da Leggere</span> del valore di 10 € utilizzabili per acquistare di libri di narrativa, saggistica, fumetti e albi illustrati. <br />
        I docenti* delle scuole  di infanzia, primarie e secondarie piemontesi di primo grado possono richiedere un carnet di dieci Buoni (del valore di 10 € ciascuno), per arricchire le biblioteche di classe e, in prospettiva, quelle scolastiche. <br />
        {/* Sono esclusi i docenti che hanno già usufruito dei Buoni durante Vita Nova e i docenti che insegnano nelle scuole della città di Torino.<br /> */}
        Ogni buono all'interno del carnet può essere speso, anche singolarmente, <a href=" https://www.salonelibro.it/ita/salto-scuola/salto-scuola-articolo/torna-il-buono-da-leggere-nelle-librerie-piemontesi.html" target="_blank">in tutte le librerie aderenti</a>.<br />
        I buoni potranno essere spesi entro il 20 marzo.<br />
        <br />
        *Sono esclusi i docenti che hanno già usufruito dei Buoni durante Vita Nova e i docenti che insegnano nelle scuole della città di Torino.<br />
        <br />
        <b>Cari docenti, i  buoni da leggere sono terminati.</b>
        <br />
        <br />
      </p>);
      setTitle("BUONO DA LEGGERE");
      setSubtitle("Scarica il buono e inizia a leggere!");
    }
    fetchBuonoInfo(setBuonoInfo, history, renderDefaultPage);
    //window.location.reload();
    //history.push("/buonolibro");
  }, [setBuoniRimasti, setBuonoInfo, history, buoniRimasti]);


  const downloadBuoni = async (event) => {
    event.preventDefault();
    if (responseForMechCode == null) {
      return;
    }
    let response;
    try {
      response = await salone.get(`/buonolibro/buono/request/${responseForMechCode}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      })
    } catch (error) {
      console.log(error.response?.data.detail);
      setError("Il codice meccanografico inserito è errato o non associato a un Istituto Scolastico piemontese.");
      return;
    }
    switch (response.data[0]?.status) {
      case "Richiesta non autorizzata":
      case "Unauthorized":
        setError("Il codice meccanografico inserito è errato o non associato a un Istituto Scolastico piemontese ma non Torinese.");
        break;
      case "Area non coperta da iniziativa":
        setError("Il codice meccanografico è associato ad un Istituto scolastico Torinese. Area non coperta da iniziativa.");
        break;
      default:
      //""
    }
    setBuono(Array.isArray(response?.data) ? response.data[0] : response.data);
    //window.location.reload();
  };

  const getSingleBuono = () => {
    salone
      .get(`/buonolibro/buono/request`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      })
      .catch((error) => {
        console.log(error.response.data.detail);
        history.push("/login");
      })

      .then((response) => {
        setBuono(Array.isArray(response?.data) ? response.data[0] : response.data);
      });
  }

  const getPdfLinks = () => {
    if (pdfUrls.length === 0) {
      return;
    }
    return <div>
      <div className="row">
        <div className=" offset-md-8 col-md-4 col-12">
          <button className="button blue-btn w-100" disabled>
            I buoni sono già stati scaricati
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-center">
          <p style={{ fontWeight: "bold" }}>{pdfUrls.length > 1 ? "Ecco i tuoi buoni:" : "Ecco il tuo buono"}</p>
        </div>
      </div>
      <div className="row">
        {pdfUrls.map((pdfUrl) => <div className="col-3 text-center"><a href={pdfUrl}>Scarica buono!</a></div>)}
      </div>
    </div>
  }

  const getCodiceMeccanograficcoForm = () => {
    if (pdfUrls.length !== 0) {
      return;
    }
    return <div className="row w-100">
      <div className="col-4 text-center">
        <p style={{ fontWeight: "bold" }}>Inserire codice meccanografico</p>
        {responseForMechCode === "" && <p className="text-danger">Prima di accedere al buono da leggere è necessario aggiornare le informazioni nel proprio profilo <Link to={{
          pathname: "/userprofile",
          state: { highlighted: true }
        }}>clicca qui.</Link></p>}
      </div>
      <div className="col-4 text-center">
        <Form >
          <input type="text" placeholder="codice meccanografico" value={responseForMechCode} required disabled />
        </Form>
      </div>
      <div className="col-4">
        <button className="button blue-btn w-100" onClick={downloadBuoni}>
          Scarica il tuo buono
        </button>
      </div>
    </div>
  }

  const dynamicMessages = () => {
    console.log("hello", error);
    let response = undefined;
    switch (buonoInfo.data.status) {
      case "AlreadyDownloaded":

        break;
      case "NoBonusForYourProfile":
        // response = <div className="row-100 col-md-4 col-12">
        //   <p style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>
        //     Ci dispiace, non puoi scaricare nessun buono
        //   </p>
        // </div>
        break;
      case "ProfileQuotaExpired":
        //response = <div className="row-100 col-4">
        //   <p style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>
        //     Ci dispiace, non ci sono più buoni disponibili
        //   </p>
        // </div>
        break;
      case "OnlyPiedmontInhabitantAllowed":
        response = <div className="row-100 col-4">
          <p style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>
            Bisogna essere residenti in Piemonte per accedere a questo Buono
          </p>
        </div>
        break;
      case "Enabled":
        if (buonoInfo.data.layout === "Form") {
          response = getCodiceMeccanograficcoForm();
        }
        if (buonoInfo.data.layout === "Single") {
          response = <div>
            <button className="button blue-btn w-100" onClick={getSingleBuono}>
              Scarica il tuo buono
            </button>
          </div>
        }
        break;
      default:
      //""
    }
    return response;
  }

  return (
    <div>
      <Header />
      <div className="row w-100">
        <Sidebar>
          <h3 style={{ textTransform: "uppercase" }}>Ciao <span style={{ color: "#1488F3" }}>{user?.first_name}</span>,<br />Benvenuta/o su SalTo+</h3>
          
          <p>In questo spazio trovi il meglio della produzione culturale curata dal Salone: video, interviste, lezioni, podcast, approfondimenti. Una grande libreria a tua disposizione: scegli un contenuto e inizia il viaggio.</p>
        </Sidebar>
        <Main>
          <div className="main-padding">
            <div className="row">
              <div className="col-12">
                <h1 className="h0">{title}</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <h2>
                  {subtitle}
                </h2>
              </div>
            </div>
            <div className="row py-4">
              <div className="col-12">
                <div className="text-center"><img src={buonoImg} className="buono-img" alt=""/></div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                {description}
                {/* {buoniRimasti && <span className="font-weight-bold">Cari docenti, sono disponibili {buoniRimasti} buoni.</span>} */}

              </div>
            </div>

            <div className="row">
              <div className="col-12">
                {/* <p className="font-weight-bold">Il download dei buoni sarà disponibile a partire dal 04 Marzo.</p> */}
                {(buonoInfo.loading === false && buonoInfo.data != null) && dynamicMessages()}
              </div>
            </div>
            {error != null && (<div className="row-100 col-4">
              <p style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>
                {error}
              </p>
            </div>)}
            <div className="row">
              {error == null && getPdfLinks()}
            </div>
          </div>
        </Main>
      </div>
      <Footer />
    </div>
  );
}

export default Buono;
