import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import Footer from '../components/layout/Footer'
import Header from '../components/layout/Header'
import Main from '../components/layout/Main'
import Sidebar from '../components/layout/Sidebar'
import { useAuth } from '../context/AuthContext'
import { useUser } from '../context/UserContext'
import { getUrl } from '../utils/functions'
import { ReactComponent as Error } from '../img/icon/error.svg'
import '../css/bookingeventvalidation.css'
import StandardValidation from '../components/validation_categories/StandardValidation'
import ProfessionalValidation from '../components/validation_categories/ProfessionalValidation'
import ScuolaValidation from '../components/validation_categories/ScuolaValidation'
import MinoriValidation from '../components/validation_categories/MinoriValidation'
import SideBarContents from '../components/layout/SideBarContents'


function BookingEventValidation() {

    const [_, __, userInfo] = useUser()
    const { booking_id, participant_id } = useParams()
    const { api } = useAuth()

    const [validateQRCode, setValidateQRCode] = useState({
        status: false,
        data: null,
        loading: false,
        error: null
    })

    const fetchValidateQRCode = async () => {
        
        try {
            setValidateQRCode({ ...validateQRCode, loading: true })
            let data = await api(getUrl("booking_admin", `access/validate/${booking_id}/${participant_id}`))
            setValidateQRCode({
                status: true,
                data: data,
                loading: false,
                error: null
            })
        } catch (error) {
            setValidateQRCode({
                status: false,
                data: null,
                loading: false,
                error: error?.response?.data?.detail?.[0]?.msg || error?.response?.data?.detail || "An error occured by the server!"
            })
        }
    }

    useEffect(() => {
        fetchValidateQRCode()
    }, [booking_id, participant_id])

    const getOKValidation = () => {
        let Component;
        switch (validateQRCode?.data?.type) {
            case "Standard":
                Component = StandardValidation;
                break;
            case "Minori":
                Component = MinoriValidation;
                break;
            case "Scuole":
                Component = ScuolaValidation;
                break;
            case "Professionali":
                Component = ProfessionalValidation;
                break;
            default:
                return;

        }
        return <Component data={validateQRCode?.data} />;
    }

    return (
        <div>
            <Header />
            {/* <div className="row w-100" style={{ minHeight: "68vh" }}> */}
            <div className="page-body">
                <Sidebar>
                    {/* <p>In questo spazio trovi il meglio della produzione culturale curata dal Salone: video, interviste, lezioni, podcast, approfondimenti. Una grande libreria a tua disposizione: scegli un contenuto e inizia il viaggio.</p>
                    <br />
                    <br /> */}
                    <SideBarContents />
                </Sidebar>
                <div className='separating-line'></div>
                <Main>
                    {validateQRCode?.error != null && (
                        <div className="container-error-message">
                            <Error />
                            <h3 className="text-danger">{validateQRCode?.error}</h3>
                        </div>

                    )}
                    {validateQRCode?.loading === false && validateQRCode?.data != null &&
                        getOKValidation()
                    }                    
                </Main>
            </div>
            <Footer />
        </div>
    )
}

export default BookingEventValidation
