import React from 'react'
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import "../../css/admindashboard.css";
import UserProfile from '../../img/icon/users_admin_dashboard.svg'
import ReadBook from '../../img/icon/read_book_admin_dashboard.svg'
import Booking from '../../img/icon/booking_admin_dashboard.svg'
import Sponsor from '../../img/icon/sponsorship_admin_dashboard.svg'
import OnlineCourses from '../../img/icon/online-course-dashboard.svg'


function AdminDashboard() {

    return <div className='dashboard-container'>
        <Header />
        <div className="main-padding-dashboard flex-grow-1">
            <div className="dashboard">
                <div className="cards-admin-container">
                    <div className={`card-admin-ashboard `} >
                        <Link to="/admin_users" className="card-img-admin">
                            <img src={UserProfile} alt="userprofile-img" />
                            <p><b>Utenti</b></p>
                        </Link>
                    </div>
                    <div className={`card-admin-ashboard`} >
                        <Link to="/admin_buonodaleggere" className="card-img-admin">
                            <img src={ReadBook} alt="userprofile-img" />
                            <p><b>Buono da leggere</b></p>
                        </Link>

                    </div>
                    <div className={`card-admin-ashboard `} >
                        <Link to="/admin_corsi_disponibili" className="card-img-admin">
                            <img src={OnlineCourses} alt="userprofile-img" />
                            <p><b> Corsi disponibili</b></p>
                        </Link>

                    </div>
                    <div className={`card-admin-ashboard `} >
                        <Link to="/prenotazione_saltopiu" className="card-img-admin">
                            <img src={Booking} alt="userprofile-img" />
                            <p><b> Prenotazioni</b></p>
                        </Link>

                    </div>
                    <div className={`card-admin-ashboard `} >
                        <Link to="/sponsor" className="card-img-admin">
                            <img src={Sponsor} alt="userprofile-img" />
                            <p><b> Prenotazioni sponsor</b></p>
                        </Link>

                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </div>;
}

export default AdminDashboard;
