import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as SearchIcon } from '../../img/icon/search_icon.svg';
import { getUrl } from '../../utils/functions';
import { useAuth } from '../../context/AuthContext';
import MultipleSelect from '../inputs/MultipleSelect';
import "../../css/filterBookings.css"
import ShowingSelect from '../inputs/ShowingSelect';
import { Interval } from 'luxon';
import { DateTime } from 'luxon';


const fetchAllProgrammes = async (api, setProgrammes) => {
    try {
        let data = await api(getUrl("programma", `api/v1/tags?filter[tagGroup]=${process.env.REACT_APP_TAG_GROUP_ID_PROGRAM}`));
        setProgrammes(data)
    } catch (error) {
        setProgrammes(null)
    }
}

const fetchAllArguments = async (api, setArguments) => {
    try {
        let data = await api(getUrl("programma", `api/v1/tag-groups`));
        setArguments(data)
    } catch (error) {
        setArguments(null)
    }
}

//! DECIDE QUALE FILTRI MOSTRARE
const visibleInputs = {
	"programma" : false,
	"ricerca" : true,
	"argomenti" : false,
	"giorno" : false,
	"orario" : false
}


//! CAMBIARE QUESTA VARIABILE PER CAMBIARE I VALORI DELLA SELECT GIORNI

const filterDateOptions = [
	{ id: "2022-05-19", name: "19 Maggio" },
	{ id: "2022-05-20", name: "20 Maggio" },
	{ id: "2022-05-21", name: "21 Maggio" },
	{ id: "2022-05-22", name: "22 Maggio" },
	{ id: "2022-05-23", name: "23 Maggio" },
]

const hourData = [];
for (let i = 0; i <= 24; i++) {
	if (i < 10) {
		hourData.push({ id: `${"0" + i}:00`, name: `${"0" + i}:00` });
	} else {
		hourData.push({ id: `${i}:00`, name: `${i}:00` });
	}
	if (i !== 23) {
		if (i < 10) {
			hourData.push({ id: `${"0" + i}:30`, name: `${"0" + i}:30` });
		} else {
			hourData.push({ id: `${i}:30`, name: `${i}:30` });
		}
	}
}

//! ------------------------------------------

//! FILTRI PER LA FILTEREVENT

const filterTitle = function(filterValues, eventData) {
	if (filterValues.search !== "") {
		if (!eventData.title.toLowerCase().includes(filterValues.search.toLowerCase())) {
			return false;
		}
	}
}

const filterPrograms = function(filterValues, eventData) {
	if (filterValues.length != 0 && filterValues[0] !== "") {
		if (!filterValues.includes(eventData.program.toString())) {
			return false;
		}
	}
};

const filterArguments = function(filterValues, eventData) {
	if (filterValues.length != 0 && filterValues[0] !== "") {
		var checkArgumentPresence = false;
		eventData.tags.forEach((singleTag) => {
			if (filterValues.includes(singleTag.toString())) {
				checkArgumentPresence = true;
			}
		})
		if (checkArgumentPresence == false) {
			return false;
		}
	}
};

const filterDays = function(filterValues, eventData) {
	if (filterValues.length === 2) {
		const indexFirstvalue = filterDateOptions.findIndex((el) => {
			return el.id === filterValues[0];
		});
		const indexSecondValue = filterDateOptions.findIndex((el) => {
			return el.id === filterValues[1];
		});
		if (indexFirstvalue > indexSecondValue) {
			filterValues = (filterDateOptions.slice(indexSecondValue, indexFirstvalue + 1)).map((el) => el.id);
		} else if (indexFirstvalue < indexSecondValue) {
			filterValues = (filterDateOptions.slice(indexFirstvalue, indexSecondValue + 1)).map((el) => el.id);
		}
	}

	if (filterValues.length != 0 && filterValues[0] !== "") {
		if (!filterValues.includes(eventData.date)) {
			return false;
		}
	}
};

const filterTimes = function(filterValues, eventData) {
	if (filterValues.length !== 0) {
		if (filterValues.length === 1) {
			if (filterValues[0] !== eventData.time) {
				return false
			}
		} else if (filterValues.length === 2) {
			const startFilterTime = DateTime.fromFormat(filterValues[0], "HH:mm");
			const endFilterTime = DateTime.fromFormat(filterValues[1], "HH:mm");
			const interval = Interval.fromDateTimes(startFilterTime, endFilterTime);
		
			const eventTime = DateTime.fromFormat(eventData.time, "HH:mm");
		
			if (!interval.contains(eventTime)) {
				return false;
			}
		}
	}
};

// COMPONENT

function FilterBookings({ eventsFullData, setData, findEvent }) {
    const { api } = useAuth();
    const [programmes, setProgrammes] = useState(null);
    const [allArguments, setArguments] = useState(null);

	const [dateValues, setDateValues] = useState([]);
	const [timeValues, setTimeValues] = useState([]);

    const [values, setValues] = useState({
        programTags: undefined,
        argumetTags: undefined,
        date: undefined,
        time: undefined,
        search: ""
    });
    const searchRef = useRef();


    useEffect(() => {
        fetchAllProgrammes(api, setProgrammes);
        fetchAllArguments(api, setArguments);
    }, []);

	const filterEvents = function(filterValues, filterFunction) {
		let filteredEvents = [];

		Object.entries(eventsFullData).forEach((eventIdPair) => {
			const eventId = eventIdPair[0];
			const eventData = eventIdPair[1].data;

			if (filterFunction(filterValues, eventData) !== false) {
				let eventToPush = {};
				eventToPush[eventId] = eventData;
				filteredEvents.push(findEvent(eventId));
			}

		})
		
		setData([]);
		if (filteredEvents.length > 0) {
			setData(filteredEvents);
		}
	}

    useEffect(() => {
    }, [values])

    return (
        <div>
            {visibleInputs.programma && (<div style={{ width: "250px", display : "inline-block"}}>
                <MultipleSelect
					textPreview={"Eventi Salone"}
                    data={programmes?.data ?? []}
                    onChange={(tags) => {
                        const newValues = { ...values, programTags: tags.join(",") };
                        setValues(newValues);
                        filterEvents(newValues.programTags.split(","), filterPrograms);
                    }} />
            </div>)}
            <div className="container-search-arguments">
                {visibleInputs.ricerca && (<div className="search-box">
                    <input ref={searchRef} type="text" placeholder="Cerca l'evento"
                        value={values.search}
                        onChange={(event) => {
                            const newValues = { ...values, search: event.target.value };
                            setValues(newValues);
                        }}
						onKeyDown={(event) => {
							if (event.key === "Enter") {
								filterEvents(values, filterTitle)
							}
						}}
						/>
                    <SearchIcon onClick={() => filterEvents(values, filterTitle)} />
                </div>)}
				{visibleInputs.programma && (<div style={{ width: "250px", display : "inline-block", marginLeft : "40px" }}>
                    <MultipleSelect
						textPreview={"Tutti gli argomenti"}
                        data={allArguments?.data ?? []}
                        onChange={(tags) => {
                            const newValues = { ...values, argumetTags: tags.join(",") };
                            setValues(newValues);
                            filterEvents(newValues.argumetTags.split(","), filterArguments);
                        }} />
                </div>)}
            </div>
            <div className="container-day-hour bookings-day-hour">
                {visibleInputs.giorno && (<div className="container-day-label">
                    <div className="day-label">Giorno</div>
                    <ShowingSelect
						data={filterDateOptions}
						textPreview={"Scegli un giorno"}
						type="date"
                        className={"select-day-prenotazioni"}
                        onChange={(dates) => {
                            const newValues = { ...values, date: dates.join(",") };
                            setValues(newValues);
                            filterEvents(newValues.date.split(","), filterDays);
                        }}
						values = {dateValues}
						setValues = {setDateValues}
						/>
                </div>)}
                {visibleInputs.orario && (<div className="container-hour-label">
                    <div className="day-label">Orario</div>
                    <ShowingSelect
						data={hourData}
                        textPreview={"Scegli orari"}
						type="time"
                        className={"select-hour-prenotazioni"}
                        onChange={(times) => {
                            const newValues = { ...values, time: times.join(",") };
                            setValues(newValues);
                        	filterEvents(newValues.time.split(","), filterTimes);
                        }}
						values = {timeValues}
						setValues = {setTimeValues}
						/>
                </div>)}
            </div>
			<p onClick={() => {
				setValues({
					programTags: undefined,
					argumetTags: undefined,
					date: undefined,
					time: undefined,
					search: ""
				});
				// Just a random filter function to get all the events
				filterEvents([], filterTimes);
				document.getElementsByClassName("text-preview");
				setDateValues([]);
				setTimeValues([]);
			}} className='clean-filter-bookings'>Pulisci i filtri utilizzati</p>
        </div>
    )
}

export default FilterBookings