import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import Main from "../components/layout/Main";
import Sidebar from "../components/layout/Sidebar";
import { useUser } from "../context/UserContext";
import { getUrl } from "../utils/functions";
import { useAuth } from "../context/AuthContext";
import "../css/singleeventpage.css";
import { ReactComponent as Loader } from "../img/loader.svg";
import PopUp from "../components/PopUp";
import BookingPopUpContents from "../components/booking_events/BookingPopUpContents";
import ButtonEvent from "../components/booking_events/ButtonEvent";
import SideBarContents from "../components/layout/SideBarContents";

import { ToastContainer } from "react-toastify";
import toastContext from "../context/ToastContext";

const fetchEvent = async (api, setEventInfo, setLoading, id, setBookedSchoolEventAmount, setScuolaType) => {
	try {
		setLoading(true);
		let data = await api(getUrl("programma", `api/v1/bookable-program-items/${id}?include=tags`));
		const requests = [];
		const authors = [];
		const exhibitors = [];
		let includeInBookedEvent;
		let location;
		let bookedSchoolEventAmount = 0;
		requests.push(async () => {
			let response = await api(getUrl("booking", "bookings/booking/"));
			includeInBookedEvent = response?.find((bookedEvent) => +id === bookedEvent.event_id);
			response.forEach(e => { if(e.type === "Scuole") bookedSchoolEventAmount++;})

			setBookedSchoolEventAmount(bookedSchoolEventAmount);
		});
		
		data?.data?.tags.forEach(t => { if(t.name === "Scuole") setScuolaType(true); })
		
		requests.push(async () => {
			location = await api(getUrl("programma", `api/v1/locations/${data?.data?.location}`));
		});
		requests.push(
			...data?.data?.authors?.map((author) => {
				return async () => {
					const result = await api(getUrl("programma", `api/v1/authors/${author}`));
					authors.push(result);
				};
			})
		);
		requests.push(
			...data?.data?.exhibitors?.map((exhibitor) => {
				return async () => {
					const result = await api(getUrl("programma", `api/v1/exhibitors/${exhibitor}`));
					exhibitors.push(result);
				};
			})
		);
		await Promise.all(requests.map(async (request) => await request()));
		setLoading(false);
		let dataAsObject = data?.data;
		let modifiedTags = dataAsObject?.tags?.map((tag) => ({ id: tag }));
		dataAsObject.tags = modifiedTags;
		setEventInfo({
			data: dataAsObject,
			location: location?.data,
			exhibitors: exhibitors,
			authors: authors,
			isBookedEvent: includeInBookedEvent,
		});

	} catch (error) {
		setLoading(false);
		setEventInfo({
			data: null,
			location: null,
			exhibitors: null,
			authors: null,
			isBookedEvent: null,
		});
	}
};

const fetchPopUpContents = async (api, setPopUpContents, setLoading, id) => {
	try {
		setLoading(true);
		let data = await api(getUrl("booking", `bookingflow/getstate?event_id=${id}`));
		setPopUpContents(data);
		setLoading(false);
	} catch (error) {
		//setLoading(false);
		setPopUpContents(null);
	}
};

function SingleEventPage() {
	const [_, __, userInfo] = useUser();
	const { evento_id } = useParams();
	const { api } = useAuth();
	const [eventInfo, setEventInfo] = useState({
		data: null,
		location: null,
		exhibitors: null,
		authors: null,
		isBookedEvent: null,
	});
	const [loading, setLoading] = useState(null);
	const [popUpLoading, setPopUpLoading] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [scuolaType, setScuolaType] = useState(false);
	const [bookedSchoolEventAmount, setBookedSchoolEventAmount] = useState(0);
	const [popUpContents, setPopUpContents] = useState(null);
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 540);
	const toastProvider = useContext(toastContext);

	const getDuration = (duration) => {
		if (duration < 60) {
			return `${duration} minuti`;
		}
		let hour = Math.floor(duration / 60);
		let minutes = duration % 60;
		if (minutes === 0) {
			return `${hour} Or${hour === 1 ? "a" : "e"}`;
		}
		return `${hour} Or${hour === 1 ? "a" : "e"} ${minutes} minuti`;
	};

	useEffect(() => {
		fetchEvent(api, setEventInfo, setLoading, evento_id, setBookedSchoolEventAmount, setScuolaType);
		fetchPopUpContents(api, setPopUpContents, setPopUpLoading, evento_id);
	}, [evento_id]);

	const refreshPopUpContents = () => {
		fetchPopUpContents(api, setPopUpContents, setPopUpLoading, evento_id);
	};

	useEffect(() => {
		const resizeHandler = () => {
			if (window.innerWidth <= 540 && !isMobile) {
				setIsMobile(true);
			}
			if (window.innerWidth > 540 && isMobile) {
				setIsMobile(false);
			}
		};
		window.addEventListener("resize", resizeHandler);
		return () => {
			window.removeEventListener("resize", resizeHandler);
		};
	}, [isMobile]);

	const notifyBooking = () => {
		const CustomLinkToast = () => {
			return (
				<p>
					Un nuovo evento è stato prenotato! <a href="/prenotazioni">Clicca qui</a> per le tue prenotazioni.
					{eventInfo?.data?.tags.map((tagId) => tagId.id).includes(parseInt(process.env.REACT_APP_TAGS_SCUOLE)) ? (
						<>
							{/* <br />
							<br /> Attenzione: per accedere al Salone Internazionale del Libro devi aver acquistato i biglietti
							d’ingresso per la tua classe. Se non l’hai ancora fatto, accedi alla piattaforma SALTO+ nella sezione
							“Biglietteria scuole” e crea la tua prenotazione. Trovi costi e istruzioni qui: */}
							<a
								href="https://www.salonelibro.it/salto-scuola/classi/classi-articolo/come-prenotare.html"
								target="_blank"
								rel="noreferrer">
								https://www.salonelibro.it/salto-scuola/classi/classi-articolo/come-prenotare.html
							</a>
						</>
					) : (
						""
					)}
				</p>
			);
		};

		toastProvider.toastInfo(CustomLinkToast);
	};
	return (
		<div>
			<Header />
			{/* <div className="row w-100"> */}
			<div className="page-body">
				<Sidebar>
					{/* <p>In questo spazio trovi il meglio della produzione culturale curata dal Salone: video, interviste, lezioni, podcast, approfondimenti. Una grande libreria a tua disposizione: scegli un contenuto e inizia il viaggio.</p>
                    <br />
                    <br /> */}
					<SideBarContents />
				</Sidebar>
				<div className="separating-line"></div>
				<Main>
					<div className="event-page">
						{
							<div>
								<ToastContainer></ToastContainer>
							</div>
						}

						{(loading || popUpLoading) && (
							<div className="loading fixed">
								<Loader />{" "}
							</div>
						)}

						{eventInfo?.data != null && (
							<>
								<h1>Prenota il seguente evento</h1>
								<div className="text">
									<p>
										Per prenotare un evento sfoglia il programma, e clicca sul pulsante prenota!
										<br />
										La precedura guidata ti accompagnerà nel processo di prenotazione.
									</p>
								</div>
								<div className="event-box">
									<div className="time-data">
										{eventInfo?.data?.date}, Ore {eventInfo?.data?.time}, Durata{" "}
										{getDuration(+eventInfo?.data?.duration)}
									</div>
									<div className="event-title">
										<h2>{eventInfo?.data?.title}</h2>
									</div>
									<div className="event-subtitle">
										<h3>{eventInfo?.data?.subtitle}</h3>
									</div>
									<div className="where">{eventInfo?.location?.name}</div>
									<div className="incontro-con">
										<h4>Incontro con</h4>
										<h4>
											{eventInfo?.authors
												?.map((item) => item.data.first_name + " " + item.data.last_name)
												.join(", ")}
										</h4>
									</div>
									<div className="a-cura-di">
										{eventInfo?.exhibitors.length > 0 ? (
											<>
												<p>A cura di</p>
												<h5>{eventInfo.exhibitors.map((exhibitor) => exhibitor.name).join(", ")}</h5>
											</>
										) : (
											""
										)}
									</div>
								</div>

								<div style={{ maxWidth: "260px", margin: "15px" }}>
									 <ButtonEvent
										isMobile={isMobile}
										data={eventInfo?.data}
										bookedEvent={eventInfo?.isBookedEvent}
										onPrenotaClick={() => setShowModal(true)}
									/> 
								</div>

								<div
									className="description"
									dangerouslySetInnerHTML={{ __html: eventInfo?.data?.description }}></div>

								{
								bookedSchoolEventAmount >= 2 && scuolaType && 
								showModal === true && popUpContents != null ? (
									<PopUp
										setShowModal={setShowModal}
										loading={popUpLoading}
										headerTitle="Errore Eventi Scuola">
											<div className="container">
										<p>Hai raggiunto il numero massimo di prenotazioni per eventi di tipo Scuola</p>
										</div>
									</PopUp>
								) : showModal === true && popUpContents != null ? (
									<PopUp
										setShowModal={setShowModal}
										loading={popUpLoading}
										headerTitle={popUpContents?.header?.title}>
										<BookingPopUpContents
											data={popUpContents}
											closeModal={setShowModal}
											refreshPopUpContents={refreshPopUpContents}
											loading={popUpLoading}
											setLoading={setPopUpLoading}
											notify={notifyBooking}
										/>
									</PopUp>
								
								) : null}
							</>
						)}
					</div>
				</Main>
			</div>
			<Footer showNewsletter={false} />
		</div>
	);
}

export default SingleEventPage;
