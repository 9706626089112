import React, { useEffect, useState } from "react";
import { useEvents } from "../../context/EventsContext";
// import { ReactComponent as Trash } from '../../img/icon/trash.svg'
import { Link } from "react-router-dom";
import DownloadPDF from "../../img/icon/download_pdf.svg";
import { getYYYYMMDDHH } from "../../utils/functions";
import Tick from "../../img/icon/tick.svg";
import { useAuth } from "../../context/AuthContext";
import { getUrl } from "../../utils/functions";
import { ReactComponent as Loader } from "../../img/loader.svg";

function PrenotazioneSaltoTable({ data, setCompleteSearch }) {
	const { events, fetchEvents } = useEvents();
	const { api } = useAuth();
	const [expandRow, setExpandRow] = useState([]); // an array of rowIds
	const [validateQRCode, setValidateQRCode] = useState({
		status: false,
		dataValidation: null,
		loading: false,
		error: null,
	});

	useEffect(() => {
		fetchEvents(data.map((item) => item.event_id));
	}, [data]);

	const bookingValidation = async (bookingId, participantId) => {
		try {
			setValidateQRCode({ ...validateQRCode, loading: true });
			let dataValidation = await api(getUrl("booking_admin", `access/validate/${bookingId}/${participantId}`));
			setValidateQRCode({
				status: true,
				dataValidation: dataValidation,
				loading: false,
				error: null,
			});
			const index = data.findIndex((item) => item.id === bookingId);
			if (index != null) {
				setCompleteSearch((oldCompleteSearch) => {
					const newCompleteSearch = { ...oldCompleteSearch };
					newCompleteSearch.data[index] = dataValidation;
					return newCompleteSearch;
				});
			}
		} catch (error) {
			setValidateQRCode({
				status: false,
				dataValidation: null,
				loading: false,
				error:
					error?.response?.dataValidation?.detail?.[0]?.msg ||
					error?.response?.dataValidation?.detail ||
					"An error occured by the server!",
			});
		}
	};

	const getExpandInformation = (row) => {
		return (
			<td
				colSpan="11"
				className="expand-information">
				{Object.values(row.participants).map((item) => {
					let accessDateTime = item.access ? new Date(item.access) : "";
					//let accessDateTimeAfterValidationClick = validateQRCode?.data?.participants[item.id]?.access ? new Date(validateQRCode?.data?.participants[item.id]?.access) : ""
					switch (item.type) {
						case "Booker":
						case "Friend":
							return (
								<p>
									{accessDateTime !== "" ? (
										<img
											src={Tick}
											className="img-inside-info-table"
											alt=""
										/>
									) : (
										<button
											className="button-inside-info-table"
											onClick={() => bookingValidation(row.id, item.id)}>
											Valida
										</button>
									)}
									{item.pdfURL !== "" && (
										<a
											href={item.pdfURL}
											download>
											<img
												src={DownloadPDF}
												style={{ width: "30px", height: "30px", marginRight: "10px" }}
												alt=""
											/>
										</a>
									)}
									Nome: <b>{item.nome}</b> Cognome: <b>{item.cognome}</b> Email: <b>{item.email}</b> Accesso:{" "}
									<b>{accessDateTime !== "" ? getYYYYMMDDHH(accessDateTime) : ""}</b>
									{validateQRCode?.loading && (
										<div className="loading fixed">
											<Loader style={{ width: "130px", height: "130px" }} />{" "}
										</div>
									)}
								</p>
							);

						case "Classroom":
							return (
								<p>
									{accessDateTime !== "" ? (
										<img
											src={Tick}
											className="img-inside-info-table"
											alt=""
										/>
									) : (
										<button
											className="button-inside-info-table"
											onClick={() => bookingValidation(row.id, item.id)}>
											Valida
										</button>
									)}
									{item.pdfURL !== "" && (
										<a
											href={item.pdfURL}
											download>
											<img
												src={DownloadPDF}
												style={{ width: "30px", height: "30px", marginRight: "10px" }}
												alt=""
											/>
										</a>
									)}
									Nome della classe: <b>{item.class_name}</b> Nome della scuola: <b>{item.school_name}</b> Numero
									cellulare: <b>{item.phone_number}</b>
									{validateQRCode?.loading && (
										<div className="loading fixed">
											<Loader style={{ width: "130px", height: "130px" }} />{" "}
										</div>
									)}
								</p>
							);
						case "Minor":
							return (
								<p>
									{accessDateTime !== "" ? (
										<img
											src={Tick}
											className="img-inside-info-table"
											alt=""
										/>
									) : (
										<button
											className="button-inside-info-table"
											onClick={() => bookingValidation(row.id, item.id)}>
											Valida
										</button>
									)}
									{item.pdfURL !== "" && (
										<a
											href={item.pdfURL}
											download>
											<img
												src={DownloadPDF}
												style={{ width: "30px", height: "30px", marginRight: "10px" }}
												alt=""
											/>
										</a>
									)}
									Nome: <b>{item.nome}</b> Cognome: <b>{item.cognome}</b> Accesso:{" "}
									<b>{accessDateTime !== "" ? getYYYYMMDDHH(accessDateTime) : ""}</b>
									{validateQRCode?.loading && (
										<div className="loading fixed">
											<Loader style={{ width: "130px", height: "130px" }} />{" "}
										</div>
									)}
								</p>
							);
						default:
							return "";
					}
				})}
			</td>
		);
	};

	let i = 0;
	return (
		<div className="row booked-list prenotazione-color-table mx-md-1">
			<table className="table table-striped ">
				<thead>
					<tr>
						<th></th>
						{/* <th>Id prenotazione</th> */}
						{/* <th className="company-name">Nome</th>
                        <th>Cognome</th>
                        <th>Email</th> */}
						<th>Evento</th>
						<th>Data e ora</th>
						<th className="participants">N° prenotati</th>
						<th>N° accessi</th>
						{/* <th>Scarica pdf</th> */}
						<th></th>
					</tr>
				</thead>
				<tbody>
					{data.map((row) => {
						const isExpanded = expandRow.includes(row.id);
						return (
							<>
								<tr
									className={i++ % 2 === 0 ? "highlighted" : ""}
									key={row.id}>
									<td>
										<span
											className="plus"
											onClick={() => {
												if (isExpanded) {
													setExpandRow(expandRow.filter((rowId) => rowId !== row.id));
												} else {
													setExpandRow([...expandRow, row.id]);
												}
											}}>
											{isExpanded ? "-" : "+"}
										</span>
									</td>
									{/* <td>{row.id}</td> */}
									{/* <td>{item.nome}</td>
                                    <td>{item.cognome}</td>
                                    <td>{item.email}</td> */}
									<td>{events[row.event_id]?.title}</td>
									<td>{events[row.event_id]?.date + " " + events[row.event_id]?.time}</td>
									<td className="number-of-participants">{row.number_of_participants}</td>
									<td>{row.access_number}</td>
									{/* <td>
                                     <a href={item.pdfURL} download>
                                        <img src={DownloadPDF} style={{ width: "40px", height: "40px" }} />
                                    </a> 
                                </td> */}
									<td>
										{/* <span className="delete"><Trash />
                                        </span> */}
										<Link
											to={{
												pathname: `/prenotazione/${row.id}`,
												state: {
													eventId: row.event_id,
													userId: row.user_id,
													isAdmin: true,
													back: true,
												},
											}}
											className="edit">
											<i className="far">&#xf044;</i>
										</Link>
									</td>
								</tr>
								{isExpanded && (
									<tr
										className={(i - 1) % 2 === 0 ? "highlighted expanded" : "expanded"}
										key={row.id + "expanded"}>
										{getExpandInformation(row)}
									</tr>
								)}
							</>
						);
					})}
				</tbody>
			</table>
		</div>
	);
}

export default PrenotazioneSaltoTable;
