import React, { useState } from 'react';
import { useHistory, Link, BrowserRouter, Route } from "react-router-dom";
//import { ReactComponent as Trash } from '../../img/icon/trash.svg';
import PopUpHatched from '../PopUpHatched';
import DeleteParticipantBooking from '../booking_events/DeleteParticipantBooking';
import DeleteBookingContents from '../booking_events/DeleteBookingContents';
import SmallPopUpHatched from '../SmallPopUpHatched';



function ScuoleBooking({ bookedEvent, eventData }) {

	const [eventDesc, setEventDesc] = useState(eventData.description.slice(0, process.env.REACT_APP_CHARACTER_LIMIT));
	const [showDeleteBooking, setShowDeleteBooking] = useState(false);

    const participants = Object.keys(bookedEvent?.participants)
        .map((participantKey) => bookedEvent?.participants[participantKey]);

    return (
        <>
		<div className="ciao-user">
			<div className='greeting-container'>
				<svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				  <path fillRule="evenodd" clipRule="evenodd" d="M9.63656 0.649902C12.5182 0.649902 14.8684 3.00006 14.8684 5.88172C14.8684 8.76338 12.5182 11.1135 9.63656 11.1135C6.7549 11.1135 4.40474 8.76338 4.40474 5.88172C4.40474 3.00006 6.7549 0.649902 9.63656 0.649902ZM9.63656 11.0135C14.4209 11.0135 18.3229 14.9155 18.2729 19.7499L16.4957 19.6999C16.4957 15.9019 13.4346 12.8408 9.63656 12.8408C5.83853 12.8408 2.77747 15.9019 2.72747 19.7499L0.950195 19.6999C0.950195 14.9155 4.8522 11.0135 9.63656 11.0135ZM9.63656 2.47718C7.74798 2.47718 6.23201 3.99314 6.23201 5.88172C6.23201 7.7703 7.74798 9.28627 9.63656 9.28627C11.5251 9.28627 13.0411 7.7703 13.0411 5.88172C13.0411 3.99314 11.5251 2.47718 9.63656 2.47718Z" fill="black" fillOpacity="0.3" />
				</svg>
				<p>
					L'evento è stato prenotato per {bookedEvent.number_of_participants} partecipanti {`( Scuola ${participants[0].school_name} Classe ${participants[0].class_name} )`}
				</p>
			</div>
			<div className="event-description">
				<p dangerouslySetInnerHTML={{ __html : eventDesc + (eventDesc.length > process.env.REACT_APP_CHARACTER_LIMIT ? "" : "<span style='color: #007bff; cursor: pointer'>&nbsp;[continua a leggere]</span>")}} onClick = {() => {
					setEventDesc(eventData.description);
				}}></p>
			</div>
			<div className="actions-container-booking-details">
				<a className='download-pdf' href={(() => {
					for (const participant in bookedEvent.participants) {
						if (bookedEvent.participants[participant].type === "Classroom") {
							return bookedEvent.participants[participant].pdfURL;
						}
					}
				})()}>Scarica il pdf della prenotazione</a>
			</div>
			<p className='delete-booking-text' style={{"marginTop" : "24px", "cursor" : "pointer"}} onClick={() => {
				setShowDeleteBooking(true);
			}}>Elimina prenotazione</p>
		</div>

			{showDeleteBooking && (
				<SmallPopUpHatched setShowModal={setShowDeleteBooking}>
					<DeleteBookingContents
					id={showDeleteBooking}
					setShowModal={setShowDeleteBooking}
					data={bookedEvent}
					refreshBooking = {() => {
						window.location = "/prenotazioni"
					}}
            		></DeleteBookingContents>
				</SmallPopUpHatched>
			)}
        </>
    )
}

export default ScuoleBooking
