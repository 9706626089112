import React, { useRef, useEffect } from "react";
import "../css/popup.css";


const CustomPopUp = ({ setShowModal, children, customModalStyle , customBoxStyle, customBodyStyle }) => {
	const modalRef = useRef(null);
	const clickOutref = useRef(null);
	const closeModal = () => {
		modalRef.current.classList.add("fadeOut");
		setTimeout(() => {
			setShowModal(false);
		}, 400)
	}

	useEffect(() => {
		const handler = (event) => {
			if (clickOutref.current != null && !clickOutref.current.contains(event.target) && event.target.className === "custom-modal") {
				closeModal();
			}
		}
		document.addEventListener("click", handler);
		return () => {
			document.removeEventListener("click", handler);
		}
	}, [clickOutref.current]);

	const modalStyle = {...{
		"width": "100%",
		"height": "100%",
		"backgroundColor": "rgba(0, 0, 0, .4)",
		"position": "fixed",
		"zIndex": "100",
		"top": 0,
		"left": 0,
		"display": "flex",
		"justifyContent": "center",
		"alignItems": "center",
		"animation": "fadeIn .4s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards"
	}, ...customModalStyle}

	const modalBoxStyle = {...{
		"backgroundColor": "white",
		"minWidth": "50%",
		"maxWidth": "500px",
		"color": "black",
		"borderRadius": "5px",
		"overflow": "hidden",
		"maxHeight": "90vh",
		"overflowY": "auto",
		"animation": "scaleUp .7s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards"
	}, ...customBoxStyle}

	const modalBodyStyle = {...{
		"boxSizing": "border-box",
    	"padding": "15px 10px",
    	"minHeight": "155px"
	},...customBodyStyle}

	return (
		<div ref={modalRef} style={modalStyle} className="custom-modal">
			<div ref={clickOutref} style={modalBoxStyle} className="custom-modal-box">
				<div className="custom-modal-body" style={modalBodyStyle}>
					{children}
				</div>
			</div>
		</div>
	)
}

export default CustomPopUp;
