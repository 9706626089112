import React from "react";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import Sidebar from "./components/layout/Sidebar";
import Main from "./components/layout/Main";
import CertificatePageContent from "./CertificatePageContent"
import salone from "./utils/salone";
import Cookies from "js-cookie";

class CertificatePage extends React.Component {
  constructor(props) {
    super(props);
    this.attestatoDownloadsFetching = React.createRef(false);
    this.attestatoDownloadsFetching.current = false;
    this.state = {
      title: "",
      items: [],
      firstname: "",
      attestatoDownloads: [],
    };
  }



  async componentDidMount() {
    try {
      let response = await salone.get(`/user/info`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      });
      this.setState({
        firstname: response?.data?.first_name,
      });
    } catch (error) {
      this.props.history.push("/login");
    };
    this.fetchAttestati();
  }

  fetchAttestati = async () => {
    let url = "/attestati/all";
    if (this.props.match.params.data !== undefined) {
      url += `?tag=${this.props.match.params.data}`;
    }
    this.attestatoDownloadsFetching.current = false;
    try {
      let response = await salone.get(url, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      });
      this.setState({
        items: response?.data,
      });

    } catch (error) {
      this.setState({
        title:
          <div>
            <h1>{error.response?.data.msg}</h1>
          </div>
      })
    }
  }

  fetchAttestatoDownload = async () => {
    try {

      let response = await salone.get(`/attestatiformazione/all?tag=${this.props.match.params.data}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      });
      this.setState({
        attestatoDownloads: response.data,
      });
    } catch (error) {
      this.setState({
        attestatoDownloads: []
      });
    }
  }

  markAsDownloadedAttestato = async (id) => {
    try {
      await salone.get(`/attestatiformazione/markasdownloaded?attestatoFormazioneId=${id}&tag=${this.props.match.params.data}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      });
    } catch (error) {
      console.error("mark as downloaded request error:", error.response?.data);
    }
  }

  getAttestatoDiFormazioneDownloadButton = () => {
    if (this.attestatoDownloadsFetching.current === false) {
      this.attestatoDownloadsFetching.current = true;
      this.fetchAttestatoDownload();
    }

    return this.state.attestatoDownloads.map((attestatoDownload) => {
      return <div className="col-4" key={attestatoDownload.id}>
        <a
          download
          target="_blank"
          rel="noreferrer"
          href={attestatoDownload["pdf_url"]}
          onClick={() => {
            this.markAsDownloadedAttestato(attestatoDownload.id);
          }}>
          <button className="button blue-btn w-100 bookingpage-button">Scarica attestato di formazione</button>
        </a>
      </div>;
    });
  }

  render() {

    return (
      <div>
        <Header />
        <div className="row w-100">
          <Sidebar>
            <h3 style={{ textTransform: "uppercase" }}>Ciao <span style={{ color: "#1488F3" }}>{this.state.firstname}</span>,<br />Benvenuta/o su SalTo+</h3>
            

          </Sidebar>
          <Main>

            <div className="container" style={{ display: "flex", flexDirection: "column" }}><h1 className="h0" style={{ color: "#1488f3" }}>Attestati di partecipazione</h1>
              <p>In questa pagina hai la possibilità di scaricare gli attestati per gli appuntamenti ai quali ti sei prenotato.</p>

              <hr></hr>

              {this.state.items.length > 0 ? this.state.items.map(item => <CertificatePageContent
                key={item.id}
                data={item}
                fetchAttestati={this.fetchAttestati}
              />) : (<div><p>Attualmente non ci sono attestati disponibili</p></div>)}

              <hr></hr>
              <br></br>
              {this.state.items.length >= 5 ? (

                <div>
                  <h1 className="h0" style={{ color: "#1488f3" }}>Attestato di formazione</h1>
                  <hr />
                  <p style={{ textAlign: "justify" }}>Il pulsante per effettuare il download dell’attestato di formazione si attiverà dopo aver scaricato almeno 5 attestati di partecipazione.
                    Il sistema calcolerà automaticamente il tuo monte ore. Nel caso tu abbia partecipato a cinque incontri ti verranno riconosciute dieci ore.
                    Nel caso in cui tu abbia partecipato a tutti i 6 incontri, assicurati di aver inserito tutte le 6 password prima di effettuare il download dell’attestato di formazione.
                    In questo caso ti verranno riconosciute dodici ore di formazione.<br></br>Ti invieremo una mail non appena sarà disponibile.</p>
                  {this.getAttestatoDiFormazioneDownloadButton()}
                </div>

              ) : null}</div>
          </Main>
        </div>

        <Footer />
      </div>
    );
  }
}
export default CertificatePage;

