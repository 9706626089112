import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import Footer from '../../../components/layout/Footer'
import Header from '../../../components/layout/Header'
import Main from '../../../components/layout/Main'
import Sidebar from '../../../components/layout/Sidebar'
import { useAuth } from '../../../context/AuthContext'
import { useUser } from '../../../context/UserContext'
import { getUrl } from '../../../utils/functions'
import { ReactComponent as Error } from '../../../img/icon/error.svg'
import '../../../css/bookingeventvalidation.css'
import MinoriValidation from '../../../components/validation_categories/MinoriValidation'
import SideBarContents from '../../../components/layout/SideBarContents'


function BookingValidationByBackOffice() {
    const [_, __, userInfo] = useUser()
    const { booking_id, participant_id } = useParams()
    const { api } = useAuth()

    const [validateQRCode, setValidateQRCode] = useState({
        status: false,
        data: null,
        loading: false,
        error: null
    })

    const fetchValidateQRCode = async () => {
        
        try {
            setValidateQRCode({ ...validateQRCode, loading: true })
            let data = await api(getUrl("booking_admin", `access/validate_backoffice/${booking_id}/${participant_id}`))
            setValidateQRCode({
                status: true,
                data: data,
                loading: false,
                error: null
            })
        } catch (error) {
            setValidateQRCode({
                status: false,
                data: null,
                loading: false,
                error: error?.response?.data?.detail?.[0]?.msg || error?.response?.data?.detail || "An error occured by the server!"
            })
        }
    }

    useEffect(() => {
        fetchValidateQRCode()
    }, [booking_id, participant_id])
 

    return (
        <div>
            <Header />
            <div className="row w-100" style={{ minHeight: "68vh" }}>
                <Sidebar>
                    
                    
                    {/* <p>In questo spazio trovi il meglio della produzione culturale curata dal Salone: video, interviste, lezioni, podcast, approfondimenti. Una grande libreria a tua disposizione: scegli un contenuto e inizia il viaggio.</p>
                    <br />
                    <br /> */}
                    <SideBarContents />
                </Sidebar>
                <Main>
                    {validateQRCode?.error != null && (
                        <div className="container-error-message">
                            <Error />
                            <h3 className="text-danger">{validateQRCode?.error}</h3>
                        </div>

                    )}
                    {validateQRCode?.loading === false && validateQRCode?.data != null &&
                        <MinoriValidation data={validateQRCode?.data}/>
                    }                    
                </Main>
            </div>
            <Footer />
        </div>
    )
}

export default BookingValidationByBackOffice
