import React, { useState, useRef, useEffect } from 'react'
import TextField from '../inputs/TextField'
import { ReactComponent as SearchIcon } from '../../img/icon/search_icon.svg'
import PopUpHatched from '../PopUpHatched'
import Pagination from '../Pagination.js'
import '../../css/searchfieldprenotazionesalto.css'
import { ReactComponent as Loader } from '../../img/loader.svg'


function SearchFieldPrenotazioneSalto({ searchResults, feedback, onClick, name, onChange, required = true, value, placeholder = "", type = "text", label, className = "col", disabled = false, autoComplete = "", onFocus, onBlur, suffix, prefix, searchUsershByFilter }) {

    const [searchQuery, setSearchQuery] = useState("")
    const [showModal, setShowModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [hasNextForUser, setHasNextForUser] = useState(null)
    const searchThisQueryRef = useRef()

    const handleOnChange = (event) => {
        setSearchQuery(event.target.value)
    }

    const fetchResults = (page) => {
        if (data?.query === searchQuery && data?.[page] != null) {
            setCurrentPage(page)
            return
        }
        searchResults(searchQuery, (result, isLastPage) => {
            if (data?.query !== searchQuery) {
                setData({
                    query: searchQuery,
                    1: result
                })
                setCurrentPage(1)
            } else {
                if (isLastPage && result.length === 0) {
                    return
                }
                setData({ ...data, [page]: result })
                setCurrentPage(page)
            }
        }, setLoading, page, setHasNextForUser)
    }

    const handleOnSearchIcon = () => {
        if (disabled) {
            return
        }
        if (searchQuery.length === 0) {
            return
        }
        setShowModal(true)
        fetchResults(1)
    }

    const selectItem = (item) => {
        onChange(item)
        setSearchQuery("")
        setCurrentPage(1)
        setShowModal(false)
        setData(null)
        setHasNextForUser(null)
        if (searchUsershByFilter) {
            searchUsershByFilter(typeof item === 'string' ? item : item.id)
        }
    }

    useEffect(() => {
        const handler = (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (e.keyCode === 13) {
                handleOnSearchIcon();
            }
        };
        searchThisQueryRef.current.querySelectorAll("input").forEach((item) => {
            item.addEventListener("keyup", handler)
        });
        return () => {
            if (searchThisQueryRef.current != null && searchThisQueryRef.current.querySelectorAll("input").length > 0) {
                searchThisQueryRef.current.querySelectorAll("input").forEach((item) => {
                    item.removeEventListener("keyup", handler)
                })
            }

        }
    }, [searchQuery])

    const resultSearchItems = (name) => {
        if(name === "search_user") return resultUserItems();
        else if(name === "search_event") return resultEventItems();
        else return resultEventTypes();
    }

    const resultEventTypes = () => {
        return <div className="row event-list">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Tipo Evento</th>
                    </tr>
                </thead>
                <tbody>
                    {
                    data?.[currentPage].data.map((item) => {
                        return <tr key={item.type} onClick={() => selectItem(item.type)}>
                            <td>{item.type}</td>
                        </tr>
                    })}

                </tbody>
            </table>
            
        </div>
    }

    
    const resultEventItems = () => {
        return <div className="row event-list">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Evento</th>
                        <th>Data</th>
                        <th>Ora</th>
                        <th>ID evento</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.[currentPage].data.map((item) => {
                        return <tr key={item.id} onClick={() => selectItem(item)}>
                            <td>{item.title}</td>
                            <td>{item.date}</td>
                            <td>{item.time}</td>
                            <td>{item.id}</td>
                        </tr>
                    })}

                </tbody>
            </table>
            {data?.[currentPage] != null && (
                <div className="pagination-salto-prenotazione">
                    <Pagination hasPrevious={data[currentPage].links?.prev != null} hasNext={data[currentPage].links?.next != null}
                        currentPage={currentPage}
                        onClick={(page) => {
                            if (page === "next") {
                                fetchResults(currentPage + 1)
                            } else {
                                fetchResults(currentPage - 1)
                            }
                        }} />
                </div>
            )}
        </div>
    }

    const resultUserItems = () => {
        return <div className="row event-list">
            <table className="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Cognome</th>
                        <th>E-mail</th>
                        {/* <th>ID utente</th> */}
                    </tr>
                </thead>
                <tbody>
                    {data?.[currentPage].map((item) => {
                        return <tr key={item.id} onClick={() => selectItem(item)}>
                            <td>{item.first_name}</td>
                            <td>{item.last_name}</td>
                            <td>{item.email}</td>
                            {/* <td>{item.id}</td> */}
                        </tr>
                    })}

                </tbody>
            </table>
            {data?.[currentPage] != null && (
                <div className="pagination-salto-prenotazione">
                    <Pagination hasPrevious={data[currentPage - 1] != null} hasNext={hasNextForUser == null || (hasNextForUser && data[currentPage + 1] != null)}
                        currentPage={currentPage}
                        onClick={(page) => {
                            if (page === "next") {
                                fetchResults(currentPage + 1)
                            } else {
                                fetchResults(currentPage - 1)
                            }
                        }} />
                </div>
            )}
        </div>
    }

    return (
        <div className={className}>
            {showModal === true && (<PopUpHatched setShowModal={(status) => {
                if (status === false) {
                    setData(null)
                    setHasNextForUser(null)
                    setShowModal(false)
                }
            }}>
                {loading ? <div className="loading fixed"><Loader style={{ width: "130px", height: "130px" }} /> </div> : (
                    data?.[currentPage] ? resultSearchItems(name) : <p>No result</p>
                )}
            </PopUpHatched>)}
            <div className="row mt-3" ref={searchThisQueryRef}>
                <TextField
                    className="col"
                    name={name}
                    value={searchQuery || value}
                    onChange={handleOnChange}
                    type={type}
                    required={required}
                    disabled={disabled}
                    placeholder={placeholder}
                    prefix={prefix}
                    onClick={onClick}
                    feedback={feedback}
                    suffix={<div
                        className="search-icon"
                        onClick={handleOnSearchIcon}
                    >
                        <SearchIcon />
                    </div>} />
            </div>
        </div>
    )
}

export default SearchFieldPrenotazioneSalto
