import React, { useState, useEffect } from 'react';
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import TextField from '../../components/inputs/TextField';
import SelectInput from '../../components/inputs/SelectInput';
import "../../css/users.css";
import UtentiTable from '../../components/utenti/UtentiTable';
import Pagination from '../../components/Pagination';
import { getAllCountries, getCitiesOfState, getStatesOfCountry } from '../../utils/data';
import { useAuth } from '../../context/AuthContext';
import { getUrl } from '../../utils/functions';
import SearchFieldPrenotazioneSalto from '../../components/prenotazione_salto/SearchFieldPrenotazioneSalto';
import { useRef } from 'react';
import PopUp from '../../components/PopUp';
import AddNewUserToSalto from '../../components/prenotazione_salto/AddNewUserToSalto'
import { ReactComponent as Loader } from "../../img/loader.svg";
import { useValuesFiltered } from '../../context/ValuesFilteredContext';
import AdminNavbar from '../../components/layout/AdminNavbar';
import { ReactComponent as CleanFields } from '../../img/icon/filter-remove.svg';



const fetchProfession = async (api, setGroupProfessions, profession, setValues) => {
    try {
        let data = await api(getUrl("user", "userprofile/professions"));
        let ambitos = {}
        data.forEach((profession) => {
            if (ambitos[profession.group] == null) {
                ambitos[profession.group] = profession.group
            }
        })
        ambitos = Object.keys(ambitos)
        setGroupProfessions({
            professions: data,
            ambitos: ambitos.map((ambito) => ({ id: ambito, name: ambito }))
        })

        if (profession) {
            setValues(oldValues => ({ ...oldValues, ambito: profession.group, profession: profession.id }))
        }

    } catch (error) {
        setGroupProfessions({
            professions: [],
            ambitos: []
        })
    }
}

function Users() {
    const { api } = useAuth()
    const [valuesFiltered, setValuesFiltered] = useValuesFiltered()
    const [values, setValues] = useState(valuesFiltered)
    const [groupProfessions, setGroupProfessions] = useState({
        professions: [],
        ambitos: []
    })
    const [filteredUsers, setFilteredUsers] = useState({
        status: false,
        data: null,
        recordsNumber: null,
        page: 1,
        error: null
    })
    const [loading, setLoading] = useState(false)
    const [hasNextPage, setHasNextPage] = useState(false)
    const [bodyForExportTable, setBodyForExportTable] = useState(null)
    const [showModaleToAddNewUser, setShowModaleToAddNewUser] = useState(false)
    const [valuePerPage, setValuePerPage] = useState(null)
    const containerRef = useRef()
    const valuesLengthRef = useRef(Object.keys(values).length)


    const fetchFilteredUsers = async (page = 1, id) => {
        const limit = valuePerPage?.limit ? valuePerPage.limit : 5
        let body = {
            limit: limit,
            offset: (page - 1) * limit
        }
        if (id != null) {
            body.sso_id = id
        }
        if (values?.user != null) {
            body.sso_id = values.user.id
        } else {
            Object.keys(values).filter(key => key !== "user").forEach((key) => {
                if (values[key] != null && values[key] !== "") {
                    if (["country", "state", "city"].includes(key)) {
                        const csc = containerRef.current.querySelector(`select[name='${key}'] option[value='${values[key]}']`);
                        if (csc) {
                            body[key] = csc.innerText;
                            return
                        }
                    }
                    body[key] = values[key]
                }
            })
        }
        setBodyForExportTable(body)

        try {
            setLoading(true)
            let data = await api(getUrl("user_admin", "backoffice/"), "post", body)

            setLoading(false)
            if (data?.data?.length === 0 && page > 1) {
                setHasNextPage(false)
                return;
            }
            setFilteredUsers({
                status: true,
                data: data?.data,
                recordsNumber: data?.n_record,
                page: page,
                error: null
            })
            if (data?.data?.length < limit) {
                setHasNextPage(false)
            } else {
                setHasNextPage(true)
            }
        } catch (error) {
            setFilteredUsers({
                status: false,
                data: null,
                recordsNumber: null,
                page: 1,
                error: error?.response?.data?.detali?.[0]?.msg ?? "An error occured by the server"
            })
            setHasNextPage(false)
            setLoading(false)
        }
    }

    const searchUsers = async (value, setData, setLoading, page = 1, setHasNextPage) => {
        if (value.length > 2) {
            const limit = 8;//per page
            try {
                setLoading(true);
                let data = await api(getUrl("booking_admin", `backofficesaltopiubookings/user?search=${value}&skip=${((page - 1) * limit)}&limit=${limit}`));
                if (data?.length < limit) {
                    //alert(data?.length)
                    setHasNextPage((data.length === 0 && page > 1) ? page - 1 : page)
                }
                setData(data, data?.length < limit ? true : false)
            } catch (error) {
                setData(null)
            }
            setLoading(false)
        }
    }

    const handleOnChange = (event) => {
        setValues(oldValues => ({ ...oldValues, [event.target.name]: event.target.value }))
        setValuesFiltered(oldValues => ({ ...oldValues, [event.target.name]: event.target.value }))
    }
    useEffect(() => {
        const newValueLength = Object.keys(values).length
        if (newValueLength === 0 && valuesLengthRef.current > 0) {
            fetchFilteredUsers()
        }
        valuesLengthRef.current = newValueLength
    }, [values])

    useEffect(() => {
        fetchProfession(api, setGroupProfessions, values?.profession?.[0], setValues);
        fetchFilteredUsers()
    }, [valuePerPage])

    const searchUsershByFilter = (id) => {
        fetchFilteredUsers(1, id)
    }

    //const isSearchFieldDisabled = Object.keys(values).filter(key => key !== "user" && values[key] != null).length > 0

    return <div>
        <Header />
        <div className='admin-pages-main'>
            <AdminNavbar back="/admin_dashboard" title="Gestione Utenti" />
            <div className="w-100">
                {loading && <div className="loading fixed" >
                    <Loader />
                </div>}
                <div className="users-admin-page" ref={containerRef}>
                    <div className="search-users">
                        <div className="row">
                            <SearchFieldPrenotazioneSalto
                                className='col-5'
                                name="search_user"
                                onChange={(item) => {
                                    setValues({ ...values, user: item })
                                    setValuesFiltered({ ...values, user: item })
                                }}
                                onClick={
                                    () => {
                                        if (values?.user?.id != null) {
                                            setValues({ ...values, user: undefined })
                                            setValuesFiltered({})
                                            return
                                        }
                                    }
                                }
                                type='text'
                                required={false}
                                //disabled={isSearchFieldDisabled}
                                placeholder='Cerca utente per cognome o email'
                                searchResults={searchUsers}
                                searchUsershByFilter={(id) => searchUsershByFilter(id)}
                                feedback={`${values?.user ? "feedback" : ""}`}
                                value={values?.user ? values?.user?.first_name + " " + values?.user?.last_name + ", " + values?.user?.email : ""}

                            />
                        </div>
                        {/* <div className="row">
                            <TextField
                                label="Nome"
                                className="col-md-4"
                                feedback={`${values?.user?.first_name ? "feedback" : ""}`}
                                name="name"
                                value={values?.user?.first_name}
                                onChange={handleOnChange}
                                type="text"
                                required={false}
                                disabled={true}
                                prefix={values.user?.id && <div
                                    className="delete-values"
                                    onClick={() => {
                                        setValues({ ...values, user: undefined })
                                        setValuesFiltered({})
                                    }}
                                >
                                    -
                                </div>}
                            />
                            <TextField
                                label="Cognome"
                                className="col-md-4"
                                name="last_name"
                                value={values.user?.last_name}
                                feedback={`${values?.user?.last_name ? "feedback" : ""}`}
                                onChange={handleOnChange}
                                type="text"
                                required={false}
                                disabled={true}
                            />
                            <TextField
                                label="Email"
                                className="col-md-4"
                                name="email"
                                value={values.user?.email}
                                feedback={`${values?.user?.email ? "feedback" : ""}`}
                                onChange={handleOnChange}
                                type="text"
                                required={false}
                                disabled={true}
                            />
                        </div> */}
                    </div>
                    <div className="row">
                        <SelectInput
                            className="col-md-4"
                            name="country"
                            label="Stato"
                            required={false}
                            feedback={`${values?.country ? "feedback" : ""}`}
                            options={getAllCountries()}
                            emptyOption={true}
                            // placeholder="Inserisci stato..."
                            disabled={values?.user?.id}
                            onChange={(event) => {
                                handleOnChange(event)
                                setValues(oldValues => ({ ...oldValues, "state": undefined }))
                            }}
                            value={values?.country}
                        />
                        <SelectInput
                            className="col-md-4"
                            name="state"
                            label="Regione"
                            required={false}
                            // placeholder="Inserisci regione..."
                            feedback={`${values?.state ? "feedback" : ""}`}
                            options={getStatesOfCountry(values?.country)}
                            emptyOption={true}
                            disabled={values?.country == null || values?.country === ""}
                            value={values?.state}
                            onChange={(event) => {
                                handleOnChange(event)
                                setValues(oldValues => ({ ...oldValues, "city": undefined }))
                            }}
                        />
                        <SelectInput
                            className="col-md-4"
                            name="city"
                            label="Città"
                            required={false}
                            type="text"
                            // placeholder="Inserisci città..."
                            feedback={`${values?.city ? "feedback" : ""}`}
                            options={getCitiesOfState(values?.state)}
                            emptyOption={true}
                            disabled={values?.state == null || values?.state === ""}
                            value={values?.city}
                            onChange={handleOnChange}
                        />

                    </div>
                    <div className="row">
                        <SelectInput
                            className="col-md-4"
                            name="profession_group"
                            label="Ambito"
                            required={false}
                            feedback={`${values?.profession_group ? "feedback" : ""}`}
                            // placeholder="Inserisci ambito..."
                            disabled={values?.user?.id}
                            options={groupProfessions.ambitos}
                            emptyOption={true}
                            value={values?.profession_group}
                            onChange={(event) => {
                                handleOnChange(event)
                                setValues(oldValues => ({ ...oldValues, "profession": undefined }))
                            }}
                        />
                        <SelectInput
                            className="col-md-4"
                            label="Professione"
                            name="profession"
                            required={false}
                            feedback={`${values?.profession ? "feedback" : ""}`}
                            disabled={!(values?.profession_group) || (values?.user?.id)}
                            // placeholder="Inserisci professione..."
                            options={groupProfessions?.professions?.filter((profession) => profession.group === values?.profession_group).map((profession) => ({
                                name: profession.description, id: profession.description
                            }))}
                            emptyOption={true}
                            value={values?.profession}
                            onChange={handleOnChange}
                        />
                        <SelectInput
                            className="col-md-4"
                            name="client_roles"
                            label="Status"
                            required={false}
                            feedback={`${values?.client_roles ? "feedback" : ""}`}
                            options={[
                                { id: "Basic", name: "Basic" },
                                { id: "Completo", name: "Completo" },
                                { id: "Esteso", name: "Esteso" },
                                { id: "Accreditato", name: "Accreditato" }
                            ]}
                            emptyOption={true}
                            disabled={values?.user?.id}
                            onChange={handleOnChange}
                            value={values?.client_roles}
                        />
                    </div>
                    <div className="row">
                        <TextField
                            className="col-md-3"
                            name="created_at_from"
                            label="Iscritti dal"
                            required={false}
                            feedback={`${values?.created_at_from ? "feedback" : ""}`}
                            disabled={values?.user?.id}
                            type="date"
                            onChange={handleOnChange}
                            value={values?.created_at_from}
                        />
                        <TextField
                            className="col-md-3"
                            name="created_at_to"
                            label="Iscritti al"
                            required={false}
                            feedback={`${values?.created_at_to ? "feedback" : ""}`}
                            disabled={values?.user?.id}
                            type="date"
                            onChange={handleOnChange}
                            value={values?.created_at_to}
                        />
                        <TextField
                            className="col-md-3"
                            name="age_from"
                            label="Età da"
                            required={false}
                            feedback={`${values?.age_from ? "feedback" : ""}`}
                            disabled={values?.user?.id}
                            type="text"
                            onChange={handleOnChange}
                            value={values?.age_from}
                        />
                        <TextField
                            className="col-md-3"
                            name="age_to"
                            label="Età a"
                            required={false}
                            feedback={`${values?.age_to ? "feedback" : ""}`}
                            disabled={values?.user?.id}
                            type="text"
                            onChange={handleOnChange}
                            value={values?.age_to}
                        />
                    </div>
                    <div className="row">
                        <SelectInput
                            className="col-md-3"
                            name="consent_send_cultural_newsletter"
                            label="Newsletter culturale"
                            required={false}
                            feedback={`${values?.consent_send_cultural_newsletter ? "feedback" : ""}`}
                            // placeholder="Scegli..."
                            disabled={values?.user?.id}
                            options={[
                                { id: 1, name: "Si" },
                                { id: 0, name: "No" }
                            ]}
                            emptyOption={true}
                            onChange={handleOnChange}
                            value={values?.consent_send_cultural_newsletter}
                        />
                        <SelectInput
                            className="col-md-3"
                            name="consent_send_commercial_newsletter"
                            label="Newsletter commerciale"
                            required={false}
                            feedback={`${values?.consent_send_commercial_newsletter ? "feedback" : ""}`}
                            // placeholder="Scegli..."
                            disabled={values?.user?.id}
                            options={[
                                { id: 1, name: "Si" },
                                { id: 0, name: "No" }
                            ]}
                            emptyOption={true}
                            onChange={handleOnChange}
                            value={values?.consent_send_commercial_newsletter}
                        />
                        <div className="col-md-1">
                            <div className="remove-field-values">
                                <CleanFields
                                    onClick={() => {
                                        setValuesFiltered({})
                                        setValues({})
                                    }}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Svuota i campi"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3 align-items-center">
                        <div className="col-auto">
                            <button className="search-btn-gestione-utenti"
                                onClick={() => setShowModaleToAddNewUser(true)}>
                                Crea Nuovo utente
                            </button>
                        </div>
                        <div className="col-md-4">
                            <button className="search-btn-users"
                                onClick={() => searchUsershByFilter()}
                            >
                                Cerca
                            </button>
                        </div>
                    </div>
                </div>
                {showModaleToAddNewUser === true && (<PopUp
                    setShowModal={setShowModaleToAddNewUser}
                    headerTitle={"Aggiungi nuovo utente"}
                >
                    <AddNewUserToSalto
                        setShowModal={setShowModaleToAddNewUser}
                    />
                </PopUp>

                )}

                {filteredUsers?.data != null && <UtentiTable
                    data={filteredUsers?.data}
                    recordsNumber={filteredUsers?.recordsNumber}
                    bodyForExportTable={bodyForExportTable}
                    searchUsershByFilter={searchUsershByFilter}
                    valuePerPage={valuePerPage}
                    setValuePerPage={setValuePerPage}
                    page={filteredUsers?.page}
                />}
                {filteredUsers?.data != null && (
                    <Pagination hasPrevious={filteredUsers?.page > 1}
                        hasNext={hasNextPage}
                        currentPage={filteredUsers?.page}
                        onClick={(pageStatus) => {
                            fetchFilteredUsers(pageStatus === "prev" ? filteredUsers?.page - 1 : filteredUsers?.page + 1)
                        }} />
                )}
            </div>
        </div>
        <Footer />
    </div>;
}

export default Users;
