import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import TextField from '../inputs/TextField'
import { getUrl } from '../../utils/functions'
import { useAuth } from '../../context/AuthContext'
import "../../css/searchquerybyprogramma.css"

let timeOut;
function SearchQueryByProgrammaFC({ fetchEvents, name, onChange, required = true, value = "", placeholder = "", type = "text", label, className = "col", disabled = false, feedback }, ref) {
    const { api } = useAuth()
    const searchRef = useRef()
    const resultRef = useRef()
    const [availableEvents, setAvailableEvents] = useState({
        status: false,
        data: null,
        loading: false,
        error: null
    })
    const [searchQuery, setSearchQuery] = useState("")
    const [autoRcommendedItem, setAutoRecommendedItem] = useState(false)
    const [isActive, setIsActive] = useState(false)
    useImperativeHandle(ref, () => {
        return {
            clear: () => {
                setSearchQuery("")
            }
        }
    }, [])
    const handleOnChange = (event) => {
        setSearchQuery(event.target.value);
        if (timeOut !== undefined) {
            clearTimeout(timeOut)
        }
        const value = event.target.value
        timeOut = setTimeout(() => { searchEvents(value) }, 500)
    }

    const searchEvents = async (value) => {
        if (value.length > 2) {
            try {
                setAvailableEvents((oldState) => ({ ...oldState, loading: true }));
                let data = await api(getUrl("programma", `api/v1/bookable-program-items?filter[search]=${value}&page[number]=1&page[size]=10`));
                setAvailableEvents({
                    status: true,
                    data: data?.data,
                    loading: false,
                    error: null
                });
                setAutoRecommendedItem(true)
            } catch (error) {
                setAvailableEvents({
                    status: false,
                    data: null,
                    loading: false,
                    error: "An error occured by the server"
                });
                setAutoRecommendedItem(false)
            }
        }
    }

    useEffect(() => {
        const handler = (e) => {
            let i = 0;
            let element = e.target
            let isInsideLiveSearch = false
            while (element != null && i < 4) {
                if (element.classList.contains("live-search") || element.classList.contains("live-search-container")) {
                    isInsideLiveSearch = true
                    break
                }
                i++;
                element = element.parentElement
            }
            if (isInsideLiveSearch === false) {
                setIsActive(false)
                setSearchQuery("")
                onChange({
                    target: {
                        name: name,
                        value: ""
                    }
                })
                setAutoRecommendedItem(false)
            }

        };
        if (isActive === true) {
            document.addEventListener("click", handler)
        }

        return () => {
            document.removeEventListener("click", handler)
        }
    }, [isActive])

    const handleOnEventTitle = (event) => {
        const EventId = event.target.getAttribute("data-id")
        onChange({
            target: {
                name: name,
                value: EventId
            }
        })
        setIsActive(false)
        setSearchQuery(event.target.innerText)
        setAutoRecommendedItem(false)
    }

    useEffect(() => {
        if (value !== "" && searchQuery === "" && fetchEvents) {
            const handler = async () => {
                const [event] = await fetchEvents([value])
                if (event != null) {
                    setSearchQuery(event.title)
                }
            }
            handler()
        }
    }, [value])

    return (
        <div className={` live-search-container ${className}`}
            ref={searchRef} >
            <div className="row">
                <TextField
                    onFocus={() => {
                        setIsActive(true)
                        setSearchQuery("")
                        onChange({
                            target: {
                                name: name,
                                value: value
                            }
                        })
                    }}
                    feedback={feedback}
                    autoComplete={"off"}
                    label={label}
                    className="col"
                    name={name}
                    value={searchQuery}
                    onChange={handleOnChange}
                    type={type}
                    required={required} />
            </div>
            {autoRcommendedItem && Array.isArray(availableEvents?.data) && <div
                className="live-search"
                ref={resultRef} >

                {availableEvents?.data.map((event) => {
                    return <div className={value === event.id ? "selected" : ""} key={event.id} data-id={event.id} onClick={handleOnEventTitle}>{event.title}</div>
                })}
            </div>}
        </div>
    )
}

const SearchQueryByProgramma = forwardRef(SearchQueryByProgrammaFC)
export default SearchQueryByProgramma
