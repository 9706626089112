import React, {forwardRef} from 'react'
const SelectInput = forwardRef(({ options, emptyOption, name, onChange, required = true, value = "", placeholder = "", label, className = "col" , disabled=false, feedback}, ref) => {
    return (
        <div className={className}>
            <p className="mt-2 mb-2 p-14">{label}{required && "*"}</p>
            <div className={`select-container ${feedback ? "form-feedback" : ""}`}>
                <select
                    ref={ref}
                    className="w-100"
                    name={name}
                    placeholder={placeholder}
                    required={required ? "required" : ""}
                    onChange={onChange}
                    value={value}
                    disabled={disabled ? "disabled" : ""}
                >
                    <option value="" disabled={!(emptyOption)}>
                        {placeholder}
                  </option>
                    {options?.map(option => <option key={option.id} data-name={option.name} value={option.id}>{option.name}</option>)}
                </select>
                <svg width="15" height="22" viewBox="0 0 15 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.5 8.5L7.5 13.5L2.5 8.5" stroke="black" strokeOpacity="0.3" strokeWidth="1.5" />
                </svg>
            </div>
            
        </div>
    )
});

export default SelectInput
