import React from 'react'
import FooterMobile from "./FooterMobile";
import FooterDesktop from "./FooterDesktop";


function Footer({showNewsletter}) {
    return (
        <div className='footer-wrapper'>
            <div className="footer-desktop">
                <FooterDesktop />
            </div>
            <div className="mobile-footer big-wrapper">
                <FooterMobile showNewsletterBool={showNewsletter}/>
            </div>
        </div>
    )
}

export default Footer