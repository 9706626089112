import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../css/dashboardCard.css";

function DashCard({cardParams}) {
	const [imageSrc, setImageSrc] = useState(cardParams.image);

	useEffect(() => {}, []);

	const cardBody = (() => {
		if (cardParams.position === null || cardParams.position === "top") {
			return(<div>
				<img src={imageSrc} className={`card-icon-${cardParams.width}`} style={cardParams.style.image}></img>
				<p className={`dashboard-card-title-${cardParams.width} dashboard-card-title`} style={cardParams.style.title}>{cardParams.title}</p>
				<p className={`dashboard-card-subtitle-${cardParams.width} dashboard-card-subtitle`} style={cardParams.style.subtitle}>{cardParams.subtitle}</p>
			</div>);
		} else if (cardParams.position == "bottom") {
			return(<div>
				<p className={`dashboard-card-title-${cardParams.width} dashboard-card-title`} style={cardParams.style.title}>{cardParams.title}</p>
				<p className={`dashboard-card-subtitle-${cardParams.width} dashboard-card-subtitle`} style={cardParams.style.subtitle}>{cardParams.subtitle}</p>
				<img src={imageSrc} className={`card-icon-${cardParams.width}`} style={cardParams.style.image}></img>
			</div>);
		} else if (cardParams.position == "left") {
			return(<div style={{"flexWrap" : "nowrap", "alignItems" : "center"}}>
				<img src={imageSrc} className={`card-icon-${cardParams.width}`} style={cardParams.style.image}></img>
				<div>
					<p className={`dashboard-card-title-${cardParams.width} dashboard-card-title`} style={cardParams.style.title}>{cardParams.title}</p>
					<p className={`dashboard-card-subtitle-${cardParams.width} dashboard-card-subtitle`} style={cardParams.style.subtitle}>{cardParams.subtitle}</p>
				</div>
			</div>);
		} else if (cardParams.position == "right") {
			return(<div style={{"flexWrap" : "nowrap", "alignItems" : "center"}}>
				<div>
					<p className={`dashboard-card-title-${cardParams.width} dashboard-card-title`} style={cardParams.style.title}>{cardParams.title}</p>
					<p className={`dashboard-card-subtitle-${cardParams.width} dashboard-card-subtitle`} style={cardParams.style.subtitle}>{cardParams.subtitle}</p>
				</div>
				<img src={imageSrc} className={`card-icon-${cardParams.width}`} style={cardParams.style.image}></img>
			</div>);
		} else if (cardParams.position == "middle") {
			return(<div>
				<p className={`dashboard-card-title-${cardParams.width} dashboard-card-title`} style={cardParams.style.title}>{cardParams.title}</p>
				<img src={imageSrc} className={`card-icon-${cardParams.width}`} style={cardParams.style.image}></img>
				<p className={`dashboard-card-subtitle-${cardParams.width} dashboard-card-subtitle`} style={cardParams.style.subtitle}>{cardParams.subtitle}</p>
			</div>)
		}
	})();

	return (<div className={`dashboard-card-container col-sm-${cardParams.width} card-size-${cardParams.width}`} onMouseEnter={() => {
		setImageSrc(cardParams.image_hover);
	}} onMouseLeave={() => {
		setImageSrc(cardParams.image);
	}} style={cardParams.style.card}>
		<a href={cardParams.path} className="dashboard-card-styler" style={cardParams.style.card_styler}>
			{cardBody}
		</a>
	</div>)
}

export default DashCard