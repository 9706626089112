import React, { useState, useEffect } from 'react';
import '../css/bookedeventdetails.css';
import { useHistory, useLocation, useParams } from 'react-router';
import Footer from '../components/layout/Footer';
import Header from '../components/layout/Header'
import Main from '../components/layout/Main'
import Sidebar from '../components/layout/Sidebar'
import { useAuth } from '../context/AuthContext';
import { useUser } from '../context/UserContext'
import { getUrl } from '../utils/functions';
import { ReactComponent as Loader } from '../img/loader.svg';
import DeleteBookingContents from '../components/booking_events/DeleteBookingContents';
import StandardBooking from '../components/booking_categories/StandardBooking';
import MinoriBooking from '../components/booking_categories/MinoriBooking';
import ScuoleBooking from '../components/booking_categories/ScuoleBooking';
import ProfessionalBooking from '../components/booking_categories/ProfessionalBooking';
import SmallPopUpHatched from '../components/SmallPopUpHatched';
import SideBarContents from '../components/layout/SideBarContents';


const fetchEvent = async (api, setEventInfo, setLoading, id) => {
    try {
        setLoading(true);
        let data = await api(getUrl("programma", `api/v1/bookable-program-items/${id}`));
        const requests = [];
        const authors = [];
        const exhibitors = [];
        let location;
        requests.push(async () => {
            location = await api(getUrl("programma", `api/v1/locations/${data?.data?.location}`));
        })
        requests.push(...(data?.data?.authors?.map((author) => {
            return async () => {
                const result = await api(getUrl("programma", `api/v1/authors/${author}`));
                authors.push(result);
            }
        })));
        requests.push(...(data?.data?.exhibitors?.map((exhibitor) => {
            return async () => {
                const result = await api(getUrl("programma", `api/v1/exhibitors/${exhibitor}`));
                exhibitors.push(result);
            }
        })));
        await Promise.all(requests.map(async (request) => await request()));
        setLoading(false);
        setEventInfo({
            data: data?.data,
            location: location?.data,
            exhibitors: exhibitors,
            authors: authors,
        });
    } catch (error) {
        setEventInfo({
            data: null,
            location: null,
            exhibitors: null,
            authors: null
        });
        setLoading(false);
    }
}

const fetchBookedEventById = async (api, setBookedEvent, id, userId, isAdmin) => {
    try {
        let url = `bookings/booking/?booking_id=${id}`
        if (userId && isAdmin) {
            url += `&user_id=${userId}`
        }
        let data = await api(getUrl("booking", url));
        setBookedEvent(data?.[0])
    } catch (error) {
        setBookedEvent(null)
    }
}

function BookedEventDetails() {

    const [_, __, userInfo] = useUser();
    const location = useLocation();
    const { evento_id } = useParams(); //id prenotazione: it's in the URL
    const eventId = location?.state?.eventId; //event_id: they comes from state
    const userId = location?.state?.userId; //user_id: they comes from state
	const isAdmin = location?.state?.isAdmin ? true : false // addionational check to see if user is from backoffice
    const back = location?.state?.back;
    const history = useHistory();
    const { api } = useAuth();
    const [eventInfo, setEventInfo] = useState({
        data: null,
        location: null,
        exhibitors: null,
        authors: null
    });
    const [loading, setLoading] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [bookedEvent, setBookedEvent] = useState(null);

    const getDuration = (duration) => {
        if (duration < 60) {
            return `${duration} minuti`;
        }
        let hour = Math.floor(duration / 60);
        let minutes = duration % 60;
        if (minutes === 0) {
            return `${hour} Or${hour === 1 ? "a" : "e"}`
        }
        return `${hour} Or${hour === 1 ? "a" : "e"} ${minutes} minuti`
    }

    const refreshBookedEvent = () => {
        fetchBookedEventById(api, setBookedEvent, evento_id, userId, isAdmin)
    }

    useEffect(() => {
        fetchEvent(api, setEventInfo, setLoading, eventId);
        fetchBookedEventById(api, setBookedEvent, evento_id, userId, isAdmin)
    }, [eventId, evento_id]);


    const getCategoryContent = () => {
        let Component;
        switch (bookedEvent?.type) {
            case "Standard":
                Component = StandardBooking;
                break;
            case "Minori":
                Component = MinoriBooking;
                break;
            case "Scuole":
                Component = ScuoleBooking;
                break;
            case "Professionali":
                Component = ProfessionalBooking;
                break;
            default:
                return;

        }
        return <Component
            bookedEvent={bookedEvent}
			eventData = {eventInfo.data}
			eventTitle={eventInfo?.data?.title}
			isFromBackoffice={location.state.back}
        />;
    }
    const handleBack = () => {
        if (back === true) {
            history.goBack();
        }
    }

    return (
        <div>
            <Header />
            <div className="page-body">
                <Sidebar >
                    <SideBarContents />
                </Sidebar>
				<div className='separating-line'></div>
                <Main>
                    {loading && (<div className="loading fixed"><Loader style={{ width: "130px", height: "130px" }} /> </div>)}
                    {eventInfo?.data != null && (
                        <div className="detailes-booked-event">
							<p style={{"cursor" : "pointer", "color" : "#007bff", "fontWeight" : "500" }} onClick={() => {
								history.goBack();
							}}> {"<  Torna ai miei eventi"}</p>
                            <div className="event-box">
                                <div className="event-title">
                                    <h2>
                                        {eventInfo?.data?.title}
                                    </h2>
									<h2>
                                        {eventInfo?.data?.subtitle}
                                    </h2>
                                </div>
                                <div className="incontro-con">
                                    <h4>
										{eventInfo?.exhibitors?.length > 0 ? ((() => {
											const authorList = eventInfo?.exhibitors?.map((item) => item.data.name)
											return "A cura di " + authorList
										})()) : ""}
                                    </h4>
                                </div>
                            </div>

                            {bookedEvent != null && getCategoryContent()}
                            <div className="buttons-styled">
                                {back === true ? <button className="button-message back" onClick={handleBack}> Torna al Back Office</button> : ""}
                            </div>
                        </div>

                    )}
                    {showModal === true && (<SmallPopUpHatched setShowModal={setShowModal}>
                        <DeleteBookingContents setShowModal={setShowModal} data={bookedEvent}/>
                    </SmallPopUpHatched>)}
                </Main>
            </div>
            <Footer showNewsletter={false} />
        </div>
    )
}

export default BookedEventDetails
