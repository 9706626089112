import Cookies from "js-cookie";
import React from "react";
import salone from "./utils/salone";


class CertificatePageContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      message: "",
      canSeeDownloadLink: this.props.data.status === "PASSWORD VERIFIED" || this.props.data.status === "PASSWORD NO",
    };
  }

  onPasswordChange = (event) => {
    this.setState({ password: event.target.value })
  }

  clickDownload = async (event) => {

    try {
      await salone.get(`/attestati/markasdownloaded?contentId=${this.props.data.id}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      });
    } catch (error) {
      console.error(error.response.data.detail);
      console.error(error);
    }
  }

  verifyPassword = async (event) => {
    try {
      let response = await salone.get(`/attestati/verifypassword?contentId=${this.props.data.id}&password=${this.state.password}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      });
      if (response.data.detail === "Password corretta") {
        this.setState({
          canSeeDownloadLink: true,
          message: ""
        });
        this.props.fetchAttestati();
      }

    } catch (error) {
      console.error(error.response?.data.msg);
      console.error(error)
      if (error.response.data.detail != null) {
        this.setState({ message: error.response.data.detail })
      }

    }
  }


  render() {

    return (
      <div >
        <div className="row" id={this.props.data.id}>
          <div className="col-sm-3 w-100">
            <img
              src={`/static/media/${this.props.data.videoFavicon}`}
              style={{ height: "150px", width: "100%", objectFit: "contain" }}
              alt=""
            />
          </div>
          <div className="col-sm-5">
            <h5 style={{ fontWeight: "bold" }}>{this.props.data.title}</h5>

            <p >{this.props.data.subtitle}</p>
          </div>
          <div className="btn col-sm-3 col-12">
            {this.state.message}
            {(this.state.canSeeDownloadLink) ? (
              <div ><a href={this.props.data?.pdfUrl}
                onClick={(e) => this.clickDownload(e)}
                target="_blank" rel="noreferrer">Attestato.Pdf</a></div>
            ) : (
              <div>
                <label htmlFor="PSW" contentEditable="true" ></label>
                <input
                  value={this.state.password}
                  type="text"
                  style={{ height: "38px", resize: "none" }}
                  placeholder="inserisci password"
                  className="form-control"
                  onChange={this.onPasswordChange} />
                <button className="button blue-btn w-100" onClick={this.verifyPassword}>Invia</button>
              </div>
            )}
          </div>


        </div>
        <br></br>
      </div>
    );
  }
}

export default CertificatePageContent;
