import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useAuth } from '../../context/AuthContext';
import '../../css/addpersonbookingmodal.css';
import { v4 as uuid } from "uuid";
import { getUrl } from '../../utils/functions';

function EventAddPeopleModal({ setShowModal, data, type, refreshBooking }) {

	const [userName, setUserName] = useState();
	const [userSurname, setUserSurname] = useState();
	const [userEmail, setUserEmail] = useState();

	const { api } = useAuth();
    const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
    const [confirmed, setConfirmed] = useState(false);

	const addFriend = async () => {
        if (!(userName && userSurname)) {
            return;
        }
        try {
            const body = { ...data }; // body = {access_number: , event_id: , participants: {id1: {}, id2: {}, ...}, ...}
            body.participants = { ...body.participants }; //participants = {id1: {}, id2: {}, ...}
            const id = uuid();//create a unique random id for new friend

            body.participants[id] = {
                access: "",
				email: userEmail,
                nome: userName,
                cognome: userSurname,
                id: id,
                type: "Friend",
                pdfURL: process.env.REACT_APP_AZURE_BLOB_BASE_URL + `${id}` + ".pdf"
            }
            body.number_of_participants = body.number_of_participants + 1;
            //delete body.type;
            let response = await api(getUrl("booking", "bookings/booking/"), "post", body);
            if (response?.success === false) {
                setError(response?.message?.delete)
            } else {
				setSuccess("Persona aggiunta con successo");
				refreshBooking();
			}
        } catch (error) {
            console.log(error?.response?.data?.detail ?? "Unkown error occured!")
        }
    }

	const addMinor = async () => {
        if (!(userName && userSurname)) {
            return;
        }
        try {
            const body = { ...data };
            const id = uuid();
			const pdfUrl = body.participants[Object.keys(body.participants)[0]].pdfURL

            body.participants[id] = {
                access: "",
                nome: userName,
                cognome: userSurname,
                id: id,
                type: "Minor",
                pdfURL: pdfUrl
            }
            body.number_of_participants = body.number_of_participants + 1;
            //delete body.type;
            let response = await api(getUrl("booking", "bookings/booking/"), "post", body);
            if (response?.success === false) {
                setError(response?.message?.delete)
                return;
            } else {				
				setSuccess("Persona aggiunta con successo");
				refreshBooking();
			}
        } catch (error) {
            setError(error?.response?.data?.detail ?? "Unkown error occured!")
        }
    }

	const onClickCall = () => {
        if(confirmed) return;
        setConfirmed(true);
        
		if (type === "Minori") {
			return addMinor()
		} else if (type === "Standard") {
			return addFriend()
		} else {
			return false
		}
	}

	const eventLimit = (() => {
		if (type === "Minori")
		{
			return "3 minori"
		}			
		else if (type === "Standard") {
			return "1 persona"
		}
	})()

	const minorOrPerson = (() => {
		if (type === "Minori")
		{
			return "dei minori"
		}			
		else if (type === "Standard") {
			return "della persona"
		}
	})()

	return(
        <div className="add-person-booking">
            <div className="add-contents">
                <div className="close-btn-container">
                    <button onClick={() => setShowModal(false)}>&#10006;</button>
                </div>
                <div className="main-text">
                    <h3 className="text">Aggiungi prenotazione</h3>
					<h5 className="text">Puoi aggiungere fino a {eventLimit} a questo evento.</h5>
                </div>
                <div className="add-form">
					<div className='input-container'>
                    	<label>
                    	    Inserisci il nome {minorOrPerson} da aggiungere alla prenotazione
                    	</label>
						<input id="person-name" placeholder="Inserisci il nome..." value={userName} onInput={input => {
							setUserName(input.target.value)
						}}></input>
					</div>
					<div className='input-container'>
						<label>
							Inserisci il cognome {minorOrPerson} da aggiungere alla prenotazione
                    	</label>
						<input id="person-surname" placeholder="Inserisci il cognome..." value={userSurname} onInput={input => {
							setUserSurname(input.target.value)
						}}></input>
					</div>
					{type === "Standard" && (<div className='input-container'>
						<label>
							Inserisci l'email {minorOrPerson} da aggiungere alla prenotazione
                    	</label>
						<input id="person-email" placeholder="Inserisci l'email..." value={userEmail} onInput={input => {
							setUserEmail(input.target.value)
						}}></input>
					</div>)}
                </div>
                {error != null && (
                    <div>
                        <p className="text-danger">{error}</p>
                    </div>
                )}
				{success != null && (
					<div>
						<p className="text-success">{success}</p>
					</div>
				)}
                <div className="add-btn">
                    <button onClick={onClickCall}>Conferma la prenotazione</button>
                </div>
                <div className="back-btn">
                    <button onClick={() => setShowModal(false)}>Torna indietro</button>
                </div>
            </div>
        </div>
	)
}

export default EventAddPeopleModal