import React, { useContext, useState, useRef } from 'react'
import "../../css/LoginModal.css"
import salone_logo from "../../img/modalelogin/logo.png"
import plus_icon from "../../img/modalelogin/waiting 1.png"
import confirmation_image from "../../img/modalelogin/confirmation_modal_nobg.png"
import { useAuth } from '../../context/AuthContext'
import { getUrl } from '../../utils/functions'
import mobileContext from '../../context/MobileContext'
// import newsLetterImage from '../../img/newsletter/SSO_Saltoplus.png'
import newsLetterImage from '../../img/modalelogin/left_image.png'

function LoginModal({ setShowModal, startingPage = 0 }) {

	const [page, setPage] = useState(startingPage);
	const mobileInfo = useContext(mobileContext);
	const [checkboxSaveLogin, setCheckboxSaveLogin] = useState(window.localStorage.loginMail === undefined ? false : true);
	const [savedLogin, setSavedLogin] = useState(window.localStorage.loginMail);
	const [emailInput, setEmailInput] = useState(window.localStorage.loginMail);
	const loginRef = useRef()

	const { api } = useAuth();

	const [errorMessage, setErrorMessage] = useState(false);

	const saveLoginMail = function (userMail) {
		window.localStorage.loginMail = userMail;
	}

	const keyUpLoginHandler = (event)=> {
		if(event.keyCode === 13){
			login()
		}
	}

	const login = async function () {
		const postBody = {
			"user_email": emailInput,
			"user_password": document.getElementById("password-login").value
		}
		let responseLogin;
		try {
			responseLogin = await api(getUrl("user", "auth/login"), "post", postBody);
		} catch (error) {
			if (error?.detail?.error_description === "Account is not fully set up") {
				setPage(1);
				return;
			} else if (error?.detail?.error_description === "Invalid user credentials") {
				setErrorMessage("Credenziali non corrette.");
				return;
			} else {
				setErrorMessage("Errore nella login.");
				return;
			}
		}
		if (checkboxSaveLogin !== false) {
			window.localStorage.loginMail = emailInput;
		}
		document.cookie = "refresh_token=" + responseLogin.refresh_token;
		const path = process.env.REACT_APP_REDIRECT_URI[process.env.REACT_APP_REDIRECT_URI.length - 1] === "/" ? "dashboard" : "/dashboard";
		window.location.href = process.env.REACT_APP_REDIRECT_URI + path;
	}

	const sendConfirmationMail = async function () {
		const postBody = {
			"user_email": document.getElementById("confirmation-mail").value
		}
		const responseSendMail = await api(getUrl("user", "auth/send_confirmation_mail"), "post", postBody);
		setPage(2);
	}

	return (
		<div className='modal-login-container'>
			{mobileInfo.isMobile ? "" : (
				<div className='modal-login-right-container' style={mobileInfo.isMobile ? { "display": "none" } : {}}>
					{<img src={newsLetterImage}></img>}
				</div>
			)}			
			{page === 0 && (<div className='modal-login-page-1 mt-3' style={mobileInfo.isMobile ? { "width": "100%" } : {"overflow" : "hidden"}}>
				<div className="close-btn-container page-1-close-btn">
					<button onClick={() => setShowModal(false)}>&#10006;</button>
				</div>
				<div className='modal-login-top-logo'>
					<img src={salone_logo} alt='logo_salone'></img>
				</div>
				<form className='modal-login-form' ref={loginRef}>
					<label className='modal-login-input-label'>E-mail</label>
					<input type='email'
						className='modal-login-input'
						id='email-login'
						placeholder='Inserisci la tua email...' 
						value={emailInput} 
						onChange={mail => setEmailInput(mail.target.value)}
						onKeyUp={keyUpLoginHandler}></input>
					<label className='modal-login-input-label'>Password</label>
					<input type='password'
						className='modal-login-input'
						id='password-login'
						placeholder='Inserisci la tua password...'
						onKeyUp={keyUpLoginHandler}></input>
					<label className='modal-login-label-ricordami'><input type='checkbox' className='modal-login-checkbox-ricordami' onClick={() => {
						setCheckboxSaveLogin(!checkboxSaveLogin);
					}}></input> Ricordami</label>
				</form>
				{errorMessage !== false && (<div className='modal-login-error'>
					<p className='error-status'>{errorMessage}</p>
				</div>)}
				<div className='modal-login-reset-password-container'>
					<a className='modal-login-reset-password'
						href={process.env.REACT_APP_CHANGE_PASSWORD}
					>
						Password dimenticata?
					</a>
				</div>
				<div className='modal-login-action-container'>
					<button className='modal-login-button' onClick={() => {
						login();
					}}>Accedi</button>
					<div className='modal-login-register-container'>
						<p>Nuovo utente?</p>
						<a className='modal-login-register-link' href={process.env.REACT_APP_REGISTRATION_LINK}>Registrati</a>
					</div>

				</div>
			</div>)}
			{page === 1 && (<div className='modal-login-page-2'>
				<div className="close-btn-container">
					<button onClick={() => setShowModal(false)}>&#10006;</button>
				</div>
				<div className='modal-login-confirmation-header'>
					<div className='modal-login-confirmation-header-image-container'>
						<img src={plus_icon} className='modal-login-confirmation-header-image'></img>
					</div>
					<p className='modal-login-confirmation-header-text'>Dai un'occhiata alla tua mail</p>
				</div>
				<div className='modal-login-confirmation-body'>
					<p className='modal-login-text-email-confirm'>
						Tocca il link nell’email che ti abbiamo inviato.<br />
						Confermando il tuo indirizzo email ci permetti farti proseguire il viaggio in Salto+.<br />
						<b>Ti aspettiamo qui, tranquillo!</b></p>
				</div>
				<div className='modal-login-confirmation-resend'>
					<label className='modal-login-confirmation-resend-label'>Indirizzo mail</label>
					<input id='confirmation-mail' type='email' className='modal-login-confirmation-resend-input' placeholder='mail...'></input>
				</div>
				<div className='modal-login-confirmation-actions'>
					<button className='modal-login-confirmation-actions-button' onClick={() => {
						sendConfirmationMail();
					}}>Invia l'email di nuovo</button>
					<p className='modal-login-confirmation-actions-text' onClick={() => {
						setShowModal(false);
					}}>Non ora</p>
				</div>
			</div>)}
			{page === 2 && (<div className='modal-login-page-3'>
				<div className="close-btn-container">
					<button onClick={() => setShowModal(false)}>&#10006;</button>
				</div>
				<div className='modal-login-loading-header'>
					<img src={confirmation_image} className='modal-login-loading-header-image'></img>
				</div>
				<div className='modal-login-loading-body'>
					<p className='modal-login-loading-body-title'>L'email è stata inviata correttamente!</p>
					<p className='modal-login-loading-body-text'>Vai nella tua mail e verifica il tuo account SalTo+</p>
				</div>
				<div className='modal-login-loading-action'>
					<button className='modal-login-loading-button' onClick={() => {
						setPage(0);
					}}>Prosegui</button>
				</div>
			</div>)}
		</div>
	)
}

export default LoginModal