import React, { useState, useEffect } from 'react'
import Footer from "../components/layout/Footer";
import Sidebar from "../components/layout/Sidebar";
import Main from "../components/layout/Main";
import Header from "../components/layout/Header";
import { useUser } from '../context/UserContext';
import { getUrl } from '../utils/functions';
import { useAuth } from '../context/AuthContext';
import { useHistory } from 'react-router';
import MediaPageSection from '../components/media/MediaPageSection';
import SideBarContents from '../components/layout/SideBarContents';

const fetchSections = async (api, setSections) => {
    try {
        let data = await api(getUrl("media", "media/attributeValueList/section"))
        setSections(data)
    } catch (error) {
        setSections(null)
    }
}

function MediaLibrary() {
    const [_, __, userInfo] = useUser()
    const { api } = useAuth()
    const history = useHistory()
    const [sections, setSections] = useState([])

    useEffect(() => {
        fetchSections(api, setSections)
    }, [])

    return (
        <div>
            <Header />
            {/* <div className="row w-100"> */}
            <div className="page-body">
                <Sidebar>  
                    {/* <p>In questo spazio trovi il meglio della produzione culturale curata dal Salone: video, interviste, lezioni, podcast, approfondimenti. Una grande libreria a tua disposizione: scegli un contenuto e inizia il viaggio.</p>
                    <br />
                    <br /> */}
                    <SideBarContents />
                </Sidebar>
                <div className='separating-line'></div>
                <Main>
                    <h1 className="h0 text-primary main-padding">
                        SalTo Media+
                    </h1>
                    {sections.map((section) => {
                        return <MediaPageSection
                            key={section.value}
                            sectionName={section.value.replace("\"", "").replace("\"", "")}
                        ></MediaPageSection>
                    })}
                </Main>
            </div>
            <Footer />
        </div>
    )
}

export default MediaLibrary
