import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export const getEventType = (data) => {
	if (data?.tags?.find((tag) => tag.id === +process.env.REACT_APP_TAGS_MINORI)) {
		return "Minori";
	} else if (data?.tags?.find((tag) => tag.id === +process.env.REACT_APP_TAGS_SCUOLE)) {
		return "Scuole";
	} else if (data?.tags?.find((tag) => process.env.REACT_APP_TAGS_PROFESSIONALI.split(",").includes(`${tag.id}`))) {
		return "Professional";
	} else {
		return "Standard";
	}
};

function ButtonEvent({ data, bookedEvent, isMobile, onPrenotaClick }) {
	const [eventType, setEventType] = useState(getEventType(data));

	const getAvailableSeats = () => {
		switch (eventType) {
			case "Standard":
				return handleStandardButton();
			default:
				return handleOtherButtons();
		}
	};

	const getMostraEventButton = () => {
		return isMobile ? (
			<div className="responsive">
				<Link
					to={{
						pathname: `/prenotazione/${bookedEvent.id}`,
						state: { eventId: data.id },
					}}
					className="event-book-btn highlighted">
					Mostra l'evento
				</Link>
			</div>
		) : (
			<div className="booked-btn">
				<Link
					to={{
						pathname: `/prenotazione/${bookedEvent.id}`,
						state: { eventId: data.id },
					}}
					className="event-book-btn">
					Mostra l'evento
				</Link>
			</div>
		);
	};

	const getPrenotaButton = (id) => {
			if (onPrenotaClick != null) {
				return isMobile ? (
					<div className="responsive">
						<Link
							to="#"
							onClick={onPrenotaClick}
							className="event-book-btn"
							style={{ padding: "8px 40px" }}>
							Prenota
						</Link>
					</div>
				) : (
					<div className="book-btn">
						<Link
							to="#"
							onClick={onPrenotaClick}
							className="event-book-btn"
							style={{ padding: "8px 40px" }}>
							Prenota
						</Link>
					</div>
				);
			}
			return isMobile ? (
				<div className="responsive">
					<Link
						to={`/evento/${data.id}`}
						className="event-book-btn">
						Prenota l'evento
					</Link>
				</div>
			) : (
				<div className="book-btn">
					<Link
						to={`/evento/${data.id}`}
						className="event-book-btn">
						Prenota l'evento
					</Link>
				</div>
			);
	};

	const getSoldOutButton = () => {
		return isMobile ? (
			<div className="responsive">
				<button
					disabled
					className="event-book-btn sold-out">
					Posti esauriti
				</button>
			</div>
		) : (
			<div className="book-btn">
				<button
					disabled
					className="event-book-btn sold-out">
					Posti esauriti
				</button>
			</div>
		);
	};

	const handleStandardButton = () => {
		let today = new Date();
		let eventDateTime = data.date.replace(/-/g, "/") + " " + data.time;
		let eventDate = new Date(eventDateTime);
		let pre_booking_end = new Date(process.env.REACT_APP_PRE_BOOKING_TIMESTAMP_END);

		const booked_seats = data.booked_places;
		const available_seats = Math.floor((+process.env.REACT_APP_PERCENTAGE_OF_BOOKABLE_PLACES * data.bookable_places) / 100);
		const total_seats = data.bookable_places;

		const isPrenota = booked_seats < available_seats && today.getTime() < pre_booking_end.getTime();

		const isArrivaPresto =
			booked_seats >= available_seats && booked_seats < total_seats && today.getTime() < pre_booking_end.getTime();

		const isPrenotaLastHours =
			booked_seats < available_seats &&
			eventDate.getTime() >= pre_booking_end.getTime() &&
			today.getTime() > eventDate.getTime() - 21600000 &&
			today.getTime() < eventDate.getTime() - 3600000;

		const isArrivaPrestoLastHours =
			booked_seats < available_seats &&
			eventDate.getTime() >= pre_booking_end.getTime() &&
			today.getTime() < eventDate.getTime() - 21600000;

		const isSoldOut = booked_seats >= total_seats || today.getTime() > eventDate.getTime() - 3600000;
		const eventsSoldoutMessage = [10347, 9650, 9645, 9653]

		if (bookedEvent != null) {
			return getMostraEventButton();
		} else if (isPrenota || isPrenotaLastHours || isArrivaPresto || isArrivaPrestoLastHours) {
			return getPrenotaButton(data.id);
		} else if (data.bookable_places === 0) {
			return <p className="not-bookable-event">Evento non prenotabile</p>;
		} else if (data.bookable_places === data.booked_places && data.bookable_places < data.location.capacity) {
			return (
				eventsSoldoutMessage.includes(data.id) ?
					<p className="not-bookable-event">
						Sold out: accesso consentito solo con prenotazione
					</p>
					:
					<p className="not-full-event">
						Se i posti a prenotazione sono esauriti, potrai comunque partecipare all'evento: il giorno dell'incontro mettiti in
						fila, saranno disponibili dei posti ad accesso libero.
					</p>
			);
		} else if (isSoldOut) {
			return getSoldOutButton();
		} else {
			return null;
		}
	};

	const handleOtherButtons = () => {
		if (bookedEvent != null) {
			return getMostraEventButton();
		} else if (data.bookable_places === 0) {
			return <p className="not-bookable-event">Evento non prenotabile</p>;
		} else if (data.bookable_places === data.booked_places && data.bookable_places < data.location.capacity) {
			return (
				eventType === "Scuole" ?
					<p className="not-bookable-event">
						Sold Out
					</p>
					:
					<p className="not-full-event">
						I posti prenotabili sono esauriti, ma potrai comunque partecipare all'evento: il giorno dell'incontro mettiti in fila, saranno disponibili dei posti ad accesso libero.
					</p>
			);
		} else if (data.bookable_places > data.booked_places) {
			return getPrenotaButton(data.id);
		} else {
			return getSoldOutButton();
		}
	};

	return getAvailableSeats();
}

export default ButtonEvent;
