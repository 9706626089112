import React from "react";


class Vimeo extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div
                className="mt-5 video-container"

            >
                <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                    <iframe src={this.props.url} frameborder="0" scrolling="no" allow="autoplay; fullscreen" allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%" }}></iframe> 
                </div>

                <div>
                    <div className="row mt-5">
                        <div className="col-12">
                            <h1 className="yt-title">{this.props.title}</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <h3>{this.props.subtitle}</h3>
                        </div>
                    </div>
                    <div className="row mb-5 pb-5">
                        <div className="col-12">{this.props.description}</div>
                    </div>
                    <div>
                        {this.props.chat == undefined ? <div></div> : <div className="w-100"
                            dangerouslySetInnerHTML={{ __html: this.props.chat }}
                        ></div>}
                    </div>

                    {/* <iframe className="w-100" src={this.props.chat} frameborder="0" allowtransparency="true" height="250"></iframe> */}
                </div>
            </div>
        );
    }
}

export default Vimeo;
