import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import Sidebar from "../components/layout/Sidebar";
import Main from "../components/layout/Main";
import EventCard from '../candidate_events/EventCard';
import { useUser } from '../context/UserContext';
import { ReactComponent as Loader } from "../img/loader.svg";
import { getUrl } from '../utils/functions';
import { useAuth } from '../context/AuthContext';
import SideBarContents from '../components/layout/SideBarContents';


const fetchAvailableEvents = async (api, form_type, setEvents) => {
    try {
        setEvents((state) => ({
            ...state,
            loading: true,
        }));
        let data = await api(getUrl("user", `candidature/getavailableforms/?form_type=${form_type}`))
        setEvents({
            status: true,
            data: data,
            loading: false,
            error: null
        })
    } catch (error) {
        setEvents({
            status: false,
            data: null,
            loading: false,
            error: error.response?.data?.detail ?? "An error occured"
        })
    }
}


function Candidatura() {
    const [events, setEvents] = React.useState({
        status: false,
        data: null,
        loading: false,
        error: null
    });

    const [_, __, userInfo] = useUser()
    const { api } = useAuth()
    let { form_type } = useParams();


    useEffect(() => {
        fetchAvailableEvents(api, form_type, setEvents);
    }, [form_type, setEvents]);

    console.log(events)


    return (
        <div>
            <Header />
            {/* <div className="row w-100 mx-0 mx-md-auto"> */}
            <div className="page-body">
                <Sidebar>
                    {/* <p>In questo spazio trovi il meglio della produzione culturale curata dal Salone: video, interviste, lezioni, podcast, approfondimenti. Una grande libreria a tua disposizione: scegli un contenuto e inizia il viaggio.</p> */}
                    <SideBarContents />
                </Sidebar>
                <div className='separating-line'></div>
                <Main>
                    <div className="main-padding" style={{ display: "flex", flexDirection: "column" }}>
                        {form_type === "candidatura" && (<><h1 className="h0 text-primary" style={{ color: "#1488f3" }}>
                            SalTo Scuola: i progetti del Salone per le scuole e i ragazzi
                        </h1>
                            <p>Candida la tua classe ai progetti del Salone dedicati alle scuole e ai ragazzi.</p></>)}

                        {events.loading === true && (
                            <div className="loading">
                                <Loader />
                            </div>
                        )}
                        {events.error != null && (
                            <h3 className="text-black">{Array.isArray(events.error) ? events.error[0].msg : events.error} <Link to={{
                                pathname: "/userprofile",
                            }}>clicca qui.</Link></h3>
                        )}

                        <div className="wrapper-event">
                            {events.data != null && events.loading === false && Array.isArray(events.data) && (
                                events.data.map((event) => (
                                    <EventCard
                                        title={event.title}
                                        subTitle={event?.subtitle}
                                        code={event.id}
                                        img={event.favicon}
                                        formType={form_type} />
                                )))}

                        </div>



                    </div>
                </Main>

            </div>
            <Footer />
        </div>
    )
}

export default Candidatura
