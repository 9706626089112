import React, { useEffect } from 'react';
import { ReactComponent as VectorIconSelect } from '../../img/icon/select_icon.svg';
import "../../css/multiselect.css";
import { useState } from 'react';
import { useRef } from 'react';


function MultipleSelect({ data, textPreview, className, onChange, defaultValueFromScanQRCode, extraClassName }) {

    const [options, showOptions] = useState(false);
    const [values, setValues] = useState(defaultValueFromScanQRCode?? []);
    const multipleRef = useRef();

    useEffect(() => {
        const handler = (event) => {
            if (multipleRef.current && !multipleRef.current.contains(event.target) && options === true) {
                showOptions(false)
            }
        }
        document.addEventListener("click", handler);
        return () => {
            document.removeEventListener("click", handler)
        }
    }, [options]);

    const sortedData = [...data];
    sortedData.sort((a, b) => {
        if (values.includes(a.id) && values.includes(b.id)) {
            return 0
        }
        if (!values.includes(a.id) && !values.includes(b.id)) {
            return 0
        }
        if (values.includes(a.id) && !values.includes(b.id)) {
            return -1
        }
        return 1;
    })
    // a === b ---> 0; there is no comparison
    // a < b ----> -1; sort a before b
    // a> b -----> 1; sort b before a


    return (
        <div className={`container-multiple-select ${extraClassName}`} ref={multipleRef}>
            <div className={className ?? "preview-multi-select"} onClick={() => showOptions(!options)}>
                <div className="text-preview">
                    {textPreview}
                </div>
                <div className="ml-3">
                    <VectorIconSelect />
                </div>
            </div>
            {options && (
                <div className="options">
                    {sortedData.map((program) => {
                        return <div className={`option ${values.indexOf(program.id) !== -1 ? "selected" : ""}`} key={program.id}
                            onClick={() => {
                                const newvalues = values.indexOf(program.id) !== -1 ? values.filter((value) => value !== program.id) : [...values, program.id];
                                setValues(newvalues);
                                onChange(newvalues);
                                //showOptions(false)
                            }}>{program.name}</div>
                    })}
                </div>
            )}
        </div>
    )
}

export default MultipleSelect
