import React, { useContext, useState } from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import Newsletter from "../shared/Newsletter";
import Arrow from "../img/home/arrow.png";
import incontri from '../img/home/incontri.png'
import servizi from '../img/home/servizi.png'
import contenuti from '../img/home/contenuti.png'
import '../css/home.css';
import PopUpLogin from "../components/PopUpLogin"
import LoginModal from "../components/login_modal/LoginModal";
import homepage_yellow from "../img/home/Imagehomepage_yellow.png";
import lettura from "../img/home/lettura.png";
import insegnamento from "../img/home/insegnamento.png";
import mobileContext from "../context/MobileContext";
import {useAuth} from "../context/AuthContext"

function Home() {
	// const mobileInfo = useContext(mobileContext);
	const [showLogin, setShowLogin] = useState(false);
	const {auth} = useAuth();

	return (
		<div>
			<Header />
			<div className="container-fluid homepage-wrapper">
				<div className="row m-0 flex-md-row-reverse pb-5 mt-4 pt-1">
					<div className="col-12 d-flex col-md-7 row-image-container no-padding">
						<img className="ml-md-auto" src={homepage_yellow} alt="" />
					</div>
					<div className="col-12 col-md-5 align-self-md-center no-padding">
						<div className="row m-0">
							<div className="col-12 p-md-0 no-padding">
								<h1 className="home-ciao">Ciao!<br />Questo è SalTo+</h1>
								<p className="home-text-new">Il tuo nuovo posto preferito. La piattaforma dedicata alle lettrici e ai lettori del
									Salone Internazionale del Libro che del Salone non ne hanno mai abbastanza.</p>
							</div>
						</div>
						<div className="row m-0">
							<div className="col-12 p-md-0 no-padding">
								<a href={process.env.REACT_APP_REDIRECT_LOGIN.replace("{{REDIRECT_URI}}", process.env.REACT_APP_REDIRECT_URI)}>
									<button className="salto-blue-button">Entra in SalTo+</button>
								</a>
							</div>
						</div>
					</div>

				</div>

				<div className="row m-0 py-3 px-md-5">
					<div className="col-12 d-flex">
						<div className="mx-auto d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-around">
							<div className="p-2 p-md-3 d-flex justify-content-center align-items-center">
								<img width="64px" className="mr-3" height="64px" src={incontri} alt="" />
								<div className="d-flex flex-column mr-5">
									<h3>Incontri <br className="d-none d-md-flex" />
										e lezioni</h3>
								</div>
							</div>
							<div className="p-2 p-md-3 d-flex justify-content-center align-items-center">
								<img width="64px" className="mr-3" height="64px" src={servizi} alt="" />
								<div className="d-flex flex-column mr-5">
									<h3>Servizi <br className="d-none d-md-flex" />
										e vantaggi</h3>
								</div>
							</div>
							<div className="p-2 p-md-3 d-flex justify-content-center align-items-center">
								<img width="64px" className="mr-3" height="64px" src={contenuti} alt="" />
								<div className="d-flex flex-column mr-5">
									<h3>Contenuti
										<br className="d-none d-md-flex" /> speciali</h3>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="row m-0 pb-3 flex-md-row-reverse pb-5 mt-4 pt-1">
					<div className="col-12 d-flex col-md-7 row-image-container no-padding">
						<img className="ml-md-auto" src={lettura} alt="lettura png" />
					</div>
					<div className="col-12 col-md-5 align-self-md-center no-padding">
						<div className="row m-0">
							<div className="col-12 p-md-0 no-padding">
								<h1 className="home-ciao">
									Uno spazio per <br />
									lettori e lettrici
								</h1>
								<p className="home-text-new">
									Per chi vuole vivere il Salone 365 giorni l'anno.
									<br /> Video, podcast, approfondimenti. E tanti nuovi servizi pronti per te.
								</p>
							</div>
						</div>
						<div className="entra-in-salto">
							<div className="row m-0">
								<div className="col-12 p-md-0">
								<a href={process.env.REACT_APP_REDIRECT_LOGIN.replace("{{REDIRECT_URI}}", process.env.REACT_APP_REDIRECT_URI)}>
									<button className="salto-blue-button">Entra in SalTo+</button>
								</a>
								</div>
							</div>
						</div>
						<div className="scopri-di-piu">
							<div className="row m-0 mt-1">
								<div className="col-12 no-padding">
									<div className="">										
										<a href={process.env.REACT_APP_REDIRECT_LOGIN.replace("{{REDIRECT_URI}}", process.env.REACT_APP_REDIRECT_URI)}>
											<div className="row m-0">
												<p>Scopri di più</p>
												<div style={{ fontSize: "20px", marginLeft: "10px", color: "#0065F2" }}>
													<img
														src={Arrow}
														alt=""
													/>
												</div>
											</div>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="row m-0 pb-3 flex-md-row-reverse pb-5 mt-4 pt-1">
					<div className="col-12 d-flex col-md-7 row-image-container no-padding">
						<img className="ml-md-auto" src={insegnamento} alt="insegnamento" />
					</div>
					<div className="col-12 col-md-5 align-self-md-center no-padding">
						<div className="row m-0">
							<div className="col-12 p-md-0 no-padding">
								<h1 className="home-ciao">
									Uno spazio per <br />
									docenti e pro
								</h1>
								<p className="home-text-new">Materiali didattici, corsi, vantaggi per la tua classe e per il tuo
									<br /> lavoro. Un solo clic, un nuovo modo di vivere il Salone.</p>
							</div>
						</div>
						<div className="entra-in-salto">
							<div className="row m-0 mt-1">
								<div className="col-12 p-md-0">
									<a href={process.env.REACT_APP_REDIRECT_LOGIN.replace("{{REDIRECT_URI}}", process.env.REACT_APP_REDIRECT_URI)}>
										<button className="salto-blue-button">Entra in SalTo+</button>
									</a>
								</div>
							</div>
						</div>
						<div className="scopri-di-piu">
							<div className="row m-0 mt-1">
								<div className="col-12 no-padding">
									<div className="">										
										<a href={process.env.REACT_APP_REDIRECT_LOGIN.replace("{{REDIRECT_URI}}", process.env.REACT_APP_REDIRECT_URI)}>
											<div className="row m-0">
												<p>Scopri di più</p>
												<div style={{ fontSize: "20px", marginLeft: "10px", color: "#0065F2" }}>
													<img
														src={Arrow}
														alt=""
													/>
												</div>
											</div>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{showLogin && (<PopUpLogin setShowModal={setShowLogin}>
					<LoginModal setShowModal={setShowLogin}></LoginModal>
				</PopUpLogin>)}

				<Newsletter showLoginModal={setShowLogin} />
			</div>
			<Footer />
		</div>
	);
}

export default Home;
