import React, { useState, useEffect, useRef } from "react";
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import Sidebar from "../components/layout/Sidebar";
import Main from "../components/layout/Main";
import { useAuth } from "../context/AuthContext";
import { useUser } from "../context/UserContext";
import { getUrl } from "../utils/functions";
import { ReactComponent as Loader } from "../img/loader.svg";
import Form from "react-bootstrap/Form";
import CheckBoxInput from "../components/inputs/CheckBoxInput";
import TextField from "../components/inputs/TextField";
import SelectInput from "../components/inputs/SelectInput";
import { useHistory } from "react-router";
import { getListOfValueJson, getAllCountries, getCitiesOfState, getStatesOfCountry } from "../utils/data";
import SideBarContents from "../components/layout/SideBarContents";
import getCustomInput from "../utils/customInputsFactory";
import "../css/attestati.css";

const getDefaultValues = (formFields) => {
	const values = {};
	if (formFields !== null) {
		formFields?.forEach((field) => {
			if (field.type === "bool") {
				values[field.code] = field.value ? true : false;
				return;
			}
			values[field.code] = field.value;
		});
	}
	return values;
};

const fetchPageContents = async (api, setData, setLoading, setFieldValues) => {
	try {
		setLoading(true);
		let label = new URLSearchParams(window.location.search).get("attestati_label");
		let data = await api(getUrl("user", "attestati/getstate?attestati_label=" + label));
		if (data?.form?.formFields != null) {
			data?.form?.formfields?.sort((a, b) => {
				// a, b are one of the objects inside the data.form_fields
				if (a.row === b.row) {
					return a.order < b.order ? -1 : 1;
				}
				return a.row < b.row ? -1 : 1;
			});
		}
		setData(data);
		setLoading(false);
		setFieldValues(getDefaultValues(data?.form?.formFields));
	} catch (error) {
		//setLoading(false);
		setData(null);
	}
};

function Attestati() {
	const [_, __, userInfo] = useUser();
	const { api } = useAuth();
	const history = useHistory();
	const formRef = useRef();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState();
	//const [data, setData] = useState(jsonData());
	const [fieldValues, setFieldValues] = useState(getDefaultValues(data?.form?.formFields));
	const [error, setError] = useState(null);
	const [showMessageIntoPopUp, setShowMessageIntoPopUp] = useState(null);

	useEffect(() => {
		fetchPageContents(api, setData, setLoading, setFieldValues);
	}, []);

	const handleOnChange = (event) => {
		setFieldValues((oldValues) => ({ ...oldValues, [event.target.name]: event.target.value }));
	};

	const sortFields = () => {
		const rows = [];
		let items = [];
		let previousRow = undefined;
		for (let i = 0; i < data?.form?.formfields?.length; i++) {
			if (previousRow == null) {
				previousRow = data?.form?.formfields[i].row;
			}
			if (data?.form?.formfields[i].row !== previousRow) {
				rows.push(items);
				items = [];
				previousRow = data?.form?.formfields[i].row;
				items.push(data?.form?.formfields[i]);
			} else {
				items.push(data?.form?.formfields[i]);
			}
		}
		if (items.length > 0) {
			rows.push(items);
		}
		return rows;
	};
	const rows = sortFields();

	const handleOnModalClick = () => {
		switch (data.button.action) {
			case "send_form":
				if (formRef.current.checkValidity()) {
					submitForm();
				} else {
					formRef.current.reportValidity();
				}
				return;
			case "go_to_external":
				window.open(data.button.link, "_blank");
				return;
			case "go_to_internal":
				history.push(data.button.link);
				return;
			case "refresh":
				//refreshPopUpContents();
				return;
		}
	};

	const submitForm = async () => {
		// {"COUNTRY": "1005", ...}, [COUNTRY, STATE, ...]

		let values = {};
		Object.keys(fieldValues).forEach((key) => {
			if (["COUNTRY", "STATE", "CITY"].includes(key)) {
				const element = formRef.current.querySelector(`select[name="${key}"]`);
				if (element) {
					const name = element.querySelector(`option[value="${fieldValues[key]}"]`).getAttribute("data-name");
					values[key] = name;
					return;
				}
			}
			values[key] = fieldValues[key];
		});
		if (!Object.keys(fieldValues).includes("CHECK_DATA_TRANSFER")) {
			values["CHECK_DATA_TRANSFER"] = false;
		}

		let body = data.form.api.body; //"{"birthdate:", ...}", now body is like a string
		Object.keys(values).forEach((key) => {
			body = body.replace(key, values[key]);
		});
		//debugger;
		body = JSON.parse(body);

		try {
			let response = await api(data.form.api.baseurl, data.form.api.method.toLowerCase(), body);
			if (response?.success === false) {
				setShowMessageIntoPopUp(response?.message?.error);
				return;
			} else if (response?.success === true && response?.message?.create_new === "Prenotazione confermata") {
				history.push("/prenotazioni");
				return;
			}
			//refreshPopUpContents();
			setFieldValues({});
			setData(response);
			setError("");
			//onCloseModal(false);
		} catch (error) {
			setError(error?.response?.data?.detail ?? "An error occured by the server");
		}
		//refreshPopUpContents();
	};

	function generateInput(item, data, length) {
		if (item.LOV && (item.LOV.length !== 0 || item.LOV_json)) {
			let options = [];
			if (item.LOV.length !== 0) {
				options = item.LOV.map((option) => {
					return { id: option, name: option };
				});
			} else {
				if (item.LOV_json === "country.json") {
					options = getAllCountries();
				} else if (item.LOV_json === "state.json") {
					let country = data.form.formfields.find((field) => field.LOV_json === "country.json");
					options = getStatesOfCountry(fieldValues[country.code]);
				} else if (item.LOV_json === "city.json") {
					let state = data.form.formfields.find((field) => field.LOV_json === "state.json");
					options = getCitiesOfState(fieldValues[state.code]);
				} else if (item.LOV_json === "profession.json") {
					options = getListOfValueJson("profession.json");
				} else {
					options = getListOfValueJson(item.LOV_json);
				}
			}
			return (
				<SelectInput
					className={length === 2 ? "col-sm-6" : length === 3 ? "col-sm-4" : "col"}
					name={item.code}
					label={item.label}
					required={item.mandatory}
					type="text"
					options={options}
					disabled={!item.editable}
					onChange={handleOnChange}
					value={fieldValues[item.code]}
					//placeholder={`Inserisci ${item.field_label}`}
				/>
			);
		} else if (item.type === "bool") {
			return (
				<CheckBoxInput
					className={length === 2 ? "col-sm-6" : length === 3 ? "col-sm-4" : "col"}
					name={item.code}
					label={item.label}
					required={item.mandatory}
					disabled={!item.editable}
					onChange={(event) => {
						setFieldValues((oldValues) => ({ ...oldValues, [event.target.name]: event.target.checked ? true : false }));
					}}
					value={fieldValues[item.code]}
				/>
			);
		} else {
			return (
				<TextField
					name={item.code}
					placeholder={item.label}
					required={item.mandatory}
					disabled={!item.editable}
					// type={item.type}
					type={item.type === "datetime" ? "date" : "text"}
					onChange={handleOnChange}
					value={fieldValues[item.code]}
				/>
			);
		}
	}

	return (
		<div>
			<Header />
			{/* <div className="row w-100" style={{ minHeight: "68vh" }}> */}
			<div className="page-body">
				<Sidebar>
					{/* <p>In questo spazio trovi il meglio della produzione culturale curata dal Salone: video, interviste, lezioni, podcast, approfondimenti. Una grande libreria a tua disposizione: scegli un contenuto e inizia il viaggio.</p> */}
					<SideBarContents />
				</Sidebar>
				<div className="separating-line"></div>
				<Main>
					{loading && (
						<div className="loading fixed">
							<Loader style={{ width: "130px", height: "130px" }} />{" "}
						</div>
					)}

					<div className="accreditation-page">
						<div className="title">
							<h1>{data?.header?.title}</h1>
						</div>
						<div className="image">
							<img src={data?.header?.img}></img>
						</div>
						<div className="text-body">
							<h4
								className="sottotitolo-header-attestati"
								dangerouslySetInnerHTML={{ __html: data?.header?.body }}></h4>
							{showMessageIntoPopUp != null && <p className="text-uppercase">{showMessageIntoPopUp}</p>}
							{error != null && <p className="text-danger">{error}</p>}
						</div>
						{loading === false && data?.form?.formfields && (
							<Form ref={formRef}>
								{rows.map((row) => {
									return (
										<div className="">
											{row.map((item) => {
												if (!item.object) {
													return generateInput(item, data, row.length);
												}
												const input = generateInput(item, data, row.length);
												const params = {
													input: input,
													title: item.object.params.title,
													checkmarkVisibility: item.object.params.check,
												};
												const component = getCustomInput("attestati", params);
												return component;
											})}
										</div>
									);
								})}
							</Form>
						)}
						{data?.button != null && (
							<div className="button-action mt-3">
								<button
									className="blue-btn"
									onClick={handleOnModalClick}>
									{data?.button?.text}
								</button>
							</div>
						)}
					</div>
				</Main>
			</div>
			<Footer />
		</div>
	);
}

export default Attestati;
