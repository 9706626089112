import React, { useState, useEffect } from 'react'
import Footer from "../components/layout/Footer";
import Sidebar from "../components/layout/Sidebar";
import Main from "../components/layout/Main";
import Header from "../components/layout/Header";
import { useUser } from '../context/UserContext';
import { getUrl } from '../utils/functions';
import { useAuth } from '../context/AuthContext';
import { useHistory, useParams } from 'react-router';
import Content from '../Content';
import SideBarContents from '../components/layout/SideBarContents';


const fetchContentsSection = async (api, setItems, section_name) => {
    try {
        let data = await api(getUrl("media", `media/contentList/?skip=0&limit=100&section=${section_name}`))
        setItems(data)

    } catch (error) {
        setItems(null)
    }
}

function MediaLibrarySection() {
    const [_, __, userInfo] = useUser()
    const { api } = useAuth()
    const history = useHistory()
    let { section_name } = useParams()
    const [items, setItems] = useState([])

    useEffect(() => {
        fetchContentsSection(api, setItems, section_name)
    }, [section_name])
    return (
        <div>
            <Header />
            {/* <div className="row w-100"> */}
            <div className="page-body">
                <Sidebar>
                    {/* <p>In questo spazio trovi il meglio della produzione culturale curata dal Salone: video, interviste, lezioni, podcast, approfondimenti. Una grande libreria a tua disposizione: scegli un contenuto e inizia il viaggio.</p>
                    <br />
                    <br /> */}
                    <SideBarContents />
                </Sidebar>
                <div className='separating-line'></div>
                <Main>
                    <h1 className="h0 text-primary main-padding">
                        {section_name}
                    </h1>
                    <div className="row px-5">
                        {items.map((item, index) => {
                            return <Content
								position={index}
                                key={item.id}
                                id={item.id}
                                title={item.attributes.title}
                                subtitle={item.attributes.subtitle}
                                videoFavicon={item.attributes["video_favicon"]}
                                history={history}
								isSection={true}
								/>
                        })}
                    </div>

                </Main>
            </div>
            <Footer />
        </div>
    )
}

export default MediaLibrarySection
