import Cookies from "js-cookie";
import React from "react";
import salone from "./utils/salone";

class BookingContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      starttime: new Date(this.props.startTimeLive),
      datetime: Date.now(),
      button: <div></div>,
      data: "",
      content: (
        <div className="w-100">
          <div className="py-2 w-100">
            <img
              src={`/static/media/${this.props.videoFavicon}`}
              //src={Barbero}
              style={{ height: "250px", width: "100%", objectFit: "contain" }}
              alt=""
            />
          </div>
          <p className="py-2 p-14">{`Live il ${this.props.startTimeLive?.substring(0, 10)} 
          alle ${this.props.startTimeLive?.substring(11, 16)}`}</p>
          <div className="py-2">
            <h4 >{this.props.title}</h4>
          </div>
        </div>
      ),
    };
  }

  visitClick(e) {
    this.props.history.push(`/videopage/${this.props.id}`)
  }

  bookingClick(e) {
    salone
      .get(`/prenotazione/book/${e.target.id}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      })
      .catch((error) => {
        console.log(error.response?.data.detail);
        this.props.history.push("/login");
      })

      .then((response) => {
        this.setState({
          data: response?.data,
          button: <button className="button white-btn w-100">Prenotato</button>,
        });
      })
      .then(() => {
        window.location.reload();
      });
  }

  unbookingClick(e) {
    salone
      .get(`/prenotazione/unbook/${e.target.id}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      })
      .catch((error) => {
        console.log(error.response?.data.detail);
        this.props.history.push("/login");
      })

      .then((response) => {
        this.setState({
          data: response?.data,
          button: (
            <button
              className="button blue-btn w-100"
              id={this.props.id}
              onClick={(e) => this.bookingClick(e)}
            >
              Prenota
            </button>
          ),
        });
        console.log(this.state.data);
      })
      .then(() => {
        window.location.reload();
      });
  }

  componentDidMount() {

    if (this.props.booked) {
      this.setState({
        button: (
          <button
            className="button white-btn w-100"
            id={this.props.id}
            onClick={(e) => this.visitClick(e)}
          >
            Guarda
          </button>
        ),
        content: (
          <div className="w-100">
            <div className="py-2 w-100" onClick={(e) => this.visitClick(e)}>
              <img
                src={`/static/media/${this.props.videoFavicon}`}
                //src={Barbero}
                style={{
                  height: "250px",
                  width: "100%",
                  objectFit: "contain",
                }}
                alt=""
              />
            </div>
            <p className="py-2 p-14">{`Live il ${this.props.startTimeLive?.substring(
              0,
              10
            )} alle ${this.props.startTimeLive?.substring(11, 16)}`}</p>
            <div className="py-2">
              <h4>{this.props.title}</h4>
            </div>
          </div>
        ),
      });
    } else if (!this.props.booked && this.state.starttime > this.state.datetime) {
      if (this.props.available > 0) {
        this.setState({
          button: (
            <button
              className="button blue-btn w-100"
              id={this.props.id}
              onClick={(e) => this.bookingClick(e)}
            >
              Prenota
            </button>
          ),
        });
      } else {
        this.setState({
          button: (
            <div></div>
          ),
        });
      }
    }
  }

  render() {
    return (
      <div className="col-12 col-md-4" key={this.props.id}>
        <div className="d-flex align-items-start flex-column h-100">
          {this.state.content}

          <p className="p-16">{this.props.subtitle}</p>
          <div className="mt-auto py-2 w-100">
            {this.state.button}
          </div>
        </div>{console.log(this.state.datetime)}
      </div>
    );
  }
}

export default BookingContent;
