import React from "react";
import '../css/home.css';

function Newsletter(props) {
    return (
        <div className="row mb-4">
            <div className="col-lg-5 col-md-6 col-12 d-flex flex-column justify-content-center no-padding">
                <h1 className="home-ciao newsletter-heading">C'è anche una <br /> bella newsletter.</h1>

            </div>
            <div className="col-lg-7 col-md-6 col-12 px-md-5">
                <div className="row">
                    <div className="col-12 p-md-5 d-flex justify-content-center no-padding">
                    {/* <a href={process.env.REACT_APP_REDIRECT_LOGIN.replace("{{REDIRECT_URI}}", process.env.REACT_APP_REDIRECT_URI)}> */}
						<a href="https://www.salonelibro.it/home-sotto-sezione-news-2/rimaniamo-in-contatto.html">
							<button className="salto-blue-button">Iscrivimi!</button>
						</a>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Newsletter;
