import React from 'react'
import { useParams } from 'react-router';
import CurrentAccessInfo from './CurrentAccessInfo';

function ProfessionalValidation({ data }) {

    const participants = Object.keys(data.participants)
        .map((participantKey) => data.participants[participantKey]); //participants=[{}, {}, ...]


    const { participant_id } = useParams()
    const currentAccess = (participants.filter((participant) => participant.id === participant_id))[0];

    return (
        <div className="container-success-message">
            {currentAccess != null && <CurrentAccessInfo
                currentAccess={currentAccess}
            />}

        </div>
    )
}

export default ProfessionalValidation
