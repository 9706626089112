import React, { useState } from 'react';
import { ReactComponent as Export } from '../../img/icon/export_table.svg';
import DownloadPDF from '../../img/icon/download_pdf.svg';
import { ReactComponent as Trash } from '../../img/icon/trash.svg';
import swal from 'sweetalert';
import { downloadBlobFile, generateFileName, getUrl } from '../../utils/functions';
import { useAuth } from '../../context/AuthContext';
import { ReactComponent as Loader } from "../../img/loader.svg";
import SelectInput from '../inputs/SelectInput';


function BuoniTable({ data, page, searchUsersCoupon, recordsNumber, bodyForExportTable, valuePerPage, setValuePerPage }) {
    const { api } = useAuth()
    const [blobFileLoading, setBlobFileLoading] = useState(false)


    const requestBuonoDaLeggere = async (userId) => {
        try {
            let response = await api(getUrl("coupon_admin", `backoffice_coupon/new_coupon_backoffice?user_id=${userId}`), "post", {})
            if (response?.success === true) {
                swal({
                    title: "Richiesta di creazione buono inviata.",
                    tex: "",
                    icon: "success"
                })
                searchUsersCoupon()
            }
        } catch (error) {

        }
    }

    const deleteBuonoDaLeggere = async (couponId) => {
        swal({
            title: "Sei sicuro di voler eliminare questo buono?",
            text: "",
            icon: "warning",
            buttons: ["NO", "Si"]
        })
            .then(async (value) => {
                if (value == null) {
                    return;
                }
                try {
                    let response = await api(getUrl("coupon_admin", `backoffice_coupon/delete_coupon_backoffice?coupon_id=${couponId}`), "post", {})
                    if (response?.success === true) {
                        swal({
                            title: "Richiesta di eliminazione buono inviata.",
                            tex: "",
                            icon: "success"
                        })
                        searchUsersCoupon()
                    }
                } catch (error) {

                }
            })
    }

    const exportXslFile = () => {
        delete bodyForExportTable.limit
        delete bodyForExportTable.offset

        var config = {
            service: 'coupon_admin',
            method: 'post',
            url: "report/download/coupon",
            headers: {
                'Content-Type': 'application/json'
            },
            data: bodyForExportTable,
        };
        let prefix = "Export buono da leggere" 
        downloadBlobFile(api, config, generateFileName(prefix), setBlobFileLoading)

    }
    const handleOnChange = (event) => {
        setValuePerPage(oldValues => ({ ...oldValues, [event.target.name]: event.target.value }))
    }
    return <div className='row mt-2 buono-list'>
        <SelectInput
            className="col-md-4"
            name="limit"
            required={false}
            options={[
                { id: 5, name: 5 },
                { id: 10, name: 10 },
                { id: 25, name: 25 },
                { id: 50, name: 50 }
            ]}
            placeholder="inserimento numero risultati in pagina"
            emptyOption={true}
            onChange={handleOnChange}
            value={valuePerPage?.limit}
        />
        <div className="records-export">
            <div className='records-find'>{recordsNumber} risultati</div>
            <Export className={`export-table ${blobFileLoading ? "downloading" : ""}`}
                data-toggle="tooltip"
                data-placement="top"
                title="Export"
                onClick={exportXslFile}
            />
        </div>
        {blobFileLoading && <div className="loading fixed" >
            <Loader />
        </div>}
        <table className="table table-striped">
            <thead>
                <tr>
                    <th>N.</th>
                    <th>Nome</th>
                    <th>Cognome</th>
                    <th>Email</th>
                    <th>Codice buono</th>
                    <th>Validato</th>
                    <th>Scarica</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {data.map((row, index) => {
                    return <tr>
                        <td>{index + 1 + ((page - 1) * (valuePerPage?.limit ?? 5))}</td>
                        <td>{row.nome}</td>
                        <td>{row.cognome}</td>
                        <td>{row.email}</td>
                        <td>{row.code}</td>
                        <td>{row.valid ? "No" : (row.valid === false ? "Si" : "")}</td>
                        <td>
                            {row.pdfURL && (
                                <a href={row.pdfURL} download>
                                    <img
                                        src={DownloadPDF}
                                        className="download-buono"
                                        alt="download coupon" />
                                </a>
                            )}
                        </td>
                        <td>
                            {row.code ? (row.valid ? (
                                <span className="delete-buono"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Cancella il buono da leggere non utilizzato."><Trash
                                        onClick={() => deleteBuonoDaLeggere(row.id)}
                                    />
                                </span>
                            ) : "") : (<span className="request-buono"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Richiedi il buono da leggere"
                                onClick={() => requestBuonoDaLeggere(row.user_id)}
                            >Richiedi buono</span>)}
                        </td>
                    </tr>

                })}
            </tbody>
        </table>
    </div>;
}

export default BuoniTable;
