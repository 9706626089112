import React from 'react';
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import Sidebar from "../components/layout/Sidebar";
import Main from "../components/layout/Main";
import { useUser } from '../context/UserContext';
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import "../css/message.css";
import SideBarContents from '../components/layout/SideBarContents';



function Message() {

    const [_, __, userInfo] = useUser()
    const history = useHistory();
    const location = useLocation();
    const message = location.state?.message;
    const type = location.state?.type;
    const back = location.state?.back;
    const link = location.state?.link;
    const label = location.state?.label;
    const src = location.state?.img;


    const handleBack = () => {
        if (back === true) {
            history.goBack();
        }
    }

    const handlepush = () => {
        if (label != null && link != null) {
            history.push(link);
        }
    }

    const Text = styled.p`
        text-align: center;
        font-size: 30px;
        &.confirm {
            color: black;
            font-weight: 400;
        }
        &.error {
            color: red;
            font-weight: 400;
        }
    `


    return (
        userInfo == null ? (<div>
            <Header />
            <div className="col-lg-12 my-5">
                <div className="main-padding w-100" >

                    <Text className={type}>{message}</Text>
                    <div className="w-100 text-center my-3">
                        <img className="img-fluid" src={src} alt=""/>
                    </div>
                    <div className="buttons-styled">
                        {back === true ? <button className="button-message back" onClick={handleBack}> Torna alla pagina</button> : ""}
                        {(label != null && link != null) ? <button className="button-message" onClick={handlepush}>{label}</button> : ""}
                    </div>

                </div>
            </div>
            <Footer />
        </div>

        ) : (
            <div>
                <Header />
                {/* <div className="row w-100 mx-0 mx-md-auto"> */}
                <div className="page-body">
                    <Sidebar>
                        {/* <p>In questo spazio trovi il meglio della produzione culturale curata dal Salone: video, interviste, lezioni, podcast, approfondimenti. Una grande libreria a tua disposizione: scegli un contenuto e inizia il viaggio.</p> */}
                        <SideBarContents />
                    </Sidebar>
                    <div className='separating-line'></div>
                    <Main>
                        <div className="main-padding" style={{ display: "flex", flexDirection: "column" }}>

                            <Text className={type}>{message}</Text>
                            <div className="w-100 text-center my-3">
                                <img className="img-fluid" src={src} alt=""/>
                            </div>
                            <div className="buttons-styled">
                                {back === true ? <button className="button-message back" onClick={handleBack}> Torna alla pagina</button> : ""}
                                {(label != null && link != null) ? <button className="button-message" onClick={handlepush}>{label}</button> : ""}
                            </div>

                        </div>
                    </Main>

                </div>
                <Footer />
            </div>
        )
    )
}

export default Message;
