import React from "react";

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      button: <div></div>,
	  inlineWidth : window.innerWidth < 520 ? "23.333333%" : "83.333333%",
	  cardStyle : this.props.isSection ? (this.props.index % 2 ? { "cursor": "pointer", "boxShadow" : "0px 0px 15px rgb(0 0 0 / 14%)" } : (window.innerWidth < 520 ? { "cursor": "pointer", "boxShadow" : "0px 0px 15px rgb(0 0 0 / 14%)", "margin":"0 10px 16px 0", "maxWidth" : "83.333333%" } : { "cursor": "pointer", "boxShadow" : "0px 0px 15px rgb(0 0 0 / 14%)", "margin":"0 10px 16px 0", "maxWidth" : "23.333333%" })) : {"cursor" : "pointer"}
    }

    this.VideoOpen = this.VideoOpen.bind(this);
  }

  VideoOpen() {
    this.props.history.push(`/VideoPage/${this.props.id}`);
  }


  render() {

    return (
      <div className="col-md-4 offset-md-0 offset-1 col-10" key={this.props.id} onClick={this.VideoOpen} style={this.state.cardStyle}>
        <div className="d-flex align-items-start flex-column h-100">
          <div className="py-2 w-100">
            <img
              src={`/static/media/${this.props.videoFavicon}`}
              //src={Barbero}
              style={{ height: "250px", width: "100%", objectFit: "contain" }}
              alt=""
            />
          </div>
          <div className="title-subtitle-continer">
            <div className="py-2">
              <h4>
                {this.props.title}
              </h4>
            </div>
            {/* <div className="py-2 w-100">
              <p className="p-16">
                {this.props.subtitle}
              </p>
            </div> */}
          </div>
        </div>
      </div>
    )
  }
}

export default Content;