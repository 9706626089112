import React, { useState, useEffect, useRef, Component } from 'react'
import { useHistory, Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import '../../css/bookedevent.css'
import { getUrl } from '../../utils/functions';
import { colors, getDuration, StripedColor } from './EventComponent';
import { ReactComponent as NotificationBell } from "../../img/icon/notification.svg"
import DeleteParticipantBooking from '../booking_events/DeleteParticipantBooking';
import EventCard from './EventCard';

//import { ReactComponent as Loader } from '../../img/loader.svg';

const fetchEvent = async (api, setEventInfo, setLoading, id, bookingId, addToFullData) => {
    try {
        setLoading(true);
        let data = await api(getUrl("programma", `api/v1/bookable-program-items?filter[id]=${id}&filter[trashed]=with`));
        const requests = [];
        const authors = [];
        const exhibitors = [];
        let location;
        requests.push(async () => {
            location = await api(getUrl("programma", `api/v1/locations/${data?.data?.[0]?.location}`));
        })

        requests.push(...(data?.data?.[0]?.authors?.map((author) => {
            return async () => {
                const result = await api(getUrl("programma", `api/v1/authors/${author}`));
                authors.push(result);
            }
        })));
        requests.push(...(data?.data?.[0]?.exhibitors?.map((exhibitor) => {
            return async () => {
                const result = await api(getUrl("programma", `api/v1/exhibitors/${exhibitor}`));
                exhibitors.push(result);
            }
        })));

        await Promise.all(requests.map(async (request) => await request()));
        setLoading(false);
        setEventInfo({
            data: data?.data?.[0],
            authors: authors,
            location: location?.data,
            exhibitors: exhibitors
        });
		addToFullData(bookingId, {
            data: data?.data?.[0],
            authors: authors,
            location: location?.data,
            exhibitors: exhibitors
        });
    } catch (error) {
        setLoading(false);
        setEventInfo({
            data: null,
            authors: null,
            location: null,
            exhibitors: null
        });
		addToFullData(bookingId, {
            data: null,
            authors: null,
            location: null,
            exhibitors: null
        });
    }
}

function BookedEvent({ eventId, bookedIdEvent, bookedEventDetails, size ,refreshBooking, newNotification, addToFullData }) {

    const { api } = useAuth();
    const descriptionRef = useRef();
    const [showMore, setShowMore] = useState(false);
    const [eventInfo, setEventInfo] = useState({
        data: null,
        authors: null,
        location: null,
        exhibitors: null
    });
    const [loading, setLoading] = useState(null);
    const [color, setColor] = useState(colors.booked);

	var eventCardData;

    useEffect(() => {
        fetchEvent(api, setEventInfo, setLoading, eventId, bookedIdEvent, addToFullData);
    }, [eventId]);

    const getEventType = (data) => {
        if (data?.tags?.find((tag) => tag === +process.env.REACT_APP_TAGS_MINORI)) {
            return "Minori"
        } else if (data?.tags?.find((tag) => tag === +process.env.REACT_APP_TAGS_SCUOLE)) {
            return "Scuole"
        } else if (data?.tags?.find((tag) => (process.env.REACT_APP_TAGS_PROFESSIONALI).split(",").includes(`${tag}`))) {
            return "Professional"
        } else {
            return "Standard"
        }
    }
    const getColorForEvent = (data, bookedEvent) => {
        let color = colors.unbooked;
        const eventType = getEventType(data);

        if (bookedEvent) {

            let snoozeTimeStamp = bookedEvent.last_snooze_timestamp.includes("+") ?  new Date(bookedEvent.last_snooze_timestamp.split("+")[0]) :  new Date(bookedEvent.last_snooze_timestamp)
            let updatedAtTime = new Date(data.updated_at)

            if (data.deleted_at !== "" && data.deleted_at != null) {
                color = colors.deleted
				newNotification(data, "deleted");
            } else if ((data.deleted_at == null || data.deleted_at === "") && updatedAtTime.getTime() >= snoozeTimeStamp.getTime()) {
                color = colors.snooze
				newNotification(data, "updated");
            } else if ((data.deleted_at == null || data.deleted_at === "")) {
                color = colors.booked
            }
        }
        return color;
    }

    useEffect(() => {
        if (eventInfo?.data == null) {
            return
        }
        const newColor = getColorForEvent(eventInfo?.data, bookedEventDetails)
        if (newColor !== color) {
            setColor(newColor)

        }
    }, [eventInfo?.data?.id])

    useEffect(() => {
        if (descriptionRef.current?.querySelector("span")) {
            descriptionRef.current?.querySelector("span").addEventListener("click", () => {
                setShowMore(!showMore)
            });
        }
    })

    const snoozeTimeStamp = async () => {
        try {
            let response = await api(getUrl("booking", `bookings/snoozeTimestamp/?booking_id=${bookedIdEvent}`), "post")
            const eventType = getEventType(eventInfo?.data);
            if (eventType === "Scuole") {
                setColor(colors.school)
                return
            }
            setColor(colors.booked)
        } catch (error) {

        }
    }
    if (loading) {
        return null
    }
	
	//! Data
	eventCardData = {
		size : size,
		color: color,
		data_evento : (new Date(eventInfo?.data?.date)).toLocaleString('it-IT', {day : "numeric", month: 'long'}),
		ora : eventInfo?.data?.time,
		luogo_sala : eventInfo?.location?.name,
		citta_padiglione : eventInfo?.location?.address,
		titolo : eventInfo?.data?.title,
		sottotitolo : (() => {
			return eventInfo?.exhibitors?.map((exhibitor) => exhibitor.data.name).join(", ")
		})(),
		bookedIdEvent : bookedIdEvent,
		eventId : eventId
	}
     return (
		<EventCard {...eventCardData} fullBookingData = {bookedEventDetails} refreshBooking = {refreshBooking} snooze={snoozeTimeStamp}></EventCard>
    )
}

export default BookedEvent
