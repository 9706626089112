import React, { useContext, useEffect } from 'react';
import { ReactComponent as VectorIconSelect } from '../../img/icon/select_icon.svg';
import "../../css/multiselect.css";
import { useState } from 'react';
import { useRef } from 'react';
import { DateTime, Settings} from "luxon";
import CustomPopUp from '../CustomPopUp';
import AdapterLuxon from '@mui/lab/AdapterLuxon'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { TimePicker } from '@mui/lab';
import { TextField } from '@mui/material';
import mobileContext from '../../context/MobileContext';
Settings.defaultLocale = "it";

function ShowingSelect({ data, textPreview, type, className, onChange, extraClassName, values, setValues }) {

    const multipleRef = useRef();
	const [showModal, setShowModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);

	let startingTime = "";
	let endingTime = "";

    useEffect(() => {
    }, []);

	const appendToValues = function(newValue) {
		const currentValues = values;
		currentValues.push(newValue);
		setValues(currentValues);
	}

	const removeFromValues = function(valueToRemove) {
		const currentValues = values;
		currentValues.forEach((singleValue, index) => {
			if (singleValue === valueToRemove) {
				currentValues.splice(index, 1);
			}
		})
		setValues(currentValues);
	}

	let minTimeHoursStart = 0;
	let minTimeHoursEnd = 0;
	let maxTimeHoursStart = 11;
	let maxTimeHoursEnd = 11;

	const modalStyle = {
		"justifyContent" : "initial"
	}
	const modalBoxStyle = {
		"maxWidth" : "initial",
		"width" : "280px",
		"minWidth" : "initial",
		"margin" : "auto"
	}
	const modalBodyStyle = {
		"height" : "100%"
	}

    return (
        <div className={`container-multiple-select ${extraClassName}`} ref={multipleRef}>
            <div className={className ?? "preview-multi-select"} onClick={() => setShowModal(true)}>
                <div className="text-preview" data-default-value={textPreview}>
                    {values.length === 0 ? textPreview : values.map((singleValue, index) => {
						const date = DateTime.fromFormat(singleValue, "yyyy-MM-dd");
						if (date.isValid) {
							return date.toFormat("dd LLL") + (index === values.length - 1 ? "" : " - ");
						} else {
							return singleValue + (index === values.length - 1 ? "" : " - ");
						}
					})}
                </div>
                <div className="ml-3">
                    <VectorIconSelect />
                </div>
            </div>
			{showModal ? (
				<CustomPopUp
					setShowModal={setShowModal}
					customModalStyle={modalStyle}
					customBoxStyle={modalBoxStyle}
					customBodyStyle={modalBodyStyle}
				>
					<div className='showing-select-modal-container'>
						<div className='showing-select-header'>
							<div className="close-btn-container page-1-close-btn">
                    			<button onClick={() => setShowModal(false)}>&#10006;</button>
                			</div>
						</div>
						<div className='showing-select-body'>
							{type === "date" && <div>
							<p className='showing-select-title' onClick={(event) => {								
								event.target.classList.toggle("selected-option");
								setValues([]);
							}}>Qualunque giorno</p>
							<div className='separe-container'>
								<div></div>
								<p style={{"display" : "inline"}}>oppure</p>
								<div></div>
							</div></div>}
							{type === "date" && data.map((singleValue, index) => {
								return <p key={index} className={'showing-select-options ' + (values.indexOf(singleValue.id) !== -1 ? "selected-option" : "")} onClick={(event) => {
									if (values.length <= 1) {
										setErrorMessage(false);
										if (!event.target.classList.contains("selected-option")) {
											appendToValues(singleValue.id);
											event.target.classList.add("selected-option");
										} else {
											removeFromValues(singleValue.id);
											event.target.classList.remove("selected-option");
										}
									} else {
										if (!event.target.classList.contains("selected-option")) {
											setErrorMessage("Seleziona al massimo 2 opzioni.");
										} else {
											removeFromValues(singleValue.id);
											event.target.classList.remove("selected-option");
											setErrorMessage(false);
										}
									}
                    	            onChange(values);
								}}>{singleValue.name}</p>
							})}
							{type === "time" && (<div>
								<p className='showing-select-title'>Imposta il periodo di tempo desiderato</p>
								<button className='showing-select-cancel' onClick={() => {									
									startingTime = "";
									endingTime = "";
									setValues([]);
								}}>Qualunque ora</button>
								<div className='separe-container'>
									<div></div>
									<p style={{"display" : "inline"}}>oppure</p>
									<div></div>
								</div>
								<div className='showing-select-time-input-container'>
									<p className='showing-select-time-title'>Orario di inizio</p>
									<div className='showing-select-time-input-row first-row'>
										<input id='start-hour' type='number' onBlur={(event) => {
											if (parseInt(event.target.value) > maxTimeHoursStart) {
												event.target.value = maxTimeHoursStart;
											} else if (parseInt(event.target.value) < minTimeHoursStart) {
												event.target.value = minTimeHoursStart;
											}
											if (event.target.value.length > 2) {
												event.target.value = event.target.value.slice(0,2)
											}
										}}></input>
										<p> : </p>
										<input id='start-minute' type='number' onBlur={(event) => {
											if (parseInt(event.target.value) > 59) {
												event.target.value = 59;
											} else if (parseInt(event.target.value) < 0) {
												event.target.value = 0;
											}
											if (event.target.value.length > 2) {
												event.target.value = event.target.value.slice(0,2)
											}
										}}></input>
										<div className='showing-select-time-ampm'>
											<button className='showing-select-am select-interval' id='start-am-interval' onClick={(event) => {
												maxTimeHoursStart = 11;
												minTimeHoursStart = 0;
												document.getElementById("start-am-interval").classList.add("select-interval");
												document.getElementById("start-pm-interval").classList.remove("select-interval");
											}}>AM</button>
											<button className='showing-select-pm' id='start-pm-interval'  onClick={(event) => {
												maxTimeHoursStart = 22;
												minTimeHoursStart = 12;
												document.getElementById("start-am-interval").classList.remove("select-interval");
												document.getElementById("start-pm-interval").classList.add("select-interval");
											}}>PM</button>
										</div>
									</div>
									<p className='showing-select-time-title'>Orario di fine</p>
									<div className='showing-select-time-input-row second-row'>
										<input id='end-hour' type='number' onBlur={(event) => {
											if (parseInt(event.target.value) > maxTimeHoursEnd) {
												event.target.value = maxTimeHoursEnd;
											} else if (parseInt(event.target.value) < minTimeHoursEnd) {
												event.target.value = minTimeHoursEnd;
											}
											if (event.target.value.length > 2) {
												event.target.value = event.target.value.slice(0,2)
											}
										}}></input>
										<p> : </p>
										<input id='end-minute' onBlur={(event) => {
											if (parseInt(event.target.value) > 59) {
												event.target.value = 59;
											} else if (parseInt(event.target.value) < 0) {
												event.target.value = 0;
											}
											if (event.target.value.length > 2) {
												event.target.value = event.target.value.slice(0,2)
											}
										}}></input>
											<div className='showing-select-time-ampm'>
												<button className='showing-select-am select-interval' id='end-am-interval'  onClick={(event) => {
													maxTimeHoursEnd = 11;
													minTimeHoursEnd = 0;
													document.getElementById("end-am-interval").classList.add("select-interval");
													document.getElementById("end-pm-interval").classList.remove("select-interval");
												}}>AM</button>
												<button className='showing-select-pm' id='end-pm-interval'  onClick={(event) => {
													maxTimeHoursEnd = 22;
													minTimeHoursEnd = 12;
													document.getElementById("end-am-interval").classList.remove("select-interval");
													document.getElementById("end-pm-interval").classList.add("select-interval");
												}}>PM</button>
										</div>
									</div>
								</div>
							</div>)
							}
						</div>
						<div className='showing-select-footer'>
							{errorMessage && <div className='showing-select-error'>
								<p>{errorMessage}</p>
								</div>}
							<div className='showing-select-actions'>
								<button className='showing-select-close' onClick={() => {
									setValues([]);
									setShowModal(false);
                    	            onChange([]);
								}}>Chiudi</button>
								<button className='showing-select-confirm' onClick={() => {								
									setShowModal(false);

									if (type === "time") {
										setValues([]);
										let startHour = document.getElementById("start-hour").value !== '' ? document.getElementById("start-hour").value : "08";
										startHour = startHour.length < 2 ? "0" + startHour : startHour;
										let startMinute = document.getElementById("start-minute").value !== '' ? document.getElementById("start-minute").value : "00";
										startMinute = startMinute.length < 2 ? "0" + startMinute : startMinute;

										let endHour = document.getElementById("end-hour").value !== '' ? document.getElementById("end-hour").value : "23";
										endHour = endHour.length < 2 ? "0" + endHour : endHour;
										let endMinute = document.getElementById("end-minute").value !== '' ? document.getElementById("end-minute").value : "00";
										endMinute = endMinute.length < 2 ? "0" + endMinute : endMinute;

										const completeTime = [startHour + ":" + startMinute, endHour + ":" + endMinute];
										setValues(completeTime);
										onChange(completeTime);
									} else {
										onChange(values);
									}
								}}>Ok</button>
							</div>
						</div>
					</div>
				</CustomPopUp>
			) : ""}
        </div>
    )
}

export default ShowingSelect
