import React from "react";
import { AzureMP } from "react-azure-mp";

class AzureVideo extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div
            className=""
            style={{
              overflow: "hidden",
              margin: "0",
            }}
          >
                <AzureMP
                    skin="amp-flush"
                    src={[
                        {
                            src: this.props.url,
                            type: "application/vnd.ms-sstr+xml",
                        },
                    ]}
                />
                <div className="row mt-5">
                    <div className="col-12">
                        <h1 className="yt-title">{this.props.title}</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h3>{this.props.subtitle}</h3>
                    </div>
                </div>
                <div className="row mb-5 pb-5">
                    <div className="col-12">{this.props.description}</div>
                </div>
                <div>
                    {this.props.chat == undefined || this.props.chat === "" ? <div></div> : <iframe src={this.props.chat} frameborder="0" allowtransparency="true" height="250"></iframe> }
                </div>
            </div>
        );
    }
}

export default AzureVideo;
